import { black, bosColor, secondary, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const DigitalSigningModalStyled = styled.div`
  .number-labels {
    span {
      padding-bottom: 1.25rem;
    }
  }
  svg {
    width: 22px;
    height: 22px;
    path {
      fill: ${secondary};
    }
  }
  button {
    .lds-ellipsis div {
      background: ${black};
    }
  }
`;
