import styled, { css } from 'styled-components';
import {
  backgroundSite,
  wizardPageStyle,
  appContentNewMaxWidth,
  padding,
  fontWeightBold,
  typography,
  lightGray,
  bosColor,
  margin,
} from '@next-components/common-styles';

interface IStyledCardProps {
  isCustom?: boolean;
}

export const VehicleAccessoriesPageStyled = styled.div`
  ${wizardPageStyle};
  max-width: ${appContentNewMaxWidth};
  height: min-content;
  background-color: ${backgroundSite};
  position: relative;
  ${padding['40'].y};
  ${padding['0'].x};

  div.leftContainer > h2 {
    font-size: 1.5rem;
    font-weight: ${typography.weight.semibold};
    line-height: 3rem;
    border-bottom: 1px solid ${lightGray};
    padding-bottom: 1.875rem;
  }
  h1 {
    font-weight: 600 !important;
  }
  a {
    pointer-events: none;
  }
  button span.label {
    font-weight: ${fontWeightBold};
    font-size: 1.125rem;
  }
`;

const gridStyle = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20.625rem, auto));
  gap: 1rem 2rem;
  justify-content: start;
`;

export const TireSetsStyled = styled.div`
  ${gridStyle};
  .secondaryContent {
    h4 {
      font-size: 1.125rem;
    }
  }
  footer {
    .accessory-button .text {
      font-size: 1.125rem;
      font-weight: ${fontWeightBold};
    }
    label {
      font-weight: ${fontWeightBold};
    }
  }
`;

export const AccessoriesStyled = styled.div`
  ${gridStyle};
  section {
    height: 100%;
  }
  .secondaryContent {
    h4 {
      font-size: 1.125rem;
    }
  }
  input[type='checkbox']#includeMva {
    box-sizing: content-box;
    ${padding['0'].a};

    &:after {
      left: 15%;
      top: 15%;
    }

    & + label {
      ${padding['8'].l};
    }

    &:disabled {
      opacity: 0.25;
    }
  }
  footer {
    .accessory-button .text {
      font-size: 1.125rem;
      font-weight: ${fontWeightBold};
    }
    label {
      font-weight: ${fontWeightBold};
    }
  }
  > div {
    input[type='checkbox']#includeMva {
      box-sizing: content-box;
      ${padding['0'].a};

      &:after {
        top: 15%;
        left: 15%;
      }
    }
  }
  .styled-checkbox-button {
    display: grid;
    grid-template-columns: 20px auto;
  }
`;

export const AccessorySetStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .cardRightContainer {
    h3.title,
    div.sub-card-header > span.sub-title {
      font-size: 1rem;
    }
  }

  .styled-checkbox-button input[type='checkbox'] {
    padding: 0;
    top: 0.25rem;
  }

  .smallText {
    font-weight: ${typography.weight.semibold};
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-left: 2.25rem;
    ${margin['8'].t};
    color: ${bosColor.concrete};
  }

  .tagClass {
    background: ${bosColor.neutralGrey};
    padding: 2px 0.75rem;
    border-radius: 0.8125rem;
    color: ${bosColor.black};
    border: 1px Solid ${bosColor.neutralPlus};
    cursor: pointer;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    display: inline-block;
  }

  .includePrice {
    font-weight: ${typography.weight.semibold};
    font-size: 1.125rem;
    line-height: 1.8125rem;
    letter-spacing: 0.02rem;
    padding-left: 2.125rem;
    ${margin['8'].t};
  }
  .custom-modal {
    .close-modal-button {
      width: auto;
      ${padding['0'].a};
      position: absolute;
      right: 1rem;
    }
    .inner-container {
      padding: 1.5rem;
    }
  }
  .tagContainer {
    display: grid;
    gap: 0.5rem;
    .p {
      padding: 0;
    }
    .title {
      font-size: 1.25rem;
      font-weight: ${typography.weight.extrabold};
      padding: 0;
    }
    .description p {
      font-size: 1rem;
      font-weight: ${typography.weight.semibold};
    }
    .smallText.tagClass {
      margin-left: 0;
    }
  }
`;

export const StyledCard = styled.div<IStyledCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  max-width: calc(100vw - 1.25rem - 0.625rem);
  height: 100%;
  text-align: left;
  transition: border-color 500ms ease-in-out;
  border-radius: 0.5rem;
  box-sizing: border-box;
  position: relative;

  &.customAccessory {
    width: 19.0625rem;
  }
  ${({ isCustom }) =>
    isCustom
      ? css`
          ${padding['24'].a};
        `
      : css`
          ${padding['0'].a};
        `};

  input[type='checkbox'] {
    ${padding['0'].a};
  }
`;
