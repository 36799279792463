import { primary } from '@next-components/common-styles';
import { LoadingSpinner } from '@next-components/loading-spinner';
import classNames from 'classnames';
import { formatNumber } from '@next-components/common-types';
import React from 'react';
import { copyWithIFSUrl } from '../../../../../../utils';
import { Button } from '@next-components/cta';

export const emptyFormatter = () => {
  return (
    <div className={'loading'}>
      <LoadingSpinner size={48} color={primary} />
    </div>
  );
};

export const marginsColumnFormatter = (item) => {
  return alignmentFormatter(formatNumber(Math.round(+item)));
};

export const ellipsisTextFormatter = (value: any) => {
  return (
    <div className="eclipsText" title={value}>
      {value}
    </div>
  );
};

export const commaFormatter = (value: any) => {
  return alignmentFormatter(formatNumber(value, 1));
};

export const alignmentFormatter = (value: string) => {
  return (
    <div style={{ width: '65px' }}>
      <div style={{ textAlign: 'right' }}>{value}</div>
    </div>
  );
};

// @ts-ignore
const ifsUrl = window._env_?.REACT_APP_IFS_MARGINS_CUSTOMER_ORDER_URL;
const url = `${ifsUrl}?WEBCTOPAGE=CUSTOMER_ORDER&ORDER_NO=`;

export const hyperlinkFormatter = (value: any) => {
  return (
    <Button
      variant="action"
      className={classNames({
        link: true,
        'primary-link': true,
      })}
      onClick={(e) => {
        copyWithIFSUrl(value, url, e);
      }}>
      {value}
    </Button>
  );
};
