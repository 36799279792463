import React, { useEffect, useState } from 'react';
import { OfferExpiryDate } from './OfferExpiryDate';
import { SalesOfferPdf } from '../../../sales-common/SalesOfferPdf';
import { INextArticle, IOrder, IVehicleConfiguration } from 'next-common';
import { OrderPrerequisite } from './OrderPrerequisite';
import { OfferPaneStyled } from './OfferPane.styled';
import { ICartPrices, ICartProps } from '@next-components/cart';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { ICentralisedProperties } from '../../../../../common';
import { IconButton } from '@next-components/icon-button';
import { IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';
import { isValidStockSaleOrder } from '../../../../../utils';

interface IOfferPaneProps extends ICentralisedProperties {
  order: IOrder;
  vehicle: IVehicleConfiguration;
  cartData: ICartProps;
  mappedEquipment: IEquipmentIdAndSKU[];
  vatPrices: ICartPrices;
  isUnavailablePimData?: boolean;
  articles?: INextArticle[];
  carType?: string;
  dealerRole?: string;
}

export const OfferPane = (props: IOfferPaneProps) => {
  const [updatingExpiryDate, setUpdatingExpiryDate] = useState(false);
  const [updatingPrerequisite, setUpdatingPrerequisite] = useState(false);
  const [isPrerequisiteBoxVisible, setIsPrerequisiteBoxVisible] = useState<boolean>(false);
  const isStockSaleOrder = isValidStockSaleOrder(props.order, props?.configuration)

  useEffect(() => {
    setIsPrerequisiteBoxVisible(!!props.order?.prerequisite);
  }, [props.order?.prerequisite]);

  if (!props.order || (!isStockSaleOrder && !props.vehicle)) return null;

  return (
    <OfferPaneStyled>
      <h2>Last ned tilbud</h2>
      <div className="expiryDateInnerSection">
        <div className="input-container">
          <OfferExpiryDate
            orderId={props.order.id}
            expiresAt={props.order.expiresAt}
            onUpdateExpiryDate={setUpdatingExpiryDate}
            createdAt={props.order.createdAt}
            isUnavailablePimData={props.isUnavailablePimData}
          />
        </div>
        <div className="input-container additional-comments-section">
          {!isPrerequisiteBoxVisible && (
            <div
              className="additional-button-section"
              onClick={() => setIsPrerequisiteBoxVisible(!isPrerequisiteBoxVisible)}>
              <IconButton
                className="additional-button"
                ariaLabel={'plus-knapp'}
                icon={IconType.NewNextPlus}
                fill={bosColor.turquoise}
                onClick={() => setIsPrerequisiteBoxVisible(!isPrerequisiteBoxVisible)}
                disabled={props.isUnavailablePimData}
              />
              <span className="additional-label">Legg til forutsetning for tilbudet</span>
            </div>
          )}
          {isPrerequisiteBoxVisible && (
            <OrderPrerequisite
              orderId={props.order.id}
              prerequisite={props.order?.prerequisite ?? ''}
              onUpdatePrerequisite={setUpdatingPrerequisite}
            />
          )}
        </div>
      </div>

      <SalesOfferPdf
        order={props.order}
        vehicleConfig={props.vehicle}
        updatingExpiryDate={updatingExpiryDate}
        updatingPrerequisite={updatingPrerequisite}
        cartData={props?.cartData}
        mappedEquipment={props?.mappedEquipment}
        vatPrices={props?.vatPrices}
        configuration={props?.configuration}
        articles={props?.articles}
        carType={props?.carType}
        dealerRole={props?.dealerRole}
      />
    </OfferPaneStyled>
  );
};
