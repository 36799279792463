import { ICustomer } from 'next-common';
import { format, isValid } from 'date-fns';

export enum ValidationType {
  PHONE_NUMBER,
  SSN,
}

export const findSsn = (customer: ICustomer, isCash: boolean = false) => {
  if (!customer) return '';
  if (customer.ssn) return customer.ssn;
  if (isCash) return '';
  const formattedDoB = new Date(customer.bosCustomer?.dateOfBirth);
  return isValid(formattedDoB) ? format(formattedDoB, 'ddMMyy') : '';
};

export const findPhone = (customer: ICustomer) => {
  if (!customer) return '';
  if (customer.signingMobilePhone) return customer.signingMobilePhone;
  if (customer.bosCustomer?.phoneMobile) return customer.bosCustomer.phoneMobile;
  return '';
};

export const isStepDisabled = (step: number, hasOrder: boolean, hasFiles: boolean = false, hasSsn: boolean = false) => {
  switch (step) {
    case 1:
      return hasOrder;
    case 2:
    case 3:
      return !hasOrder;
    case 4:
      return !hasOrder || !hasFiles;
    case 5:
      return !hasOrder || !hasFiles || !hasSsn;
  }
};
