import React from 'react';
import { IUserSubMenu } from '../UserSubMenus';
import { SettingHeaderItem } from './SettingsHeaderItem';
import { SettingsHeaderStyled } from './SettingsHeader.styled';
import { Icon, IconType } from '@next-components/bos-icon';

export interface ISettingsHeaderProps {
  selected?: IUserSubMenu;

  onReset(): void;
  onClose(): void;
}

export const SettingsHeader = (props: ISettingsHeaderProps) => (
  <SettingsHeaderStyled>
    {!props.selected && <div className="profile-header-text primary-link">Innstillinger</div>}
    {props.selected && <SettingHeaderItem item={props.selected} key={props.selected.key} onReset={props.onReset} />}
    <div className="profile-header-button">
      <div
        className="close-button"
        onClick={() => {
          props.onClose();
        }}>
        <Icon icon={IconType.NewNextCross} height={20} width={20} aria-hidden />
      </div>
    </div>
  </SettingsHeaderStyled>
);
