import { ERROR_POLICY } from '../../../common';
import { useQuery } from '@apollo/client';
import { queryDetailsActualsSection } from '../../../graphql';

export interface IActualsDataParams {
  companyId?: string;
  departmentNumber: string;
  fromVoucherDate: string;
  serialNo: string;
  toVoucherDate: string;
}

export const useActualsData = (props: IActualsDataParams) => {
  const { data, loading } = useQuery(queryDetailsActualsSection, {
    variables: {
      serialNumber: props.serialNo,
      fromVoucherDate: props.fromVoucherDate,
      departmentNumber: props.departmentNumber,
      companyId: props.companyId,
      toVoucherDate: props.toVoucherDate,
    },
    skip:
      !props.serialNo && !props.fromVoucherDate && !props.departmentNumber && !props.companyId && !props.toVoucherDate,
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });
  return { data, loading };
};
