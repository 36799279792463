import { useState, useEffect } from 'react';
import { eventEmitter, Events } from '../../utils';

export const useSkusPriceUpdate = () => {
  const [skuPrice, setSkuPrice] = useState(new Map());

  const eventCall = (skus) => {
    setSkuPrice(skus);
  };

  useEffect(() => {
    eventEmitter.subscribe(Events.PRICE_UPDATE, eventCall);
    return () => {
      eventEmitter.unsubscribe(Events.PRICE_UPDATE);
    };
  }, []);

  return skuPrice;
};
