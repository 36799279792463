import { Button } from "@next-components/cta";

export interface IRulesActionButtonContainer {
    onCancel: () => void;
    onConfirm: () => void;
};

export const RulesActionButtonContainer = (props: IRulesActionButtonContainer) => {
    return (
        <div className="actionButtonContainer">
            <Button
                className="cancel"
                variant={'secondary'}
                isLoading={false}
                onClick={() => props.onCancel()}>
                Avbryt
            </Button>
            <Button
                className="confirm"
                variant={'cta'}
                disabled={false}
                isLoading={false}
                onClick={() => props.onConfirm()}>
                Bekreft endringer
            </Button>
        </div>
    )
};