import React, { useEffect, useState } from 'react';
import {
  AccessoryType,
  calculateTotalTaxOnCustomAccesories,
  getVehicleAccessoryCustomId,
  IConfiguration,
  IOrder,
  isElectricCar,
  IVehicleAccessory,
  MAKE
} from 'next-common';
import { CustomAccessory } from '../../../common-components/CustomAccessory';
import { OrderAccordion } from '../../sales-common/OrderAccordion';
import { IAccessoryChange, ICustomAccessoryProps } from '../AccessoryTool';
import { AccessoriesStyled, AccessorySetStyled } from '../VehicleAccessoriesPage.styled';
import { SalesWizardButtonMenu } from '../../sales-wizard/sales-wizard-menu/SalesWizardButtonMenu';
import { getActiveStep, getStepData, IStepMenuData } from '../../sales-wizard/sales-wizard-menu';
import { VatCode } from 'sales-common';
import { isValidStockSaleOrder } from '../../../../utils';

interface ICustomAccessorySetProps {
  loading?: boolean;
  order: IOrder;
  accessories: Partial<ICustomAccessoryProps>[];
  changes: IAccessoryChange[];
  isCustomAccessoryOpen?: boolean;
  orderId: string;
  isUnavailablePimData?: boolean;
  calculationId?: string;
  activeStep?: number;
  menuLoading?: boolean;
  configuration?: IConfiguration;
  rootUrl?: string;
  isVehicleValidForReserveOrSign?: boolean;
  onAddAccessory(id: string, accessoryData?: Partial<ICustomAccessoryProps>): void;
  onRemoveAccessory(id: string, accessory: Partial<ICustomAccessoryProps>): void;
  onClick?(stepData?: IStepMenuData | any): void;
  onClickSigning?(stepData?: IStepMenuData): void;
  vehicleAvailabilityCode?: string;
}

const emptyManualCardData: Partial<ICustomAccessoryProps> = {
  isSelected: false,
  isManualInput: true,
  isCustomIncludeMVA: false,
};
export const CustomAccessorySet = (props: ICustomAccessorySetProps) => {
  const [customAccessoryData, setCustomAccessoryData] = useState<Partial<ICustomAccessoryProps>>(emptyManualCardData);
  const [selectedAccessories, setSelectedAccessories] = useState<Partial<ICustomAccessoryProps>[]>([]);
  const isElectric = isElectricCar(props?.order?.pimSnapshot, null);

  const isStockSaleOrder = isValidStockSaleOrder(props?.order, props?.configuration);
  const activeStep = getActiveStep(props?.rootUrl);
  const stepData = getStepData(activeStep, props.orderId, props?.rootUrl);

  useEffect(() => {
    if (props?.order) {
      setCustomAccessoryData({
        ...emptyManualCardData,
        id: getVehicleAccessoryCustomId(props?.order, emptyManualCardData),
      });
    }
  }, [props?.order]);

  useEffect(() => {
    setSelectedAccessories(props?.accessories);
  }, [props?.accessories]);

  useEffect(() => {
    if (!props?.changes?.length) {
      setSelectedAccessories(props?.accessories);
    }
  }, [props?.changes]);

  const handleCustomeSave = async (id: string, name: string, price: number, isIncludeMVA: boolean): Promise<void> => {
    let priceExcludingVat: number = 0;

    if (!isIncludeMVA) {
      const customPayloadForPrice = {
        accessoryType: AccessoryType.CUSTOM,
        isCustomIncludeMVA: isIncludeMVA,
        price,
      };
      priceExcludingVat = price - calculateTotalTaxOnCustomAccesories([customPayloadForPrice] as IVehicleAccessory[]);
    } else {
      priceExcludingVat = price;
    }

    const vatCode: string = isIncludeMVA ? VatCode.DEDUCTABLE : VatCode.NON_DEDUCTABLE;
    const savedData: Partial<ICustomAccessoryProps> = {
      id,
      name,
      isSelected: true,
      isManualInput: true,
      price,
      priceExcludingVat,
      isCustomIncludeMVA: isIncludeMVA,
      vatCode,
    };

    const accessoriesCopy: Partial<ICustomAccessoryProps>[] = [...(selectedAccessories || [])];
    accessoriesCopy.push(savedData);
    setSelectedAccessories(accessoriesCopy);
    props.onAddAccessory(id, savedData);
    setCustomAccessoryData({
      ...emptyManualCardData,
      id: getVehicleAccessoryCustomId(props?.order, emptyManualCardData),
    });
  };

  const handleRemoveAccessory = async (id: string): Promise<void> => {
    const newAccessoryList = selectedAccessories?.filter((accessory) => accessory?.id !== id);
    setSelectedAccessories(newAccessoryList);
    const removedAccessory = selectedAccessories?.find((accessory) => accessory?.id === id);
    props.onRemoveAccessory(id, removedAccessory);
  };

  return (
    <AccessorySetStyled>
      {props?.order?.lead?.make !== MAKE.Smart && (
      <OrderAccordion label={'Egendefinert'} isOpen={props?.isCustomAccessoryOpen}>
        <AccessoriesStyled>
          {selectedAccessories?.map((accessory) => (
            <CustomAccessory
              key={accessory?.id}
              id={accessory?.id}
              name={accessory?.name}
              price={accessory?.price}
              isIncludeMVA={accessory?.isCustomIncludeMVA}
              isSelected={accessory?.isSelected}
              disabled={props?.loading ?? false}
              onRemove={handleRemoveAccessory}
            />
          ))}
          <CustomAccessory
            key={'manual'}
            id={customAccessoryData?.id}
            name={customAccessoryData?.name}
            price={customAccessoryData?.price}
            isIncludeMVA={customAccessoryData?.isCustomIncludeMVA}
            disabled={props?.loading ?? false}
            isElectric={isElectric}
            onSave={handleCustomeSave}
          />
        </AccessoriesStyled>
      </OrderAccordion>
      )}
      <div className="footerMenu">
        <SalesWizardButtonMenu
          orderId={props?.orderId}
          order={props?.order}
          calculationId={props?.calculationId}
          activeStep={props?.activeStep}
          isUnavailablePimData={props?.isUnavailablePimData}
          menuLoading={props?.menuLoading}
          onClick={props?.onClick}
          isStockSaleOrder={isStockSaleOrder}
          onClickSigning={props?.onClickSigning}
          bottomButton={true}
          stepData={stepData?.[stepData?.length - 1]}
          isVehicleValidForReserveOrSign={props?.isVehicleValidForReserveOrSign}
          vehicleAvailabilityCode={props.vehicleAvailabilityCode}
        />
      </div>
    </AccessorySetStyled>
  );
};
