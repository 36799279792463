import React, { useEffect } from 'react';
import { FinancingType, IOrder } from 'next-common';
import { CustomerInputs } from '../../../sales-common/CustomerInputs';
import { validateSsnOnCustomerType } from '../../../../../utils';
import { findSsn } from '../../../sales-signing-page/sales-signing-contract/modals/signing.utils';
import { CustomModal } from '@next-components/custom-modal';
import { ModalActions } from '../../../sales-common/modal.styled';
import { Button } from '@next-components/cta';
import { CustomModalWrapper } from '../../../../layout/CustomModalWrapper.styled';

interface ISendSmsModal {
  show: boolean;
  order: IOrder;
  onConfirm(send: boolean): void;
}

export const SendSmsModal = (props: ISendSmsModal) => {
  const [ssnValid, setSsnValid] = React.useState(true);
  const [mobileValid, setMobileValid] = React.useState(true);

  const isCash = props.order?.chosenOffer?.financingType === FinancingType.CASH;

  const onMobileValidityChange = (value: boolean) => {
    setMobileValid(value);
  };

  const onSsnValidityChange = (value: boolean) => {
    setSsnValid(value);
  };

  useEffect(() => {
    if (!props.order) return;
    setSsnValid(isCash || validateSsnOnCustomerType(findSsn(props.order?.customer), props.order?.customer));
  }, [props.order?.customer]);

  if (!props.show) return null;

  return (
    <CustomModalWrapper className="backdrop smModal" active={props.show}>
      <CustomModal onCloseClick={() => props.onConfirm(false)} active={props.show} isModelCloseAllowedOutside={false}>
        <header>
          <h2>Send signerings SMS</h2>
        </header>
        <CustomerInputs
          customer={props.order?.customer}
          financingType={props.order?.chosenOffer?.financingType}
          onMobileValidityChange={onMobileValidityChange}
          onSsnValidityChange={onSsnValidityChange}
        />
        <footer>
          <ModalActions items={2}>
            <Button onClick={() => props.onConfirm(true)} disabled={!ssnValid || !mobileValid}>
              Bekreft
            </Button>
            <Button onClick={() => props.onConfirm(false)} variant={'secondary'}>
              Avbryt
            </Button>
          </ModalActions>
        </footer>
      </CustomModal>
    </CustomModalWrapper>
  );
};
