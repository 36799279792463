import styled from 'styled-components';
import { bosColor, typography, white, padding, margin } from '@next-components/common-styles';

interface IStyledVehicleDetailProps {
  hasButtonsOnly?: boolean;
}

export const VehicleDetailStyled = styled.div<IStyledVehicleDetailProps>`
  display: grid;
  grid-auto-rows: auto;
  column-gap: 3.1875rem;
  background-color: ${white};

  .vehicleDetailContainer {
    ${padding['0'].a};
    display: grid;
    gap: 0;
    border-bottom: 0;

    .header {
      display: grid;
      font-weight: ${typography.weight.semibold};
      ${padding['0'].a};
      ${margin['0'].b};
      place-items: inherit;
      &.headerRow {
        border-bottom: 0;
        color: ${bosColor.concrete};
        font-weight: ${typography.weight.semibold};
      }

      .sortable {
        font-size: 0.875rem;
        color: ${bosColor.black};
        font-weight: ${typography.weight.extrabold};
        ${padding['8'].t};
        ${padding['0'].b};
        ${padding['16'].l};
        cursor: initial;
        pointer-events: none;
      }
      &.disabled {
        div {
          color: ${bosColor.neutralPlus};
        }
      }
    }
    .gridListItemContainer {
      .gridListItem {
        button.vehicleDetailBtn {
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          }
        }
        .vehicleDetailTagContainer {
          display: grid;
          grid-template-columns: max-content auto;
          align-items: center;
          .itemValue {
            background: none;
          }
        }
      }
    }
    .item {
      display: grid;
      background-color: ${white};
      ${margin['0'].a}
      ${padding['0'].a}
      place-items: inherit;
      position: relative;
      border: none;
      cursor: initial;

      &.selected {
        border-color: ${white};
      }

      &:hover {
        border-color: ${white};
      }

      > div {
        font-size: 1rem;
        color: ${bosColor.concrete};
        font-weight: ${typography.weight.semibold};
        ${padding['0'].t};
        ${padding['16'].b};
        ${padding['16'].l};
        span {
          font-size: 1rem;
        }
      }
      &.disabled {
        div {
          color: ${bosColor.neutralPlus};
          span,
          > div {
          }
          > span > span:first-child {
            opacity: 0.65;
          }
        }
      }

      &.empty,
      &.load-more {
        place-items: center;
        cursor: inherit;
        border: 0;
      }
    }
  }
`;
