import { disabled, negative, neutral, positive } from '@next-components/common-styles';
import { ApplicationResultCode } from 'next-common';

export const applicationStati = [
  {
    key: ApplicationResultCode.NEW_CASE,
    color: disabled,
    label: 'Behandles',
    info: 'Forventet behandlingstid: 2-15 minutter',
  },
  {
    key: ApplicationResultCode.DOCUMENTS_APPROVED,
    color: disabled,
    label: 'Behandles',
    info: 'Forventet behandlingstid: 2-15 minutter',
  },
  {
    key: ApplicationResultCode.DOCUMENTS_GENERATED,
    color: disabled,
    label: 'Behandles',
    info: 'Forventet behandlingstid: 2-15 minutter',
  },
  {
    key: ApplicationResultCode.CONTROL,
    color: neutral,
    label: 'Sendt til manuell behandling',
    info: 'Forventet behandlingstid: 1-2 virkedager',
  },
  {
    key: ApplicationResultCode.REJECTED,
    color: negative,
    label: 'Avslått',
    info: 'Søk på nytt med annet finansforlag',
  },
  {
    key: ApplicationResultCode.DISBURSED,
    color: positive,
    label: 'Nedbetalt',
    info: '',
  },
  {
    key: ApplicationResultCode.CANCELLED,
    color: disabled,
    label: 'Kansellert',
    info: '',
  },
  { key: ApplicationResultCode.APPROVED, color: positive, label: 'Godkjent', info: '' },
];

export const statusCodes = Object.keys(ApplicationResultCode).map((x) => ApplicationResultCode[x]);
