import React, { useEffect } from 'react';
import { IIconProps, InputField } from '@next-components/input-field';
import { FormRowValidationWarning, StyledFormRow } from './FormRow.styled';
import { formatNumberInput, InputType } from '@next-components/common-types';
import { LoadingSpinner } from '@next-components/loading-spinner';

export interface IFormRow {
  id: string;
  isEditable: boolean;
  isValid: boolean;
  collapsedWarning?: boolean;
  label: string;
  maxLength?: number;
  placeholder?: string;
  type?: InputType;
  validationMessage?: string;
  disabled?: boolean;

  startAdornment?: IIconProps | string;
  endAdornment?: IIconProps | string;
  value?: any;
  defaultValue?: any;
  valuePostfix?: string;
  valueLoading?: boolean;
  className?:string;
  labelChild?:React.ReactNode;

  decimals?: 0 | 2 | 1 | 3 | 4;
  onChange?(value): void;
  onSubmit?(value): void;
  onBlur?(value): void;
}

export const getDisplayValue = (props: IFormRow) => {
  return typeof props.value === 'number' || typeof props.defaultValue === 'number'
    ? props.value || props.defaultValue || '0'
    : props.value || props.defaultValue || '';
};

export const FormRow = (props: IFormRow) => {
  const [displayValue, setDisplayValue] = React.useState(getDisplayValue(props));

  useEffect(() => {
    setDisplayValue(getDisplayValue(props));
  }, [props.value, props.defaultValue]);

  return (
    <StyledFormRow className={props.className}>
      <label className={`smallFont ${props.labelChild ? 'labelChild' : ''}`}>
        {props.label} {props.labelChild ?? null}
      </label>
      {props.isEditable ? (
        <InputField
          className={`borderlight input-with-warning ${props.isValid ? '' : 'invalid'}`}
          defaultValue={props.defaultValue?.toString()}
          value={props.value?.toString()}
          id={props.id}
          maxLength={props.maxLength}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onSubmit={props.onSubmit}
          withErrorMessage={false}
          isValid={props.isValid}
          errorMessage={props.validationMessage}
          startAdornment={props.startAdornment}
          endAdornment={!props.disabled && props.value && props.endAdornment}
          placeholder={props.placeholder}
          type={props.type || InputType.TEXT_LINE}
          decimals={props.decimals}
          disabled={props.disabled}
        />
      ) : props?.valueLoading ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <p className={'smallFont'}>
          {`${
            typeof displayValue === 'number'
              ? formatNumberInput(displayValue.toString(), props?.type).formatted
              : displayValue
          }${props.valuePostfix}`}
        </p>
      )}
      <div />
      {props?.validationMessage
        ? (props?.collapsedWarning && props?.isValid) || (
            <FormRowValidationWarning>
              <p>{props?.isValid ? ' ' : props?.validationMessage || 'Obligatorisk'}</p>
            </FormRowValidationWarning>
          )
        : null}
    </StyledFormRow>
  );
};

FormRow.defaultProps = {
  valueLoading: false
}