import styled from 'styled-components';
import { bosColor, disabled, typography } from '@next-components/common-styles';

export const InfoBlockStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  &.flexColumn {
    flex-direction: column;
  }
  &.boldText {
    span {
      font-weight: ${typography.weight.extrabold} !important;
    }
  }
  &.lightText {
    span {
      color: ${bosColor.concrete};
    }
  }
  &.smallText > span {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: ${typography.weight.semibold};
    line-height: 170%;
  }
  span {
    font-size: 1rem;
    line-height: 175%;
    font-weight: ${typography.weight.semibold};
    color: ${bosColor.black};

    &:not(&.value) {
      flex: 1 0 0;
    }
    .vat {
      color: ${disabled};
      margin-left: 15px;
    }
    &.value {
      text-align: right;
      font-weight: ${typography.weight.semibold};
    }
  }
`;
