import { includes, unionBy, intersection, isNil, cloneDeep, get, defaultTo, filter } from 'lodash';
import {
  getMakeCodeByName,
  HashMap,
  IEquipmentTypes,
  IInterior,
  IVarnish,
  IVehicleConfiguration,
  IVehicleOptionInput,
  MAKE,
  VehicleEquimentTypeEnum,
  VehicleEquipmentCategoryEnum,
} from 'next-common';
import { PropertiesTableValue } from 'sales-common';
import {
  getUpdatedConjunctionPrices,
  IConjunctionReturnValue,
  ICrystallizeMappedEquipmentChunk,
  ICrystallizeMappedEquipmentProduct,
  IEquipmentIdAndSKU,
  IMappedEquipment,
  getNewConjunctionValues,
} from 'sales-crystallize-common';
import { IModelPackages } from '../../components/sales/sales-vehicle-picker-page';
import { ISalesPackageOptionValue } from '../../components/sales/sales-wizard/vehicleSegregationWorker';
import { VehicleConfig, calculateRetailPrice } from './vehicleOrder';
import { validBrandForSelectables } from '../../common';
import { ICartDataProps, IListverkChangeProps, IRimChangeProps } from '../../components/sales/sales-vehicle-picker-page/ResultGrid';

export type IPartialEnrichedEquipment = Partial<
  {
    product: Partial<ICrystallizeMappedEquipmentProduct>;
  } & ICrystallizeMappedEquipmentChunk
>;

export const filterNullEquipmentValue = <T>(equipmentObject: HashMap, key: string, defaultValue: T) => {
  const value = get(equipmentObject, key, defaultValue);
  if (defaultValue === null || value !== null) {
    return value;
  }

  return defaultValue;
};

export const isValidBrandForSelectables = (brand: string): boolean => {
  const getBrandCode = getMakeCodeByName(brand);
  return includes(validBrandForSelectables, getBrandCode ?? '');
};

export const mapEquipment = (element: Partial<IEquipmentTypes>, type: VehicleEquimentTypeEnum) => ({
  isHighlight: element?.isHighlight,
  marginAmount: element?.marginAmount,
  marginPercentage: element?.marginPercentage,
  name: element?.name,
  priceExcludingVat: element?.priceExcludingVat,
  priceIncludingVat: element?.priceIncludingVat,
  sku: element?.sku,
  pimSku: element?.pimSku,
  pimId: element?.id,
  category: type === VehicleEquimentTypeEnum.Selectable ? element?.category : null,
  type,
  unavailableForFactoryOrder: element?.unavailableForFactoryOrder,
  vatCode: element?.vatCode,
  retailPrice: element?.retailPrice,
  factoryCodeId: element?.manufacturerComponentId ?? null,
});

export const prepareEquipments = (
  selectedEquipment: IEquipmentTypes[],
  selectedRims: IEquipmentTypes,
  selectedListverk: IEquipmentTypes,
  selectedTaklakk: IEquipmentTypes,
  vehicleEquipments = [],
) => {
  let sanitizedEquipments =
    selectedEquipment?.map((element) => mapEquipment(element, VehicleEquimentTypeEnum.Selectable)) ?? [];

  if (selectedRims) {
    sanitizedEquipments = [...sanitizedEquipments, mapEquipment(selectedRims, VehicleEquimentTypeEnum.Rims)];
  }
  if (selectedListverk) {
    sanitizedEquipments = [...sanitizedEquipments, mapEquipment(selectedListverk, VehicleEquimentTypeEnum.Selectable)];
  }
  if (selectedTaklakk) {
    sanitizedEquipments = [...sanitizedEquipments, mapEquipment(selectedTaklakk, VehicleEquimentTypeEnum.Selectable)];
  }
  if (vehicleEquipments?.length) {
    sanitizedEquipments = unionBy(sanitizedEquipments, vehicleEquipments, 'sku');
    const priceUpdate = Object.assign(
      {},
      ...(vehicleEquipments?.map((equipement) => ({ [equipement?.sku]: equipement?.priceExcludingVat })) || []),
    );

    sanitizedEquipments?.forEach((eqiupment) => {
      eqiupment.priceExcludingVat = priceUpdate?.[eqiupment?.sku] ?? eqiupment?.priceExcludingVat;
    });
  }

  return sanitizedEquipments;
};

/**
 * Taking the equipment pricing from the vehicle-config before mutation is triggered
 */
export const prepareEquipmentFromVehicleConfig = (
  vehicleConfig: IVehicleConfiguration,
  selectableEquipment: IEquipmentTypes[],
  rim: IEquipmentTypes,
  listverk: IEquipmentTypes,
  taklakk: IEquipmentTypes,
) => {
  const selectedSelectable = [] as IEquipmentTypes[];
  let selectedRim = null as IEquipmentTypes;
  let selectedListverk = null as IEquipmentTypes;
  let selectedTaklakk = null as IEquipmentTypes;

  selectableEquipment?.forEach((equip) => {
    const equipment = vehicleConfig?.model?.selectableEquipment?.find((selectEquip) => selectEquip?.sku === equip?.sku);
    const priceExcludingVat = equipment?.priceExcludingVat ?? equip?.priceExcludingVat;
    const marginPercentage = equipment?.marginPercentage ?? equip?.marginPercentage;
    selectedSelectable?.push({
      ...equip,
      priceExcludingVat,
      priceIncludingVat: equipment?.priceIncludingVat ?? equip?.priceIncludingVat,
      retailPrice: calculateRetailPrice(priceExcludingVat, marginPercentage),
    });
  });

  if (rim) {
    const equipment = vehicleConfig?.model?.rims?.find((equip) => equip?.sku === rim?.sku);
    const priceExcludingVat = equipment?.priceExcludingVat ?? rim?.priceExcludingVat;
    const marginPercentage = equipment?.marginPercentage ?? rim?.marginPercentage;
    selectedRim = {
      ...rim,
      priceExcludingVat,
      priceIncludingVat: equipment?.priceIncludingVat ?? rim?.priceIncludingVat,
      retailPrice: calculateRetailPrice(priceExcludingVat, marginPercentage),
    };
  }

  if (listverk) {
    const equipment = vehicleConfig?.model?.selectableEquipment?.find((equip) => equip?.sku === listverk?.sku);
    const priceExcludingVat = equipment?.priceExcludingVat ?? listverk?.priceExcludingVat;
    const marginPercentage = equipment?.marginPercentage ?? listverk?.marginPercentage;
    selectedListverk = {
      ...listverk,
      priceExcludingVat,
      priceIncludingVat: equipment?.priceIncludingVat ?? listverk?.priceIncludingVat,
      retailPrice: calculateRetailPrice(priceExcludingVat, marginPercentage),
    };
  }
  if (taklakk) {
    const equipment = vehicleConfig?.model?.selectableEquipment?.find((equip) => equip?.sku === taklakk?.sku);
    const priceExcludingVat = equipment?.priceExcludingVat ?? taklakk?.priceExcludingVat;
    const marginPercentage = equipment?.marginPercentage ?? taklakk?.marginPercentage;
    selectedTaklakk = {
      ...taklakk,
      priceExcludingVat,
      priceIncludingVat: equipment?.priceIncludingVat ?? taklakk?.priceIncludingVat,
      retailPrice: calculateRetailPrice(priceExcludingVat, marginPercentage),
    };
  }

  return { selectedSelectable, selectedRim, selectedListverk, selectedTaklakk };
};

const getUpdatedOptions = (updatedSkus: string[], sanitizedOptions: IVehicleOptionInput, updatedOptions, updatedPrices) => {
  if (updatedSkus?.includes(sanitizedOptions?.colorId)) {
    updatedOptions.colorPriceExcludingVat = Number(updatedPrices?.[sanitizedOptions?.colorId] || 0);
  }
  if (updatedSkus?.includes(sanitizedOptions?.interiorId)) {
    updatedOptions.interiorPriceExcludingVat = Number(updatedPrices?.[sanitizedOptions?.interiorId] || 0);
  }
  return updatedOptions;
}
/**
 * Only supported for MER brand, for dynamic pricing based on selection.
 */
export const updatedPricingForSelectableAndOptions = (
  options: IVehicleOptionInput | IVehicleOptionInput[],
  equipments: any,
  mappedEquipment: IEquipmentIdAndSKU[],
  make: MAKE,
) => {
  let priceConjunctionTable = [];
  if (isValidBrandForSelectables(make)) {
    const equipmentSkuList = removeInvalidValuesFromArray<string>(equipments?.map((equipment) => equipment?.sku));
    const sanitizedOptions = Array.isArray(options) ? options?.[0] : options;
    const optionsSkuList = removeInvalidValuesFromArray<string>([
      sanitizedOptions?.colorId,
      sanitizedOptions?.interiorId,
    ]);
    const { updatedSkus, updatedPrices, skuPrices } = getUpdatedSelectables(
      [...(defaultTo(equipmentSkuList, [])), ...(defaultTo(optionsSkuList, []))],
      mappedEquipment,
    );

    priceConjunctionTable = cloneDeep(skuPrices ?? []);

    if (updatedSkus?.length) {
      const updatedPriceEquipment = equipments?.map((equipment) =>
        updatedSkus?.includes(equipment?.sku) ? updatePrice(equipment, updatedPrices) : equipment,
      );

      let updatedOptions = { ...sanitizedOptions };
      updatedOptions = getUpdatedOptions(updatedSkus, sanitizedOptions, updatedOptions, updatedPrices);

      updatedOptions.priceExcludingVat =
        (updatedOptions?.colorPriceExcludingVat || 0) + (updatedOptions?.interiorPriceExcludingVat || 0);
      updatedOptions.colorRetailPrice = calculateRetailPrice(
        updatedOptions?.colorPriceExcludingVat,
        sanitizedOptions?.colorMarginPercentage,
      );
      updatedOptions.interiorRetailPrice = calculateRetailPrice(
        updatedOptions?.interiorPriceExcludingVat,
        sanitizedOptions?.interiorMarginPercentage,
      );

      return { options: updatedOptions, equipments: updatedPriceEquipment, priceConjunctionTable };
    }
  }

  return { options, equipments, priceConjunctionTable };
};

export const getUpdatedSelectables = (skuList: string[] = [], mappedEquipment: IEquipmentIdAndSKU[] = []) => {
  const skuPayload: string = `${skuList?.join(';') || ''};`;

  //Get updated prices from PIM
  const { updatedPrices, skuPrices } = getUpdatedPrices(mappedEquipment, skuPayload);
  const updatedSkus = Object.keys(updatedPrices);

  const updateFindInSkus = intersection(skuList, updatedSkus);
  return { updatedSkus: updateFindInSkus, updatedPrices, skuPrices };
};

export const getUpdatedPrices = (mappedEquipment: IEquipmentIdAndSKU[], skuPayload: string = '') => {
  let skuPrices: ((PropertiesTableValue & { sku: string }) | any)[] = [];
  skuPrices = getUpdatedConjunctionPrices(skuPayload, mappedEquipment);
  let updatedPrices = {};

  skuPrices?.forEach((price) => {
    if (isValidPriceChange(price)) {
      updatedPrices = { ...updatedPrices, [price?.sku]: price?.value };
    }
  });

  return { updatedPrices, skuPrices: priceConjunctionInputMapping(skuPrices) };
};

export const isValidPriceChange = (price) => {
  const keys = price?.key?.split(';')?.filter((key) => key !== '');
  return keys?.length && price?.value;
};

export const updatePrice = (equipment: ISalesPackageOptionValue, updatedPrices) => {
  const priceExcludingVat = Number(updatedPrices?.[equipment?.sku] || 0)
  return {
    ...equipment,
    priceExcludingVat,
    retailPrice: calculateRetailPrice(priceExcludingVat, equipment?.marginPercentage),
  };
};

export const priceConjunctionInputMapping = (skuPrices): { key: string; price: string; sku: string }[] => {
  return (
    skuPrices?.map((skuPrice) => ({
      key: skuPrice?.key ?? '',
      price: skuPrice?.value ?? '',
      sku: skuPrice?.sku ?? '',
    })) ?? []
  );
};

export const removeInvalidValuesFromArray = <T extends unknown>(data: T[] = []) => {
  return data?.filter((value) => !isNil(value) && value !== '');
};

export const findSelectableSkus = <Type extends { key?: string; id?: string }>(selectedData: Type[]) => {
  return removeInvalidValuesFromArray(
    selectedData?.map((data) => {
      if (
        data?.key === VehicleConfig.COLOR ||
        data?.key === VehicleConfig.INTERIOR ||
        data?.key === VehicleConfig.RIM ||
        data?.key === VehicleEquipmentCategoryEnum.Listverk ||
        data?.key === VehicleEquipmentCategoryEnum.Taklakk ||
        data?.key?.startsWith(VehicleConfig.SELECTABLE_EQUIPMENT)
      ) {
        return data?.id;
      }
    }),
  );
};

//Prepare object of selectble with original price from PIM
export const fetchOriginalPriceOfSelectables = (data: IModelPackages[]) => {
  const colorPrices =
    data?.[0]?.colors?.map((color) => ({ [color?.varnishCode]: {
      priceExcludingVat: color?.additionalPriceExcludingVat,
      marginPer: color?.marginPercentage
    } })) || [];
  const interiorPrices =
    data?.[0]?.interiors?.map((interior) => ({
      [interior?.interiorCode]: {
        priceExcludingVat: interior?.additionalPriceExcludingVat,
        marginPer: interior?.marginPercentage
      },
    })) || [];

  const rimPrices = data?.[0]?.rims?.map((rim) => ({ [rim?.sku]: {
    priceExcludingVat: rim?.priceExcludingVat,
    marginPer: rim?.marginPercentage
  } })) || [];
  const equipmentPrices =
    data?.[0]?.selectableEquipment?.map((equipment) => ({
      [equipment?.sku]: {
        priceExcludingVat: equipment?.priceExcludingVat,
        marginPer: equipment?.marginPercentage
      }
    })) || [];

  return Object.assign({}, ...[...colorPrices, ...interiorPrices, ...rimPrices, ...equipmentPrices]);
};

export const isRulesPayloadCheckFulfilled = (
  selectedVariantData: IModelPackages[],
  mappedEquipment: IEquipmentIdAndSKU[],
  make: string,
) => {
  return selectedVariantData && mappedEquipment && isValidBrandForSelectables(defaultTo(make, ''));
};

export const rulesArrPayloadToString = (payload: string[]) => {
  if (!payload || payload?.length < 1) {
    return ';';
  }
  return `${payload?.join(';') || ''};`;
};

export const rulesPayloadMapper = (mappedEquipments: IMappedEquipment[]): IPartialEnrichedEquipment[] => {
  // This type would be updated to `EnrichedEquipment` when pim-sku is available.
  const enrichedEquipments: IPartialEnrichedEquipment[] = [];

  mappedEquipments?.forEach((equipment) => {
    const sku = filterNullEquipmentValue(equipment, 'sku', '');
    enrichedEquipments.push({
      product: {
        sku,
        equipmentSKU: sku,
        name: equipment?.name,
      },
      equipmentInConjunctionWith: filterNullEquipmentValue(equipment, 'inConjunctionWith', ''),
      equipmentNotInConjunctionWith: filterNullEquipmentValue(equipment, 'notInConjunctionWith', ''),
    });
  });

  return enrichedEquipments;
};

export const prepareSuggestedSkuList = (conjunctionValue) => {
  const currentSku = get(conjunctionValue, 'sku', []);
  const equipmentSelectionToEnable = get(conjunctionValue, 'equipmentSelectionToEnable', []);
  let skuList = { [currentSku]: [] }
  if (equipmentSelectionToEnable?.length) {
    let skus = equipmentSelectionToEnable?.map(ele => {
      return { [ele?.sku]: ele?.name };
    });
    skuList = { [currentSku]: skus }
  }
  return skuList;
}

export const getDisableSkuListOnLoad = (
  data: IModelPackages[],
  make: string,
  conjunctionEquipmentData: IPartialEnrichedEquipment[],
) => {
  const disableSkusList = new Map();
  if (data && isValidBrandForSelectables(make ?? '')) {
    // @ts-ignore (pim-sku change would fix the type error, future task)
    const { disable } = getNewConjunctionValues('', conjunctionEquipmentData ?? []);
    const disableSku: IConjunctionReturnValue[] = disable || [];
    disableSku?.map((conjunctionValue, index) =>
      disableSkusList.set(get(conjunctionValue, 'equipmentSku', ''), prepareSuggestedSkuList(conjunctionValue)),
    );
  }
  return disableSkusList;
};

export const getRequestedCategoryEquipment = (
  equipements: IEquipmentTypes[],
  category: VehicleEquipmentCategoryEnum,
): IEquipmentTypes[] => {
  const equipmentFilter: IEquipmentTypes[] = filter(equipements ?? [], ['category', category]);
  return equipmentFilter;
};

export const getListverkFromEquipList = (equipements: IEquipmentTypes[]): IEquipmentTypes[] => {
  return getRequestedCategoryEquipment(equipements, VehicleEquipmentCategoryEnum.Listverk);
};

export const getTaklakFromEquipList = (equipements: IEquipmentTypes[]): IEquipmentTypes[] => {
  return getRequestedCategoryEquipment(equipements, VehicleEquipmentCategoryEnum.Taklakk);
};

export const getExterirTilleggFromEquipList = (equipements: IEquipmentTypes[]): IEquipmentTypes[] => {
  return getRequestedCategoryEquipment(equipements, VehicleEquipmentCategoryEnum.Exterior);
};

export const getInteriorTilleggFromEquipList = (equipements: IEquipmentTypes[]): IEquipmentTypes[] => {
  return getRequestedCategoryEquipment(equipements, VehicleEquipmentCategoryEnum.Interior);
};

export const getColorPrice = (color: IVarnish, priceUpdate: Map<any, any>) => {
  return priceUpdate?.has(color?.varnishCode)
    ? Number(priceUpdate?.get(color?.varnishCode))
    : color?.additionalPriceExcludingVat;
};

export const getRimPrice = (rim: IEquipmentTypes, priceUpdate: Map<any, any>) => {
  return priceUpdate?.has(rim?.sku) ? Number(priceUpdate?.get(rim?.sku)) : rim?.priceExcludingVat;
};

export const getTaklakkPrice = (taklakk: IEquipmentTypes, priceUpdate: Map<any, any>) => {
  return priceUpdate?.has(taklakk?.sku) ? Number(priceUpdate?.get(taklakk?.sku)) : taklakk?.priceExcludingVat;
};

export const getInteriorsPrice = (interior: IInterior, priceUpdate: Map<any, any>,) => {
  return priceUpdate?.has(interior?.interiorCode)
    ? Number(priceUpdate?.get(interior?.interiorCode))
    : interior?.additionalPriceExcludingVat;
};

export const getListverkPrice = (listverk: IEquipmentTypes, priceUpdate: Map<any, any>,) => {
  return priceUpdate?.has(listverk?.sku) ? Number(priceUpdate?.get(listverk?.sku)) : listverk?.priceExcludingVat;
};

export const getColorChangeInput = (
  color: IVarnish,
  priceUpdate: Map<any, any>,
  selectedColorId?: string,
  isOptimisticUpdate: boolean = false,
): ICartDataProps => {
  return {
    code: selectedColorId === color?.varnishCode ? null : color?.varnishCode,
    name: color?.name,
    price: getColorPrice(color, priceUpdate),
    vatCode: color?.vatCode,
    isRecommended: color?.isRecommended,
    isOptimisticUpdate,
    configType: VehicleConfig.COLOR,
    dealerPriceExclVat: calculateRetailPrice(getColorPrice(color, priceUpdate), color?.marginPercentage),
  }
};

export const getRimsChangeInput = (
  rim: IEquipmentTypes,
  priceUpdate: Map<any, any>,
  selectedRimId?: string,
  isOptimisticUpdate: boolean = false,
): IRimChangeProps => {
  return {
    rim: selectedRimId === rim?.id ? null : rim,
    price: getRimPrice(rim, priceUpdate),
    vatCode: rim?.vatCode,
    isOptimisticUpdate,
    configType: VehicleConfig.RIM,
    dealerPriceExclVat: calculateRetailPrice(getRimPrice(rim, priceUpdate), rim?.marginPercentage),
    effectedIndicator: rim?.id,
  };
};

export const getTaklakkChangeInput = (
  equipment: IEquipmentTypes,
  priceUpdate: Map<any, any>,
  selectedEquipmentId?: string,
  isOptimisticUpdate: boolean = false,
): IListverkChangeProps => {
  return {
    equipment: selectedEquipmentId === equipment?.id ? null : equipment,
    price: getTaklakkPrice(equipment, priceUpdate),
    vatCode: equipment?.vatCode,
    isOptimisticUpdate,
    configType: VehicleEquipmentCategoryEnum.Taklakk,
    dealerPriceExclVat: calculateRetailPrice(getTaklakkPrice(equipment, priceUpdate), equipment?.marginPercentage),
    effectedIndicator: equipment?.id,
  }
};

export const getInteriorChangeInput = (
  interior: IInterior,
  priceUpdate: Map<any, any>,
  selectedInteriorId?: string,
  isOptimisticUpdate: boolean = false,
): ICartDataProps => {
  const finalPrice = getInteriorsPrice(interior, priceUpdate)
  return {
    code: selectedInteriorId === interior?.interiorCode ? null : interior?.interiorCode,
    name: interior?.name,
    price: finalPrice,
    vatCode: interior?.vatCode,
    isRecommended: interior?.isRecommended,
    isOptimisticUpdate,
    configType: VehicleConfig.INTERIOR,
    dealerPriceExclVat: calculateRetailPrice(finalPrice, interior?.marginPercentage),
  }
};

export const onListverkChangeInput = (
  equipment: IEquipmentTypes,
  priceUpdate: Map<any, any>,
  selectedEquipmentId?: string,
  isOptimisticUpdate: boolean = false,
): IListverkChangeProps => {
  return {
    equipment: selectedEquipmentId === equipment?.id ? null : equipment,
    price: getListverkPrice(equipment, priceUpdate),
    vatCode: equipment?.vatCode,
    isOptimisticUpdate,
    configType: VehicleEquipmentCategoryEnum.Listverk,
    dealerPriceExclVat: calculateRetailPrice(getListverkPrice(equipment, priceUpdate), equipment?.marginPercentage),
    effectedIndicator: equipment?.id,
  };
};