import { ApolloClient, useApolloClient } from '@apollo/client';
import { IUser } from 'next-common';
import { queryAllUsersByDepartmentIds } from '../../graphql';
import { ERROR_POLICY } from '../constants';
import { useEffect, useState } from 'react';
import { uniqBy } from 'lodash';

let allDealerUsers = [];

const allUsersQuery = async (client: ApolloClient<object>, dealerIds: string[]): Promise<IUser[]> => {
  if (!dealerIds || dealerIds?.length === 0) return null;
  try {
    const { data } = await client.query({
      query: queryAllUsersByDepartmentIds,
      variables: {
        departmentIds: Array.isArray(dealerIds) ? dealerIds : [dealerIds],
      },
      errorPolicy: ERROR_POLICY,
    });
    return data?.findUsersByDepartmentIds ?? [];
  } catch (error: any) {
    const errorMsg = `Could not get the users with dealer ids: "${dealerIds}"`;
    // tslint:disable-next-line:no-console
    console.error(errorMsg, error);
    if (typeof error?.statusCode === 'number' && error.statusCode === 200) {
      throw new Error(errorMsg);
    }
  }
};

const getUsers = (
  dealerIds: string[],
  client: ApolloClient<object>,
  onSuccess: (value: IUser[]) => Promise<void>,
): void => {
  allUsersQuery(client, dealerIds).then(users => {
    onSuccess(users);
  }).catch(err => {
    const errorMsg = `Could not get the users with dealer ids: "${dealerIds}"`;
    // tslint:disable-next-line:no-console
    console.error(errorMsg, err);
  });
};

export const useDealerUsers = (dealerIds: string[]): IUser[] => {
  const client = useApolloClient();
  const [dealerUsers, setDealerUsers] = useState([]);

  const onSuccess = async (users?: IUser[]) => {
    if (users?.length > 0) {
      allDealerUsers = uniqBy([...allDealerUsers, ...(users || [])], 'ident');
    }
    setDealerUsers(allDealerUsers);
  };

  useEffect(() => {
    getUsers(dealerIds, client, onSuccess);
  }, [client, dealerIds]);

  return dealerUsers;
};
