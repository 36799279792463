import { OrderValidation } from 'next-common';

export const parseValidationError = (error: OrderValidation): string => {
  switch (error) {
    case OrderValidation.MissingOffer:
      return 'Finansiering er ikke valgt';
    case OrderValidation.MissingCustomer:
      return 'Kunde er ikke valgt';
    case OrderValidation.MissingCustomerReferenceBosApiId:
      return 'Kunde mangler oppføring i BOS-api';
    case OrderValidation.MissingCustomerSocialNumber:
      return 'Mangler personnummer på kunde';
    case OrderValidation.MissingUser:
      return 'Selger mangler';
    case OrderValidation.MissingUserReferenceBosApiId:
      return 'Selger mangler oppføring i BOS-api';
    case OrderValidation.MissingUserDefaultDepartmentId:
      return 'Selger mangler forhandlernummer';
    case OrderValidation.MissingVehicle:
      return 'Bilpakke mangler';
    default:
      return error?.toString() || '';
  }
};

const nonCriticalValidationErrors: OrderValidation[] = [OrderValidation.MissingCustomerSocialNumber];

export const filterOutNonCriticalValidationErrors = (errors: OrderValidation[]): OrderValidation[] => {
  return errors.filter((x) => !nonCriticalValidationErrors.includes(x));
};

export const extractValidationCodes = (errorMessage: string): OrderValidation[] => {
  if (!errorMessage) return [];
  const startOfCodes = errorMessage.indexOf(':');
  if (startOfCodes < 0) return [];
  const codeStrings = errorMessage.slice(startOfCodes + 1).split(';');
  return codeStrings.map((x) => OrderValidation[x.trim()]);
};
