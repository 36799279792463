import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

const PriceChangeInfoStyle: Style = {
  position: 'absolute',
  bottom: 85,
  padding: '5 15',
};

const InfoTextStyle: Style = {
  fontSize: 8,
};

export const PriceChangeInfo = (props: {pageBreak: boolean}) => {
  return (
    <View style={PriceChangeInfoStyle} break={props?.pageBreak}>
      <Text
        style={InfoTextStyle}
        render={({ totalPages, pageNumber }) => {
          if (totalPages === pageNumber) {
            return `Vi tar forbehold om pris-, avgifts- og spesifikasjonsendringer, samt godkjennelse fra finansieringsselskap ifm lån/leasing. Ved behov for ytterligere informasjon eller avtale om prøvekjøring, ta gjerne kontakt med oss. Vi håper tilbudet er av interesse og ser fram til en nærmere samtale.`;
          }
        }}
      />
    </View>
  );
};
