import axios, { AxiosRequestConfig } from 'axios';
import { selectedGroup, apimEndpoint } from '../../common';
import { IVehicleUpdate } from '../../components/sales/sales-wizard';
import { getHeader } from '../axiosHeaderConfig';
interface IApiResponse {
  url?: string;
}

let webSocket: WebSocket;

export const connectToWebSocket = async (group: string) => {
  const HEADER: AxiosRequestConfig = await getHeader();
  const response: IApiResponse = await axios
    .get(`${apimEndpoint ?? window.location.origin}/api/negotiate/${group}`, HEADER)
    .then((result) => result?.data)
    .catch((err) => console.error(err));
  if (response?.url) return new WebSocket(response?.url, 'json.webpubsub.azure.v1');
};

export const isGroupChanged = (group: string) => {
  const existingGroup = selectedGroup();
  if (existingGroup !== group) {
    selectedGroup(group);
    return true;
  }
  return false;
};

export const handleWebsocketEvents = (group: string, receiveVehicleUpdate: (updatedVehicle: IVehicleUpdate) => void) => {
  webSocket.onopen = () => {
    if (webSocket?.readyState === 1) {
      webSocket.send(
        JSON.stringify({
          type: 'joinGroup',
          group,
        }),
      );
    }
  };
  webSocket.onmessage = (message) => {
    const response = JSON.parse(message?.data);
    if (response?.type === 'message') {
      receiveVehicleUpdate(response?.data);
    }
  };
};

export const connectToAzureWebPubSub = async (
  group: string,
  receiveVehicleUpdate: (updatedVehicle: IVehicleUpdate) => void,
) => {
  if (!group) return;
  webSocket = await connectToWebSocket(group);
  if (webSocket && group) handleWebsocketEvents(group, receiveVehicleUpdate);
};

export const disconnectSocket = () => {
  if (webSocket) webSocket.close();
};
