import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import React from 'react';
import { IValidationToastNotification } from '../../common';
import { WarningStyled } from './Warning.styled';

export interface IReservedWarningProps extends IValidationToastNotification {
  serialNo: string;
  message: string;
}

export const ReservedWarning = (props: IReservedWarningProps) => {
  let state = '';
  switch (props.message) {
    case 'VehicleSold':
      state = 'solgt';
      break;
    case 'AlreadyReserved':
      state = 'reservert';
      break;
    default:
      state = 'utilgjengelig';
  }
  return (
    <Messagebar severityLevel={MessagebarSeverityLevel.ERROR} title={`Bilen er ${state}`} onClose={props?.onClose}>
      <WarningStyled>
        <div>
          Bilen med serienummer {props.serialNo ?? ''} er {state}
        </div>
      </WarningStyled>
    </Messagebar>
  );
};
