import { backgroundCard } from '@next-components/common-styles';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { getAccessoryCalculatedPriceByCustomerType, IOrder, IVehicleAccessory } from 'next-common';
import React from 'react';
import { IAccessoryConfig } from '../SalesOfferPdf';
import { HorizontalWarpableListStyled, NoneSelectedStyled, SectionStyled } from './common-styles';
import { ImageOrPlaceholder } from './ImageOrPlaceholder';
import { PriceOrIncluded } from './PriceOrIncluded';
import { v4 as uuidv4 } from 'uuid';

const AccessoryStyled: Style = {
  backgroundColor: backgroundCard,
  width: '48%',
  marginVertical: 2,
  marginHorizontal: 5,
  maxHeight: 180,
  flexDirection: 'row',
};

const ImageStyled: Style = {
  height: 60,
  width: 60,
  paddingTop: '8',
  objectFit: 'contain',
  objectPosition: '0% 40%',
  marginRight: '10',
};

const DescriptionStyled: Style = {
  paddingLeft: 5,
  fontSize: 10,
  flexWrap: 'wrap',
  marginRight: 20,
};

export const Accessory = (props: IAccessoryProps) => {
  return (
    <View style={AccessoryStyled} wrap={false}>
      <View>
        {String(props?.accessory?.image)?.includes('fragilebox') ? (
          <View style={ImageStyled} />
        ) : (
          <ImageOrPlaceholder source={props.accessory.image} style={ImageStyled} />
        )}
      </View>
      <View
        style={{
          paddingTop: '8',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '90%',
        }}>
        <Text style={DescriptionStyled}>
          {props?.accessory?.name ?? ''}
        </Text>
        <PriceOrIncluded
          value={getAccessoryCalculatedPriceByCustomerType(
            props?.accessory as IVehicleAccessory,
            props.order?.customer?.customerType,
          )}
        />
      </View>
    </View>
  );
};

interface IAccessoryProps {
  accessory: IAccessoryConfig;
  order?: IOrder;
}

interface IAccessoriesProps {
  accessories: IAccessoryConfig[];
  order?: IOrder;
}

export const Accessories = (props: IAccessoriesProps) => {
  return (
    <View style={SectionStyled}>
      <Text style={{ fontSize: 10, margin: '0 5 5', fontWeight: 'bold' }}>Tilbehør</Text>
      <View style={HorizontalWarpableListStyled}>
        {props?.accessories?.length > 0 &&
          props?.accessories?.map((a) => <Accessory key={uuidv4()} accessory={a} order={props?.order} />)}
        {(!props?.accessories || props?.accessories?.length < 1) && (
          <View style={NoneSelectedStyled}>
            <Text>Ingen valgt</Text>
          </View>
        )}
      </View>
    </View>
  );
};
