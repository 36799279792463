import { ApolloClient, useApolloClient } from '@apollo/client';
import { queryEmployee } from '../../graphql';
import { ERROR_POLICY } from '../constants';
import { useEffect, useState } from 'react';
import { IApiEmployee } from 'next-common';
import { useMsal } from '@azure/msal-react';

interface IEmployeeQueryResult {
  employee: IApiEmployee;
}

export const employeeQuery = async (
  client: ApolloClient<object>,
  email: string,
  onSuccess: (value: IApiEmployee) => void,
): Promise<void> => {
  try {
    const { data }: { data: IEmployeeQueryResult } = await client.query({
      query: queryEmployee,
      variables: {
        email: email,
      },
      errorPolicy: ERROR_POLICY,
    });
    const result = data?.employee;
    onSuccess(result);
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
    throw error;
  }
};

export const getEmployee = (email: string, client: ApolloClient<object>, onSuccess: (value: IApiEmployee) => void): void => {
  if (!email) return;
  employeeQuery(client, email, onSuccess).then(() => {
  }).catch(err => {
    const errorMsg = `Could not get employee details with email: "${email}"`;
    // tslint:disable-next-line:no-console
    console.error(errorMsg, err);
  });
};

export const useEmployee = (email?: string): IApiEmployee => {
  const client = useApolloClient();
  const { accounts } = useMsal();
  const [employee, setEmployee] = useState(null);

  if (!email) {
    email = accounts?.[0]?.username ?? null;
  }

  useEffect(() => {
    if (!employee) {
      getEmployee(email, client, setEmployee);
    }
  }, [email]);
  return employee;
};
