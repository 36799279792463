import React, { useState } from 'react';
import { CustomerType, IBosCustomer } from 'next-common';
import { CheckBox } from '@next-components/bos-checkbox';
import { Button } from '@next-components/cta';
import { CustomerFormType, CustomerForm } from './CustomerForm';
import { CustomerSelectedStyled, FormHeader } from './CustomerPage.styled';
import { ButtonStyled } from '../../../../sales-wizard/sales-header/SalesHeader.styled';

export interface ICustomerSelectedProps {
  customer: IBosCustomer;
  customerType: CustomerType;
  customerFormType: CustomerFormType;
  isSavingCustomer: boolean;
  queryString?: string;
  submitForm(formData: IBosCustomer): void;
  onClose?(): void;
}

export const Confirmation = (props) => (
  <div className="confirmation-checkbox">
    <CheckBox
      checked={props?.isCustomerConfirmed || false}
      label="Jeg bekrefter at informasjonen er riktig"
      id="confirmation-checkbox"
      onChange={() => props?.onConfirm(!props?.isCustomerConfirmed || false)}
    />
  </div>
);

const ConfirmationFooter = (props) => (
  <ButtonStyled className='customerDetailModal'>
    <Button
      className="custom-button"
      disabled={!props.isValidForm}
      onClick={props.submitForm}
      isLoading={props.isSavingCustomer}>
      <span>Lagre</span>
    </Button>
    <Button className="cancel-button cancel-button-font" variant="secondary" onClick={props.onClose}>
      <span className="text">Avbryt</span>
    </Button>
  </ButtonStyled>
);

export const CustomerSelected = (props: ICustomerSelectedProps) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [isCustomerConfirmed, setIsCustomerConfirmed] = useState(false);
  const [formData, setFormData] = useState<IBosCustomer>(null);
  const toggleCustomerConfirmed = (value) => {
    setIsCustomerConfirmed(value);
  };
  const updateValidation = (value: boolean) => {
    setIsValidForm(value);
  };

  const updatedFormData = (customer: IBosCustomer) => {
    setFormData(customer);
  };

  const submitForm = () => {
    if (typeof props.submitForm === 'function') {
      props.submitForm(formData);
    }
  };

  return (
    <CustomerSelectedStyled className="customerSelectedStyled">
      <FormHeader>
        <div className="headerText">Rediger kundedata</div>
      </FormHeader>
      <CustomerForm
        customer={props.customer}
        customerType={props.customerType}
        updatedFormData={updatedFormData}
        updateValidation={updateValidation}
      />
      <Confirmation isCustomerConfirmed={isCustomerConfirmed} onConfirm={(value) => toggleCustomerConfirmed(value)} />
      <ConfirmationFooter
        isValidForm={isValidForm && isCustomerConfirmed}
        submitForm={submitForm}
        isSavingCustomer={props.isSavingCustomer}
        onClose={props.onClose}
      />
    </CustomerSelectedStyled>
  );
};
