import { IconType } from '@next-components/bos-icon';
import { EnumMapper, IOrderEvent, OrderEventType, SigningType } from 'next-common';

export enum EventLevel {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Key = 'key',
}

export const signingTypeText = (type: string) => {
  switch (type) {
    case SigningType.DIGITAL.toString():
      return 'Digitalt';
    case SigningType.MANUAL.toString():
      return 'Manuelt';
    default:
      return 'Ukjent';
  }
};

export const serialNumberText = (serial: string) => {
  if (!serial) return 'Fabikkbestilt';
  return serial;
};

const eventDisplayProps = (type: OrderEventType): IOrderEventItem => {
  const display = {
    displayName: '?',
    eventLevel: EventLevel.Info,
  };

  switch (type) {
    case OrderEventType.CREATED:
      return { displayName: 'Opprettet', eventLevel: EventLevel.Key };
    case OrderEventType.DELIVERED:
      return { displayName: 'Utlevert', eventLevel: EventLevel.Key };
    case OrderEventType.PAUSED:
      return { displayName: 'Satt på pause', eventLevel: EventLevel.Info };
    case OrderEventType.RESUMED:
      return { displayName: 'Gjenopptatt', eventLevel: EventLevel.Info };
    case OrderEventType.CANCELLED:
      return { displayName: 'Kansellert', eventLevel: EventLevel.Warning };
    case OrderEventType.OFFER_EXPIRED:
      return { displayName: 'Tilbud utløpt', eventLevel: EventLevel.Warning };
    case OrderEventType.CONFIRMED:
      return { displayName: 'Ordre bekreftet', eventLevel: EventLevel.Key };
    case OrderEventType.VEHICLE_ADDED:
      return {
        displayName: 'Kjøretøy lagt til',
        eventLevel: EventLevel.Info,
        eventLabel: 'Serienummer:',
        formatter: serialNumberText,
      };
    case OrderEventType.FAILURE_VEHICLE_ADDED:
      return {
        displayName: 'Det oppstod en feil når kjøretøy ble lagt på ordre',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.VEHICLE_REMOVED:
      return {
        displayName: 'Kjøretøy fjernet',
        eventLevel: EventLevel.Info,
        eventLabel: 'Serienummer:',
        formatter: serialNumberText,
      };
    case OrderEventType.FAILURE_VEHICLE_REMOVED:
      return {
        displayName: 'Det oppstod en feil ved fjerning av kjøretøy',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.TRADEIN_ADDED:
      return { displayName: 'Innbyttebil lagt til', eventLevel: EventLevel.Info, eventLabel: 'Reg.nummer:' };
    case OrderEventType.TRADEIN_REMOVED:
      return { displayName: 'Innbyttebil fjernet', eventLevel: EventLevel.Info, eventLabel: 'Reg.nummer:' };
    case OrderEventType.DISCOUNT_ADJUSTED:
      return { displayName: 'Justert rabatt', eventLevel: EventLevel.Info, eventLabel: 'Ny rabatt:' };
    case OrderEventType.IMPORTER_DISCOUNT_ADJUSTED:
      return { displayName: 'Importørstøtte', eventLevel: EventLevel.Info, eventLabel: 'Ny importørstøtte:' };
    case OrderEventType.OFFER_CHOSEN:
      return {
        displayName: 'Finansieringsalternativ valgt',
        eventLevel: EventLevel.Key,
        eventLabel: 'Finansieringstype:',
      };
    case OrderEventType.FAILURE_CALCULATION_ADDED:
      return {
        displayName: 'Det oppstod en feil under opprettelse av nytt finansieringsalternativ',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.FAILURE_OFFER_CHOSEN:
      return {
        displayName: 'Det oppstod en feil under valg av finansieringsalternativ',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.CALCULATION_ADDED:
      return {
        displayName: 'Finansieringsalternativ lagt til',
        eventLevel: EventLevel.Info,
        eventLabel: 'Finansieringstype:',
      };
    case OrderEventType.CALCULATION_REMOVED:
      return {
        displayName: 'Finansieringsalternativ fjernet',
        eventLevel: EventLevel.Info,
        eventLabel: 'Finansieringstype:',
      };
    case OrderEventType.FAILURE_CALCULATION_REMOVED:
      return {
        displayName: 'Det oppstod en feil ved sletting av finansieringstype',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.FAILURE_VEHICLE_ACCESSORY_TOGGLE:
      return {
        displayName: 'Det oppstod en feil ved endring av tilbehør',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.CUSTOMER_ADDED:
      return { displayName: 'Kunde valgt', eventLevel: EventLevel.Key };
    case OrderEventType.CUSTOMER_REMOVED:
      return { displayName: 'Kunde fjernet', eventLevel: EventLevel.Key };
    case OrderEventType.FAILURE_CUSTOMER_ADD:
      return {
        displayName: 'Det oppstod en feil ved lagring av kunde',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.SIGNING_INITIATED:
      return { displayName: 'Signering startet', eventLevel: EventLevel.Key };
    case OrderEventType.FAILURE_SIGN_CONTRACT:
      return {
        displayName: 'Signering av kontrakt feilet',
        eventLevel: EventLevel.Error,
        eventLabel: 'Cid:',
      };
    case OrderEventType.CONTRACT_SIGNED:
      return {
        displayName: 'Kontrakt signert',
        eventLevel: EventLevel.Key,
        eventLabel: 'Signeringstype:',
        formatter: (toFormat) => signingTypeText(toFormat),
      };
    case OrderEventType.ACCESSORIES_UPDATED:
      return {
        displayName: 'Tilbehør endret',
        eventLevel: EventLevel.Info,
        formatter: (toFormat) => toFormat.replace('+', 'Lagt til').replace('-', 'Fjernet'),
      };
    case OrderEventType.FAILURE_FINANCE:
      return { displayName: 'Feil ved finansiering', eventLevel: EventLevel.Error };
    case OrderEventType.FINANCE_APPLICATION_SENT:
      return { displayName: 'Finansieringsøknad sent', eventLevel: EventLevel.Key };
    case OrderEventType.VEHICLE_ORDER_SENT:
      return { displayName: 'Bestilling sendt IFS', eventLevel: EventLevel.Key };
    case OrderEventType.FAILURE_IFS:
      return { displayName: 'Feil ved bestilling', eventLevel: EventLevel.Error };
    case OrderEventType.RESERVATION_WAITING:
      return { displayName: 'Reservasjon bestilt', eventLevel: EventLevel.Info, eventLabel: 'Serienummer:' };
    case OrderEventType.RESERVATION_SUCCESS:
      return { displayName: 'Reservasjon bekreftet', eventLevel: EventLevel.Key, eventLabel: 'Serienummer:' };
    case OrderEventType.RESERVATION_FAIL:
      return { displayName: 'Reservasjon feilet', eventLevel: EventLevel.Error, eventLabel: 'Serienummer:' };
    case OrderEventType.RESERVATION_CANCEL_WAITING:
      return {
        displayName: 'Sletting av reservasjon bestilt',
        eventLevel: EventLevel.Info,
        eventLabel: 'Serienummer:',
      };
    case OrderEventType.RESERVATION_CANCEL_SUCCESS:
      return {
        displayName: 'Sletting av reservasjon bekreftet',
        eventLevel: EventLevel.Key,
        eventLabel: 'Serienummer:',
      };
    case OrderEventType.RESERVATION_CANCEL_FAIL:
      return {
        displayName: 'Sletting av reservasjon feilet',
        eventLevel: EventLevel.Error,
        eventLabel: 'Serienummer:',
      };
    case OrderEventType.SERVICE_CONTRACT_ADDED:
      return {
        displayName: 'Servicekontrakt lagt til',
        eventLevel: EventLevel.Info,
        eventLabel: 'Servicekontrakttype:',
      };
    case OrderEventType.SERVICE_CONTRACT_REMOVED:
      return {
        displayName: 'Servicekontrakt fjernet',
        eventLevel: EventLevel.Info,
        eventLabel: 'Servicekontrakttype:',
      };
    case OrderEventType.SMS_SENT_SIGNING:
      return {
        displayName: 'SMS leveringsstatus',
        eventLevel: EventLevel.Info,
        eventLabel: 'Telefonnummer:',
      };
    case OrderEventType.UPDATE_BOS_CUSTOMER:
      return { displayName: 'Kunde oppdatert', eventLevel: EventLevel.Key };
    case OrderEventType.MB_API_FETCHED:
      return { displayName: 'MB API success', eventLevel: EventLevel.Key };
    case OrderEventType.MB_API_FETCHED_FAILED:
      return { displayName: 'MB API Feil', eventLevel: EventLevel.Error };
    case OrderEventType.SERIAL_NUMBER_REMOVED:
      return {
        displayName: 'Serienummer fjernet',
        eventLevel: EventLevel.Info,
      };
    case OrderEventType.CANCEL_ORDER_PENDING:
      return {
        displayName: 'Annulering av ordre i IFS pågår',
        eventLevel: EventLevel.Info,
      }
    case OrderEventType.IFS_CANCEL_ORDER_SUCCESS: 
      return {
        displayName: 'Annullering av ordre i IFS utført',
        eventLevel: EventLevel.Key
      }
    case OrderEventType.IFS_CANCEL_ORDER_FAILED: 
      return {
        displayName: 'Annullering av ordre i IFS feilet',
        eventLevel: EventLevel.Error
      }
    default:
      return display;
  }
};

export interface IOrderEventItem extends Partial<IOrderEvent> {
  displayName: string;
  eventLabel?: string;
  eventLevel: EventLevel;
  icon?: IconType;
  formatter?(toFormat: string): string;
  code?: string;
}

export const mapEvent = (event: IOrderEvent): IOrderEventItem => {
  const mappedType = EnumMapper.mapEnumStringValueToEnumValue(event, 'eventType', OrderEventType);
  return {
    ...event,
    ...mappedType,
    // @ts-ignore
    ...eventDisplayProps(mappedType.eventType),
  };
};
