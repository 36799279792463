import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputType } from '@next-components/common-types';
import { InputField } from '@next-components/input-field';
import { CheckBox } from '@next-components/bos-checkbox';
import { IOrder, IVehicle, IVehicleInput } from 'next-common';
import { isDemoOrder, isStockOrder, updateVehicleMutationParsed } from '../../../../../common';
import { addWeeks, endOfMonth, format, isSameDay, isSameMonth, isValid, startOfDay } from 'date-fns';
import 'date-fns/locale/nb';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';
export interface IDeliveryProps {
  order: IOrder;
  isCash: boolean;
}

interface DeliveryProps {
  id: string;
  input: IVehicleInput;
}

interface MutationProps {
  invoke: boolean;
  variables: DeliveryProps;
}

const findEstimatedDeliveryDate = (vehicle: IVehicle) => {
  let estimated = addWeeks(new Date(), 1);
  if (!vehicle?.estimatedDeliveryDate) return null;
  const saved = new Date(vehicle?.estimatedDeliveryDate); 
  return isValid(saved) ? saved : estimated;
};

const findLatestDeliveryDate = (vehicle: IVehicle) => {
  let latest = addWeeks(new Date(), 1);
  if (!vehicle?.latestDeliveryDate) return null;
  const saved = new Date(vehicle?.latestDeliveryDate);
  return isValid(saved) ? saved : latest;
};

const findIsDeliveryTimeUncertain = (vehicle: IVehicle) => {
  return vehicle?.isDeliveryTimeUncertain;
};

const MutationFragment = (props: MutationProps) => {
  const { invoke, variables } = props;
  const [saveVehicle] = useMutation(updateVehicleMutationParsed, { fetchPolicy: 'no-cache' });
  const updateMutation = async () => {
    try {
      await saveVehicle({ variables });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (invoke && variables) updateMutation();
  }, [invoke, variables]);
  return null;
};

export const Delivery = (props: IDeliveryProps) => {
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState(null);
  const [latestDeliveryDate, setLatestDeliveryDate] = useState(null);
  const [isDeliveryTimeUncertain, setIsDeliveryTimeUncertain] = useState<boolean>(false);

  const [changes, setChanges] = useState<IVehicleInput>();
  const [deliveryPayload, setDeliveryPayload] = useState<DeliveryProps>(null);
  const [invokeMutation, setInvokeMutation] = useState<boolean>(false);

  useEffect(() => {
    if (!props.order) return;
    const vehicle = props.order?.vehicles?.[0];
    if (!vehicle) return;
    const hasDates = !!vehicle?.latestDeliveryDate || !!vehicle?.estimatedDeliveryDate;
    if (!hasDates) return;
    const estimated = findEstimatedDeliveryDate(vehicle);
    const latest = findLatestDeliveryDate(vehicle);
    setEstimatedDeliveryDate(estimated);
    setLatestDeliveryDate(latest);
    setIsDeliveryTimeUncertain(findIsDeliveryTimeUncertain(vehicle));
  }, [props.order]);

  const updateVehicle = async (customChanges?: IVehicleInput) => {
    const variables = {
      id: props.order?.vehicles?.[0]?.id,
      input: customChanges ?? changes,
    };
    setDeliveryPayload(variables);
  };

  const onEstDeliveryChange = (value = null) => {
    const formattedEstDeliveryDate = value ? new Date(value) : null;
    if (
      !formattedEstDeliveryDate &&
      (!isValid(formattedEstDeliveryDate) || isSameDay(formattedEstDeliveryDate, estimatedDeliveryDate))
    ) {
      setEstimatedDeliveryDate(null);
      setChanges({
        ...changes,
        estimatedDeliveryDate: null,
      });
    } else {
      setEstimatedDeliveryDate(formattedEstDeliveryDate);
      setChanges({
        ...changes,
        estimatedDeliveryDate: format(startOfDay(formattedEstDeliveryDate), "yyyy-MM-dd'T'HH:mm:ss'Z'") ?? null,
      });
    }
  };

  const onLatestDeliveryChange = (value = null) => {
    const formattedDeliveryDate = value ? new Date(value) : null;
    if (
      !formattedDeliveryDate &&
      (!isValid(formattedDeliveryDate) || isSameMonth(formattedDeliveryDate, latestDeliveryDate))
    ) {
      setLatestDeliveryDate(null);
      setChanges({
        ...changes,
        latestDeliveryDate: null,
      });
    } else {
      setLatestDeliveryDate(formattedDeliveryDate);
      setChanges({ ...changes, latestDeliveryDate: endOfMonth(formattedDeliveryDate)?.toISOString() ?? null });
    }
  };

  const onIsDeliveryTimeUncertainChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeliveryTimeUncertain(value?.target?.checked);
    setChanges({ ...changes, isDeliveryTimeUncertain: value?.target?.checked });
    updateVehicle({ ...changes, isDeliveryTimeUncertain: value?.target?.checked });
  };

  useEffect(() => {
    if (deliveryPayload) {
      setChanges(null);
      setInvokeMutation(true);
    }
  }, [deliveryPayload]);

  return (
    <OrderAccordion label="Levering" isDisabled={isStockOrder(props?.order?.lead?.orderType) || isDemoOrder(props?.order?.lead?.orderType)}>
      <MutationFragment invoke={invokeMutation} variables={deliveryPayload} />
      <div className="input-block">
        <InputField
          label="Forventet leveringsdato"
          type={InputType.DATE}
          className="borderLight"

          onChange={(value) => onEstDeliveryChange(value)}
          value={estimatedDeliveryDate && format((estimatedDeliveryDate), 'yyyy-MM-dd')}
          placeholder="Velg dato"
          onBlur={() => updateVehicle()}
        />
        <InputField
          label="Seneste leveringstid"
          className="borderLight"
          type={InputType.MONTH}
          onChange={(value) => onLatestDeliveryChange(value)}
          value={latestDeliveryDate && format((latestDeliveryDate), 'yyyy-MM')}
          placeholder="Velg dato"
          onBlur={() => updateVehicle()}
        />
      </div>
      <div>
        <CheckBox
          id="isDeliveryTimeUncertain"
          className="dark small"
          label="Levering forventes mer enn 6 mnd etter kontraktsdato"
          checked={isDeliveryTimeUncertain}
          onChange={(value) => onIsDeliveryTimeUncertainChange(value)}
        />
      </div>
      </OrderAccordion>
  );
};
