import React, { useMemo } from 'react';
import { actions, initialState, IVehicleCtxProps, reducer } from '.';
import { VehicleContext } from '../contextStore';

export const VehicleContextProvider = (props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = useMemo(() => {
    return {
      addVehicleData: ({ modelId, variantId }: IVehicleCtxProps) => {
        dispatch({ type: actions.VEHICLE_CHANGE, payload: { modelId, variantId } });
      },
      getVehicleData: (): IVehicleCtxProps => {
        return state;
      },
    }
  }, [state]);

  return <VehicleContext.Provider value={value}>{props.children}</VehicleContext.Provider>;
};
