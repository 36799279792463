/**
 * This file won't be included in the index.ts for export as `import.meta.url` is not support in the jest
 * environment.
 */
const saleAllCarsWorker = () => {
  const worker = new Worker(
    new URL('../../components/sales/sales-wizard/sale-all-cars-worker/saleAllCarsWorkerHandler.ts', import.meta.url),
  );

  return worker;
};

export default saleAllCarsWorker;

export const saleAllCarsFilterWorker = () => {
  const worker = new Worker(
    new URL(
      '../../components/sales/sales-wizard/sale-all-cars-worker/saleAllCarsFilterWorkerHandler.ts',
      import.meta.url,
    ),
  );

  return worker;
};
