import styled from 'styled-components';
import { appContentNewMaxWidth, backgroundSite, wizardPageStyle } from '@next-components/common-styles';

export const SigningPageStyled = styled.div`
  display: grid;
  height: min-content;
  margin: 0 auto;
  min-height: calc(100vh - 32rem);
  background-color: ${backgroundSite};
  grid-template-columns: 5fr 7fr;
  grid-column-gap: 2.5rem;
  padding-bottom: 3rem;
  padding-top: 2rem;
  position: relative;
  transition: all 1s ease-in;
  width: 100%;
  ${wizardPageStyle};
  max-width: ${appContentNewMaxWidth};

  .signingSubHeader {
    padding-top: 3rem;
  }

  .missing-info {
    h3 {
      margin-top: 2.5rem;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 2rem;
    }
  }
  .leftContainer.signingPageStyle {
    overflow: hidden !important;
  }
`;
