import { ITradeInVehicle } from 'next-common';

export const saveTradeIn = async (orderId: string, tradeIn: ITradeInVehicle, updateTradeInMutation): Promise<any> => {
  const { capital, ...input } = tradeIn;
  const variables = {
    orderId,
    tradeIn: input,
  };
  try {
    const { data } = await updateTradeInMutation({ variables });
    return data.saveTradeIn;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};

export const removeTradeIn = async (orderId: string, tradeInId: string, removeTradeInMutation): Promise<any> => {
  const variables = {
    orderId,
    tradeInId,
  };
  try {
    const { data } = await removeTradeInMutation({ variables });
    return data.removeTradeIn;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};
