import styled from 'styled-components';
import { bosColor } from '@next-components/common-styles';

export const AgreementInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const AgreementInfoItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  border-bottom: 1px solid ${bosColor.neutralGrey};

  &:first-child {
    border-top: 1px solid ${bosColor.neutralGrey};
  }
  &.flexColumn {
    flex-direction: column;
    gap: 1rem;

    &.halfGap {
      gap: 0.5rem;
    }
    &.quarterGap {
      gap: 0.25rem;
    }
  }
  &.lastItem {
    border-bottom: none;
  }
  .infoItem {
    width: 100%;
    gap: 2rem;

    .label {
      flex: 1 0 0;
    }
  }
`;
