import { ICartProps, ICartSummaryData } from "@next-components/cart";
import { IRulesTable } from "../../common";
import { IEquipmentIdAndSKU, IMappedEquipment } from "sales-crystallize-common";
import { cloneDeep, isNil } from "lodash";
import { getFactoryEquipmentLevelForFactoryOrder, getOneOffTaxFromEquipments, IEquipmentTypes, INextArticle, invokeProvidedBrandSpecificCode, IVehicleConfiguration, IVehicleEquipment, MAKE, VehicleEquipmentCategoryEnum } from "next-common";
import { prepareVehicleConfig, VehicleConfig } from "./vehicleOrder";
import { IRuleValues } from "../../components/common-components/RulesPopup/RulesDetail";
import { IModelPackages } from "../../components/sales/sales-vehicle-picker-page";
import { ArticleType, VatCode } from "sales-common";
import { prepareArticles } from "./vat";
import { MainCategory } from "./rules";

export interface IArticlesResponse {
    updatedArticles: INextArticle[],
    isOneOffTaxError: boolean,
};

export interface ICurrentSelections {
    selectedColorId: string;
    selectedInteriorId: string;
    selectedEquipmentIds: IEquipmentTypes[];
    selectedRims: IEquipmentTypes;
    selectedListverk: IEquipmentTypes;
    selectedTaklakk: IEquipmentTypes,
};

export interface ISelectionData {
    equipmentIds: IEquipmentTypes[],
    rimIds: IEquipmentTypes,
    colorId: string,
    interiorId: string;
    listverkData: IEquipmentTypes,
    taklakkData: IEquipmentTypes,
};

export interface ISelectionDataWithPayload extends ISelectionData {
    rulesPayload: string[];
    rulesPayloadWithType?: { [key: string]: string; };
}

const getCartSummaryDataByCategory = (
    tableData: IRuleValues,
    equipment: IMappedEquipment
): ICartSummaryData => {
    let summaryData: ICartSummaryData = null;
    switch (equipment?.mainCategory) {
        case MainCategory.Utstyr: {
            if (equipment?.category === VehicleEquipmentCategoryEnum.Listverk) {
                summaryData = prepareVehicleConfig(
                    VehicleEquipmentCategoryEnum.Listverk,
                    tableData?.sku,
                    tableData?.name,
                    tableData?.price,
                    equipment?.vatCode,
                );
            } else if (equipment?.category === VehicleEquipmentCategoryEnum.Taklakk) {
                summaryData = prepareVehicleConfig(
                    VehicleEquipmentCategoryEnum.Taklakk,
                    tableData?.sku,
                    tableData?.name,
                    tableData?.price,
                    equipment?.vatCode,
                );
            } else {
                summaryData = prepareVehicleConfig(
                    `${VehicleConfig.SELECTABLE_EQUIPMENT}_${equipment?.id || ''}`,
                    tableData?.sku,
                    tableData?.name,
                    tableData?.price,
                    equipment?.vatCode,
                )
            }
            break;
        }
        case MainCategory.Lakk: {
            summaryData = prepareVehicleConfig(
                VehicleConfig.COLOR,
                tableData?.sku,
                tableData?.name,
                tableData?.price,
                equipment?.vatCode,
            );
            break;
        }
        case MainCategory.Felger: {
            summaryData = prepareVehicleConfig(
                VehicleConfig.RIM,
                tableData?.sku,
                tableData?.name,
                tableData?.price,
                equipment?.vatCode,
            );
            break;
        }
        case MainCategory.Interiør: {
            summaryData = prepareVehicleConfig(
                VehicleConfig.INTERIOR,
                tableData?.sku,
                tableData?.name,
                tableData?.price,
                equipment?.vatCode,
            );
            break;
        }
    }

    return summaryData;
};

const getUpdatedCartSummaryFromRulesTable = (
    rulesTableData: IRulesTable,
    existingCartData: ICartProps,
    mappedEquipment: IEquipmentIdAndSKU[],
): ICartSummaryData[] => {
    let updatedSummaryCartData: ICartSummaryData[] = null;
    
    const removeSkus = rulesTableData?.ruleRemoveVaules?.map((value) => {
        return value?.sku;
    });
    if (!isNil(rulesTableData?.ruleRemoveVaules)) {
        const selectSkus = rulesTableData?.ruleAddVaules?.map((value) => {
            return value?.sku;
        });
        updatedSummaryCartData = existingCartData?.data?.filter((cartData) => {
            return !removeSkus?.includes(cartData?.id) && !selectSkus?.includes(cartData?.id);
        });
    }

    if (!isNil(rulesTableData?.ruleAddVaules)) {
        for (const tableData of rulesTableData?.ruleAddVaules || []) {
            const isSKUAvailableInRemovedList = removeSkus?.includes(tableData?.sku)
            if(!isSKUAvailableInRemovedList) {
                const selectedEquipment: IMappedEquipment = mappedEquipment?.find((equipment) => {
                    return equipment?.sku === tableData?.sku;
                });
                updatedSummaryCartData.push(
                    getCartSummaryDataByCategory(tableData, selectedEquipment)
                );
            }
        }
    }

    return updatedSummaryCartData;

};

export const prepareCartDataFromRulesTable = (
    rulesTableData: IRulesTable,
    existingCartData: ICartProps,
    mappedEquipment: IEquipmentIdAndSKU[],
): ICartProps => {
    const updatedSummaryCartData: ICartSummaryData[] = getUpdatedCartSummaryFromRulesTable(
        rulesTableData,
        existingCartData,
        mappedEquipment
    );
    const updatedCartData: ICartProps = {
        ...existingCartData,
        data: updatedSummaryCartData,
        prices: {
            ...existingCartData?.prices,
            salesTotalExcludingVatAndTax: rulesTableData.popupPrice > 0 ? rulesTableData.popupPrice :
                existingCartData?.prices?.salesTotalExcludingVatAndTax - rulesTableData.popupPrice
        }
    }

    return updatedCartData;
};

export const isPreselectedAndNotToBeRemoved = (
    removeSkus: string[],
    equipment: IEquipmentTypes,
    currentSelections: IEquipmentTypes[],
) => {
    return (!removeSkus?.includes(equipment?.sku) && currentSelections?.find((equipmentValue) => {
        return equipmentValue?.sku === equipment?.sku
    }))
}

export const getAndPrepareSelectedDataFromRulesTable = (
    rulesTableData: IRulesTable,
    currentSelections?: ICurrentSelections,
    currentVariantData?: IModelPackages[],
  ): ISelectionDataWithPayload => {
    const { equipmentIds, rimIds, colorId, interiorId, listverkData, taklakkData } = getSelectionDataFromRulesTable(
      currentVariantData?.[0],
      rulesTableData,
      currentSelections,
    );
    const [rulesPayload]: [string[], { [key: string]: string }] = prepareRulesPayloadFromSelectedData({
      equipmentIds,
      rimIds,
      colorId,
      interiorId,
      listverkData,
      taklakkData,
    });
    return { equipmentIds, rimIds, colorId, interiorId, listverkData, taklakkData, rulesPayload }
};
  
export const getSelectionDataFromRulesTable = (
    currentVariantData: IModelPackages,
    rulesTableData: IRulesTable,
    currentSelections: ICurrentSelections,
): ISelectionData => {
    const selectSkus = rulesTableData?.ruleAddVaules?.map((value) => {
        return value?.sku;
    });

    const removeSkus = rulesTableData?.ruleRemoveVaules?.map((value) => {
        return value?.sku;
    });

    const equipmentIds = currentVariantData?.selectableEquipment?.filter((equipmentId) => {
        if (equipmentId?.category !== VehicleEquipmentCategoryEnum.Listverk &&
            ((!removeSkus?.includes(equipmentId?.sku) &&
                selectSkus?.includes(equipmentId?.sku)) ||
                isPreselectedAndNotToBeRemoved(removeSkus, equipmentId, currentSelections?.selectedEquipmentIds)
            )) {
            return true;
        }
    });

    const listverkData = currentVariantData?.selectableEquipment?.find((equipmentId) => {
        if (equipmentId?.category === VehicleEquipmentCategoryEnum.Listverk &&
            ((!removeSkus?.includes(equipmentId?.sku) &&
                selectSkus?.includes(equipmentId?.sku)) ||
                isPreselectedAndNotToBeRemoved(removeSkus, equipmentId, [currentSelections?.selectedListverk])
            )) {
            return true;
        }
    });

    const taklakkData = currentVariantData?.selectableEquipment?.find((equipmentId) => {
        if (equipmentId?.category === VehicleEquipmentCategoryEnum.Taklakk &&
            ((!removeSkus?.includes(equipmentId?.sku) &&
                selectSkus?.includes(equipmentId?.sku)) ||
                isPreselectedAndNotToBeRemoved(removeSkus, equipmentId, [currentSelections?.selectedTaklakk])
            )) {
            return true;
        }
    });

    const rimIds = currentVariantData?.rims?.find((equipmentId) => {
        if ((!removeSkus?.includes(equipmentId?.sku) && selectSkus?.includes(equipmentId?.sku)) ||
            (!removeSkus?.includes(equipmentId?.sku) && currentSelections?.selectedRims?.sku === equipmentId?.sku)) {
            return true;
        }
    });

    const colorId = currentVariantData?.colors?.find((equipmentId) => {
        if ((!removeSkus?.includes(equipmentId?.varnishCode) && selectSkus?.includes(equipmentId?.varnishCode)) ||
            (!removeSkus?.includes(equipmentId?.varnishCode) && currentSelections?.selectedColorId === equipmentId?.varnishCode)) {
            return true;
        }
    })?.varnishCode;

    const interiorId = currentVariantData?.interiors?.find((equipmentId) => {
        if (!removeSkus?.includes(equipmentId?.interiorCode) && selectSkus?.includes(equipmentId?.interiorCode) ||
            (!removeSkus?.includes(equipmentId?.interiorCode) && currentSelections?.selectedInteriorId === equipmentId?.interiorCode)
        ) {
            return true;
        }
    })?.interiorCode;

    return {
        equipmentIds,
        rimIds,
        colorId,
        interiorId,
        listverkData,
        taklakkData,
    };
};

export const prepareRulesPayloadFromSelectedData = ({
    equipmentIds,
    rimIds,
    colorId,
    interiorId,
    listverkData,
    taklakkData,
}: ISelectionData): [string[], { [key: string]: string; }] => {
    const rulesPayload: string[] = [];
    let rulesPayloadWithType: { [key: string]: string; } = {};

    equipmentIds?.forEach?.((equipment) => {
        rulesPayload?.push(equipment?.sku);
        rulesPayloadWithType = { ...rulesPayloadWithType, [equipment?.sku]: VehicleConfig.SELECTABLE_EQUIPMENT}
    });
    if (rimIds?.sku) {
        rulesPayload?.push(rimIds?.sku);
        rulesPayloadWithType = { ...rulesPayloadWithType, [rimIds?.sku]: VehicleConfig.RIM}
    }
    if (colorId) {
        rulesPayload?.push(colorId);
        rulesPayloadWithType = { ...rulesPayloadWithType, [colorId]: VehicleConfig.COLOR}
    }
    if (interiorId) {
        rulesPayload?.push(interiorId);
        rulesPayloadWithType = { ...rulesPayloadWithType, [interiorId]: VehicleConfig.INTERIOR}
    }
    if (listverkData?.sku) {
        rulesPayload?.push(listverkData?.sku);
        rulesPayloadWithType = { ...rulesPayloadWithType, [listverkData?.sku]: VehicleEquipmentCategoryEnum.Listverk}
    }
    if (taklakkData?.sku) {
        rulesPayload?.push(taklakkData?.sku);
        rulesPayloadWithType = { ...rulesPayloadWithType, [taklakkData?.sku]: VehicleEquipmentCategoryEnum.Taklakk}
    }

    return [rulesPayload, rulesPayloadWithType];
};

export const getSelectedRimsFromRulesTable = (
    selectSkus: string[],
    removeSkus: string[],
    equipmentTypes: IEquipmentTypes[],
): IEquipmentTypes => {
    const clonedEquipmentTypes = cloneDeep(equipmentTypes);
    const equipmentIds = clonedEquipmentTypes?.find((equipmentId) => {
        if (!removeSkus?.includes(equipmentId?.sku) && selectSkus?.includes(equipmentId?.sku)) {
            return true;
        }
    });

    return equipmentIds;
};

export const getSelectEquipmentIdsFromRulesTable = (
    selectSkus: string[],
    removeSkus: string[],
    equipmentTypes: IEquipmentTypes[],
): IEquipmentTypes[] => {
    const clonedEquipmentTypes = cloneDeep(equipmentTypes);
    const equipmentIds = clonedEquipmentTypes?.filter((equipmentId) => {
        if (!removeSkus?.includes(equipmentId?.sku) && selectSkus?.includes(equipmentId?.sku) && equipmentId?.category !== VehicleEquipmentCategoryEnum.Listverk) {
            return true;
        }
    });

    return equipmentIds;
};

export const mapSelectabeleEquipment = (equip: IVehicleEquipment) => ({
    id: equip?.pimId,
    name: equip?.name,
    sku: equip?.sku,
    pimSku: equip?.pimSku,
    manufacturerComponentId: equip?.factoryCodeId ?? null,
    priceIncludingVat: equip?.priceIncludingVat,
    priceExcludingVat: equip?.priceExcludingVat,
    marginAmount: equip?.marginAmount,
    marginPercentage: equip?.marginPercentage,
    isHighlight: equip?.isHighlight,
    retailPrice: equip?.retailPrice,
    category: equip?.category,
    vatCode: equip?.vatCode,
    unavailableForFactoryOrder: equip?.unavailableForFactoryOrder,
});

// use from here in picker page
export const filterAccessoryFromArticles = (existingArticles: INextArticle[], isOptimisticUpdate: boolean = false) => {
    let filteredArticles: INextArticle[] = null;
    if (existingArticles && existingArticles?.length > 0) {
        filteredArticles = existingArticles?.filter((article) =>
            isOptimisticUpdate ? article : article.articleType !== ArticleType.ACCESSORY,
        );
        return filteredArticles;
    }
    return existingArticles;
};

export const prepareArticlesBasedOnType = (
    equipment: IMappedEquipment,
    tableData: IRuleValues,
    existingArticles: INextArticle[],
) => {
    let newArticles = [...filterAccessoryFromArticles(existingArticles)];

    switch (tableData?.mainCategory) {
        case MainCategory.Utstyr: {
            if (equipment?.category === VehicleEquipmentCategoryEnum.Listverk) {
                newArticles = [...prepareArticles(
                    VehicleEquipmentCategoryEnum.Listverk,
                    tableData?.price,
                    equipment?.vatCode,
                    ArticleType.EQUIPMENT,
                    tableData?.price,
                    false,
                    newArticles,
                    false,
                    equipment?.sku,
                )];
            } else if (equipment?.category === VehicleEquipmentCategoryEnum.Taklakk) {
                newArticles = [...prepareArticles(
                    VehicleEquipmentCategoryEnum.Taklakk,
                    tableData?.price,
                    equipment?.vatCode,
                    ArticleType.EQUIPMENT,
                    tableData?.price,
                    false,
                    newArticles,
                    false,
                    equipment?.sku,
                )];
            } else {
                newArticles = [...prepareArticles(
                    `${VehicleConfig.SELECTABLE_EQUIPMENT}_${equipment?.id || ''}`,
                    tableData?.price,
                    equipment?.vatCode,
                    ArticleType.EQUIPMENT,
                    tableData?.price,
                    false,
                    newArticles,
                    false,
                    tableData?.sku,
                )];
            }
            break;
        }
        case MainCategory.Lakk: {
            newArticles = [...prepareArticles(
                VehicleConfig.COLOR,
                tableData?.price,
                equipment?.vatCode,
                ArticleType.EQUIPMENT,
                tableData?.price,
                false,
                newArticles,
                false,
                tableData?.sku,
            )];
            break;
        }
        case MainCategory.Felger: {
            newArticles = [...prepareArticles(
                VehicleConfig.RIM,
                tableData?.price,
                equipment?.vatCode,
                ArticleType.EQUIPMENT,
                tableData?.price,
                false,
                newArticles,
                false,
                tableData?.sku,
            )];
            break;
        }
        case MainCategory.Interiør: {
            newArticles = [...prepareArticles(
                VehicleConfig.INTERIOR,
                tableData?.price,
                equipment?.vatCode,
                ArticleType.EQUIPMENT,
                tableData?.price,
                false,
                newArticles,
                false,
                tableData?.sku,
            )];
            break;
        }
    }

    return newArticles;
};

export const getOneOffTaxForKia = (
    vehicleConfiguration: IVehicleConfiguration,
    equipments: IEquipmentTypes[],
    existingArticles: INextArticle[],
): IArticlesResponse => {
    let isOneOffTaxError: boolean = false;
    let oneOffTaxArticles: INextArticle[] = cloneDeep(existingArticles);
    let kiaOneOffTax = getOneOffTaxFromEquipments(vehicleConfiguration, equipments);
    let factoryEquipmentLevel = getFactoryEquipmentLevelForFactoryOrder(vehicleConfiguration, equipments);
    if (isNil(kiaOneOffTax) || isNil(factoryEquipmentLevel)) {
        isOneOffTaxError = true;
    } else {
        oneOffTaxArticles = [...prepareArticles(
            VehicleConfig.TAREWEIGHT,
            kiaOneOffTax,
            VatCode.NOT_APPLICABLE,
            ArticleType.TAREWEIGHT,
            0,
            false,
            oneOffTaxArticles,
            false,
        ),]
    }

    return {
        isOneOffTaxError,
        updatedArticles: oneOffTaxArticles,
    }
};

export const prepareArticlesFromRulesTable = (
    rulesTableData: IRulesTable,
    existingArticles: INextArticle[],
    mappedEquipment: IEquipmentIdAndSKU[],
    vehicleConfiguration: IVehicleConfiguration,
): IArticlesResponse => {
    let isOneOffTaxError: boolean = false;
    const selectSkus = rulesTableData?.ruleAddVaules?.map((value) => {
        return value?.sku;
    });
    const removeSkus = rulesTableData?.ruleRemoveVaules?.map((value) => {
        return value?.sku;
    });
    const clonedArticles = cloneDeep(existingArticles);

    let updatedArticles = clonedArticles?.filter((article) => {
        if (!selectSkus?.includes(article?.id) && !removeSkus?.includes(article?.id)) {
            return true;
        }
    })

    rulesTableData?.ruleAddVaules?.forEach((tableData) => {
        const isSKUAvailableInRemovedList = removeSkus?.includes(tableData?.sku)
        if(!isSKUAvailableInRemovedList) {
            const selectedEquipment: IMappedEquipment = mappedEquipment?.find((equipment) => {
                return equipment?.sku === tableData?.sku;
            });
            updatedArticles = prepareArticlesBasedOnType(
                selectedEquipment,
                tableData,
                updatedArticles,
            )
        }
    });

    if (invokeProvidedBrandSpecificCode(MAKE.Kia, { vehicleConfiguration })) {
        const selectedEquipments = vehicleConfiguration?.model?.selectableEquipment?.filter((equipment) => {
            if (
                !removeSkus?.includes(equipment?.sku) &&
                selectSkus?.includes(equipment?.sku) &&
                equipment?.category !== VehicleEquipmentCategoryEnum.Listverk &&
                equipment?.category !== VehicleEquipmentCategoryEnum.Taklakk
            ) {
                return true;
            }
        })
        const kiaArticlesResponse = getOneOffTaxForKia(
            vehicleConfiguration,
            selectedEquipments,
            updatedArticles
        );
        updatedArticles = kiaArticlesResponse?.updatedArticles;
        isOneOffTaxError = kiaArticlesResponse?.isOneOffTaxError;
    }


    return {
        updatedArticles,
        isOneOffTaxError,
    };
};