import styled from 'styled-components';
import {
  backgroundCard,
  backgroundSite,
  black,
  fontWeightBlack,
  primary,
  secondary,
  white,
} from '@next-components/common-styles';
import { EclipsTextStyled } from '../../Assets';

export const DetailsTableStyled = styled(EclipsTextStyled)`
  fade {
    @include transition($transition-fade);

    &:not(.show) {
      opacity: 0;
    }
  }

  .collapse {
    &:not(.show) {
      display: none;
    }
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .boldFont {
    font-weight: ${fontWeightBlack};
  }

  .underline {
    text-decoration: underline;
  }

  .accordion-table {
    .card {
      border: 0;
      .card-header {
        padding: 0;
        border: 0;
        background-color: transparent;
        th {
          border-bottom: 0;
        }
      }
      .card-body {
        padding: 0;
      }
    }
  }

  .plus-minus-btn {
    background-color: ${primary};
    border-radius: 20px;
    color: ${white};
    padding: 5px 15px 0;
    font-weight: bold;
    margin-top: 5px;
    .plusButton {
      font-size: 24px;
      line-height: 20px;
    }
    .minusButton {
      font-size: 38px;
      position: relative;
      top: 2px;
      line-height: 20px;
    }
  }

  .mainTbl {
    margin-bottom: 45px;

    .devider td {
      border-top: 1px solid ${black};
      margin: 0;
      padding: 0;
    }

    tr th {
      border-top: 0;
      font-weight: 600;
      border-bottom: 1px solid ${black};
      width: 25%;

      &:first-child {
        border-top: 0;
        font-size: 22px;
        color: ${black};
      }
    }
    tr {
      .tablerowspacing {
        padding-bottom: 5px;
      }
      th,
      td {
        font-size: 14px;
        color: ${black};
        padding: 5px 8px;
        &.table-padding {
          padding: 1rem;
        }
        &:nth-child(2) {
          background-color: ${backgroundCard};
        }
      }

      td {
        border: 0;
      }

      th {
        &.bottom-content {
          vertical-align: bottom;
        }
      }
    }

    &.sub tr:last-child td:nth-child(2) {
      border-radius: 0 0 10px 10px;
    }

    &.sub thead tr th {
      border-radius: 0;
    }
  }
  .detail-table-bg {
    padding-top: 4rem;
    background-color: ${backgroundSite};
    .card {
      background-color: transparent;
    }
  }
  .primary-link {
    color: ${secondary};
    .text {
      font-size: 1rem;
    }
  }
  .content-padding {
    padding: 2rem;
  }
  .center-content {
    text-align: center;
  }
  .right-content {
    text-align: right;
  }
`;
