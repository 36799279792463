import { HashMap, ITireSet, IVehicleConfiguration, IVehicleConfigurationPricing, MAKE } from 'next-common';
import { IVehicleStock } from '../../../common';
import { PropertiesTableValue, ArticleType, IArticle, VatCode } from 'sales-common';
import { cloneDeep, defaultTo, isEmpty } from 'lodash';
import {  calculatePricesBasedOnArticleList, mapVehicleApplicationCodeStringToEnum, mapVatCodeStringToEnum } from 'sales-common-calculations';
import { IEquipmentIdAndSKU, getUpdatedConjunctionPrices } from 'sales-crystallize-common';
const DELIMETER = "^";
export interface ISalesPackageOptionValueAdditional {
  id?: string;
  marginAmount?: number;
  marginPercentage?: number;
  isHighlight?: boolean;
  unavailableForFactoryOrder?: boolean;
  manufacturerComponentId?: string; 
  vatCode?: string;
}

export interface ISalesPackageOptionValue extends ISalesPackageOptionValueAdditional {
  name: string;
  sku: string;
  pimSku?: string;
  priceIncludingVat: number;
  priceExcludingVat: number;
  type: string;
  category?: string;
  retailPrice?: number;
}

type SalesPackageOptionType = {
  [key: string]: ISalesPackageOptionValue;
};

export interface IVehicleStockSegregatedType {
  packageSku: string;
  unAvailableSkus: string[];
  interior: ISalesPackageOptionValue[];
  interiorSelectable: ISalesPackageOptionValue[];
  exteriorSelectable: ISalesPackageOptionValue[];
  listverkSelectable: ISalesPackageOptionValue[];
  taklakkSelectable: ISalesPackageOptionValue[];
  rims: ISalesPackageOptionValue[];
  varnish: ISalesPackageOptionValue[];
  interiorName?: string;
  totalPrice?: number;
}

export interface IVehicleStockWithSegregatedTypes extends IVehicleStock, IVehicleStockSegregatedType { }

type IRules = PropertiesTableValue & { sku: string };

interface IMappedEquipment {
  mappedEquipment: IEquipmentIdAndSKU[]
}

const prepareArticle = (priceExVat: number, vatCode: VatCode, articleType: ArticleType) => {
  return {
    priceExclVat: defaultTo(priceExVat, 0),
    vatCode: defaultTo(vatCode, null),
    articleType: articleType,
  };
}

const calculateTotalPrice = (
  pricing: IVehicleConfigurationPricing,
  tires: ITireSet[],
  equipmentArticles: IArticle[],
) => {
  const articles = [...equipmentArticles];
  const vatCode = mapVatCodeStringToEnum(pricing?.vatCode);
  articles.push(prepareArticle(pricing?.carPriceExclVatAndFees, vatCode, ArticleType.VEHICLE));
  articles.push(prepareArticle(pricing?.packagePriceExclVat, vatCode, ArticleType.PACKAGE));
  articles.push(prepareArticle(pricing?.extraDeliveryCharge, vatCode, ArticleType.DELIVERY));
  articles.push(prepareArticle(pricing?.extraWreckage, VatCode.NOT_APPLICABLE, ArticleType.SCRAPDEPOSIT));
  articles.push(prepareArticle(pricing?.oneOffTaxWeight, VatCode.NOT_APPLICABLE, ArticleType.TAREWEIGHT));
  articles.push(prepareArticle(Math.abs(pricing?.customerAdvantageExcludingVat), VatCode.NOT_APPLICABLE, ArticleType.DISCOUNT_AMOUNT));

  if(tires?.length) {
    const tireVatCode = tires?.[0]?.elWithoutVat ? VatCode.DEDUCTABLE : mapVatCodeStringToEnum(tires?.[0]?.vatCode);
    articles.push(prepareArticle(tires?.[0]?.additionalPriceExcludingVat, tireVatCode, ArticleType.WINTERTIRES));
  }
  const vehicleApplicationCode = mapVehicleApplicationCodeStringToEnum('EL');
  const result = calculatePricesBasedOnArticleList({ articles: cloneDeep(articles), vehicleApplicationCode});
  return Math.round(result?.totalPriceInclVatInclTaxInclDiscount ?? 0);
};

export const vehicleSegregationWorkerHelper = () => {
  const vehicleSegragationConst = {
    Varnish: 'varnish',
    Interior: 'interior',
    Selectable: 'Selectable',
    Rim: 'Rims',
  };
  const vehicleSegregationSelectableCategoryConst = {
    Interior: 'Interior',
    Exterior: 'Exterior',
    Listverk: 'Listverk',
    Taklakk: 'Taklakk',
  };

  const createVehicleQueryHashMaps = (data, hashMap) => {
    const varnishMap: SalesPackageOptionType = {};
    const interiorMap: SalesPackageOptionType = {};
    const selectableEquipmentMap: SalesPackageOptionType = {};
    const rimEquipmentMap: SalesPackageOptionType = {};

    data?.varnishes?.forEach((varnish) => {
      varnishMap[varnish?.varnishCode] = {
        id: varnish?.id,
        name: varnish?.name,
        sku: varnish?.varnishCode,
        pimSku: varnish?.pimSku,
        priceIncludingVat: varnish?.additionalPrice,
        priceExcludingVat: varnish?.additionalPriceExcludingVat,
        type: vehicleSegragationConst.Varnish,
        retailPrice: varnish?.retailPrice,
        marginPercentage: varnish?.marginPercentage,
        manufacturerComponentId: varnish?.manufacturerComponentId,
        vatCode: varnish?.vatCode,
      };
    });
    data?.interiors?.forEach((interior) => {
      interiorMap[interior?.interiorCode] = {
        id: interior?.id,
        name: interior?.name,
        sku: interior?.interiorCode,
        pimSku: interior?.pimSku,
        priceIncludingVat: interior?.additionalPrice,
        priceExcludingVat: interior?.additionalPriceExcludingVat,
        type: vehicleSegragationConst?.Interior,
        retailPrice: interior?.retailPrice,
        marginPercentage: interior?.marginPercentage,
        manufacturerComponentId: interior?.manufacturerComponentId,
        vatCode: interior?.vatCode,
      };
    });
    data?.model?.selectableEquipment?.forEach((selectableEquip) => {
      selectableEquipmentMap[selectableEquip?.sku] = {
        name: selectableEquip?.name,
        sku: selectableEquip?.sku,
        pimSku: selectableEquip?.pimSku,
        priceIncludingVat: selectableEquip?.priceIncludingVat,
        priceExcludingVat: selectableEquip?.priceExcludingVat,
        type: vehicleSegragationConst.Selectable,
        category: selectableEquip?.category,
        id: selectableEquip?.id,
        isHighlight: selectableEquip?.isHighlight,
        marginAmount: selectableEquip?.marginAmount,
        marginPercentage: selectableEquip?.marginPercentage,
        unavailableForFactoryOrder: selectableEquip?.unavailableForFactoryOrder,
        retailPrice: selectableEquip?.retailPrice,
        vatCode: selectableEquip?.vatCode,
        manufacturerComponentId: selectableEquip?.manufacturerComponentId
      };
    });
    data?.model?.rims?.forEach((rimEquip) => {
      rimEquipmentMap[rimEquip?.sku] = {
        name: rimEquip?.name,
        sku: rimEquip?.sku,
        pimSku: rimEquip?.pimSku,
        priceIncludingVat: rimEquip?.priceIncludingVat,
        priceExcludingVat: rimEquip?.priceExcludingVat,
        type: vehicleSegragationConst.Rim,
        id: rimEquip?.id,
        isHighlight: rimEquip?.isHighlight,
        marginAmount: rimEquip?.marginAmount,
        marginPercentage: rimEquip?.marginPercentage,
        unavailableForFactoryOrder: rimEquip?.unavailableForFactoryOrder,
        retailPrice: rimEquip?.retailPrice,
        vatCode: rimEquip?.vatCode,
        manufacturerComponentId: rimEquip?.manufacturerComponentId
      };
    });
    const pricingMap = {
      ['pricing']: data?.pricing,
    };
    const tiresMap = {
      ['tires']: data?.tires?.filter(tire => tire?.isRecommended),
    }

    const mappedEquipmentMap = {
      ['mappedEquipment']: data?.mappedEquipment,
    }

    hashMap?.set(data?.sku, Object.assign(varnishMap, interiorMap, selectableEquipmentMap, rimEquipmentMap, pricingMap, tiresMap, mappedEquipmentMap));
  };

  const getOptions = (stock: IVehicleStock) => {
    return (stock?.brand === MAKE.Peugeot || stock?.brand === MAKE.Mercedes) ? stock?.priceOptions : stock?.optionCode;
  };

  const getPackageSku = (packageOption: string[], stock: IVehicleStock) => {
    return packageOption?.length ? `${stock?.modelId || ''} ${packageOption.join('-')}` : stock?.modelId;
  };

  const filterUnavailableSku = (interior, stock: IVehicleStock, salesPackageQueryMap, unAvailableSkus) => {
    let inValidSku = unAvailableSkus;

    // PEU will accept vehicle which have price options with 2 characters.
    if (stock?.brand === MAKE.Peugeot) {
      inValidSku = inValidSku?.filter(sku => sku?.length > 2);
    }

    // If interior (price-options) and interiorCode from vehicle-stock not there in PIM, then unavailable stock.
    if (!interior?.length && stock?.interiorCode && !!salesPackageQueryMap && !salesPackageQueryMap?.[stock?.interiorCode]) {
      inValidSku?.push(stock?.interiorCode)
    }
    return inValidSku;
  };

  const getSkuPrice = (salesPackageQueryMap: IMappedEquipment, priceOptions: string[]) => {
    if (!isEmpty(salesPackageQueryMap?.mappedEquipment) && priceOptions?.length) {
      return getUpdatedConjunctionPrices(priceOptions.join(';'), salesPackageQueryMap.mappedEquipment);
    }
    return [];
  };

  const updateSelectablePrices = (equipment: ISalesPackageOptionValue, rules: IRules[]) => {
    if (!equipment) return null;
    const skuData = { ...equipment };
    rules?.forEach((rule) => {
      if (skuData?.sku === rule?.sku) {
        skuData.priceExcludingVat = Number(rule?.value || 0);
      }
    });
    return skuData;
  };

  const vehicleStockMainMapper = ({ stock, vehicleQueryMap, packageSkuOptions }): IVehicleStockSegregatedType => {
    const optionsParams = getOptions(stock);
    const packageOption = optionsCheckfromSku(optionsParams, packageSkuOptions);
    const priceOptions = filterPackageFromPriceOptions(stock?.priceOptions, packageOption);

    const packageSku = getPackageSku(packageOption, stock);

    const interiorSelectable = [];
    const exteriorSelectable = [];
    const listverkSelectable = [];
    const taklakkSelectable = [];
    const rims = [];
    const varnish = [];
    const interior = [];
    let unAvailableSkus = [];
    let interiorName : string = '';
    const articles: IArticle[] = [];
    const salesPackageQueryMap = vehicleQueryMap?.get(packageSku);
    const rules = getSkuPrice(salesPackageQueryMap, priceOptions);

    priceOptions?.forEach((option) => {
      if (!salesPackageQueryMap) {
        return {
          packageSku: packageOption,
          interiorSelectable,
          exteriorSelectable,
          listverkSelectable,
          taklakkSelectable,
          rims,
          varnish,
          interior,
          unAvailableSkus,
        };
      }
      if (!salesPackageQueryMap?.[option]) {
        unAvailableSkus.push(option);
      } else {
        const skuData = updateSelectablePrices(salesPackageQueryMap?.[option], rules);
        const vatCode = mapVatCodeStringToEnum(skuData?.vatCode);
        switch (skuData?.type) {
          case vehicleSegragationConst.Selectable:
            switch (skuData?.category) {
              case vehicleSegregationSelectableCategoryConst.Interior:
                interiorSelectable.push(skuData);
                articles.push(prepareArticle(skuData?.priceExcludingVat, vatCode, ArticleType.EQUIPMENT));
                break;

              case vehicleSegregationSelectableCategoryConst.Exterior:
                articles.push(prepareArticle(skuData?.priceExcludingVat, vatCode, ArticleType.EQUIPMENT));
                exteriorSelectable.push(skuData);
                break;

              case vehicleSegregationSelectableCategoryConst.Listverk:
                articles.push(prepareArticle(skuData?.priceExcludingVat, vatCode, ArticleType.EQUIPMENT));
                listverkSelectable.push(skuData);
                break;

              case vehicleSegregationSelectableCategoryConst.Taklakk:
                articles.push(prepareArticle(skuData?.priceExcludingVat, vatCode, ArticleType.EQUIPMENT));
                taklakkSelectable.push(skuData);
                break;

              default:
                break;
            }
            break;

          case vehicleSegragationConst.Rim:
            articles.push(prepareArticle(skuData?.priceExcludingVat, vatCode, ArticleType.EQUIPMENT));
            rims.push(skuData);
            break;

          case vehicleSegragationConst.Varnish:
            articles.push(prepareArticle(skuData?.priceExcludingVat, vatCode, ArticleType.EQUIPMENT));
            varnish.push(skuData);
            break;

          case vehicleSegragationConst.Interior:
            articles.push(prepareArticle(skuData?.priceExcludingVat, vatCode, ArticleType.EQUIPMENT));
            interior.push(skuData);
            break;

          default:
            break;
        }
      }
    });

    if (!priceOptions?.length) {
      const colorData = salesPackageQueryMap?.[stock?.colorId];
      articles.push(prepareArticle(colorData?.priceExcludingVat, colorData?.vatCode, ArticleType.EQUIPMENT));
      const interiorData = salesPackageQueryMap?.[stock?.interiorCode];
      articles.push(prepareArticle(interiorData?.priceExcludingVat, interiorData?.vatCode, ArticleType.EQUIPMENT));
      if (stock?.brand === MAKE.Smart && taklakkSelectable.length === 0 && optionsParams) {
        const optionsParamsArr = optionsParams?.split(DELIMETER);
        optionsParamsArr?.forEach((option) => {
          if (
            salesPackageQueryMap?.[option] &&
            salesPackageQueryMap?.[option]?.category === vehicleSegregationSelectableCategoryConst.Taklakk
          ) {
            const skuData = updateSelectablePrices(salesPackageQueryMap?.[option], rules);
            taklakkSelectable.push(skuData);
          }
        });
      }
    }

    //To show interior name in vehiclePickerDialog when interior FREE
    if (!interior?.length) {
      interiorName = defaultTo(salesPackageQueryMap?.[stock?.interiorCode]?.name, '');
    }

    // Filter for MER: interior and PEU: Price option
    unAvailableSkus = filterUnavailableSku(interior, stock, salesPackageQueryMap, unAvailableSkus);

    // Calculate Total price for a vehicle
    const totalPrice = calculateTotalPrice(salesPackageQueryMap?.pricing, salesPackageQueryMap?.tires, articles);

    return {
      packageSku: packageOption?.join('-'),
      interiorSelectable,
      exteriorSelectable,
      listverkSelectable,
      taklakkSelectable,
      rims,
      varnish,
      interior,
      unAvailableSkus,
      interiorName,
      totalPrice,
    };
  };

  const optionsCheckfromSku = (options: string, packageSkuOptions: HashMap) => {
    const optionArr = options?.split(DELIMETER);
    return optionArr?.filter((option) => packageSkuOptions?.[option]) || [];
  };

  const filterPackageFromPriceOptions = (priceOptions: string, packageOption: string[]) => {
    if (!priceOptions) return [];
    if (!packageOption?.length) return priceOptions?.split(DELIMETER);
    const toRemove = new Set(packageOption);
    return priceOptions?.split(DELIMETER)?.filter((option) => !toRemove?.has(option));
  };

  return { createVehicleQueryHashMaps, vehicleStockMainMapper, optionsCheckfromSku, filterPackageFromPriceOptions };
};

export const vehicleSegregationWorker = (
  vehicleQueryData: IVehicleConfiguration[],
  vehicleStock: Map<string, IVehicleStock[]>,
  packageSkuOptions: HashMap,
) => {
  const vehicleQueryMap = new Map();
  const vehicleStockMap = new Map();
  const helper = vehicleSegregationWorkerHelper();

  vehicleQueryData?.forEach((modelPackage) => {
    helper?.createVehicleQueryHashMaps(modelPackage, vehicleQueryMap);
  });

  vehicleStock?.forEach((stocksData, key) => {
    stocksData?.forEach((stock) => {
      if (vehicleStockMap?.has(key)) {
        const existingVehicle = vehicleStockMap?.get(key);
        const mappedData = helper?.vehicleStockMainMapper({ stock, vehicleQueryMap, packageSkuOptions });

        const stockMapping = Object.assign(stock, mappedData);
        vehicleStockMap?.set(key, existingVehicle?.concat([stockMapping]));
      } else {
        const mappedData = helper?.vehicleStockMainMapper({ stock, vehicleQueryMap, packageSkuOptions });

        const stockMapping = Object?.assign(stock, mappedData);

        vehicleStockMap?.set(key, [stockMapping]);
      }
    });
  });

  return vehicleStockMap;
};
