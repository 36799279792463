import React from 'react';
import { IMarginInput } from 'next-common';
import { formatNumberInput, InputType } from '@next-components/common-types';
import { MarginOverviewStyled } from '../MarginPane.styled';

interface IMarginOverviewProps {
  margin: IMarginInput;
}

export const MarginOverview = (props: IMarginOverviewProps) => {
  const formattedMarginPercentage =
    props.margin?.marginPercentage &&
    formatNumberInput(props.margin?.marginPercentage.toString(), InputType.FLOAT, 1).formatted;
  const formattedMarginValue =
    props.margin?.marginValue && formatNumberInput(props.margin?.marginValue.toString(), InputType.INTEGER).formatted;
  const formattedCampaignDiscount =
    props.margin?.campaignDiscount &&
    formatNumberInput(props.margin?.campaignDiscount.toString(), InputType.INTEGER).formatted;
  const formattedImporterPercentage =
    props.margin?.campaignImporterPercentage &&
    formatNumberInput(props.margin?.campaignImporterPercentage.toString(), InputType.FLOAT, 2).formatted;
  const formattedCampaignImporter =
    props.margin?.campaignImporter &&
    formatNumberInput(props.margin?.campaignImporter.toString(), InputType.INTEGER).formatted;

  return (
    <MarginOverviewStyled className={'marginDetailContainer'}>
      <div className="value">
        <div id="totalMarginOverview">
          <p className="label">Total margin</p>
          <p className="valueta label">{formattedMarginValue ?? 0} kr</p>
          <p className="percentage">
            <span className={formattedMarginPercentage ? '' : 'valueZero'}>{formattedMarginPercentage ?? 0} %</span>
          </p>
        </div>
        <div id="campaignDiscountOverview">
          <p className="label">Kundefordel</p>
          <p className="valueta label">{formattedCampaignDiscount ?? 0} kr</p>
          <p className="percentage">
            <span className={formattedImporterPercentage ? '' : 'valueZero'}>{formattedImporterPercentage ?? 0} %</span>
          </p>
        </div>
        <div id="campaignImporter">
          <p className="label">Importørstøtte</p>
          <p className="valueta label">{formattedCampaignImporter ?? 0} kr</p>
          <p className="percentage">
            <span className={formattedImporterPercentage ? '' : 'valueZero'}>{formattedImporterPercentage ?? 0} %</span>
          </p>
        </div>
      </div>
    </MarginOverviewStyled>
  );
};
