import React, { ReactNode } from 'react';
import { OverlayContainer } from '@next-components/overlay-container';
import { bosColor } from '@next-components/common-styles';
import { useDisableScroll } from '../../../common';
import { ModelWrapperContainer } from './ModelWrapper.styled';

export interface IModalWrapper {
  isActive: boolean;
  children: ReactNode;
  className?: string;
  allowScrolling?: boolean;
}

export const ModalWrapper = (props: IModalWrapper) => {
  const { isActive, children, allowScrolling = false, className } = props;
  useDisableScroll(allowScrolling ? false : isActive);
  return (
    <ModelWrapperContainer>
      {isActive && (
        <OverlayContainer className={className} overlayBgColor={`${bosColor.ultramarine}cc`} zIndex={10001}>
          {children}
        </OverlayContainer>
      )}
    </ModelWrapperContainer>
  );
};
