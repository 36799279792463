import { fontWeightBold, black } from '@next-components/common-styles';
import styled from 'styled-components';

export const SettingsItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  cursor: pointer;

  .title {
    font-size: 16px;
    font-weight: ${fontWeightBold};
  }

  .iconStyled {
    display: flex;
    align-items: center;
    svg {
      path {
        fill: ${black};
      }
    }
  }
`;
