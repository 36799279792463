import { bosColor, typography } from '@next-components/common-styles';
import styled from 'styled-components';
import { InfoBlockStyled } from './InfoBlock/InfoBlock.styled';

export const ModalInner = styled.div`
  display: grid;
  grid-template-columns: none;
  grid-row-gap: 1.5rem;
  padding: 1.5rem;
  max-height: calc(100vh - 12rem);
  overflow: visible;
  .link-btn {
    cursor: pointer;
  }
  p.modalText {
    padding: 0 1rem;
  }
  h5 {
    color: ${bosColor.black};
    font-size: 0.875rem;
    font-weight: ${typography.weight.semibold} !important;
    line-height: 170%;
  }
  .name-section {
    ${InfoBlockStyled} {
      gap: 0.25rem;
      flex-direction: column;
      span {
        font-size: 1.125rem;
        line-height: 175%;
        font-weight: ${typography.weight.semibold};
        color: ${bosColor.black};

        &.value {
          line-height: 165%;
          font-size: 1.5rem;
          text-align: right;
          font-weight: ${typography.weight.extrabold};
        }
      }
    }
  }
.dropdown-container {
    font-weight: ${typography.weight.light};
    .orderTypeDropdown {
      font-weight: ${typography.weight.semibold};
    }
  }
`;

export const ModalActions = styled.div`
  padding-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-rows: none;
  grid-template-columns: repeat(${(props) => props.items}, ${(props) => 12 / props.items}fr);
  grid-column-gap: 2rem;
  justify-content: space-evenly;
  place-items: center;
  .loader-button {
    background-color: ${bosColor.darkBlue};
  }

  &.commentBoxModal {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    gap: 1rem;
    padding-top: 1rem;

    button {
      justify-content: center;
      width: 100%;
      font-weight: ${typography.weight.extrabold};
    }
  }
`;

export const SignerFooterBtnContainer = styled.div`
  display: flex;
  padding-top: 1rem;
  gap: 1rem;
  align-item: flex-start;
  button {
    flex: 1 0 0;
    align-items: center;
    justify-content: center;
    font-weight: ${typography.weight.extrabold};
  }`;

export const MBConfigModalWrapper = styled.div`
  background: rgba(32, 33, 70, 0.8);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .custom-modal {
    display: flex;
    width: 36.375rem;
    padding: 2rem 2rem 1.5rem 2rem;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
    border-radius: 0.5rem;
    background: ${bosColor.white};
    box-shadow: 0px 2px 16px 0px rgba(48, 48, 111, 0.08), 0px 8px 24px 2px rgba(48, 48, 111, 0.04);

    .close-modal-button {
      display: none;
    }

    .inner-container {
      padding: 0;
    }
    .content-container {
      display: grid;
      gap: 1rem;
      header {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        h3 {
          position: relative;
          top: 0.375rem;
        }
        + div {
          padding: 0;
          .modalText {
            padding-left: 2.5rem;
          }
        }
      }     
      p:not(.modalText) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      footer {
        > div {
          place-items: flex-end;
          padding-top: 1rem;
          button {
            display: flex;
            width: 9rem;
            padding: 0.75rem 1rem;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }`;
