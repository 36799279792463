import styled from 'styled-components';
import { black, darkerGrey, backgroundSite, bosBrand, primary } from '@next-components/common-styles';
import { EclipsTextStyled } from '../../Assets';

export const OverviewTableStyled = styled(EclipsTextStyled)`
  .calendar-filter {
    grid-template-columns: 3fr 3fr 1.5fr;
    grid-template-rows: auto;
    display: grid;
  }
  .header {
    padding: 0 0.625rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 3.125rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .header-container {
    h1 {
      font-size: 1.875rem;
      margin: 1.25rem 0;
    }
    padding-top: 2.1875rem;
  }
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.625rem;
  }
  .reset-button {
    display: flex;
    align-items: center;
  }

  .tblFull {
    tr {
      th,
      td {
        font-size: 12px;
        font-weight: 600;
      }
      th {
        color: ${black};
        font-size: 15px;
      }
      td {
        color: ${darkerGrey};
      }
    }
    tbody tr {
      cursor: pointer;
      &:nth-of-type(odd) {
        background-color: ${backgroundSite};

        td:last-child {
          border-radius: 0 10px 10px 0;
        }
      }

      &:nth-of-type(odd) td:first-child {
        border-radius: 10px 0 0 10px;
      }
    }

    .devider td {
      border-top: 1px solid ${bosBrand};
      margin: 0;
      padding: 0;
    }
  }
  .position-relative {
    position: relative;
  }
  .react-datepicker__month-container {
    .react-datepicker__month--selected {
      background-color: ${primary};
    }
    .react-datepicker__month--in-range {
      background-color: ${primary};
    }
  }
  .centered {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .headerText {
    font-weight: bold;
    margin-left: auto;
    padding-right: 25px;
    margin-top: -4.5px;
  }
`;
