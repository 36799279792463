export const SubHeaderMakeStyle = {
    'DEFAULT': {
        'SRT': {
            'kunde':{
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
            'telefon': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'epost': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'adresse': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'gyldigtil': {
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
        },
        'MER': {
            'kunde': {
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
            'telefon': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'epost': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'adresse': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'gyldigtil': {
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
        },
        'KIA': {
            'kunde': {
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
            'telefon': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'epost': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'adresse': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'gyldigtil': {
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
        },
        'PEU': {
            'kunde': {
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
            'telefon': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'epost': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'adresse': {
                fontWeight: 'normal',
                paddingRight: 3,
                fontSize: 7
            },
            'gyldigtil': {
                fontWeight: 'bold',
                paddingRight: 3,
                fontSize: 7
            },
        }
    },
    'INDEPENDENT_DEALER': {
        'SRT': {
            'kunde': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'telefon': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'epost': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'adresse': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'gyldigtil': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
        },
        'MER': {
            'kunde': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'telefon': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'epost': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'adresse': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'gyldigtil': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
        },
        'KIA': {
            'kunde': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'telefon': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'epost': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'adresse': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
            'gyldigtil': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 8
            },
        },
        'PEU': {
            'kunde': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 7
            },
            'telefon': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 7
            },
            'epost': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 7
            },
            'adresse': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 7
            },
            'gyldigtil': {
                fontWeight: 400,
                paddingRight: 3,
                fontSize: 7
            },
        }
    }
}