import styled from 'styled-components';
import { bosColor, typography } from '@next-components/common-styles';

export const EventPaneStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
`;

export const EventListStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventListHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  > div.topWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  h3.title {
    color: ${bosColor.black};
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
`;
