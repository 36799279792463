import React, { useState, useEffect } from 'react';
import { IApiEmployee, IDealer } from 'next-common';
import { DealerOption } from './DealerOption';
import { DealerSelectStyled } from './DealerSelect.styled';
import { useReactiveVar } from '@apollo/client';
import { orderBy } from 'lodash';
import { selectedDealerId } from '../../../../../common';
import { v4 as uuidv4 } from 'uuid';

export interface IDealerSelectProps {
  employee: IApiEmployee;

  onSelect(): void;
}

export const DealerSelect = (props: IDealerSelectProps) => {
  const [options, setOptions] = useState<IDealer[]>([]);

  const selectedDealer = useReactiveVar<string>(selectedDealerId);

  useEffect(() => {
    setOptions(orderBy(props.employee?.dealers || [], ['dealerId']));
  }, [props.employee]);

  const handleSelect = (selected: IDealer) => {
    selectedDealerId(selected.dealerId);
    props.onSelect();
  };

  return (
    <DealerSelectStyled>
      {options?.map((dealer) => (
        <DealerOption
          handleSelect={() => handleSelect(dealer)}
          selected={dealer.dealerId === selectedDealer}
          key={uuidv4()}
          dealer={dealer}
        />
      ))}
    </DealerSelectStyled>
  );
};
