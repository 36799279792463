import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { IPackage } from 'next-common';
import { HorizontalWarpableListStyled, NoneSelectedStyled, SectionStyled } from './common-styles';
import { PriceOrIncluded } from './PriceOrIncluded';
import { backgroundCard } from '@next-components/common-styles';
import { v4 as uuidv4 } from 'uuid';

const PackageStyled: Style = {
  padding: 15,
  backgroundColor: backgroundCard,
  width: '31%',
  justifyContent: 'space-between',
  marginVertical: 5,
  marginHorizontal: 5,
};

const Package = (props: IPackage) => (
  <View style={PackageStyled}>
    <Text style={{ fontSize: 10 }}>{props.name}</Text>
    <PriceOrIncluded value={props.additionalPrice} />
  </View>
);

interface IPackagesProps {
  packages: IPackage[];
}

export const Packages = (props: IPackagesProps) => (
  <View style={SectionStyled} wrap={false}>
    <Text style={{ fontSize: 16, margin: '0 5 5' }}>Utstyrspakke</Text>
    <View style={HorizontalWarpableListStyled}>
      {props.packages?.map((p) => <Package key={uuidv4()} {...p} />)}
      {(!props.packages || props.packages.length < 1) && (
        <View style={NoneSelectedStyled}>
          <Text>Ingen pakker</Text>
        </View>
      )}
    </View>
  </View>
);
