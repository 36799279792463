import { useState } from 'react';
import { IConjunctionReturnValue, IMappedEquipment } from 'sales-crystallize-common';
import { IRulesTable } from '../interfaces';
import {
  ICurrentSelections,
  addDefaultsInRulesTable,
  createRulesTableData,
  equipmentsToConsiderDeSelection,
  filterOutSelectedEquipments,
  handleDeSelection,
  handleDisableEquipmentSelection,
} from '../../utils';
import { cloneDeep, remove } from 'lodash';
import { VehicleEquipmentCategoryEnum } from 'next-common';
import { IModelPackages } from '../../components/sales/sales-vehicle-picker-page';

export interface ICurrentSelectionDetails {
  selectedSku?: string;
  deSelectedSku?: string;
}

export const useConjunction = () => {
  const [rulesTableData, setRulesTableData] = useState<IRulesTable>(null);
  const [currentlySelectedSku, setCurrentlySelectedSku] = useState<string>();
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [unSelectSkus, setUnSelectSkus] = useState<string[]>([]);
  const [currentSelectionDetails, setCurrentSelectionDetails] = useState<ICurrentSelectionDetails>(null);
  const [previousDeSelection, setPreviousDeSelection] = useState<IMappedEquipment>(null);

  const isCurrentlySelectedToBeRemoved = (
    selectedSku: string,
    selectedSkus: string[],
    mappedEquipments: IMappedEquipment[],
    previouslySelectedSkus?: string[],
    currentVariantData?: IModelPackages[],
    currentSelections?: ICurrentSelections,
    onloadDisableSku?:  Map<string, number>,
  ) => {
    const selectedEquipment = mappedEquipments?.find((equipment) => {
      return equipment?.sku === selectedSku;
    });

    const selectedEquipments = mappedEquipments?.filter((equipment) => {
      return equipment?.sku !== selectedSku && selectedSkus?.includes(equipment?.sku);
    });
    const previouslySelected = mappedEquipments?.find((equipment) => {
      return previouslySelectedSkus?.includes(equipment?.sku);
    });
    const selectedEquipmentsWithPreviousSelections = cloneDeep(selectedEquipments);
    selectedEquipmentsWithPreviousSelections.push(previouslySelected);
    const { selectedEquipmentWithoutExtraCategorySelected, prevSelectedEquipmentToRemove } =
      filterOutSelectedEquipments(
        'mainCategory',
        selectedEquipment?.mainCategory,
        selectedEquipment?.mainCategory,
        selectedEquipmentsWithPreviousSelections,
      );

    const conjunctionStoreData = handleDeSelection(
      selectedEquipment,
      selectedEquipmentWithoutExtraCategorySelected,
      mappedEquipments,
    );

    remove(conjunctionStoreData?.removedPrevSelectedEquipment, (equipment) => {
      return equipment?.sku === selectedSku;
    });

    if (
      conjunctionStoreData?.removedPrevSelectedEquipment?.length > 0 &&
      equipmentsToConsiderDeSelection.includes(selectedEquipment?.mainCategory)
    ) {
      if (prevSelectedEquipmentToRemove.length > 0) {
        conjunctionStoreData?.removedPrevSelectedEquipment?.unshift(prevSelectedEquipmentToRemove?.[0]);
      }
    }
    const preparedRules: IRulesTable = createRulesTableData(conjunctionStoreData, selectedEquipment);
    const preparedRulesWithDefaults: IRulesTable = addDefaultsInRulesTable(currentSelections, currentVariantData, preparedRules, onloadDisableSku, mappedEquipments)
    if (preparedRulesWithDefaults?.ruleRemoveVaules?.length > 0) {
      setRulesTableData(preparedRulesWithDefaults);
    }

    return preparedRules;
  };

  const prepareRulesTableOnDeSelection = (
    equipment: IMappedEquipment,
    selectedEquipment: IMappedEquipment[],
    allEquipment: IMappedEquipment[],
    currentVariantData?: IModelPackages[],
    currentSelections?: ICurrentSelections,
    onloadDisableSku?:  Map<string, number>,
  ) => {
    setPreviousDeSelection(equipment);
    const equipmentSelection = handleDeSelection(equipment, selectedEquipment, allEquipment);
    const preparedRules: IRulesTable = createRulesTableData(equipmentSelection, equipment);
    const preparedRulesWithDefaults: IRulesTable = addDefaultsInRulesTable(currentSelections, currentVariantData, preparedRules, onloadDisableSku, allEquipment)
    setRulesTableData(preparedRulesWithDefaults);
    setUnSelectSkus(
      preparedRules?.ruleRemoveVaules?.map((data) => {
        return data?.sku;
      }),
    );
    return preparedRules;
  };

  const prepareRulesTable = (
    selectedSku: string,
    selectedSkus: string[],
    mappedEquipments: IMappedEquipment[],
    disabledSkus?: IConjunctionReturnValue[],
    previouslySelectedSkus?: string[],
    currentVariantData?: IModelPackages[],
    currentSelections?: ICurrentSelections,
    onloadDisableSku?:  Map<string, number>,
    isConfirm: boolean = false,
  ): IRulesTable => {
    setUnSelectSkus(previouslySelectedSkus);
    const selectedEquipment = mappedEquipments?.find((equipment) => {
      return equipment?.sku === selectedSku;
    });

    const selectedEquipments = mappedEquipments?.filter((equipment) => {
      return equipment?.sku !== selectedSku && selectedSkus?.includes(equipment?.sku);
    });

    const previouslySelected = mappedEquipments?.find((equipment) => {
      return previouslySelectedSkus?.includes(equipment?.sku);
    });

    let prevSelectedEquipmentToRemove: IMappedEquipment[] = [];
    if (
      equipmentsToConsiderDeSelection?.includes(selectedEquipment?.mainCategory) ||
      selectedEquipment?.mainCategory === VehicleEquipmentCategoryEnum.Taklakk
    ) {
      const selectedEquipmentsWithPreviousSelections = cloneDeep(selectedEquipments);
      selectedEquipmentsWithPreviousSelections.push(previouslySelected);

      const filteredSelectedEquipments = filterOutSelectedEquipments(
        '',
        '',
        selectedEquipment?.mainCategory,
        selectedEquipmentsWithPreviousSelections,
      );
      prevSelectedEquipmentToRemove = filteredSelectedEquipments?.prevSelectedEquipmentToRemove;
    }

    const equipmentSelection = handleDisableEquipmentSelection(
      selectedEquipment,
      selectedEquipments,
      mappedEquipments,
      isConfirm,
      prevSelectedEquipmentToRemove,
    );

    const preparedRules: IRulesTable = createRulesTableData(equipmentSelection, selectedEquipment);
    const preparedRulesWithDefaults: IRulesTable = addDefaultsInRulesTable(currentSelections, currentVariantData, preparedRules, onloadDisableSku, mappedEquipments)
    setRulesTableData(preparedRulesWithDefaults);
    return preparedRules;
  };

  return {
    rulesTableData,
    currentlySelectedSku,
    isConfirmed,
    unSelectSkus,
    currentSelectionDetails,
    previousDeSelection,
    isCurrentlySelectedToBeRemoved,
    prepareRulesTable,
    setIsConfirmed,
    setCurrentlySelectedSku,
    setUnSelectSkus,
    setRulesTableData,
    setPreviousDeSelection,
    prepareRulesTableOnDeSelection,
    setCurrentSelectionDetails,
  };
};
