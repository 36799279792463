import { useMutation } from '@apollo/client';
import { Button } from '@next-components/cta';
import { CustomModal } from '@next-components/custom-modal';
import { InputField } from '@next-components/input-field';
import lodashGet from 'lodash/get';
import { useState } from 'react';
import { InputType } from 'sales-common';
import { createMinimalOrderMutationParsed, customerId, useDisableScroll } from '../../../../common';
import { CustomModalWrapper } from '../../../layout/CustomModalWrapper.styled';
import { ModalInner } from '../../../sales/sales-common/modal.styled';
import { CreateOrderErrorMessage, CustomModalAction } from './CreateOrder.styled';
import { IConfiguration, OrderCreationType, OrderSource, OrderType } from 'next-common';
import { BosDropdown } from '@next-components/bos-dropdown';
import { IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';

export interface ICreateOrder {
  onClose(): void;
  createOrder:boolean;
  configuration: IConfiguration;
}

const orderTypeList = [
  {
    label: OrderCreationType.CUSTOMER,
    value: OrderType.ORDER,
  },
  {
    label: OrderCreationType.DEMO,
    value: OrderType.DEMO,
  },
  {
    label: OrderCreationType.STOCK,
    value: OrderType.STOCK,
  },
];

export const CreateOrder = (props: ICreateOrder) => {
  const [make, setMake] = useState<string>('');
  const [modelSeries, setModelSeries] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedValue, setSelectedValue] = useState({
    label: OrderCreationType.CUSTOMER,
    value: '1',
  });
  
  const [createOrderMutation] = useMutation(createMinimalOrderMutationParsed);
  useDisableScroll(props?.createOrder)


  const createOrder = () => {
    setErrorMessage('');
    setIsLoading(true);
    const input = {
      customerId: customerId,
      make: make ? make.toUpperCase() : '',
      modelSeries: modelSeries ?? '',
      quoteId: '',
      stepId: '',
      source: OrderSource[OrderSource.NEXT],
      orderType: selectedValue?.label || ''
    };
    createOrderMutation({ variables: { input } })
      .then((result) => {
        const orderURI = lodashGet(result, 'data.createMinimalOrder.orderURI');
        if (orderURI) {
          window.open(orderURI, '_blank');
          props.onClose();
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setErrorMessage('Det oppsto en feil under oppretting av en bestilling');
      });
  };

  const onChangeMake = (input: string) => {
    setMake(input);
    setErrorMessage('');
  }

  const onChangeModelSeries = (input: string) => {
    setModelSeries(input);
    setErrorMessage('');
  }

  const onModalClose = () => {
    props.onClose();
  }

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
  };
  
  const configuration = props?.configuration?.features;
  const toUpperCaseArray = (arr?: string[]): string[] => arr?.map((elem) => elem.toUpperCase()) || [];

  const stoppedDemoBrands: string[] = toUpperCaseArray(configuration?.stoppedDemoBrands);
  const stoppedStockBrands: string[] = toUpperCaseArray(configuration?.stoppedStockBrands);

  const dropDownOptions = () => {
    let filteredOrderTypeList = [...orderTypeList];
    if (stoppedDemoBrands?.length > 0 && stoppedDemoBrands?.includes(make.toUpperCase())) {
      filteredOrderTypeList = filteredOrderTypeList.filter((item) => item.label !== OrderCreationType.DEMO);
    }
    if (stoppedStockBrands?.length > 0 && stoppedStockBrands?.includes(make.toUpperCase())) {
      filteredOrderTypeList = filteredOrderTypeList.filter((item) => item.label !== OrderCreationType.STOCK);
    }
    return filteredOrderTypeList;
  };

  return (
    <CustomModalWrapper className="backdrop smModal width40" active={true}>
      <CustomModal onCloseClick={onModalClose} active={true} isModelCloseAllowedOutside={false}>
        <header>
          <h2>Opprett ordre</h2>
        </header>
        <ModalInner>
          <InputField
            id="makeInput"
            className="inputDescriptionText"
            label="Merke"
            type={InputType.TEXT_LINE}
            disabled={false}
            defaultValue={make}
            onChange={(input: string) => onChangeMake(input)}
          />
          <InputField
            id="modelInput"
            className="inputDescriptionText"
            label="Modellserie"
            type={InputType.TEXT_LINE}
            disabled={false}
            defaultValue={modelSeries}
            onChange={(input: string) => onChangeModelSeries(input)}
          />
          <BosDropdown
            onChange={handleChange}
            value={selectedValue}
            className='orderTypeDropdown'
            options={dropDownOptions()}
            placeholder="Velg type"
            label={'Bestillingstype'}
            iconProps={{
              iconDropdownDown: IconType.ChevronDown,
              iconDropdownUp: IconType.ChevronUp,
              iconColor: bosColor.black,
            }}          
          />
        </ModalInner>
        <CreateOrderErrorMessage className='createOrderError'>{errorMessage !== '' ? errorMessage : null}</CreateOrderErrorMessage>
        <footer>
          <CustomModalAction items={2}>
            <Button onClick={createOrder} isLoading={isLoading} disabled={make === "" || modelSeries === ""} > Bekreft</Button>
            <Button variant="secondary" onClick={onModalClose} isLoading={isLoading}> Avbryt </Button>
          </CustomModalAction>
        </footer>
      </CustomModal>
    </CustomModalWrapper>
  )
}
export default CreateOrder;