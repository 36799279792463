import * as React from 'react';
import classNames from 'classnames';
import { filterAndOrderCols, formatted } from '../DynamicList.utils';
import { IDynamicListItemProps } from '../IDynamicList';
import { VehicleDetail } from './VehicleDetail';
import { Accordion } from '@next-components/accordion';
import { VehicleListMerStyled, checkboxColWidth } from './VehicleListMer.styled';
import { Icon, IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';
import { v4 as uuidv4 } from 'uuid';

export const VehicleListItemMer = (props: IDynamicListItemProps) => {
  const columns = filterAndOrderCols(props.cols);
  const hasCheckboxCol = props.selectable && props.withCheckbox;

  const columnWidth = columns?.map((column) => column?.colWidth ?? '1fr');
  if (hasCheckboxCol) {
    columnWidth?.unshift(checkboxColWidth);
  }

  const handleClick = () => (!props.disabled ? props.onElementClick(props.data) : null);
  return props.withAccordion ? (
    <div className={`accordionContainer${props?.selected ? ' selected' : ''}`}>
      <Accordion
        hasCheckBox={hasCheckboxCol}
        labelContent={
          <VehicleListMerStyled
            onClick={handleClick}
            columnWidth={columnWidth}
            className={classNames('item grid-row ', {
              selected: !!props.selected,
              disabled: !!props.disabled,
            })}
            withAccordion={props.withAccordion}>
            {hasCheckboxCol && (
              <div className="selectable">
                {!props?.disabled ? (
                  <div className={'checkboxRadio'}></div>
                ) : (
                  <div className={'lockIcon'}>
                    <Icon icon={IconType.NewNextLock} aria-hidden="true" height={22} width={18} color={bosColor.neutralPlus} />
                  </div>
                )}
              </div>
            )}

            {columns?.map((column) => (
              <div className="grid-item" key={`item_${column?.key || ''}_${uuidv4()}`}>
                <div key={`item_${column?.key || ''}_${uuidv4()}`}>
                  {formatted(column, props.data?.[column?.key], props.data)}
                </div>
              </div>
            ))}
          </VehicleListMerStyled>
        }
      >
        {props.withAccordion && props.data && <VehicleDetail onButtonClickDetailPage={props.onButtonClickDetailPage} isLoading={props?.isLoading} data={props.data} cols={props.detailsCols} />}
      </Accordion>
    </div>
  ) : (
    <VehicleListMerStyled
      onClick={handleClick}
      columnWidth={columnWidth}
      className={classNames('item grid-row ', {
        selected: !!props.selected,
        disabled: !!props.disabled,
      })}
      withAccordion={props.withAccordion}>
      {hasCheckboxCol && (
        <div className="selectable">
          <div className={'checkboxRadio'}></div>
        </div>
      )}

      {columns?.map((column) => (
        <div className="grid-item" key={`item_${column?.key || ''}_${uuidv4()}`}>
          <div key={`item_${column?.key || ''}_${uuidv4()}`}>{formatted(column, props.data?.[column?.key], props.data)}</div>
        </div>
      ))}
    </VehicleListMerStyled>
  );
};
