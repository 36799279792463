import {
  discountValueExcludingVat,
  discountValueIncludingVat,
  IMarginInput,
  IOrder,
  IVatCalculation,
} from 'next-common';
import { get } from 'lodash';

export const createAndAddMarginToOrder = async (
  orderId: string,
  input: IMarginInput,
  createAndAddMarginToOrderMutation,
  articlesForDNBCalculations,
): Promise<IOrder> => {
  try {
    const { data } = await createAndAddMarginToOrderMutation({
      variables: {
        orderId: orderId,
        input,
        nextArticles: articlesForDNBCalculations,
      },
    });
    if (data?.createAndAddMarginToOrder) {
      return data.createAndAddMarginToOrder;
    }
    return null;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};

// Below function is only used for dynamic calculation of margin percentage in next-frontend
export const calcMarginPercentageWithoutVat = (
  marginValue: number,
  totalPriceExcVat: number,
  lastMarginDiscountValue: number,
  inputDisount: number,
): number => {
  const totalPrice = totalPriceExcVat - inputDisount + lastMarginDiscountValue;
  if (totalPrice === 0) return 0;
  return +((marginValue / totalPrice) * 100)?.toFixed(1);
};

export const checkIfVatIsToBeCalculated = (vatCalculation: IVatCalculation, discountIncludingVat: number): boolean => {
  return get(vatCalculation, 'electrifiedCarVatCalculationBasis', 0) - discountIncludingVat > 0;
};

export const calculateDiscountValue = (
  vatCalculation: IVatCalculation,
  discountIncludingVat: number,
  isCalculateMVA = false,
): number => {
  if (isCalculateMVA) {
    return discountIncludingVat;
  }
  return checkIfVatIsToBeCalculated(vatCalculation, discountIncludingVat)
    ? discountValueExcludingVat(discountIncludingVat)
    : discountIncludingVat;
};

export const getCampaignDiscountInitialValue = (
  vatCalculation: IVatCalculation,
  discount: number,
  isPercentageSelected: boolean,
): number => {
  return checkIfVatIsToBeCalculated(vatCalculation, discount) && !isPercentageSelected
    ? discountValueIncludingVat(discount)
    : discount;
};
