import { IBosApiVehicleMarginCalculationDetails } from 'next-common';
import { marginsActionTypes } from './action-types';
import { IDropdownOption } from 'sales-common';
import { IAction } from '../../../../../../common';

export interface ISetAllSyncFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  monthsSelectedFilters: IDropdownOption<string>[];
  makesSelectedFilters: IDropdownOption<string>[];
  modelsSelectedFilters: IDropdownOption<string>[];
  serialNumbersSelectedFilters: IDropdownOption<number>[];
  departmentNumbersSelectedFilters: IDropdownOption<number>[];
  salesPersonsSelectedFilters: IDropdownOption<string>[];
  hideWithoutSales: boolean;
}

export interface ISetAllSyncFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: ISetAllSyncFiltersActionPayload;
}

export interface IResetAllSyncFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IResetAllSyncFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IResetAllSyncFiltersActionPayload;
}

export interface ISetMonthFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  visibleSelectedFilters: IDropdownOption<string>[];
}

export interface ISetMonthFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: ISetMonthFiltersActionPayload;
}

export interface IResetMonthFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IResetMonthFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IResetMonthFiltersActionPayload;
}

export interface ISetMakeFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  visibleSelectedFilters: IDropdownOption<string>[];
}

export interface ISetMakeFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: ISetMakeFiltersActionPayload;
}

export interface IResetMakeFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IResetMakeFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IResetMakeFiltersActionPayload;
}

export interface ISetModelFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  visibleSelectedFilters: IDropdownOption<string>[];
}

export interface ISetModelFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: ISetModelFiltersActionPayload;
}

export interface IResetModelFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IResetModelFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IResetModelFiltersActionPayload;
}

export interface ISetSerialNumberFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  visibleSelectedFilters: IDropdownOption<number>[];
}

export interface ISetSerialNumberFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: ISetSerialNumberFiltersActionPayload;
}

export interface IResetSerialNumberFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IResetSerialNumberFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IResetSerialNumberFiltersActionPayload;
}

export interface ISetDepartmentNumberFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  visibleSelectedFilters: IDropdownOption<number>[];
}

export interface ISetDepartmentNumberFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: ISetDepartmentNumberFiltersActionPayload;
}

export interface IResetDepartmentNumberFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IResetDepartmentNumberFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IResetDepartmentNumberFiltersActionPayload;
}

export interface ISetSalesPersonFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  visibleSelectedFilters: IDropdownOption<string>[];
}

export interface ISetSalesPersonFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: ISetSalesPersonFiltersActionPayload;
}

export interface IResetSalesPersonFiltersActionPayload {
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IResetSalesPersonFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IResetSalesPersonFiltersActionPayload;
}

export interface IToggleHideWithoutSalesFiltersActionPayload {
  hideWithoutSales: boolean;
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
}

export interface IToggleHideWithoutSalesFiltersAction extends IAction {
  type: marginsActionTypes;
  payload: IToggleHideWithoutSalesFiltersActionPayload;
}

export const setAllSyncFiltersAction = (payload: ISetAllSyncFiltersActionPayload): ISetAllSyncFiltersAction => ({
  type: marginsActionTypes.SET_ALL_SYNC_FILTERS,
  payload,
});

export const resetAllSyncFiltersAction = (payload: IResetAllSyncFiltersActionPayload): IResetAllSyncFiltersAction => ({
  type: marginsActionTypes.RESET_ALL_SYNC_FILTERS,
  payload,
});

export const setMonthFiltersAction = (payload: ISetMonthFiltersActionPayload): ISetMonthFiltersAction => ({
  type: marginsActionTypes.SET_MONTH_FILTER,
  payload,
});

export const resetMonthFiltersAction = (payload: IResetMonthFiltersActionPayload): IResetMonthFiltersAction => ({
  type: marginsActionTypes.RESET_MONTH_FILTER,
  payload,
});

export const setMakeFiltersAction = (payload: ISetMakeFiltersActionPayload): ISetMakeFiltersAction => ({
  type: marginsActionTypes.SET_MAKE_FILTER,
  payload,
});

export const resetMakeFiltersAction = (payload: IResetMakeFiltersActionPayload): IResetMakeFiltersAction => ({
  type: marginsActionTypes.RESET_MAKE_FILTER,
  payload,
});

export const setModelFiltersAction = (payload: ISetModelFiltersActionPayload): ISetModelFiltersAction => ({
  type: marginsActionTypes.SET_MODEL_FILTER,
  payload,
});

export const resetModelFiltersAction = (payload: IResetModelFiltersActionPayload): IResetModelFiltersAction => ({
  type: marginsActionTypes.RESET_MODEL_FILTER,
  payload,
});

export const setSerialNumberFiltersAction = (
  payload: ISetSerialNumberFiltersActionPayload,
): ISetSerialNumberFiltersAction => ({
  type: marginsActionTypes.SET_SERIAL_NUMBER_FILTER,
  payload,
});

export const resetSerialNumberFiltersAction = (
  payload: IResetSerialNumberFiltersActionPayload,
): IResetSerialNumberFiltersAction => ({
  type: marginsActionTypes.RESET_SERIAL_NUMBER_FILTER,
  payload,
});

export const setDepartmentNumberFiltersAction = (
  payload: ISetDepartmentNumberFiltersActionPayload,
): ISetDepartmentNumberFiltersAction => ({
  type: marginsActionTypes.SET_DEPARTMENT_NUMBER_FILTER,
  payload,
});

export const resetDepartmentNumberFiltersAction = (
  payload: IResetDepartmentNumberFiltersActionPayload,
): IResetDepartmentNumberFiltersAction => ({
  type: marginsActionTypes.RESET_DEPARTMENT_NUMBER_FILTER,
  payload,
});

export const setSalesPersonFiltersAction = (
  payload: ISetSalesPersonFiltersActionPayload,
): ISetSalesPersonFiltersAction => ({
  type: marginsActionTypes.SET_SALES_PERSON_FILTER,
  payload,
});

export const resetSalesPersonFiltersAction = (
  payload: IResetSalesPersonFiltersActionPayload,
): IResetSalesPersonFiltersAction => ({
  type: marginsActionTypes.RESET_SALES_PERSON_FILTER,
  payload,
});

export const toggleHideWithoutSalesFiltersAction = (
  payload: IToggleHideWithoutSalesFiltersActionPayload,
): IToggleHideWithoutSalesFiltersAction => ({
  type: marginsActionTypes.TOGGLE_HIDE_WITHOUT_SALES_FILTER,
  payload,
});