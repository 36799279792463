import { bosColor, margin, padding, white, typography } from '@next-components/common-styles';
import { IDynamicListTableWrapperProps } from './IDynamicList';
import styled from 'styled-components';

interface IStyledDynamicListProps {
  isNewDynamicList?: boolean;
}

export const DynamicListStyled = styled.div<IStyledDynamicListProps>`
  ${padding['24'].x};

  #contentCount {
    ${padding['16'].t};
    text-align: center;
  }

  .tableContainer + .item.load-more {
    ${(props) =>
      props.isNewDynamicList &&
      `border: 0;
      ${padding['32'].t};
      ${padding['0'].x};
      ${margin['0'].a};
      position: relative;
      cursor: initial;
      > div {
        font-size: 1rem;
        color: ${bosColor.concrete};
        position: absolute;
        bottom: -3.375rem;
        font-weight: ${typography.weight.semibold};
      }    
    `}
  }

  .tableContainer {
    scroll-behavior: smooth;
    &::-webkit-scrollbar, .scrollbarHide::-webkit-scrollbar {
      display: none;
    }

    .accordionContainer {
      background-color: ${bosColor.white};
      &.selected {
        background: ${bosColor.lightGrey};
        button {
          border-bottom: 0;
        }
        div > .btnAccordion {
          .labelContainer > .item {
            &.grid-row {
              background: none;
            }
          }
        }
      }

      background-color: ${white};
      > div {
        border-bottom: none;

        button {
          ${padding['0'].a};
          border-bottom: 1px solid ${bosColor.lightGrey};
          display: grid;
          gap: 0rem;
          .item.itemRow {
            border-bottom: none;
          }

          > div + div {
            justify-content: flex-end;
            ${margin['16'].r};
            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
          & + div {
            ${padding['16'].x};
            &.content[open] {
              padding: 1rem;
              border-bottom: 1px solid ${bosColor.lightGrey};
            }
          }
          .caption {
            font-size: 1rem;
            line-height: 1.375rem;
            color: ${bosColor.black};
            font-weight: ${typography.weight.semibold};
            margin-right: 0.25rem;
          }
        }
      }

      > div {
        &[open],
        &.selected {
          background: ${bosColor.lightGrey};
          button {
            border-bottom: 0;
            &.btnAccordion {
              .item.grid-row {
                background-color: ${bosColor.lightGrey};
              }
            }
          }
        }
      }
    }
  }
`;

export const TableWrapper = styled.div<IDynamicListTableWrapperProps>`
  max-height: calc(100vh - 23rem);
  overflow: ${(props) => (props.isNewDynamicList ? 'scroll' : 'inherit')};
  border: 1px solid ${bosColor.neutralGrey};
  border-radius: 0.5rem;
  .tooltip {
    top: -6.6rem !important;
  }
`;

export const TaklakkWrapper = styled.div<IDynamicListTableWrapperProps>`
  span {
    background: ${bosColor.neutralGrey};
    padding: 2px 0.75rem;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.75rem;
  }
`;