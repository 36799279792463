import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { getErrorMessageForError } from './ErrorMessageMapper';
import { IValidationToastNotification } from '../../common';

interface IErrorNotification extends IValidationToastNotification {
  errorCode: number;
}

interface ICustomErrorNotifications extends IValidationToastNotification {
  errorCode: number
  title: string;
}

export const ErrorNotification = (props: IErrorNotification) => (
  <Messagebar
    severityLevel={props?.severityLevel}
    title={getErrorMessageForError(props?.errorCode)}
    onClose={props?.onClose}
  />
);


/**
 * The CustomErrorNotification component renders a Messagebar with specified severity level, title, and
 * onClose function.
 * @param {ICustomErrorNotifications} props - The `props` parameter in the `CustomErrorNotification`
 * component is an object that contains the following properties:
 */
export const CustomErrorNotification = (props: ICustomErrorNotifications) => {
  const getTitle = (errorCode: number, title: string) => {
    return `${getErrorMessageForError(errorCode)} ${title}`;
  };

  return (
    <Messagebar
      severityLevel={props.severityLevel}
      title={getTitle(props?.errorCode, props?.title)}
      onClose={props?.onClose}
    />
  );
};

ErrorNotification.defaultProps = {
  severityLevel: MessagebarSeverityLevel.ERROR,
};
