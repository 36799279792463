import { white } from "@next-components/common-styles";

export const FooterMakeStyle = {
    'DEFAULT': {
        'SRT': {
            'header': {
                fontSize: 12, 
                fontWeight: 600, 
                paddingBottom: 5
            },
            'info': {
                paddingBottom: 1,
                fontSize: 8
            }
        },
        'MER': {
            'header': {
                fontSize: 12,
                fontWeight: 600,
                paddingBottom: 5
            },
            'info': {
                paddingBottom: 1,
                fontSize: 8
            }
        },
        'KIA': {
            'header': {
                fontSize: 12,
                fontWeight: 600,
                paddingBottom: 5
            },
            'info': {
                paddingBottom: 1,
                fontSize: 8
            }
        },
        'PEU': {
            'header': {
                fontSize: 12,
                fontWeight: 600,
                paddingBottom: 5
            },
            'info': {
                paddingBottom: 1,
                fontSize: 8
            }
        }
    },
    'INDEPENDENT_DEALER': {
        'SRT': {
            'header': {
                color: white,
                fontSize: 10,
                fontWeight: 700,
                paddingBottom: 5
            },
            'info': {
                color: white,
                fontSize: 8,
                fontWeight: 400,
                paddingBottom: 1
            }
        },
        'MER': {
            'header': {
                color: white,
                fontSize: 10,
                fontWeight: 700,
                paddingBottom: 5
            },
            'info': {
                color: white,
                fontSize: 8,
                fontWeight: 400,
                paddingBottom: 1
            }
        },
        'KIA': {
            'header': {
                color: white,
                fontSize: 10,
                fontWeight: 'bold',
                paddingBottom: 5
            },
            'info': {
                color: white,
                fontSize: 8,
                fontWeight: 400,
                paddingBottom: 1
            }
        },
        'PEU': {
            'header': {
                color: white,
                fontSize: 8,
                fontWeight: 700,
                paddingBottom: 5,
                textTransform: 'uppercase'
            },
            'info': {
                color: white,
                fontSize: 7,
                fontWeight: 400,
                paddingBottom: 1
            }
        }
    }
}