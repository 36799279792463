import styled from 'styled-components';
import { backgroundSite, primary, secondary, white } from '@next-components/common-styles';

export const DetailsStyled = styled.div`
  .container {
    max-width: 1140px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    .table {
      width: 100%;
      border-spacing: 0;
      tr th:first-child {
        text-align: left;
      }
      td.text-right {
        text-align: right;
      }
      td.text-center {
        text-align: center;
      }
      tr.devider {
        td {
          padding: 0;
        }
      }
      tr td {
        padding: 8px 8px;
      }
    }
  }
  .inner-container {
    margin-left: -20px;
  }
  .full-column {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    &.info-padding {
      padding: 0;
    }
  }
  .half-section {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .row-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .back-strip {
    display: flex;
    height: 100%;
    padding: 0px 0px 0px 1.125rem;
    position: sticky;
    top: 64px;
    width: 100%;
    z-index: 10;
    background-color: ${backgroundSite};
    .backLink {
      margin: 10px 0;
      display: flex;
      cursor: pointer;
      align-items: center;
      .iconStyled {
        margin-right: 10px;

        svg {
          background: ${primary};
          border-radius: 50%;
          height: 18px;
          width: 18px;
          circle {
            display: none;
          }
          path {
            fill: ${white};
            stroke: ${white};
          }
          line {
            fill: ${white};
            stroke: ${white};
            stroke-width: '4';
          }
        }
      }
      .primary-link {
        color: ${secondary};
        .text {
          font-size: 1rem;
        }
      }
    }
  }
`;
