import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { AlertModal } from '@next-components/alert-modal';
import { IAlertPopup } from '../../sales/sales-wizard';
import { OtherDealerVehicleChoosenValidationMsg } from '../../../utils';

interface IPopupProps {
  isOpen: boolean;
  availabilityCode: string;
  onCancel: () => void;
  onApprove: () => void;
}

export const OtherDealerVehicleSelectionPopup = ({ isOpen, availabilityCode, onCancel, onApprove }: IPopupProps) => {

  const data: IAlertPopup = {
    title: `Bilen tilhører annen forhandler`,
    description: OtherDealerVehicleChoosenValidationMsg(availabilityCode),
    actionsList: [
      {
        label: 'Ja, velg denne bilen',
        actionMethod: onApprove,
      },
      {
        label: 'Avbryt',
        actionMethod: onCancel,
        actionVariant: 'secondary',
      },
    ],
  };

  return (
    <ModalWrapper isActive={isOpen}>
      <AlertModal isOpen={isOpen} onCloseClick={onCancel} {...data}></AlertModal>
    </ModalWrapper>
  );
};
