import { gql } from '@apollo/client';

export const globalErrorsQuery = gql`
  query onLocalError {
    globalErrors {
      status
      type
    }
  }
`;
