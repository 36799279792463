import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CustomerType, EnumMapper, IBosCustomer, ICustomer, IOrder } from 'next-common';
import { saveCustomerMutationParsed } from '../../../../../../common';
import { CustomerFormType } from './CustomerForm';
import { CustomerSelected } from './CustomerSelected';

interface ICustomerPage {
  order: IOrder;
  onClose(): void;
}

export const CustomerPage = (props: ICustomerPage) => {
  const [searchCriteria, setSearchCriteria] = useState('0');
  const [customer, setCustomer] = useState<IBosCustomer>(null);
  const [customerType, setCustomerType] = useState(null);
  const [customerFormType, setCustomerFormType] = useState<CustomerFormType>(null);
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [saveCustomer] = useMutation(saveCustomerMutationParsed);
  const [isSavingCustomer, setIsSavingCustomer] = useState(false);

  useEffect(() => {
    setUpdatedOrder(props.order);
    if (props.order?.customer?.bosCustomer) {
      setCustomer(props.order.customer.bosCustomer);
      setCustomerType(CustomerType[props.order.customer.bosCustomer.customerType]);
      setCustomerFormType(CustomerFormType.EXISTING_CUSTOMER);
      setSearchCriteria(
        props.order?.customer?.bosCustomer?.organizationNo || props.order?.customer?.bosCustomer?.phoneMobile,
      );
    }
  }, [props.order]);

  const getOrder = () => updatedOrder || null;

  const mapFormData = (formData: any): IBosCustomer => {
    return EnumMapper.mapEnumsToStringRecursively({
      ...formData,
    });
  };

  const submitForm = async (formData: IBosCustomer) => {
    setIsSavingCustomer(true);
    try {
      const changes = mapFormData(formData);
      const bosApiId = customer?.id || null;
      const input = {
        ...changes,
        id: bosApiId,
        customerType: props.order.customer.bosCustomer.customerType,
      };
      await onUpdateBosCustomer(input);
      props.onClose();
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
    setIsSavingCustomer(false);
  };

  const onUpdateBosCustomer = async (input: IBosCustomer): Promise<ICustomer> => {
    try {
      const { data } = await saveCustomer({ variables: { orderId: getOrder().id, input } });
      return data?.saveCustomer || null;
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  return (
    <>
      {customer ? (
        <CustomerSelected
          customer={customer}
          customerType={customerType}
          customerFormType={customerFormType}
          queryString={searchCriteria}
          submitForm={submitForm}
          isSavingCustomer={isSavingCustomer}
          onClose={props.onClose}
        />
      ) : null}
    </>
  );
};
