import { formatNumber } from "sales-common";

export interface IRuleValues {
    name: string;
    price: number;
    sku: string;
    category: string;
    mainCategory: string;
}

export interface IRulesDetail {
    header: string;
    ruleValues: IRuleValues[];
    prefix: string;
}

export const RulesDetail = (props: IRulesDetail) => {
    const {
        header,
        ruleValues,
        prefix,
    } = props;

    return (
        <>
            <div className="rulesHeader">
                {header}
            </div>
            {ruleValues?.map((ruleValue) => {
                return (
                    <div className="rulesDetail" key={`rules-detail=${ruleValue?.name}`}>
                        <div className="ruleName">
                            {prefix} {ruleValue?.name}
                        </div>
                        <div className="rulePrice">
                            {ruleValue?.price !== 0 ? prefix : ''} {formatNumber(ruleValue?.price)} kr
                        </div>
                    </div>
                )
            })}
        </>
    )
};