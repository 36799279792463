import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ERROR_POLICY } from '../constants';
import { getLeasingCompanyListQuery } from '../graphql';
import { removeDuplicateObjectFromArray } from '../../utils';

export const useLeasingCompanies = () => {
  const [leasingCompanies, setLeasingCompanies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!leasingCompanies?.length) {
      leasingCompaniesData();
    }
  }, []);

  const [leasingCompaniesData, { loading }] = useLazyQuery(getLeasingCompanyListQuery, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const filteredList = removeDuplicateObjectFromArray(data?.getLeasingCompanyList, 'organizationNo');
      setLeasingCompanies(filteredList);
    },
    onError: (error) => {
      setError(error);
    }
  });
  return { leasingCompanies, loading, error };
};
