import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IValidationToastNotification } from '../../common';
import { IPriceChangedFields } from '../../utils';
import { WarningStyled } from './Warning.styled';
import { v4 as uuidv4 } from 'uuid';

export interface IPimWarningProps extends IValidationToastNotification {
    priceChangedFields: IPriceChangedFields[];
}

export const PimWarning = (props: IPimWarningProps) => {
  const fields = props?.priceChangedFields ?? [];
  return (
    <Messagebar
      severityLevel={MessagebarSeverityLevel.WARNING}
      title="Pris endret i PIM"
      onClose={props?.onClose}>
      <WarningStyled>
        <ul>{fields?.map((price) => <li key={uuidv4()}>{`${price?.name ?? ''}:`} <ul><li>
          Gjeldende pris: {price?.currentPrice ?? 0} Kr</li>
          <li>Ny pris: {price?.newPrice ?? 0} Kr</li></ul></li>)}</ul>
      </WarningStyled>
    </Messagebar>
  );
};
