import { backgroundCard, secondary, white } from '@next-components/common-styles';
import styled from 'styled-components';

export const DealerSelectStyled = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
`;

export const DealerOptionStyled = styled.div`
  margin-bottom: 6px;
  display: grid;
  grid-template-columns: auto 25px;
  cursor: pointer;
  padding: 2px 12px;
  &.selected {
    background-color: ${backgroundCard};
    .user-body-button > svg {
      vertical-align: top;
    }
  }
  .circle-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: ${secondary};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      height: 100%;
      path {
        fill: ${white};
      }
    }
  }
`;
