import { MAKE } from 'next-common';
 
export interface ITTCodeStatusList {
  code: number[];
  name: string;
}
 
export enum TTCODE_STATUS_NAME {
  ORDER_NOT_CONFIRMED = 'Bestilt – ikke bekreftet',
  MODIFIABLE = 'Endringsbar',
  IN_PRODUCTION = 'I produksjon',
  FINISHED_PRODUCTION = 'Ferdig produsert',
  UNDER_TRANSPORT = 'Under transport',
  IN_DRAMMEN = 'I Drammen',
  AT_DEALER = 'Hos forhandler',
}
 
const getRange = (start: number, end: number): number[] =>
  Array.from({ length: end - start + 1 }, (_, index) => start + index);
 
export const TTCodeRanges = {
  [MAKE.Mercedes]: {
    ORDER_NOT_CONFIRMED: { min: 1, max: 9 },
    MODIFIABLE: { min: 10, max: 24 },
    IN_PRODUCTION: { min: 25, max: 29 },
    FINISHED_PRODUCTION: { min: 30, max: 49 },
    UNDER_TRANSPORT: { min: 50, max: 79 },
    IN_DRAMMEN: { min: 80, max: 119 },
    AT_DEALER: { min: 120, max: 126 },
  },
  [MAKE.Kia]: {
    ORDER_NOT_CONFIRMED: { min: 1, max: 39 },
    FINISHED_PRODUCTION: { min: 40, max: 49 },
    UNDER_TRANSPORT: { min: 50, max: 79 },
    IN_DRAMMEN: { min: 80, max: 119 },
    AT_DEALER: { min: 120, max: 126 },
  },
  // For other car brands, share the same ranges, (e.g., Peugeot, Citroen, etc.)
  [MAKE.Peugeot]: {
    ORDER_NOT_CONFIRMED: { min: 1, max: 9 },
    MODIFIABLE: { min: 10, max: 19 },
    IN_PRODUCTION: { min: 20, max: 29 },
    FINISHED_PRODUCTION: { min: 30, max: 49 },
    UNDER_TRANSPORT: { min: 50, max: 79 },
    IN_DRAMMEN: { min: 80, max: 119 },
    AT_DEALER: { min: 120, max: 126 },
  },
};
 
export const getTTCodeStatusName = (brand: string, ttCode: number): string => {
  let status: string;
  switch (brand) {
    case MAKE.Mercedes:
      switch (true) {
        case ttCode >= TTCodeRanges[MAKE.Mercedes].ORDER_NOT_CONFIRMED.min &&
          ttCode <= TTCodeRanges[MAKE.Mercedes].ORDER_NOT_CONFIRMED.max:
          status = TTCODE_STATUS_NAME.ORDER_NOT_CONFIRMED;
          break;
        case ttCode >= TTCodeRanges[MAKE.Mercedes].MODIFIABLE.min && ttCode <= TTCodeRanges[MAKE.Mercedes].MODIFIABLE.max:
          status = TTCODE_STATUS_NAME.MODIFIABLE;
          break;
        case ttCode >= TTCodeRanges[MAKE.Mercedes].IN_PRODUCTION.min && ttCode <= TTCodeRanges[MAKE.Mercedes].IN_PRODUCTION.max:
          status = TTCODE_STATUS_NAME.IN_PRODUCTION;
          break;
        case ttCode >= TTCodeRanges[MAKE.Mercedes].FINISHED_PRODUCTION.min &&
          ttCode <= TTCodeRanges[MAKE.Mercedes].FINISHED_PRODUCTION.max:
          status = TTCODE_STATUS_NAME.FINISHED_PRODUCTION;
          break;
        case ttCode >= TTCodeRanges[MAKE.Mercedes].UNDER_TRANSPORT.min && ttCode <= TTCodeRanges[MAKE.Mercedes].UNDER_TRANSPORT.max:
          status = TTCODE_STATUS_NAME.UNDER_TRANSPORT;
          break;
        case ttCode >= TTCodeRanges[MAKE.Mercedes].IN_DRAMMEN.min && ttCode <= TTCodeRanges[MAKE.Mercedes].IN_DRAMMEN.max:
          status = TTCODE_STATUS_NAME.IN_DRAMMEN;
          break;
        case ttCode >= TTCodeRanges[MAKE.Mercedes].AT_DEALER.min && ttCode <= TTCodeRanges[MAKE.Mercedes].AT_DEALER.max:
          status = TTCODE_STATUS_NAME.AT_DEALER;
          break;
        default:
          status = '';
      }
      return status;
    case MAKE.Kia:
      switch (true) {
        case ttCode >= TTCodeRanges[MAKE.Kia].ORDER_NOT_CONFIRMED.min && ttCode <= TTCodeRanges[MAKE.Kia].ORDER_NOT_CONFIRMED.max:
          status = TTCODE_STATUS_NAME.ORDER_NOT_CONFIRMED;
          break;
        case ttCode >= TTCodeRanges[MAKE.Kia].FINISHED_PRODUCTION.min && ttCode <= TTCodeRanges[MAKE.Kia].FINISHED_PRODUCTION.max:
          status = TTCODE_STATUS_NAME.FINISHED_PRODUCTION;
          break;
        case ttCode >= TTCodeRanges[MAKE.Kia].UNDER_TRANSPORT.min && ttCode <= TTCodeRanges[MAKE.Kia].UNDER_TRANSPORT.max:
          status = TTCODE_STATUS_NAME.UNDER_TRANSPORT;
          break;
        case ttCode >= TTCodeRanges[MAKE.Kia].IN_DRAMMEN.min && ttCode <= TTCodeRanges[MAKE.Kia].IN_DRAMMEN.max:
          status = TTCODE_STATUS_NAME.IN_DRAMMEN;
          break;
        case ttCode >= TTCodeRanges[MAKE.Kia].AT_DEALER.min && ttCode <= TTCodeRanges[MAKE.Kia].AT_DEALER.max:
          status = TTCODE_STATUS_NAME.AT_DEALER;
          break;
        default:
          status = '';
      }
      return status;
    case MAKE.Peugeot:
    case MAKE.Citroen:
    case MAKE.DS:
    case MAKE.Smart:
    case MAKE.Opel:
    case MAKE.Fuso:
      switch (true) {
        case ttCode >= TTCodeRanges[MAKE.Peugeot].ORDER_NOT_CONFIRMED.min &&
          ttCode <= TTCodeRanges[MAKE.Peugeot].ORDER_NOT_CONFIRMED.max:
          status = TTCODE_STATUS_NAME.ORDER_NOT_CONFIRMED;
          break;
        case ttCode >= TTCodeRanges[MAKE.Peugeot].MODIFIABLE.min && ttCode <= TTCodeRanges[MAKE.Peugeot].MODIFIABLE.max:
          status = TTCODE_STATUS_NAME.MODIFIABLE;
          break;
        case ttCode >= TTCodeRanges[MAKE.Peugeot].IN_PRODUCTION.min && ttCode <= TTCodeRanges[MAKE.Peugeot].IN_PRODUCTION.max:
          status = TTCODE_STATUS_NAME.IN_PRODUCTION;
          break;
        case ttCode >= TTCodeRanges[MAKE.Peugeot].FINISHED_PRODUCTION.min &&
          ttCode <= TTCodeRanges[MAKE.Peugeot].FINISHED_PRODUCTION.max:
          status = TTCODE_STATUS_NAME.FINISHED_PRODUCTION;
          break;
        case ttCode >= TTCodeRanges[MAKE.Peugeot].UNDER_TRANSPORT.min && ttCode <= TTCodeRanges[MAKE.Peugeot].UNDER_TRANSPORT.max:
          status = TTCODE_STATUS_NAME.UNDER_TRANSPORT;
          break;
        case ttCode >= TTCodeRanges[MAKE.Peugeot].IN_DRAMMEN.min && ttCode <= TTCodeRanges[MAKE.Peugeot].IN_DRAMMEN.max:
          status = TTCODE_STATUS_NAME.IN_DRAMMEN;
          break;
        case ttCode >= TTCodeRanges[MAKE.Peugeot].AT_DEALER.min && ttCode <= TTCodeRanges[MAKE.Peugeot].AT_DEALER.max:
          status = TTCODE_STATUS_NAME.AT_DEALER;
          break;
        default:
          status = '';
      }
      return status;
    default:
      return '';
  }
};
 
export const getTTCodeStatusNameByBrand = (brand: string): string[] => {
  switch (brand) {
    case MAKE.Kia:
      return Object.values(TTCODE_STATUS_NAME).filter(
        (value) => value !== TTCODE_STATUS_NAME.MODIFIABLE && value !== TTCODE_STATUS_NAME.IN_PRODUCTION,
      );
    case MAKE.Peugeot:
    case MAKE.Citroen:
    case MAKE.DS:
    case MAKE.Smart:
    case MAKE.Opel:
    case MAKE.Fuso:
    case MAKE.Mercedes:
      return Object.values(TTCODE_STATUS_NAME);
    default:
      return [];
  }
};
 
export const getTTCodeRangeByBrandAndStatus = (brand: string, status: string): number[] => {
  switch (brand) {
    case MAKE.Mercedes:
      switch (status) {
        case TTCODE_STATUS_NAME.ORDER_NOT_CONFIRMED:
          return getRange(TTCodeRanges[MAKE.Mercedes].ORDER_NOT_CONFIRMED.min, TTCodeRanges[MAKE.Mercedes].ORDER_NOT_CONFIRMED.max);
 
        case TTCODE_STATUS_NAME.MODIFIABLE:
          return getRange(TTCodeRanges[MAKE.Mercedes].MODIFIABLE.min, TTCodeRanges[MAKE.Mercedes].MODIFIABLE.max);
 
        case TTCODE_STATUS_NAME.IN_PRODUCTION:
          return getRange(TTCodeRanges[MAKE.Mercedes].IN_PRODUCTION.min, TTCodeRanges[MAKE.Mercedes].IN_PRODUCTION.max);
 
        case TTCODE_STATUS_NAME.FINISHED_PRODUCTION:
          return getRange(TTCodeRanges[MAKE.Mercedes].FINISHED_PRODUCTION.min, TTCodeRanges[MAKE.Mercedes].FINISHED_PRODUCTION.max);
 
        case TTCODE_STATUS_NAME.UNDER_TRANSPORT:
          return getRange(TTCodeRanges[MAKE.Mercedes].UNDER_TRANSPORT.min, TTCodeRanges[MAKE.Mercedes].UNDER_TRANSPORT.max);
 
        case TTCODE_STATUS_NAME.IN_DRAMMEN:
          return getRange(TTCodeRanges[MAKE.Mercedes].IN_DRAMMEN.min, TTCodeRanges[MAKE.Mercedes].IN_DRAMMEN.max);
 
        case TTCODE_STATUS_NAME.AT_DEALER:
          return getRange(TTCodeRanges[MAKE.Mercedes].AT_DEALER.min, TTCodeRanges[MAKE.Mercedes].AT_DEALER.max);
 
        default:
          return [];
      }
 
    case MAKE.Kia:
      switch (status) {
        case TTCODE_STATUS_NAME.ORDER_NOT_CONFIRMED:
          return getRange(TTCodeRanges[MAKE.Kia].ORDER_NOT_CONFIRMED.min, TTCodeRanges[MAKE.Kia].ORDER_NOT_CONFIRMED.max);
 
        case TTCODE_STATUS_NAME.FINISHED_PRODUCTION:
          return getRange(TTCodeRanges[MAKE.Kia].FINISHED_PRODUCTION.min, TTCodeRanges[MAKE.Kia].FINISHED_PRODUCTION.max);
 
        case TTCODE_STATUS_NAME.UNDER_TRANSPORT:
          return getRange(TTCodeRanges[MAKE.Kia].UNDER_TRANSPORT.min, TTCodeRanges[MAKE.Kia].UNDER_TRANSPORT.max);
 
        case TTCODE_STATUS_NAME.IN_DRAMMEN:
          return getRange(TTCodeRanges[MAKE.Kia].IN_DRAMMEN.min, TTCodeRanges[MAKE.Kia].IN_DRAMMEN.max);
 
        case TTCODE_STATUS_NAME.AT_DEALER:
          return getRange(TTCodeRanges[MAKE.Kia].AT_DEALER.min, TTCodeRanges[MAKE.Kia].AT_DEALER.max);
 
        default:
          return [];
      }
 
    case MAKE.Peugeot:
    case MAKE.Citroen:
    case MAKE.DS:
    case MAKE.Smart:
    case MAKE.Opel:
    case MAKE.Fuso:
      switch (status) {
        case TTCODE_STATUS_NAME.ORDER_NOT_CONFIRMED:
          return getRange(TTCodeRanges[MAKE.Peugeot].ORDER_NOT_CONFIRMED.min, TTCodeRanges[MAKE.Peugeot].ORDER_NOT_CONFIRMED.max);
 
        case TTCODE_STATUS_NAME.MODIFIABLE:
          return getRange(TTCodeRanges[MAKE.Peugeot].MODIFIABLE.min, TTCodeRanges[MAKE.Peugeot].MODIFIABLE.max);
 
        case TTCODE_STATUS_NAME.IN_PRODUCTION:
          return getRange(TTCodeRanges[MAKE.Peugeot].IN_PRODUCTION.min, TTCodeRanges[MAKE.Peugeot].IN_PRODUCTION.max);
 
        case TTCODE_STATUS_NAME.FINISHED_PRODUCTION:
          return getRange(TTCodeRanges[MAKE.Peugeot].FINISHED_PRODUCTION.min, TTCodeRanges[MAKE.Peugeot].FINISHED_PRODUCTION.max);
 
        case TTCODE_STATUS_NAME.UNDER_TRANSPORT:
          return getRange(TTCodeRanges[MAKE.Peugeot].UNDER_TRANSPORT.min, TTCodeRanges[MAKE.Peugeot].UNDER_TRANSPORT.max);
 
        case TTCODE_STATUS_NAME.IN_DRAMMEN:
          return getRange(TTCodeRanges[MAKE.Peugeot].IN_DRAMMEN.min, TTCodeRanges[MAKE.Peugeot].IN_DRAMMEN.max);
 
        case TTCODE_STATUS_NAME.AT_DEALER:
          return getRange(TTCodeRanges[MAKE.Peugeot].AT_DEALER.min, TTCodeRanges[MAKE.Peugeot].AT_DEALER.max);
 
        default:
          return [];
      }
 
    default:
      return [];
  }
};
 
export const ttCodes = [
  {
    id: 1,
    description: 'Bestilt - ikke bekreftet',
  },
  {
    id: 10,
    description: 'Bekreftet bestilling fra fabrikk',
  },
  {
    id: 20,
    description: 'Planlagt for produksjon',
  },
  {
    id: 22,
    description: 'Produksjonsdato satt',
  },
  {
    id: 24,
    description: 'Produksjon bekreftet',
  },
  {
    id: 26,
    description: 'Produksjon begynt',
  },
  {
    id: 28,
    description: 'I produksjon',
  },
  {
    id: 30,
    description: 'Ferdig produsert',
  },
  {
    id: 32,
    description: 'Fabrikkombygget (MER)',
  },
  {
    id: 34,
    description: 'Retur fabrikk (MER)',
  },
  {
    id: 35,
    description: 'Ferdig inspisert fabrikk (Opel)',
  },
  {
    id: 40,
    description: 'Sendt fra fabrikk',
  },
  {
    id: 42,
    description: 'Fabrikkombygging',
  },
  {
    id: 44,
    description: 'Retur fabrikk',
  },
  {
    id: 46,
    description: 'Sendt utenlandsk påbygger',
  },
  {
    id: 48,
    description: 'Ankommet utenlandsk påbygger',
  },
  {
    id: 49,
    description: 'Send til eksporthavn',
  },
  {
    id: 50,
    description: 'Ankomst eksporthavn',
  },
  {
    id: 60,
    description: 'Ferdig understsellbehandling',
  },
  {
    id: 65,
    description: 'Ankomst Wallhamn',
  },
  {
    id: 70,
    description: 'Sendt fra eksporthavn',
  },
  {
    id: 80,
    description: 'Ankomst Importhavn',
  },
  {
    id: 82,
    description: 'Utlevering kjøreordre',
  },
  {
    id: 90,
    description: 'Tolldeklarert',
  },
  {
    id: 91,
    description: 'Plukket av Bos log',
  },
  {
    id: 92,
    description: 'Plukk er mottatt av transportør',
  },
  {
    id: 93,
    description: 'Skade vekk',
  },
  {
    id: 94,
    description: 'Delevent',
  },
  {
    id: 95,
    description: 'Klargjøring importhavn (PDO tjenester)',
  },
  {
    id: 96,
    description: 'Levert påbygger',
  },
  {
    id: 97,
    description: 'Forhandlerlager Drammen',
  },
  {
    id: 98,
    description: 'Teknisk og kosmetisk klargjøring Drammen',
  },
  {
    id: 100,
    description: 'Sendt fra importhavn',
  },
  {
    id: 110,
    description: 'Ankomst påbygger i Norge',
  },
  {
    id: 120,
    description: 'Ankomstmeldt hos forhandler',
  },
  {
    id: 125,
    description: 'Levert forhandler fra transportør',
  },
  {
    id: 126,
    description: 'Mottatt av forhandler med APP',
  },
  {
    id: 130,
    description: 'Klargjøring',
  },
];
