import { useEffect } from 'react';

export const useDisableScroll = (isactive: boolean) => {
  useEffect(() => {
    if (isactive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
      
    return () => {
      document.body.style.overflow = '';
    };
  }, [isactive]);
};


