import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import client from './apollo';
import { MsalProvider, AuthenticatedTemplate } from '@azure/msal-react';
import MsalAuth from './components/MsalAuthentication';
import { setTokenLocalStorage, acquireToken, msalInstance, getTokenLocalStorage } from './config/authentication';

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance?.getAllAccounts();
if (accounts?.length > 0) {
  msalInstance?.setActiveAccount(accounts?.[0] ?? null);
}

msalInstance
  .handleRedirectPromise()
  .then(async (tokenResponse) => {
    if (tokenResponse === null || tokenResponse === undefined) {
      await acquireToken(msalInstance);
    } else {
      setTokenLocalStorage(tokenResponse?.accessToken);
    }
  })
  .catch((error) => {
    console.error(error);
  });

export const InitialComponent = () => {
  const token = getTokenLocalStorage();
  const [emptyToken, setEmptyToken] = useState(true);

  useEffect(() => {
    if (!token) {
      acquireToken(msalInstance).then(() => {
        setEmptyToken(false);
      });
    } else {
      setEmptyToken(false);
    }
  }, [token]);

  if (emptyToken) return null;

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuth>
        <AuthenticatedTemplate>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <QueryParamProvider adapter={ReactRouter6Adapter}>
                <App />
              </QueryParamProvider>
            </BrowserRouter>
          </ApolloProvider>
        </AuthenticatedTemplate>
      </MsalAuth>
    </MsalProvider>
  );
};

export const initiateApplication = () => {
  const container = document.getElementById('root');
  const root = ReactDOM.createRoot(container);
  root.render(<InitialComponent />);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
