import React from 'react';
import {
  PendingConfirmationBody,
  PendingConfirmationWrapper,
  StatusMessageListItemHeader,
} from '../OrderStatusList.styled';
import { IBosVehicleOrderStatus, IOrder, OrderType, VehicleReserveStatus } from 'next-common';
import { DateTimeBlock } from '../../../../sales-common/DateTimeBlock';
import { Accordion } from '@next-components/accordion';
import { Icon } from '@next-components/bos-icon';
import { getDynamicAccordionIcon } from './ReservedCarInfo';

export const hasPendingReservation = (order: IOrder, lastStatus: IBosVehicleOrderStatus) => {
  if (!order?.reserveDetails?.vehicleReserveStatus) return false;
  const reserveStatus = order.reserveDetails.vehicleReserveStatus;
  if (!reserveStatus.toString().includes('WAITING')) return false;
  if (
    lastStatus &&
    lastStatus.orderType === OrderType[OrderType.RESERVE] &&
    reserveStatus.toString() === VehicleReserveStatus[VehicleReserveStatus.WAITING]
  ) {
    return false;
  }
  if (
    lastStatus &&
    lastStatus.orderType === OrderType[OrderType.CANCEL_RESERVE] &&
    reserveStatus.toString() === VehicleReserveStatus[VehicleReserveStatus.WAITING_CANCELLATION]
  ) {
    return false;
  }
  return true;
};

export const PendingReservation = ({ vehicleReserveStatus }: { vehicleReserveStatus: VehicleReserveStatus }) => {
  const waitType =
    vehicleReserveStatus?.toString() === VehicleReserveStatus[VehicleReserveStatus.WAITING]
      ? 'Bestilling av reservasjon'
      : 'Kansellering av reservasjon';

  return (
    <PendingConfirmationWrapper>
      <div className="messageWrapper">
        <Accordion
          nextAccordion
          className={'warning'}
          labelContent={
            <div className="accordionLabelContainer">
              <div className={'iconContainer'}>
                <Icon icon={getDynamicAccordionIcon('warning')} />
              </div>
              <StatusMessageListItemHeader>
                <div className={`reserveHeader`}>
                  <h3>Reservasjon</h3>
                </div>
                <div className="ifsDate">
                  <DateTimeBlock dateTime={new Date().toISOString()} shortDate />
                </div>
              </StatusMessageListItemHeader>
            </div>
          }>
          <PendingConfirmationBody className="PendingConfirmationBody">
            <div className="leftContainer">
              <div className="bodyItem">
                <span className="label">Status</span>
                <p className="value">{waitType}</p>
              </div>
            </div>
            <div className="rightContainer">
              <span>Sjekker automatisk igjen hvert 15 sekund</span>
            </div>
          </PendingConfirmationBody>
        </Accordion>
      </div>
    </PendingConfirmationWrapper>
  );
};
