import styled from 'styled-components';
import { bosColor, typography, white, padding, disabled, margin } from '@next-components/common-styles';

interface IStyledListRowProps {
  columnWidth?: string[];
  buttonWidth?: string[];
  isNewDynamicList?: boolean;
  hasButtonsOnly?: boolean;
  withAccordion?: boolean;
}

export const checkboxColWidth = '3rem';

export const VehicleListMerStyled = styled.div<IStyledListRowProps>`
  display: grid;
  grid-auto-rows: auto;
  column-gap: 0;
  grid-template-columns: ${(props) => props.columnWidth.join(' ')};

  .br-1 {
    border-right: 1px solid ${bosColor.neutralPlus};
  }
  .grid-row {
    display: grid;
    grid-template-columns: ${(props) => props.columnWidth.join(' ')};
    > div {
      ${padding['12'].y};
      ${padding['16'].l};
      ${padding['0'].r};
    }
  }

  &.header {
    font-weight: ${typography.weight.semibold};
    ${padding['8'].t};
    ${padding['0'].b};
    ${padding['8'].x};
    place-items: start;
    &.headerRow {
      .grid-row div {
        font-size: 1rem;
        font-weight: ${typography.weight.extrabold};
      }
      background-color: ${bosColor.lightGrey};
      color: ${bosColor.concrete};
      position: sticky;
      top: 0;
      z-index: 1;
      font-weight: ${typography.weight.semibold};
      display: block;
      width: 100%;
      line-height: 1.125rem;
      word-break: break-word;
      text-align: left;
      ${padding['0'].a};
      color: ${bosColor.concrete};
      .selectable {
        ${padding['0'].r};
      }

      .headerContainer {
        display: grid;
        grid-template-columns: '1fr';
        gap: 1rem;
        height: 3rem;
        .headerBottomContainer,
        .headerTopContainer {
          display: grid;
          grid-template-columns: 1fr 3rem;
        }
        .headerTopContainer {
          height: 3.5rem;
          .grid-row {
            &.header-top {
              > div {
                ${padding['16'].y};
                display: flex;
                align-item: center;
              }
              display: grid;
              grid-template-columns: 16.5rem 14.5% 6.5% 7% 8% 14.5% 7.5% 7% 6% 6% 1fr;
              color: ${bosColor.black};
            }
          }
        }

        .headerBottomContainer {
          background: ${bosColor.neutralGrey};
          color: ${bosColor.black};
          .grid-row {
            &.header-inner {
              > div {
                &.sortable {
                  &:nth-child(7),
                  &:nth-child(4) {
                    border-right: 1px solid ${bosColor.neutralPlus};
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.item {
    &.grid-row {
      display: grid;
      background: ${white};
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: ${typography.weight.semibold};
      border-bottom: ${(props) => !props?.withAccordion && `1px solid ${bosColor.neutralPlus}`};

      &.disabled {
        cursor: default;
        .grid-item {
          div {
            color: ${disabled};
            > span > span:first-child {
              opacity: 0.65;
            }
            span {
              color: ${disabled};
            }
            span.red {
              color: ${bosColor.neutral};
            }
          }
        }
      }
      .grid-item {
        ${padding['16'].y};
        ${padding['16'].l};
        ${padding['0'].r};
        &:nth-child(7),
        &:nth-child(4) {
          border-right: 1px solid ${bosColor.neutralPlus};
        }
        div > div {
          display: flex;
          gap: 0.25rem;
          flex-wrap: wrap;

          span {
            &.moreOptions {
              pointer-events: none;
              border-radius: 0.75rem;
              font-size: 0.875rem;
              line-height: 140%;
            }
            span {
              background: ${bosColor.neutralGrey};
              padding: 2px 0.75rem;
              border-radius: 0.75rem;
              font-size: 0.875rem;
              line-height: 140%;
            }
          }
        }
        span.black {
          color: ${bosColor.black};
        }
        span.gray {
          color: ${disabled};
        }
        span.red {
          color: ${bosColor.neutral};
        }
      }
    }

    .selectable {
      display: flex;
      justify-content: start;
      align-items: center;

      .checkboxRadio {
        position: relative;
        left: 1rem;

        &:before {
          position: absolute;
          content: '';
          width: 1.375rem;
          height: 1.375rem;
          border: 2px solid ${bosColor.black};
          border-radius: 1.25rem;
          left: 0;
          top: -0.625rem;
          box-sizing: border-box;
        }
      }
      .lockIcon {
        position: relative;
        left: 1rem;

        svg {
          transform: rotate(0);
        }
      }
    }
    &.selected .selectable .checkboxRadio:after {
      content: '';
      width: 0.75rem;
      height: 0.75rem;
      background: ${bosColor.black};
      position: absolute;
      top: -0.3125rem;
      left: 0.3125rem;
      border-radius: 100%;
      box-sizing: border-box;
    }

    &.selected,
    &:hover {
      cursor: pointer;
    }
    &.disabled {
      cursor: default;
      opacity: 1;
    }

    &.empty,
    &.load-more {
      place-items: center;
    }

    &.empty.sellAllCars {
      padding: 1rem;
    }

    &.empty:hover {
      border-color: ${(props) => props.isNewDynamicList && white};
    }

    .selectable > .checkbox {
      width: 80%;
    }
    .vehicle-color > svg {
      background: none;
    }
  }

  .sortable {
    cursor: pointer;
  }
`;

export const VehicleListDetailMerStyled = styled.div<IStyledListRowProps>`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 1.5rem;
  padding: 1.5rem 3.5rem;
  background: #fcfcfc !important;
  border: 1px solid #e3e3e3;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 0.5rem;

  .gridListItemContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &.utstyrspakkeContainer .gridListItem {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 800 !important;
    }
    .imageContainer {
      border-radius: 0.5rem;
      width: 240px;
      height: 147px;
      border: 2px solid #f1f1f1;
      margin-top: 0.5rem;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    label.labelTitle {
      font-weight: 800;
      line-height: 150%;
      color: #191919;
    }
    .itemValue {
      font-weight: 600;
      line-height: 150%;
      &.vehicleDetailBtn {
        ${margin[24].r};
        height: 3rem;
        padding: 0.75rem 1.5rem;
        &.secondary {
          border-width: 2px;
        }
        &:last-child {
          ${margin[0].r};
          color: ${bosColor.white} !important;
          :hover {
            color: initial !important;
          }
        }
      }
      > div {
        display: flex;
        gap: 0.25rem;
        flex-wrap: wrap;

        span {
          background: ${bosColor.neutralGrey};
          padding: 2px 0.75rem;
          border-radius: 0.75rem;
          font-size: 1rem;
          line-height: 1.75rem;
        }
      }
      span.black {
        color: ${bosColor.black};
      }
      span.gray {
        color: ${disabled};
      }
      span.red {
        color: ${bosColor.neutral};
      }
    }
  }
`;
