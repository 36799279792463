import styled from 'styled-components';
import { bosColor, fontWeightExtraBold, secondary, typography } from '@next-components/common-styles';

export const OfferPaneStyled = styled.div`
  display: grid;
  gap: 1.5rem;
  > h2 {
    color: ${bosColor.black};
    font-size: 1.5rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 165%;
  }
  .expiryDateInnerSection {
    display: grid;
    margin-right: 0;
    margin-left: 0;
    flex-direction: row;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
  .additional-comments-section{
    align-items: center;
    justify-content: center;
    display: grid;
    > div {
      width: 100%;
    }
  }

  .input-container {  
    padding: 1.5rem;
    background-color: ${bosColor.lightGrey};
    border: 1px solid ${bosColor.neutralGrey};
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    .additional-button-section{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      cursor: pointer;
    }
    .additional-label{
      font-size: 1.125rem;
      font-weight: ${fontWeightExtraBold};
      line-height: 175%;
    }
    .additional-button {
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: ${bosColor.turquoise};
      svg {
        width: 100%;
        height: 100%;
        padding-block: 3px;
      }
    }
    .additional-button :hover{
      width: 1.5rem;
      height: 1.5rem;
    }
    .icon-container {
      a {
        font-family: 'Mulish', Helvetica, Arial, sans-serif;
      }
      cursor: pointer;
      line-height: 100%;
      svg {
        width: 22px;
        height: 22px;
        color: ${secondary};
      }
    }
  }
`;
