import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { MAKE } from 'next-common';
import { IAvailableVehicleStockList } from 'sales-common';
import { vehicleStockBySerialNoQuery } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export interface IVehicleStockParams {
  make?: MAKE;
  series?: string;
  group?: string;
  serialNo: string;
  isUnavailableInCache?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export interface IVehicleStockResult {
  data: IAvailableVehicleStockList[];
  loading: boolean;
}

export const useVehicleStock = (params: IVehicleStockParams): IVehicleStockResult => {
  const { data, loading } = useQuery(vehicleStockBySerialNoQuery, {
    variables: {
      group: params?.group,
      serialNo: params?.serialNo,
      isUnavailableInCache: params?.isUnavailableInCache || false,
    },
    errorPolicy: ERROR_POLICY,
    skip: !params?.serialNo,
    fetchPolicy: params?.fetchPolicy || 'cache-first',
  });
  const stock = data?.vehicleStockBySerialNo?.data || [];
  return { data: stock, loading };
};
