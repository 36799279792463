import React, { useEffect } from 'react';
import { Tooltip } from '@next-components/tooltip';
import { IOrder } from 'next-common';
import { MissingItem } from './MissingItem';
import { Button } from '@next-components/cta';

export interface IChooseFinanceActionProps {
  order: IOrder;
  onClick(calculationId: string): void;
  value: string;
}

export const ChooseFinanceAction = (props: IChooseFinanceActionProps) => {
  const [missingItems, setMissingItems] = React.useState<string[]>([]);

  useEffect(() => {
    const items: string[] = [];
    if (!props.order?.customer) items.push(MissingItem.Customer);
    if (!props.order?.vehicles || props.order.vehicles.length < 1) items.push(MissingItem.Car);
    setMissingItems(items);
  }, [props.order]);

  return (
    <Tooltip text={`Velg ${missingItems.join(' og ')} før du kan signere`} enabledCondition={missingItems.length > 0}>
      <Button
        colorVariant="secondary"
        className="sign-button"
        disabled={missingItems.length > 0}
        onClick={(e) => {
          e.preventDefault();
          if (typeof props.onClick === 'function') {
            props.onClick(props.value);
          }
        }}>
        Signer
      </Button>
    </Tooltip>
  );
};
