import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';

export const CustomMessageBar = ({ orderEditable = false, isFinance = false }) => {
  const messageText = (): React.ReactNode => {
    if (isFinance) {
      return (
        <span>
          Legg til så mange finansvariasjoner du ønsker. Når du er klar kan du eksportere ett
          tilbud med alle variantene. Tilbudet kan hentes opp og signeres når som helst.
        </span>
      );
    } else if (orderEditable) {
      return (
        <span>
          Ved oppdatering av ordre er det ikke lenger mulig å endre konfigurasjon av bilen.
          <br /> Tilbehør, finansiering og tjenester kan fortsatt endres eller legges til.
          <br />
          <br /> Mva. på tilbehør avhenger av totalprisen på bilen.
        </span>
      );
    } else {
      return 'Mva. på tilbehør avhenger av totalprisen på bilen.';
    }
  };

  const messageProps = {
    messageBoxInfo: true,
    severityLevel: MessagebarSeverityLevel.INFORMATION,
    title: messageText(),
    className: 'customMessageBar',
  };
  return <Messagebar {...messageProps} />;
};
