import React, { useEffect, useState } from 'react';
import { CustomModal } from '@next-components/custom-modal';
import { CustomerPage } from './CustomerPage';
import { CustomerType, IOrder, IVehicleConfiguration } from 'next-common';
import { formatPhoneNumberInput } from '@next-components/common-types';
import { IVehiclePageProps, useDisableScroll } from '../../../../../common';
import { PageSteps } from '../NewHeaderWrapper';
import { CustomModalWrapper } from '../../../../layout/CustomModalWrapper.styled';
import { Icon, IconType } from '@next-components/bos-icon';
import { Button } from '@next-components/cta';
import { ModalWrapper } from '../../../../common-components/ModalWrapper';

export interface ICustomerPageProps extends IVehiclePageProps {
  activeStep?: number;
  order?: IOrder;
  vehicle?: IVehicleConfiguration;
  showCustomerPopup?: boolean;
  setShowCustomerPopup?(value: boolean): void;
}

export const CustomerInfo = (props: ICustomerPageProps) => {
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [customer, setCustomer] = useState(null);
  useDisableScroll(props.showCustomerPopup);

  useEffect(() => {
    setUpdatedOrder(props.order);
    setCustomer(props.order?.customer?.bosCustomer);
  }, [props.order]);

  const getCommonModalProps = () => ({
    onCloseClick: () => props?.setShowCustomerPopup(false),
  });

  const DefaultSign = () => <>-</>;
  const CustomerName = () => {
    if (customer?.customerType === CustomerType[CustomerType.PRIVATE]) {
      return `${customer?.firstName || ''} ${customer?.lastName || ''}`;
    } else {
      return customer?.organizationName || '-';
    }
  };

  const customerAddress = () =>
    `${customer?.address1 ? `${customer?.address1}, ` : ''}${customer?.zipCode} ${customer?.city}`;

  return (
    <>
      <div className="profile-left">
        <div>
          <span>
            <Icon icon={IconType.NewNextUserCustomer} height={24} width={24} />
          </span>
          <h4>{customer ? <CustomerName /> : <DefaultSign />}</h4>
        </div>
        <div>
          <span>
            <Icon icon={IconType.NewNextPinSolid} height={24} width={24} />
          </span>
          <label>{customer ? customerAddress() : <DefaultSign />}</label>
        </div>
        <div>
          <span>
            <Icon icon={IconType.NewNextPhoneSolid} height={24} width={24} />
          </span>
          <label>
            {customer?.phoneMobile ? (
              formatPhoneNumberInput(`+47${customer?.phoneMobile}`, true)?.formatted
            ) : (
              <DefaultSign />
            )}
          </label>
        </div>
        <div>
          <span>
            <Icon icon={IconType.NewNextEMailSolid} height={24} width={24} />
          </span>
          <label>{customer?.email ? customer?.email : <DefaultSign />}</label>
        </div>
      </div>
      <div className="profile-right">
        {props.activeStep !== PageSteps.START
          ? customer && (
              <Button
                variant="secondary"
                colorVariant="secondary"
                onClick={() => {
                  props?.setShowCustomerPopup(true);
                }}>
                <span>
                  <Icon icon={IconType.NewNextPen} />
                </span>
                <label>{'Rediger kundedata'}</label>
              </Button>
            )
          : null}
      </div>
      {props?.showCustomerPopup && (
        <CustomModalWrapper className="backdrop rightModal" active={props?.showCustomerPopup}>
          <ModalWrapper isActive={props?.showCustomerPopup}>
            <CustomModal
              {...getCommonModalProps()}
              active={props?.showCustomerPopup}
              isModelCloseAllowedOutside={false}>
              <CustomerPage order={updatedOrder} onClose={() => props?.setShowCustomerPopup(false)} />
            </CustomModal>
          </ModalWrapper>
        </CustomModalWrapper>
      )}
    </>
  );
};
