import React from 'react';
import { FilterBarMarginsStyled } from './FilterBarStyled.styled';
import { BosDropdown } from '@next-components/bos-dropdown';
import { IFilterBarProps } from 'next-common';
import { CheckBox } from '@next-components/bos-checkbox';
import get from 'lodash/get';

export const FilterBarMargins = (props: IFilterBarProps) => {
  return (
    <FilterBarMarginsStyled>
      <div className="filterContainerRow2">
        <div className="multiSelectOption">
          <label className="label-center">Velg fakturamåned</label>
          <BosDropdown
            className="month multi-select"
            value={get(props, 'selected.selectedMonths')}
            onChange={get(props, 'onChange.onMonthChange')}
            options={get(props, 'options.monthOptions')}
            placeholder={'Month'}
            isSearchable
            isMulti
          />
        </div>
        <div className="multiSelectOption">
          <label className="label-center">Merke</label>
          <BosDropdown
            className="merke multi-select"
            value={get(props, 'selected.selectedMakes')}
            onChange={get(props, 'onChange.onMakeChange')}
            options={get(props, 'options.makeOptions')}
            placeholder={'Merke'}
            isSearchable
            isMulti
          />
        </div>
        <div className="multiSelectOption">
          <label className="label-center">Modell</label>
          <BosDropdown
            className="modell multi-select"
            value={get(props, 'selected.selectedModels')}
            onChange={get(props, 'onChange.onModelChange')}
            options={get(props, 'options.modelOptions')}
            placeholder={'Modell'}
            isSearchable
            isMulti
          />
        </div>
      </div>
      <div className="filterContainerRow2">
        <div className="multiSelectOption">
          <label className="label-center">Serienr</label>
          <BosDropdown
            className="Serienr multi-select"
            value={get(props, 'selected.selectedSerialNumbers')}
            onChange={get(props, 'onChange.onSerialNumberChange')}
            options={get(props, 'options.serialNumberOptions')}
            placeholder={'Serienr'}
            isSearchable
            isMulti
          />
        </div>
        <div className="multiSelectOption">
          <label className="label-center">Avdelingnr</label>
          <BosDropdown
            className="departmentNo multi-select"
            value={get(props, 'selected.selectedDepartmentNumbers')}
            onChange={get(props, 'onChange.onDepartmentNumberChange')}
            options={get(props, 'options.departmentNumberOptions')}
            placeholder={'Avdelingnr'}
            isSearchable
            isMulti
          />
        </div>
        <div className="multiSelectOption">
          <label className="label-center">Selger</label>
          <BosDropdown
            className="selger multi-select"
            value={get(props, 'selected.selectedSalesPersons')}
            onChange={get(props, 'onChange.onSalesPersonChange')}
            options={get(props, 'options.salesPersonOptions')}
            placeholder={'Selger'}
            isSearchable
            isMulti
          />
        </div>
      </div>
      <div className="filter-checkbox checkboxMargins">
        <CheckBox
          label={'Skjul rader uten salg'}
          id={'margins-sales-filter'}
          className={'styled-checkbox'}
          onChange={(e) => get(props, 'onChange.onHideWithoutSalesChange')?.(get(e, 'target.checked'))}
          checked={!!get(props, 'selected.isSelectedHideWithoutSales')}
          disabled={false}
        />
      </div>
    </FilterBarMarginsStyled>
  );
};

export default FilterBarMargins;
