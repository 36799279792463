import { IOrder, IfsAvailabilityCode, ImporterLocationId } from "next-common";
import { IVehicleStock, VehicleStockStatus } from "sales-common";
import { IDynamicListStockTableData } from "../../components/common-components/DynamicListStock";
import { ValidationErrorCode, getErrorMessageForError } from "../../components/notifications";
import { IUnavailableVehicleWarning, checkVehicleSoldOrReserved } from "./vehicleOrder";
import { isEmpty } from "lodash";

export const importerLocationIds = Object.values<string>(ImporterLocationId);

export const isVehicleSold = (vehicle: IVehicleStock): boolean => {
    return vehicle?.status === VehicleStockStatus.SOLD || vehicle?.availabilityCode?.includes(IfsAvailabilityCode.Sold);
}

export const isVehicleReserved = (vehicle: IVehicleStock): boolean => {
    return vehicle?.status === VehicleStockStatus.RESERVED || vehicle?.availabilityCode?.includes(IfsAvailabilityCode.Reserved);
}

export const isDemoVehicle = (vehicle: IVehicleStock | IDynamicListStockTableData): boolean => {
  return vehicle?.status === VehicleStockStatus.DEMO || vehicle?.availabilityCode?.includes(IfsAvailabilityCode.Demo);
}

export const isFreeVehicle = (vehicle: IVehicleStock | IDynamicListStockTableData): boolean => {
  return vehicle?.status === VehicleStockStatus.FREE || vehicle?.availabilityCode?.includes(IfsAvailabilityCode.Free);
}

export const isVehicleSoftLocked = (vehicle: IVehicleStock, orderId: string): boolean => {
    return vehicle?.orderId && vehicle?.orderId !== orderId;
}

export const isVehicleProcessedByOtherDealer = (availabilityCode: string, employeeDealerIds: string[]): boolean => {
    const locationId = availabilityCode?.split("-")?.[0];
    return !employeeDealerIds.includes(locationId) && availabilityCode?.includes(IfsAvailabilityCode.Processed);
}

export const isVehicleProcessedByDealer = (availabilityCode: string, employeeDealerIds: string[]): boolean => {
  const locationId = availabilityCode?.split("-")?.[0];
  return employeeDealerIds.includes(locationId) && availabilityCode?.includes(IfsAvailabilityCode.Processed);
}

export const isVehicleFreeAtDealer = (availabilityCode: string, employeeDealerIds: string[]): boolean => {
  const locationId = availabilityCode?.split("-")?.[0];
  return employeeDealerIds.includes(locationId) && (availabilityCode?.includes(IfsAvailabilityCode.Free) || availabilityCode?.includes(IfsAvailabilityCode.Demo));
}
 
export const isVehicleAvailableFromOtherDealer = (vehicle: IVehicleStock, employeeDealerIds: string[]): boolean => {
  const locationId = vehicle?.availabilityCode?.split("-")?.[0];
  return !employeeDealerIds.includes(locationId) && (isFreeVehicle(vehicle) || isDemoVehicle(vehicle));
}

export const isVehicleOnImporterLocation = (locationId: string): boolean => {
    return importerLocationIds?.length && importerLocationIds?.includes(locationId?.toString());
}

export const isVehicleOnDealerLocation = (validLocationIds: string[] = [], locationId: string = ''): boolean => {
    const employeeDealerIds = getEmpDealerIdFromAllValidLocationIds(validLocationIds);
    return employeeDealerIds?.length && employeeDealerIds?.includes(locationId?.toString());
}

export const isVehicleOnOtherDealerLocation = (validLocationIds: string[] = [], locationId: string = ''): boolean => {
    if(isVehicleOnImporterLocation(locationId)) return false;
    if(isVehicleOnDealerLocation(validLocationIds, locationId)) return false;
    return true;
}

export const isVehicleDisableAtCurrentLocation = (vehicle: IVehicleStock, validLocationIds: string[]): boolean => {
  const employeeDealerIds = getEmpDealerIdFromAllValidLocationIds(validLocationIds);

  if(isVehicleOnOtherDealerLocation(validLocationIds, vehicle?.locationId)) {
    if(isVehicleAvailableFromOtherDealer(vehicle, employeeDealerIds)) return false;
    return true;
  }
  if(isVehicleOnImporterLocation(vehicle?.locationId)) return false;
  if(isVehicleOnDealerLocation(validLocationIds, vehicle?.locationId)) {
    if(isVehicleProcessedByDealer(vehicle?.availabilityCode, employeeDealerIds)) return false;
    if(isVehicleFreeAtDealer(vehicle?.availabilityCode, employeeDealerIds)) return false;
    return true;
  }
  return true;
}

export const isVehicleAvailableAtImporterLocation = (vehicle: IVehicleStock): boolean => {
  const locationId = vehicle?.availabilityCode?.split("-")?.[0];
  return importerLocationIds?.includes(locationId) && (isFreeVehicle(vehicle) || isDemoVehicle(vehicle));
}

//This function is only used for perform validation when vehicle is on other dealer location.
export const isVehicleValidForReserveOrSignin = (vehicle: IVehicleStock, validLocationIds: string[], order: IOrder = null): boolean => {
  //If Vehicle is reserved or Sold from same order then its always valid vehicle
  if (!isEmpty(order?.vehicleOrderStatus?.status) || order?.reserveDetails?.quotationNumber) return true;
  if(isVehicleOnOtherDealerLocation(validLocationIds, vehicle?.locationId)) return false;
  if(isVehicleOnImporterLocation(vehicle?.locationId)) return true;

  if(isVehicleOnDealerLocation(validLocationIds, vehicle?.locationId)) {
    const employeeDealerIds = getEmpDealerIdFromAllValidLocationIds(validLocationIds);
    //Availability code must be LA_AL(FREE) OR TI_AL(Processed)
    if(isVehicleProcessedByDealer(vehicle?.availabilityCode, employeeDealerIds)) return true;
    if(isVehicleFreeAtDealer(vehicle?.availabilityCode, employeeDealerIds)) return true;
    if(isVehicleSold(vehicle) || isVehicleReserved(vehicle)) return true;
    return false;
  }

  return false;
}

export const isSelectedVehileIsUnavailable = (order: IOrder, currentVehicleStatus: IUnavailableVehicleWarning = null, vehicle: IVehicleStock = null) => {
  currentVehicleStatus = currentVehicleStatus || checkVehicleSoldOrReserved(vehicle, order?.id);
  return currentVehicleStatus?.isTaken && !order?.reserveDetails?.quotationNumber;
}

export const getEmpDealerIdFromAllValidLocationIds = (validLocationIds: string[] = []): string[] => {
  const employeeDealerIds = validLocationIds?.filter(locationId => !importerLocationIds?.includes(locationId?.toString()))
  return employeeDealerIds;
}

export const getVehicleAvailabityStatus = (vehicle: IDynamicListStockTableData): string => {
    if (isDemoVehicle(vehicle)) return 'DE';
    if (vehicle?.status === VehicleStockStatus.FREE) {
      if(vehicle?.availabilityCode?.includes(IfsAvailabilityCode.Free))
        return 'LA';
      if(vehicle?.availabilityCode?.includes(IfsAvailabilityCode.Processed))
        return 'TI';
    }
    return ''
}

export const OtherDealerVehicleReserveOrSignValidationMsg = (availabilityCode: string = ''): string => {
  return `${getErrorMessageForError(ValidationErrorCode.OTHER_DEALER_VEHICLE_RESERVE_OR_SIGN_VALIDATION)} ${availabilityCode ? `(${availabilityCode})` : ''}`
}

export const OtherDealerVehicleChoosenValidationMsg = (availabilityCode: string = ''): string => {
  return `${getErrorMessageForError(ValidationErrorCode.OTHER_DEALER_VEHICLE_RESERVE_OR_SIGN_VALIDATION)} Er du sikker på at du vil velge denne bilen?`
}