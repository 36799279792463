import { OrderStatus } from 'next-common';

export enum OrderCategory {
  OFFER = 'OFFER',
  ORDER = 'ORDER',
  DELIVERED = 'DELIVERED',
  STOCK = 'STOCK',
  DEMO = 'DEMO',
  CANCEL_ORDER = 'CANCEL'
}

export const getStati = (category: OrderCategory): OrderStatus[] => {
  switch (category) {
    case OrderCategory.OFFER:
      return [
        OrderStatus.CREATED,
        OrderStatus.DRAFT_CREATED,
        OrderStatus.PAUSED,
        OrderStatus.FAILURE,
        OrderStatus.SIGNED,
        OrderStatus.EXPIRED,
      ];
    case OrderCategory.ORDER:
    case OrderCategory.STOCK:
    case OrderCategory.DEMO:
      return [OrderStatus.CONFIRMED, OrderStatus.CANCEL_ORDER_FAILED];
    case OrderCategory.DELIVERED:
      return [OrderStatus.COMPLETED];
    case OrderCategory.CANCEL_ORDER:
      return [OrderStatus.CANCEL_ORDER_PENDING, OrderStatus.CANCEL_ORDER_SUCCESS];
    default:
      return [OrderStatus.CREATED];
  }
};
