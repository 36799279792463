import React, { useEffect, useState } from 'react';
import { ICartProps } from '@next-components/cart';
import classNames from 'classnames';
import { HashMap, IEquipmentTypes, MAKE, VehicleEquipmentCategoryEnum } from 'next-common';
import { CarConfigureContainerStyled, Results } from './ResultGrid.styled';
import {
  eventEmitter, 
  Events,
  IPartialEnrichedEquipment,
  ISkuVehicleCount,
  MainCategory,
  VehicleConfig,
  getColorChangeInput,
  getDisableEnableSKUList,
  getDisableSkuListOnLoad,
  getInteriorChangeInput,
  getRimsChangeInput,
  getTaklakkChangeInput,
  isDisabledSKU,
  isRulesPayloadCheckFulfilled,
  isValidPriceChange,
  onListverkChangeInput,
  rulesArrPayloadToString,
  rulesPayloadMapper,
  getCurrentModelData, 
  getCurrentVariantData
} from '../../../../utils';
import { IModelPackages, IVehicleModel } from '../../sales-vehicle-picker-page/VehiclePickerPage';
import { SectionExterior, SectionInterior, VehicleModel, VehicleVariant } from './OrderAccordian';
import { usePrevious } from '../../../../common';
import { getUpdatedConjunctionPrices, IEquipmentIdAndSKU, IMappedEquipment } from 'sales-crystallize-common';
import { defaultTo, difference, isEmpty, isNil } from 'lodash';
import { PropertiesTableValue } from 'sales-common';
import { RulesPopup } from '../../../common-components/RulesPopup/RulesPopup';
import {
  ICurrentSelectionDetails,
  IRulesTable,
  useConjunction,
  useDisableSkus,
  useSkusPriceUpdate,
} from '../../../../common';
export interface ICartDataProps {
  id?: string;
  name: string;
  price: number;
  code?: string;
  isRecommended?: boolean;
  sku?: string;
  modelCode?: string;
  vatCode?: string;
  isOptimisticUpdate?: boolean;
  configType?: VehicleConfig;
  dealerPriceExclVat: number;
  path?: string;
}

export interface ICommonRulesProps {
  setCurrentlySelectedSku?: (sku: string) => void;
  setCurrentSelectionDetails?: (data: ICurrentSelectionDetails) => void;
}
export interface IRimChangeProps {
  rim: IEquipmentTypes;
  price: number;
  vatCode: string;
  isOptimisticUpdate?: boolean;
  configType?: VehicleConfig;
  dealerPriceExclVat: number;
  effectedIndicator?: string;
}
export interface IResultGridProps {
  selectedModelId: string;
  selectedVariantId: string;
  selectedColorId: string;
  selectedInteriorId: string;
  selectedModelCode: string;
  selectedModelName: string;
  selectedVariantName: string;
  vehicleData: IVehicleModel[];
  vehicleModelData: IVehicleModel[];
  customerType?: string;
  cartData: ICartProps;
  disabled: boolean;
  selectedEquipmentIds: IEquipmentTypes[];
  selectedRims: IEquipmentTypes;
  allSkuVehicleCount?: ISkuVehicleCount;
  isChange: boolean;
  setCartData?(data: ICartProps): void;
  onModelChange?(data: ICartDataProps): void;
  onVariantChange?(data: ICartDataProps): void;
  onColorChange?(data: ICartDataProps): void;
  onRimChange?(data: IRimChangeProps): void;
  onInteriorChange?(data: ICartDataProps): void;
  onSelectEquipmentChange?(
    data: IEquipmentTypes[],
    removeId?: string,
    isOptimisticUpdate?: boolean,
    configType?: VehicleConfig | VehicleEquipmentCategoryEnum,
    dealerPriceExclVat?: number,
    effectedSku?: string,
  ): void;
  selectablesRulesSkuPayload?: string[];
  selectedListverk?: IEquipmentTypes;
  onListverkChange?(data: IListverkChangeProps): void;
  updatePrices(): void;
  vehicleBrand?: MAKE;
  selectedTaklakk?: IEquipmentTypes;
  onTaklakkChange?(data: IListverkChangeProps): void;
  modelOptions?: HashMap;
  setRulesTableData?(data: IRulesTable): void;
}

export interface IListverkChangeProps {
  equipment: IEquipmentTypes;
  price: number;
  vatCode: string;
  isOptimisticUpdate?: boolean;
  configType?: VehicleConfig | VehicleEquipmentCategoryEnum;
  dealerPriceExclVat: number;
  effectedIndicator?: string;
}

export const ResultGrid = (props: IResultGridProps) => {
  const [selectedModelData, setSelectedModelData] = useState<IVehicleModel[]>(null);
  const [selectedVariantData, setSelectedVariantData] = useState<IModelPackages[]>(null);
  const [mappedEquipment, setMappedEquipment] = useState<IEquipmentIdAndSKU[]>([]);
  const [enrichedEquipment, setEnrichedEquipment] = useState<IPartialEnrichedEquipment[]>([]);
  const [showRulesPopup, setShowRulesPopup] = useState<boolean>(false);
  const prevSelectableRulesSkuPayload = usePrevious(props?.selectablesRulesSkuPayload);
  const { onloadDisableSku } = useDisableSkus();
  const {
    prepareRulesTableOnDeSelection,
    currentSelectionDetails,
    unSelectSkus,
    previousDeSelection,
    prepareRulesTable,
    rulesTableData,
    currentlySelectedSku,
    setCurrentlySelectedSku,
    setCurrentSelectionDetails,
    setPreviousDeSelection,
    setRulesTableData,
    isCurrentlySelectedToBeRemoved,
    setUnSelectSkus,
  } = useConjunction();
  const priceUpdate = useSkusPriceUpdate();

  // Model data selector
  useEffect(() => {
    if (props.selectedModelId && props.vehicleModelData) {
      const currentModelData: IVehicleModel[] = getCurrentModelData(props.selectedModelId, props.vehicleModelData);
      setSelectedModelData(currentModelData);
    }
  }, [props.selectedModelId, props.vehicleModelData]);

  // All the skus processing for rules and passing to different events
  useEffect(() => {
    if (isRulesPayloadCheckFulfilled(selectedVariantData, mappedEquipment, props?.cartData?.make)) {
      let disableSkusList = new Map();
      let enableSkusList = new Map();
      const skuPriceList = new Map();
      if (props?.selectablesRulesSkuPayload?.length > 0) {
        eventEmitter.dispatch(Events.SKU_RULES_PREPARED, false);
        const rulesPayload: string = rulesArrPayloadToString(props?.selectablesRulesSkuPayload);
        let skuPrice: ((PropertiesTableValue & { sku: string }) | any)[] = [];
        const unSelectedSkus = difference(
          defaultTo(prevSelectableRulesSkuPayload, []),
          defaultTo(props?.selectablesRulesSkuPayload, []),
        );
        try {
          const { disableSkusMappedList, enableSkusMappedList, disableSku } = getDisableEnableSKUList(
            rulesPayload,
            enrichedEquipment,
          );
          disableSkusList = disableSkusMappedList;
          enableSkusList = enableSkusMappedList;

          skuPrice = getUpdatedConjunctionPrices(rulesPayload, mappedEquipment);

          if (
            currentlySelectedSku &&
            isDisabledSKU(currentlySelectedSku, disableSkusMappedList, enableSkusMappedList, onloadDisableSku)
          ) {
            setPreviousDeSelection(null);
            const rules = prepareRulesTable(
              currentlySelectedSku,
              props?.selectablesRulesSkuPayload,
              mappedEquipment,
              disableSku,
              unSelectedSkus,
              selectedVariantData,
              {
                selectedColorId: props.selectedColorId,
                selectedInteriorId: props.selectedInteriorId,
                selectedEquipmentIds: props.selectedEquipmentIds,
                selectedRims: props.selectedRims,
                selectedListverk: props.selectedListverk,
                selectedTaklakk: props.selectedTaklakk,
              },
              onloadDisableSku
            );
            if (rules?.ruleAddVaules || rules?.ruleRemoveVaules) {
              setShowRulesPopup(true);
            }
          } else if (currentSelectionDetails?.deSelectedSku) {
            const deSelectedEquipment = mappedEquipment?.find?.((equipment) => {
              return equipment?.sku === currentSelectionDetails?.deSelectedSku;
            });

            const selectedEquipments = mappedEquipment?.filter?.((equipment) => {
              return props?.selectablesRulesSkuPayload?.includes(equipment?.sku);
            });
            selectedEquipments?.push(deSelectedEquipment);
            const rules = prepareRulesTableOnDeSelection(deSelectedEquipment, selectedEquipments, mappedEquipment, selectedVariantData,
              {
                selectedColorId: props.selectedColorId,
                selectedInteriorId: props.selectedInteriorId,
                selectedEquipmentIds: props.selectedEquipmentIds,
                selectedRims: props.selectedRims,
                selectedListverk: props.selectedListverk,
                selectedTaklakk: props.selectedTaklakk,
              },
              onloadDisableSku);
            const confirmedRuleRemoveVaules = rules?.ruleRemoveVaules?.filter((value) => {
              return value?.sku !== currentSelectionDetails?.deSelectedSku;
            });
            if ((rules?.ruleAddVaules || rules?.ruleRemoveVaules) && !isEmpty(confirmedRuleRemoveVaules)) {
              setCurrentSelectionDetails(null);
              setShowRulesPopup(true);
            }
          } else if (currentlySelectedSku && !showRulesPopup) {
            const rulesTable = isCurrentlySelectedToBeRemoved(
              currentlySelectedSku,
              props?.selectablesRulesSkuPayload,
              mappedEquipment,
              unSelectedSkus,
              selectedVariantData, 
              {
                selectedColorId: props.selectedColorId,
                selectedInteriorId: props.selectedInteriorId,
                selectedEquipmentIds: props.selectedEquipmentIds,
                selectedRims: props.selectedRims,
                selectedListverk: props.selectedListverk,
                selectedTaklakk: props.selectedTaklakk,
              },
              onloadDisableSku
            );
            if (rulesTable?.ruleRemoveVaules?.length > 0) {
              setUnSelectSkus(unSelectedSkus);
              setShowRulesPopup(true);
            }
          }
        } catch (error) {
          console.warn(`Fetch selectable rule error - ${error}`);
        }

        skuPrice?.forEach((priceUpdate) => {
          if (isValidPriceChange(priceUpdate)) {
            skuPriceList.set(priceUpdate?.sku, priceUpdate?.value);
          }
        });
        eventEmitter.dispatch(Events.SKU_RULES_PREPARED, true);
      }

      eventEmitter.dispatch(Events.DISABLE_SKU, disableSkusList);
      eventEmitter.dispatch(Events.ENABLE_SKU, enableSkusList);
      // eventEmitter.dispatch(Events.UNSELECT_SKU, unselectSkusList);
      eventEmitter.dispatch(Events.PRICE_UPDATE, skuPriceList);
    }
  }, [selectedVariantData, props?.selectablesRulesSkuPayload, currentlySelectedSku]);

  // Variant data selector along with on-load disabled rules
  useEffect(() => {
    if (props.selectedVariantId && props.vehicleData) {
      const currentVariantData: IModelPackages[] = getCurrentVariantData(
        props.selectedVariantId,
        props.vehicleData?.[0],
      );
      const equipmentsData = currentVariantData?.[0]?.mappedEquipment;
      const conjunctionEquipmentData = rulesPayloadMapper(equipmentsData);
      loadDisableSkus(currentVariantData, conjunctionEquipmentData);
      setEnrichedEquipment(conjunctionEquipmentData);
      setMappedEquipment(equipmentsData);
      setSelectedVariantData(currentVariantData);
    }
  }, [props.selectedVariantId, props.vehicleData]);

  const loadDisableSkus = (data: IModelPackages[], conjunctionEquipmentData: IPartialEnrichedEquipment[]) => {
    let disableSkusList = new Map();
    disableSkusList = getDisableSkuListOnLoad(data, props?.cartData?.make, conjunctionEquipmentData);
    eventEmitter.dispatch(Events.ONLOAD_DISABLE_SKU, disableSkusList);
  };

  const onVariantChange = (data: ICartDataProps) => {
    props.onVariantChange(data);
  };

  const handleRulesPopupConfirm = () => {
    props.setRulesTableData(rulesTableData);
    setPreviousDeSelection(null);
    setCurrentSelectionDetails(null);
    setCurrentlySelectedSku(null);
    setShowRulesPopup(!showRulesPopup);
  };

  const getPreviouslySelectedSkus = (): string[] => {
    let previouslySelectedSkus: string[] = unSelectSkus || [];

    return previouslySelectedSkus;
  };

  const handleRulesPopupclose = () => {
    let selectedEquipment: IMappedEquipment = mappedEquipment?.find((equipment) => {
      return equipment?.sku === currentlySelectedSku;
    });
    if (isNil(selectedEquipment) && !isEmpty(previousDeSelection)) {
      selectedEquipment = previousDeSelection;
    }
    const previouslySelectedSkus = getPreviouslySelectedSkus();
    switch (selectedEquipment?.mainCategory) {
      case MainCategory.Utstyr: {
        if (selectedEquipment?.category === VehicleEquipmentCategoryEnum.Listverk) {
          const previouslySelected = selectedVariantData?.[0]?.selectableEquipment?.find((equipment) => {
            return (
              equipment?.category === VehicleEquipmentCategoryEnum.Listverk &&
              previouslySelectedSkus?.includes(equipment?.sku)
            );
          });
          if (previouslySelected) {
            props.onListverkChange(onListverkChangeInput(previouslySelected, priceUpdate));
          } else {
            const selectedListverk = selectedVariantData?.[0]?.selectableEquipment?.find((equipment) => {
              return equipment?.sku === currentlySelectedSku;
            });
            props.onListverkChange(onListverkChangeInput(selectedListverk, priceUpdate, selectedEquipment?.id));
          }
        } else if (selectedEquipment?.category === VehicleEquipmentCategoryEnum.Taklakk) {
          const previouslySelected = selectedVariantData?.[0]?.selectableEquipment?.find((equipment) => {
            return (
              equipment?.category === VehicleEquipmentCategoryEnum.Taklakk &&
              previouslySelectedSkus?.includes(equipment?.sku)
            );
          });
          if (previouslySelected) {
            props.onTaklakkChange(getTaklakkChangeInput(previouslySelected, priceUpdate));
          } else {
            const selectedTaklakk = selectedVariantData?.[0]?.selectableEquipment?.find((equipment) => {
              return equipment?.sku === currentlySelectedSku;
            });
            props.onTaklakkChange(getTaklakkChangeInput(selectedTaklakk, priceUpdate, selectedEquipment?.id));
          }
        } else {
          if (previousDeSelection) {
            const selectedEquipment = selectedVariantData?.[0]?.selectableEquipment?.find((equipment) => {
              return equipment?.sku === previousDeSelection?.sku;
            });
            let codes = [...props.selectedEquipmentIds, selectedEquipment];
            props.onSelectEquipmentChange(codes);
          } else {
            let codes = [...props.selectedEquipmentIds];
            codes = codes?.filter((selected) => selected?.id !== selectedEquipment?.id);
            props.onSelectEquipmentChange(codes, selectedEquipment?.id);
          }
        }
        break;
      }
      case MainCategory.Lakk: {
        const previouslySelected = selectedVariantData?.[0]?.colors?.find((color) => {
          return previouslySelectedSkus?.includes(color?.varnishCode);
        });
        if (previouslySelected) {
          props.onColorChange(getColorChangeInput(previouslySelected, priceUpdate, null, true));
        } else {
          const selectedColor = selectedVariantData?.[0]?.colors?.find((color) => {
            return color?.pimSku === currentlySelectedSku;
          });
          props.onColorChange(getColorChangeInput(selectedColor, priceUpdate, null, true));
        }
        break;
      }
      case MainCategory.Felger: {
        const previouslySelected = selectedVariantData?.[0]?.rims?.find((equipment) => {
          return previouslySelectedSkus?.includes(equipment?.sku);
        });
        if (previouslySelected) {
          props.onRimChange(getRimsChangeInput(previouslySelected, priceUpdate));
        } else {
          const selectedRim = selectedVariantData?.[0]?.rims?.find((rim) => {
            return rim?.sku === currentlySelectedSku;
          });
          props.onRimChange(getRimsChangeInput(selectedRim, priceUpdate, selectedRim?.id, false));
        }
        break;
      }
      case MainCategory.Interiør: {
        const previouslySelected = selectedVariantData?.[0]?.interiors?.find((equipment) => {
          return previouslySelectedSkus?.includes(equipment?.interiorCode);
        });
        if (previouslySelected) {
          props.onInteriorChange(getInteriorChangeInput(previouslySelected, priceUpdate));
        } else {
          const selectedInterior = selectedVariantData?.[0]?.interiors?.find((interior) => {
            return interior?.interiorCode === currentlySelectedSku;
          });
          props.onInteriorChange(getInteriorChangeInput(selectedInterior, priceUpdate, selectedInterior?.interiorCode));
        }
        break;
      }
    }
    setPreviousDeSelection(null);
    setCurrentSelectionDetails(null);
    setCurrentlySelectedSku(null);
    setRulesTableData(null);
    setShowRulesPopup(!showRulesPopup);
  };

  return (
    <Results className={classNames({ results: true, leftContainer: true })}>
      <CarConfigureContainerStyled>
        <VehicleModel
          data={props.vehicleModelData}
          selectedModelId={props.selectedModelId}
          selectedModelCode={props.selectedModelCode}
          selectedModelName={props.selectedModelName}
          disabled={props.disabled}
          onModelChange={props.onModelChange}
          vehicleBrand={props.vehicleBrand}
        />
        <VehicleVariant
          data={selectedModelData?.[0]}
          selectedModelId={props.selectedModelId}
          selectedVariantId={props.selectedVariantId}
          disabled={props.disabled}
          allSkuVehicleCount={props.allSkuVehicleCount}
          onVariantChange={onVariantChange}
          vehicleBrand={props.vehicleBrand}
          modelOptions={props.modelOptions}
          selectedVariantName={props.selectedVariantName}
          selectedVariantData={selectedVariantData?.[0]}
        />
        <SectionExterior
          data={selectedVariantData?.[0]}
          isOpen={!!props.selectedModelId && !!props.selectedVariantId}
          isDisabled={!props.selectedModelId || !props.selectedVariantId}
          selectedColorId={props.selectedColorId}
          disabled={props.disabled}
          selectedRims={props.selectedRims}
          customerType={props.customerType}
          onRimChange={props.onRimChange}
          onColorChange={props.onColorChange}
          selectedEquipmentIds={props.selectedEquipmentIds}
          onSelectEquipmentChange={props.onSelectEquipmentChange}
          updatePrices={props.updatePrices}
          vehicleBrand={props.vehicleBrand}
          onTaklakkChange={props.onTaklakkChange}
          selectedTaklakk={props.selectedTaklakk}
          setShowRulesPopup={setShowRulesPopup}
          setCurrentlySelectedSku={setCurrentlySelectedSku}
          setCurrentSelectionDetails={setCurrentSelectionDetails}
          rulesPayload={rulesArrPayloadToString(props?.selectablesRulesSkuPayload || [])}
        />

        <SectionInterior
          data={selectedVariantData?.[0]}
          isOpen={!!props.selectedModelId && !!props.selectedVariantId}
          isDisabled={!props.selectedModelId || !props.selectedVariantId}
          selectedInteriorId={props.selectedInteriorId}
          disabled={props.disabled}
          onInteriorChange={props.onInteriorChange}
          selectedEquipmentIds={props.selectedEquipmentIds}
          onSelectEquipmentChange={props.onSelectEquipmentChange}
          customerType={props.customerType}
          onListverkChange={props.onListverkChange}
          selectedListverk={props.selectedListverk}
          updatePrices={props.updatePrices}
          vehicleBrand={props.vehicleBrand}
          setCurrentlySelectedSku={setCurrentlySelectedSku}
          setCurrentSelectionDetails={setCurrentSelectionDetails}
          rulesPayload={rulesArrPayloadToString(props?.selectablesRulesSkuPayload || [])}
        />
        <RulesPopup
          isOpen={showRulesPopup}
          selectableName={rulesTableData?.selectableName}
          ruleAddVaules={rulesTableData?.ruleAddVaules}
          ruleRemoveVaules={rulesTableData?.ruleRemoveVaules}
          ruleDefaultValues={rulesTableData?.ruleDefaultValues || []}
          popupPrice={rulesTableData?.popupPrice}
          handleClose={handleRulesPopupclose}
          handleConfirm={handleRulesPopupConfirm}
        />
      </CarConfigureContainerStyled>
    </Results>
  );
};
