import React from 'react';
import { FinancingType, getFinancingTypeTranslatedValue } from 'next-common';
import { StyledState } from './FinancingState.styled';
import { Icon, IconType } from '@next-components/bos-icon';

export const SuccessState = ({ financingType }: { financingType: FinancingType }) => {
  const financingTypeDescription = getFinancingTypeTranslatedValue(financingType);

  return (
    <div className="success-state">
      <StyledState success>
        <div className="icon-container">
          <span className="circle-checkbox">
            <Icon icon={IconType.NewNextCheck} viewBox="0 0 32 32" />
          </span>
        </div>
        <h3>{financingTypeDescription}forslag lagret</h3>
      </StyledState>
    </div>
  );
};

export const EmptyState = () => (
  <div className="empty-state">
    <StyledState empty>
      <div className="icon-container">
        <Icon icon={IconType.NewNextPen} />
      </div>
      <h3>Marker en rad fra tabellen til venstre for å redigere</h3>
    </StyledState>
  </div>
);
