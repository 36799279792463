import { getCachedToken } from '../config/authentication';
import { AxiosRequestConfig } from 'axios';
import { AxiosHeaderEnum } from '../common';
import { getAppendingNumericIdFromUrl } from "./sales";
/**
 * @param headerFormat [headerFormat=AxiosHeaderEnum.HEADER] - AxiosHeaderEnum
 * @returns AxiosRequestConfig
 */
export const getHeader = async (headerFormat: AxiosHeaderEnum = AxiosHeaderEnum.HEADER) => {
  const token = await getCachedToken();
  const HEADER: AxiosRequestConfig = fetchHeaderWithCorrectConfig(token, headerFormat);
  return HEADER;
};

// Centeral logic for fetching axios header config
const fetchHeaderWithCorrectConfig = (token, headerFormat: AxiosHeaderEnum) => {
  let axiosHeader: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
      "X-Order-Id": `${getAppendingNumericIdFromUrl()}`
    },
  };
  switch (headerFormat) {
    case AxiosHeaderEnum.HEADER_BLOB:
      axiosHeader = {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Order-Id": `${getAppendingNumericIdFromUrl()}`
        },
      };
      break;
    case AxiosHeaderEnum.HEADER_FORMDATA:
      axiosHeader = {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          "X-Order-Id": `${getAppendingNumericIdFromUrl()}`
        },
      };
      break;
    default:
      break;
  }
  return axiosHeader;
};
