import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { formatNumber } from '@next-components/common-types';

export const PriceOrIncluded = (props) => {
  const hasPrice = !isNaN(props.value) && props.value > 0;
  return (
    <View style={{ height: '30', paddingLeft: 5 }}>
      <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
        {isNaN(props.value) && 'Pris mangler'}
        {hasPrice ? `kr ${formatNumber(props.value)}` : 'Inkludert'}
      </Text>
    </View>
  );
};
