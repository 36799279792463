import React, { useEffect, useState } from 'react';
import { IApiEmployee, IUser } from 'next-common';
import { IDropdownOption } from '@next-components/common-types';
import { BosDropdown } from '@next-components/bos-dropdown';
import { useDealerUsers } from '../../../../common';
import { orderBy } from 'lodash';
import { IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';

export interface ISellerFilterProps {
  dealerIds: string[];
  selected: string;
  employee: IApiEmployee;

  onChange(e): void;
}

const allSellers = { value: null, label: 'Alle' };

const parseEmailAsName = (email: string) => {
  if (!email) return null;
  const namePart = email.split('@')[0];
  const nameParts = namePart.split('.');
  return nameParts.map((n) => `${n[0].toUpperCase()}${n.substring(1)}`).join(' ');
};

const parseUser = (user: IUser) => {
  const label = user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : parseEmailAsName(user.email);
  const value = user.ident ? user.ident.toLowerCase() : user.email;
  return { value, label: label || value };
};

const parseSellerOptions = (users: IUser[], employee: IApiEmployee): IDropdownOption<any>[] => {
  const employeeIndex = users.findIndex(({ email }) => email?.toLowerCase() === employee?.email?.toLowerCase());
  let employeeUser;
  if (employeeIndex > -1) {
    // Pull current user out of array if present
    employeeUser = parseUser(users.splice(employeeIndex, 1)[0]);
  } else if (employee?.employeeId && employee?.email) {
    // Make entry for current user if not present i array
    employeeUser = {
      label: parseEmailAsName(employee.email),
      value: employee.employeeId.toLowerCase(),
    };
  }
  const parsedUsers = orderBy(users.map(parseUser), ['label']);
  if (employeeUser) {
    // Add current user to the top of array
    parsedUsers.unshift(employeeUser);
  }
  return [allSellers, ...parsedUsers];
};

export const SellerFilter = (props: ISellerFilterProps) => {
  const [sellerOptions, setSellerOptions] = useState([]);
  const sellers = useDealerUsers(props.dealerIds ?? []);

  useEffect(() => {
    if (!sellers) return;
    const options = parseSellerOptions(sellers, props.employee);
    setSellerOptions(options);
  }, [sellers]);

  return (
    <BosDropdown
      onChange={props.onChange}
      value={sellerOptions.find((x) => x.value === props.selected?.toLowerCase())}
      options={sellerOptions}
      placeholder="Velg selger"
      iconProps={{
        iconDropdownDown: IconType.NewNextChevronDown,
        iconDropdownUp: IconType.NewNextChevronUp,
        iconColor: bosColor.black,
      }}
      label={'Selger'}
      nextDropdown
    />
  );
};
