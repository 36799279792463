import React, { useState } from 'react';
import { IBosApiAttachmentData, IOrder } from 'next-common';
import { getSignedContract, postToGenerator } from './pdfgenerator.utils';
import { Button } from '@next-components/cta';
import { Icon, IconType } from '@next-components/bos-icon';
import { SmallLink } from '@next-components/link';
import { bosColor } from '@next-components/common-styles';
import { previewServiceContractClick } from '../ServiceContractPDF';

export interface IContractPdfProps {
  order: IOrder;
  generate?: boolean;
  contract?: IBosApiAttachmentData;
  isDisabled?: boolean;
  isServiceContract?: boolean;
  additionalText?: string;
}

export const ContractPDF = (props: IContractPdfProps) => {
  const [processing, setProcessing] = useState(false);
  const [serviceContractPdfProcessing, setServiceContractPdfProcessing] = useState(false);
  const [isContractDownloaded, setIsContractDownloaded] = useState(false);

  const onGenerateContract = () => {
    postToGenerator(props.order)?.finally(() => {
      setProcessing(false);
      setIsContractDownloaded(true);
      setTimeout(() => {
        setIsContractDownloaded(false);
      }, 5000);
    });
  };

  const onDownloadContract = () => {
    getSignedContract(props.order, props.contract)?.finally(() => {
      setProcessing(false);
    });
  };

  const onClick = () => {
    setProcessing(true);
    if (props.generate) {
      onGenerateContract();
    } else {
      onDownloadContract();
    }
  };

  const text = `Last ned${props.generate ? '' : ' signert'} ${props.isServiceContract ? 'NBF-' : ''} kontrakt ${
    props.generate ? 'for signering' : ''
  }${!props?.isServiceContract && props?.additionalText ? props?.additionalText : ''}`;
  const setProcessingText = () => {
    if (processing) {
      return '';
    } else {
      return text;
    }
  };

  if (props.generate !== true) {
    return (
      <div className="icon-container">
        <SmallLink
          isLoading={processing}
          iconLeft={IconType.NewNextDocument}
          onLinkClick={onClick}
          text={setProcessingText()}
        />
      </div>
    );
  } else {
    return (
      <div className="text-style">
        <Button
          isLoading={processing}
          disabled={processing || props.isDisabled}
          onClick={onClick}
          className="contractPdfBtn">
          <span>{setProcessingText()}</span>
        </Button>
        {props?.order?.chosenOffer?.serviceContract && (
          <Button
            className="contractPdfBtn"
            isLoading={serviceContractPdfProcessing}
            disabled={serviceContractPdfProcessing || props.isDisabled}
            onClick={() =>
              previewServiceContractClick(
                props.order?.id,
                props.order?.serviceContractBosId || props.order?.salesContractPdfId,
                setServiceContractPdfProcessing,
              )
            }>
            <span>{serviceContractPdfProcessing ? '' : 'Last ned Serviceavtale-kontrakt for signering'}</span>
          </Button>
        )}
        {isContractDownloaded && (
          <div className="successMessageWrapper">
            <Icon icon={IconType.NewNextCheckFilled} color={bosColor.komorebi} />
            <h3 className="display-xs">Kontrakt lastet ned</h3>
          </div>
        )}
      </div>
    );
  }
};
