import { BosDropdown } from '@next-components/bos-dropdown';
import { useLocation } from 'react-router-dom';
import {
  CustomerType,
  defaultLeasingInput,
  defaultLoanInput,
  FinancingType,
  getConditionalValue,
  ICalculation,
  IFinancing,
  IFinancingRequest,
  IOrder,
  IVehicleConfiguration,
} from 'next-common';
import { formatNumber, IDropdownOption, InputType } from '@next-components/common-types';
import React, { useEffect, useState } from 'react';
import { ICentralisedProperties, priceOverrunLeasing } from '../../../../../common';
import { FormRow } from '../../FormRow';
import { StyledForm } from './FinancingForm.styled';
import { getAdministrationFee, getCategoryCodePIMFromConfig, getDurationOptions, getEstablishmentFee, getIncVatDownPayment, getMonthlyPrice, getNominalInterest, getOriginalDownPayment, getRangeOptions, getResidualValue, getResidualValuePer } from './FinancingForm.utils';
import { InterestFormRow } from './InterestFromRow';
import { Button } from '@next-components/button';
import { Button as AddCalculationButton } from '@next-components/cta';
import { ButtonStyled } from '../../../sales-wizard/sales-header/SalesHeader.styled';
import { PivotField } from '@next-components/pivot-field';
import { DropDownValues } from '../../../../common-components/MarginCart';
import { OnChangeValue } from 'react-select';
import { defaultTo, get } from 'lodash';
import { Icon, IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';

const getChangeIndex = (formChanges: IFormChange[], fieldName) => {
  return formChanges.findIndex((change) => change.fieldName === fieldName);
};

export const fieldIds = {
  downPayment: 'downPayment',
  durationMonths: 'durationMonths',
  mileage: 'mileage',
  manualNominalInterest: 'manualNominalInterest',
};

export interface IFormChange {
  fieldName: string;
  originalValue: number;
  value: number;
}

interface IFinancingFormProps extends ICentralisedProperties {
  loading?: boolean;
  offerId?: string;
  calculation: ICalculation;
  dialogTitle?: string;
  order?: IOrder;
  vehicleConfig: IVehicleConfiguration;
  customerType: CustomerType;
  unknownText?: string;
  type?: FinancingType;
  isSaving?: boolean;
  isEditable?: boolean;
  isModal: boolean;
  hideComments?: boolean;
  addNew?: boolean;
  isDeleted?: boolean;
  isStockSaleOrder?: boolean;
  setAddNew?(addNew: boolean): void;
  onUpdateCalculation(changes): void;
  onAddComment(calculation: ICalculation): void;
  onAddCalculation?(financingType: FinancingType, changes?): void;
  onDeleteCalculation?(calculation: ICalculation): void;
  onAlertModalToggle?(): void;
  setLeasingInput?(value): void;
}

export const FinancingForm = (props: IFinancingFormProps) => {
  const [inputType, setInputType] = useState<InputType>(InputType.INTEGER);
  const dropDownOptions: IDropdownOption<any>[] = Object.entries(DropDownValues).map(([key, value], index) => {
    return { label: value, value: key, isSelected: index === 0 };
  });
  const [downPayment, setDownPayment] = useState<number>();
  const [isSaving, setIsSaving] = useState<boolean>(props.isSaving);
  const [changes, setChanges] = useState<IFormChange[]>([]);
  const [financing, setFinancing] = useState<IFinancing>(props.calculation?.financing);
  const [request, setRequest] = useState<IFinancingRequest>(props.calculation?.financing?.request);
  const [financingType, setFinancingType] = useState<FinancingType>(props.type || props.calculation?.financingType);
  const isLoan = (financingType ?? props?.type) === FinancingType.LOAN;
  const [currentDropdownSelection, setCurrentDropdownSelection] = useState<any>(DropDownValues.KR);

  const defaultDuration = isLoan ? defaultLoanInput.durationMonths : defaultLeasingInput.durationMonths;
  const durationSelectedValue = request?.durationMonths ?? defaultDuration;
  const durationText = isLoan ? 'Nedbetalingstid' : 'Varighet';

  const rangeSelectedValue = request?.mileage ?? defaultLeasingInput.mileage;
  const rangeText = 'Årlig kjørelengde';

  const [residualValue, setResidualValue] = useState<number>(getResidualValue(financing) ?? 0);
  const [maximumResidualValue, setMaximumResidualValue] = useState<number>(financing?.maximumResidualValue);
  const [residualValuePercentage, setResidualValuePercentage] = useState<number>(getResidualValuePer(financing));
  const [milage, setMilage] = useState<number>(
    props.calculation?.financing?.request?.mileage ?? defaultLeasingInput.mileage,
  );

  const price = get(props, 'order.vatCalculation.salesTotalIncludingVatAndTax', 0);
  const [currency, setCurrency] = useState<number>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [downPaymentFormatted, setDownPaymentFormatted] = useState<string>(`${downPayment}`);
  const [shouldUpdateAddNew, setShouldUpdateAddNew] = useState<boolean>(false);
  const [isRenteVisible, setIsRenteVisible] = useState<boolean>(false);
  const isAddingNew = props?.calculation ? false : props?.addNew;
  const actionText = isAddingNew ? 'Kalkulér' : 'Oppdater';
  const dialogTitle = props?.dialogTitle?.charAt(0)?.toLowerCase() + props?.dialogTitle?.slice(1) || '';
  const displayText = `${actionText} ${dialogTitle}`;
  const isFieldVisible = props?.calculation ? true : !props?.addNew;
  const location = useLocation();
  const categoryCodePIM = props.isStockSaleOrder ? props.order?.pimSnapshot?.salesArticle : getCategoryCodePIMFromConfig(props.vehicleConfig, props.order);

  useEffect(() => {
    setIsSaving(props.isSaving);
    if (props.isSaving) {
      setChanges([]);
    }
  }, [props.isSaving]);

  useEffect(() => {
    if (shouldUpdateAddNew && props?.addNew) {
      props?.setAddNew(false);
    }
    setShouldUpdateAddNew(props?.loading);
  }, [props?.loading]);

  useEffect(() => {
    setChanges([]);
    if (props?.addNew) {
      setMilage(defaultLeasingInput.mileage);
    }
  }, [props?.addNew]);

  useEffect(() => {
    setDownPayment(getUpdatedDownPayment());
    setDownPaymentFormatted(`${getDownPaymentValue()}`);
    if (currentDropdownSelection === DropDownValues.PERCENTAGE) {
      setDownPaymentFormatted(`${convertToPercentage(getDownPaymentValue(), price)}`);
      setDownPayment(convertToPercentage(getDownPaymentValue(), price));
    }
    setResidualValue(getResidualValue(props?.calculation?.financing) ?? 0);
    setResidualValuePercentage(getResidualValuePer(props.calculation?.financing) ?? 0);
    setMaximumResidualValue(props?.calculation?.financing?.maximumResidualValue ?? 0);
  }, [props?.offerId]);

  useEffect(() => {
    setDownPayment(getUpdatedDownPayment());
    setDownPaymentFormatted(`${getUpdatedDownPayment()}`);
  }, [props.type]);

  useEffect(() => {
    if (!request && props.addNew) {
      setDownPayment(getUpdatedDownPayment());
      setDownPaymentFormatted(`${getUpdatedDownPayment()}`);
    } 
    if(!props.addNew) {
      setMilage(request?.mileage)
    }
  }, [request, props.addNew]);

  useEffect(() => {
    if (currentDropdownSelection === DropDownValues.PERCENTAGE) {
      setCurrency(parseFloat(convertToCurrency(downPayment, price).toFixed()));
    }
  }, [downPayment, financing]);

  useEffect(() => {
    setIsSaving(false);
    setFinancing(props?.calculation?.financing);
    setRequest(props?.calculation?.financing?.request);
    setFinancingType(props?.calculation?.financingType);
    setResidualValue(getResidualValue(props?.calculation?.financing) ?? 0);
    setResidualValuePercentage(getResidualValuePer(props.calculation?.financing) ?? 0);
    setMaximumResidualValue(props?.calculation?.financing?.maximumResidualValue ?? 0);
  }, [props.calculation]);
  const onDurationChange = (option: any, originalValue) => {
    onValueChange(option?.value, fieldIds.durationMonths, originalValue);
  };

  const getDownPaymentValue = () => {
    const selectedOffer = props?.order?.offers?.find((offer) => offer?.id === props?.offerId);
    return selectedOffer?.financing?.request?.downPayment;
  };

  const onRangeChange = (option: any, originalValue) => {
    setMilage(option?.value);
    onValueChange(option?.value, fieldIds.mileage, originalValue);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onValueChange = (value, fieldName, originalValue, formatted = '') => {
    if (!value && typeof value !== 'number') return null;
    const formChanges = [...changes];
    let newChange;
    const previousChangeIndex = getChangeIndex(formChanges, fieldName);
    if (value === originalValue) {
      // 1. if value is the same than original value, remove change
      if (previousChangeIndex > -1) {
        formChanges.splice(previousChangeIndex, 1);
        setChanges(formChanges);
      }
    } else {
      // 2. if value is different than original value, add change
      if (fieldName === fieldIds.downPayment) {
        setDownPayment(value);
        setDownPaymentFormatted(`${formatted}`);
        if (currentDropdownSelection === DropDownValues.PERCENTAGE) {
          value = parseFloat(convertToCurrency(value, price).toFixed());
        }
        value = getConditionalValue(isNaN(value), 0, value);
      }
      if (previousChangeIndex > -1) {
        formChanges.splice(previousChangeIndex, 1);
      }
      newChange = { fieldName, originalValue, value };
      setChanges([...formChanges, newChange]);
    }
  };

  const onInputChange = (event, fieldName, originalValue) => {
    let valueString: string = event?.currentTarget?.value ?? event.target.value;
    valueString = valueString?.replace(/\s/g, '')?.replace(',', '.');
    const value: number = parseFloat(valueString);
    onValueChange(value, fieldName, originalValue);
  };

  const getChangedFieldValue = (fieldName: keyof typeof fieldIds) => {
    const field = fieldIds.hasOwnProperty(fieldName) ? fieldIds[fieldName] : null;
    if (!field) return null;
    const change = changes.find((changeItem) => changeItem.fieldName === field);
    return change ? change?.value : null;
  };

  const getUpdatedDuration = () => {
    const durationChange = getChangedFieldValue('durationMonths');
    return durationChange ?? durationSelectedValue;
  };

  const getUpdatedRange = () => {
    const rangeChange = getChangedFieldValue('mileage');
    return rangeChange ?? rangeSelectedValue;
  };

  const getUpdatedDownPayment = () => {
    const change = getChangedFieldValue('downPayment');
    return typeof change === 'number' ? change : getOriginalDownPayment(isLoan, request);
  };

  const getLeasingChangesInput = () => {
    const leasingPayload =  [
      {
        fieldName: 'mileage',
        originalValue: milage,
        value: milage,
      },
      ...changes,
      {
        fieldName: 'residualValue',
        originalValue: residualValue,
        value: residualValue,
      },
      {
        fieldName: 'maximumResidualValue',
        originalValue: maximumResidualValue,
        value: maximumResidualValue,
      },
      {
        fieldName: 'residualValuePercentage',
        originalValue: residualValuePercentage,
        value: residualValuePercentage,
      },
    ];
    if(!isInterestChanged() && !financing?.request?.manualNominalInterest && isFieldVisible) {
      return [
        ...leasingPayload,
        {
          fieldName: "manualNominalInterest",
          originalValue: getNominalInterest(financing),
          value: getNominalInterest(financing),
        },
      ]
    }
    return leasingPayload;
  };

  const isInterestChanged = () => {
    return changes?.find(change => change?.fieldName === "manualNominalInterest");
  } 

  const getLoanChangesInput = () => {
    const downPaymentChange = {
      fieldName: 'downPayment',
      originalValue: downPayment,
      value: downPayment,
    }
    if(!isInterestChanged() && !financing?.request?.manualNominalInterest && isFieldVisible) {
      return [
        downPaymentChange,
        ...changes,
        {
          fieldName: "manualNominalInterest",
          originalValue: getNominalInterest(financing),
          value: getNominalInterest(financing),
        },
      ]
    }
    return [
     downPaymentChange,
      ...changes,
    ];
  };

  const checkForNullValueForResidual = () => {
    return financingType === FinancingType.LEASING && residualValue === 0;
  };

  if (financingType === FinancingType.CASH) {
    return (
      // tslint:disable-next-line:no-empty
      <StyledForm name="Egenfinansiering" onSubmit={() => {}}>
        <div className={'form-row'}>
          {location.pathname.indexOf('view') < 0 && (
            <h1>
              {props.calculation?.serviceContract?.isLoanPaymentIntervalSelected === true
                ? typeof props.calculation?.financing?.totalCost === 'number'
                  ? `${formatNumber(
                      props.calculation?.financing.totalCost + props.calculation?.serviceContract.totalPrice,
                    )} kr`
                  : props.unknownText
                : typeof props.calculation?.financing?.totalCost === 'number'
                ? `${formatNumber(props.calculation?.financing.totalCost)} kr`
                : props.unknownText}
            </h1>
          )}
        </div>
      </StyledForm>
    );
  }

  const handleOnChangeDropDown = (dropDownValue: OnChangeValue<IDropdownOption<any>, false>, inputValue: number) => {
    let valueString: string = inputValue ? inputValue.toString() : '';
    valueString = valueString?.replace(/\s/g, '')?.replace(',', '.');
    const input: number = parseFloat(valueString);
    if (currentDropdownSelection !== dropDownValue.label) {
      updateInputFormatting(dropDownValue);
      if (dropDownValue.label === DropDownValues.PERCENTAGE) {
        setDownPayment(convertToPercentage(input, price));
        setDownPaymentFormatted(`${convertToPercentage(input, price)}`);
      } else if (dropDownValue.label === DropDownValues.KR) {
        setDownPayment(convertToCurrency(input, price));
        setDownPaymentFormatted(`${convertToCurrency(input, price)}`);
      }
      setCurrentDropdownSelection(dropDownValue.label);
    }
  };

  const updateInputFormatting = (dropDownValue?: OnChangeValue<IDropdownOption<any>, false>) => {
    if (!dropDownValue) {
      dropDownValue = dropDownOptions.find((value) => value.isSelected);
    }

    switch (dropDownValue.label) {
      case DropDownValues.KR:
        setInputType(InputType.INTEGER);
        break;
      case DropDownValues.PERCENTAGE:
        setInputType(InputType.FLOAT);
        break;
    }
  };

  const validateValue = (dropDownValue: OnChangeValue<IDropdownOption<any>, false>, inputValue: number): boolean => {
    if (!dropDownValue) {
      dropDownValue = dropDownOptions.find((value) => value.isSelected);
    }
    // Disallow negative values
    switch (dropDownValue.label) {
      case DropDownValues.PERCENTAGE:
        return inputValue >= 0 && inputValue <= 100;
      case DropDownValues.KR: {
        const percentage = convertToPercentage(inputValue, price);
        return percentage >= 0 && percentage <= 100;
      }
    }
    return true;
  };

  const convertToCurrency = (downPayment: number, price: number): number => {
    return (downPayment * price) / 100;
  };

  const convertToPercentage = (downPayment: number, price: number): number => {
    return (downPayment / price) * 100;
  };

  const pivotFieldChange = (
    input: number | string,
    dropDownValue?: OnChangeValue<IDropdownOption<any>, false>,
    formatted?: string,
  ) => {
    setIsValid(validateValue(dropDownValue, Number(input)));
    let valueString: string = input ? input.toString() : '';
    valueString = valueString?.replace(/\s/g, '')?.replace(',', '.');
    const value: number = parseFloat(valueString);
    if (parseFloat(valueString) !== parseFloat(downPayment.toFixed(2))) {
      updateInputFormatting(dropDownValue);
      onValueChange(value, fieldIds.downPayment, getOriginalDownPayment(isLoan, request), formatted);
    }
  };

  const getConditionalInput = () => {
    return isLoan ? getLoanChangesInput() : getLeasingChangesInput();
  };

  const handleSave = () => {
    if (props.type === FinancingType.LEASING) {
      props?.setLeasingInput({ financingType: FinancingType.LEASING, input: getConditionalInput() });
      const alternativeLeasing = defaultTo(props?.order?.alternativeLeasing?.organizationNo, null);
      if (alternativeLeasing) {
        props?.onAlertModalToggle();
      } else {
        props.onAddCalculation(financingType || props.type, getConditionalInput());
      }
    } else {
      props.onAddCalculation(financingType || props.type, getConditionalInput());
    }
  }

  const validity =
    !props.isSaving && downPayment > 0
      ? {
          isValid,
          withErrorMessage: !isValid,
          errorMessage: 'Gyldig intervall 0 til 100 %',
        }
      : {};

  return (
    <StyledForm name={`${isLoan ? 'loan' : 'leasing'}-update`} onSubmit={onSubmit} isEditable={props.isEditable}>
      <label className="loanleasingtitle">{props?.dialogTitle}</label>
      <div className="formRowContainer">
        <div className={`form-row base-position ${props.type === FinancingType.LOAN ? 'narrowWidth' : ''}`}>
          {props.isEditable ? (
            <div className="fieldWrapper">
              <PivotField
                label={isLoan ? 'Egenkapital' : 'Startleie (inkl. mva)'}
                hideValidationIcon
                dropDownOptions={dropDownOptions}
                onChangeDropDown={handleOnChangeDropDown}
                value={downPaymentFormatted}
                onChange={(input, drop, formatted) => pivotFieldChange(input, drop, formatted)}
                type={inputType}
                {...validity}
                disabled={isSaving}
              />
              {currentDropdownSelection === DropDownValues.PERCENTAGE ? (
                <label className={'smallFont'} htmlFor={fieldIds.downPayment}>
                  <span>
                    <FormRow
                      id="currencyValue"
                      isValid
                      isEditable={false}
                      label={isLoan ? 'Egenkapital' : 'Startleie (inkl. mva):'}
                      value={isNaN(currency) ? 0 : currency}
                      valuePostfix={' kr'}
                      type={InputType.FLOAT}
                      className="downPaymentWrapper"
                    />
                  </span>
                </label>
              ) : null}
            </div>
          ) : (
            <p className={'smallFont'}>{`${formatNumber(getOriginalDownPayment(isLoan, request))} kr`}</p>
          )}
          {/* {isFieldVisible && !isLoan && (
            <FormRow
              id="downPaymentIncVat"
              isValid
              isEditable={false}
              label="inkl. mva: "
              value={getIncVatDownPayment(financing, financing?.downPayment || getOriginalDownPayment(isLoan, request) || 0)}
              valuePostfix={' kr'}
              type={InputType.FLOAT}
            />
          )} */}
        </div>
        {!isLoan ? (
          <div className={'form-row'}>
            <label className={'smallFont'} htmlFor={fieldIds.mileage}>
              {rangeText}
            </label>
            {props.isEditable ? (
              <BosDropdown
                className="next-dropdown"
                nextDropdown
                key={`${fieldIds.mileage}_${financing?.id}_${get(props, 'calculation.id', '')}`}
                onChange={(option) => onRangeChange(option, rangeSelectedValue)}
                options={getRangeOptions(getUpdatedDuration(), getUpdatedRange())}
                placeholder={rangeText}
                iconProps={{
                  iconDropdownDown: IconType.NewNextChevronDown,
                  iconDropdownUp: IconType.NewNextChevronUp,
                  iconColor: bosColor.black,
                }}
                disabled={isSaving}
              />
            ) : (
              <p className={'smallFont'}>{`${formatNumber(rangeSelectedValue)} km`}</p>
            )}
          </div>
        ) : null}
      </div>
      <div className="formRowContainer">
        <div className={`form-row ${props.type === FinancingType.LOAN ? 'narrowWidth' : ''}`}>
          <label className={'smallFont'} htmlFor={fieldIds.durationMonths}>
            {durationText}
          </label>
          {props.isEditable ? (
            <BosDropdown
              className="next-dropdown"
              nextDropdown
              maxMenuHeight={100}
              key={`${fieldIds.durationMonths}_${financing?.id}_${get(props, 'calculation.id', '')}`}
              onChange={(option) => onDurationChange(option, durationSelectedValue)}
              options={getDurationOptions(getUpdatedRange(), getUpdatedDuration(), props.type === FinancingType.LOAN)}
              placeholder={durationText}
              iconProps={{
                iconDropdownDown: IconType.NewNextChevronDown,
                iconDropdownUp: IconType.NewNextChevronUp,
                iconColor: bosColor.black,
              }}
              disabled={isSaving}
            />
          ) : (
            <p className={'smallFont'}>{`${durationSelectedValue} mnd`}</p>
          )}
        </div>
        {isFieldVisible && (
          <InterestFormRow
            isRenteVisible={isRenteVisible}
            setIsRenteVisible={setIsRenteVisible}
            type={props?.type}
            isEditable={props?.isEditable}
            onInterestChange={onInputChange}
            calculation={props?.calculation}
            isLoading={isSaving}
          />
        )}
      </div>
      {isFieldVisible && (
        <div className="formSummaryContainer">
          {isFieldVisible && (
            <InterestFormRow
              isRenteVisible={isRenteVisible}
              setIsRenteVisible={setIsRenteVisible}
              type={props?.type}
              isEditable={false}
              onInterestChange={onInputChange}
              calculation={props.calculation}
              isLoading={isSaving}
            />
          )}
          {!isLoan && (
            <FormRow
              id="residualValue"
              isValid
              isEditable={false}
              label="Restverdi"
              value={checkForNullValueForResidual() ? 'Mangler' : residualValue}
              valuePostfix={checkForNullValueForResidual() ? '' : ' kr'}
              type={InputType.FLOAT}
            />
          )}
          {isFieldVisible && (
            <>
              <FormRow
                id="administrationFee"
                isValid
                isEditable={false}
                label="Etableringsgebyr"
                value={
                  typeof getEstablishmentFee(financing) === 'number'
                    ? formatNumber(getEstablishmentFee(financing), 1)
                    : props.unknownText
                }
                valuePostfix={' kr'}
                type={InputType.FLOAT}
              />
              {isLoan && (
                <FormRow
                  id="documentFee"
                  isValid
                  isEditable={false}
                  label="Tinglysingsgebyr"
                  value={
                    typeof financing?.documentFeeInclVat === 'number'
                      ? formatNumber(financing.documentFeeInclVat, 1)
                      : props.unknownText
                  }
                  valuePostfix={' kr'}
                  type={InputType.FLOAT}
                />
              )}
              <FormRow
                id="invoiceFee"
                isValid
                isEditable={false}
                label="Administrasjonsgebyr"
                value={
                  typeof getAdministrationFee(financing) === 'number'
                    ? formatNumber(getAdministrationFee(financing), 1)
                    : props.unknownText
                }
                valuePostfix={' kr'}
                type={InputType.FLOAT}
              />
            </>
          )}

          {!isLoan && (
            <FormRow
              id="priceOverrunLeasing"
              isValid
              isEditable={false}
              label="Pris overkjørte km"
              value={formatNumber(priceOverrunLeasing, 1) ?? props.unknownText}
              valuePostfix={' kr / km'}
              type={InputType.FLOAT}
            />
          )}
          {isFieldVisible && (
            <div className={'form-row'}>
              <label className={'smallFont'}>Månedspris</label>
              {checkForNullValueForResidual() ? (
                <p className="smallFont">{'Mangler'}</p>
              ) : (
                <h1>
                  {typeof getMonthlyPrice(financing) === 'number'
                    ? `${formatNumber(getMonthlyPrice(financing))} kr`
                    : props.unknownText}
                </h1>
              )}
            </div>
          )}
        </div>
      )}
      {props.isEditable && (
        <ButtonStyled>
          {props.isEditable ? (
            <>
              <AddCalculationButton
                disabled={
                  (changes.length === 0 &&
                    ((props.calculation !== undefined && props.addNew === false) ||
                      (props.calculation !== undefined && props.addNew === true))) ||
                  (!categoryCodePIM && !isLoan) ||
                  (isFieldVisible && !isValid)
                }
                isLoading={isSaving}
                onClick={() => (!isFieldVisible ? handleSave() : props.onUpdateCalculation(getConditionalInput()))}>
                <span>{displayText}</span>
              </AddCalculationButton>
              <Button
                className={!isAddingNew ? 'red' : ''}
                variant={'secondary'}
                onClick={() => props?.onDeleteCalculation(props?.calculation)}
                isLoading={isSaving}>
                {isAddingNew ? (
                  <span>Avbyrt</span>
                ) : (
                  <>
                    <Icon icon={IconType.NewNextTrash} />
                    <span>Slett dette {dialogTitle}et</span>
                  </>
                )}
              </Button>
            </>
          ) : null}
          {/* The below code is commented because we have not got any response for the query which we have asked. */}
          {/* {!props.hideComments && !props?.addNew && (
          <FinanceCommentPane
            disabled={changes.length > 0 || isSaving}
            calculation={props.calculation}
            onAddComment={props.onAddComment}
          />
        )} */}
        </ButtonStyled>
      )}
    </StyledForm>
  );
};

FinancingForm.defaultProps = {
  unknownText: 'ukjent',
  isSaving: false,
};
