// All common Enums regarding Header will added in this file
export enum RabattOrMargin {
  RABATT = 'RABATT',
  MARGIN = 'MARGIN',
}

export enum ModalState {
  NONE,
  LOAN,
  LEASING,
  TRADEIN,
}
