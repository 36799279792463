import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IBosApiAttachmentData, IOrder } from 'next-common';
import { AxiosHeaderEnum, apimEndpoint } from '../../../../common';
import { getHeader } from '../../../../utils';

export const handlePdfResult = (result: AxiosResponse) => {
  const file = new Blob([result.data], { type: 'application/pdf;base64' });
  const fileUrl = URL.createObjectURL(file);
  window.open(fileUrl, '_blank');
};

export const postToGenerator = async (order: IOrder) => {
  const HEADER_BLOB = await getHeader(AxiosHeaderEnum?.HEADER_BLOB);
  return (
    axios
      .get(`${apimEndpoint ?? window.location.origin}/api/initiateManualSigning/${order.id}`, HEADER_BLOB)
      .then((result) => {
        handlePdfResult(result);
      })
      // tslint:disable-next-line:no-console
      .catch((err) => console.error(err))
  );
};

export const getSignedContract = async (order: IOrder, contract: IBosApiAttachmentData) => {
  const HEADER_BLOB: AxiosRequestConfig = await getHeader(AxiosHeaderEnum?.HEADER_BLOB);
  return (
    axios
      .get(`${apimEndpoint ?? window.location.origin}/api/getSignedContract/${order.id}/${contract.id}`, HEADER_BLOB)
      .then((result) => {
        handlePdfResult(result);
      })
      // tslint:disable-next-line:no-console
      .catch((err) => console.error(err))
  );
};
