import styled from 'styled-components';
import { disabled } from '@next-components/common-styles';

export const DateTimeBlockStyled = styled.div`
  display: flex;
  font-size: 90%;
  place-self: flex-start;
  color: ${disabled};

  div.icon {
    margin-top: -6px;
    margin-right: 9px;
    height: 19px;
    width: 19px;
    svg {
      path {
        fill: ${disabled};
      }
    }
  }
`;
