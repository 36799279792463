import { IMenuItem, Menu } from '@next-components/menu';
import { Tooltip } from '@next-components/tooltip';
import { formatNumber } from '@next-components/common-types';
import React from 'react';
import { ttCodes } from '../../common';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
  .tooltip {
    top: 30px;
  }
`;

export const priceFormatter = (value: any, rowData: any, postfix: string = 'kr') => {
  if (typeof value !== 'number' && !value) return '';
  return `${formatNumber(value)}${postfix ?? ''}`;
};

export const monthlyPriceFormatter = (value: any, rowData: any) => {
  if (typeof value !== 'number' && !value) return '-';
  return `${formatNumber(value)}`;
};

export const monthlyPriceFormatterBold = (value: any, rowData: any) => {
  if (typeof value !== 'number' && !value) return '-';
  return <span style={{ fontWeight: 'bold' }}>{formatNumber(value)}</span>;
};

export const cashMonthyPriceFormatter = (value: any, rowData: any, postfix: string = 'kr / mnd') => {
  if (typeof value !== 'number' && !value) return '';
  return `${formatNumber(value)}${postfix ?? ''}`;
};

export const ttCodeFormatter = (code?: number) => {
  if (!code) return '';
  const desc = ttCodes.find((x) => x.id === code)?.description;
  return (
    <TooltipWrapper>
      <Tooltip text={desc} enabledCondition={!!desc}>
        <span style={{ fontSize: '14px' }}>{code}</span>
      </Tooltip>
    </TooltipWrapper>
  );
};

export const menuFormatter = (items: IMenuItem[], rowData: any, actionCallback = null) => {
  const menuItems = items.map((item) => ({ ...item, context: rowData }));
  if (menuItems.length < 1) return <div />;
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <Menu callback={actionCallback} items={menuItems} />
    </div>
  );
};

export const interiorCodeFormatter = (code?: string, interiorList?: any) => {
  if (!code) return '';
  if (!interiorList) return '';
  const name = interiorList.find((x) => x.interiorCode === code)?.name;
  const nameWithoutCode = name?.split('-')[0] ?? '';
  return (
    <TooltipWrapper>
      <Tooltip text={nameWithoutCode.trim()} enabledCondition={!!nameWithoutCode}>
        <span style={{ fontSize: '14px' }}>{code}</span>
      </Tooltip>
    </TooltipWrapper>
  );
};
