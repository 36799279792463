import {
  backgroundCard,
  bosColor,
  hexToRGBA,
  negative,
  neutral,
  positive,
  typography,
} from '@next-components/common-styles';
import styled from 'styled-components';

export const OrderStatusListStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &.showLoader  {
   > div {
     height: 100vh;
    position: fixed;
   }
  }

  > div.topWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .title + div {
      div[disabled] {
        &,
        label > input,
        span {
          cursor: default;
        }
      }
    }
  }

  .ifsDate {
    display: grid;
    margin-top: -0.5rem;
    place-content: end;
  }
  .notReceived, .notAvailable {
    .message-box-info {
      .iconContainer {
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
  .notAvailable {
    .message-box-info.messageBarWarning {
      width: 100%;
    }
  }
`;

export const OrderStatusListWrapper = styled.div`
  .notReceived {
    padding-bottom: 1rem;
  }
  .message-box-info {
    width: 100%;

    .message-bar-body {
      width: 100%;
    }
  }
  .messageWrapper {
    > div {
      gap: 0;
      border-radius: 0.25rem;
      border: 1px solid ${bosColor.neutralPlus};

      button.btnAccordion:not(.search-button) {
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 0.25rem;
        background: ${bosColor.white};

        .labelContainer > .accordionLabelContainer {
          display: flex;
          gap: 0.75rem;

          .iconContainer {
            svg {
              width: 1.5rem;
              height: 1.5rem;
              margin-top: 2px;

              path {
                color: ${bosColor.neutralPlus};
                fill: currentColor;
              }
            }

            &.initial {
              svg {
                margin-top: 0;
              }
            }
          }
        }
        .distanceAndIconContainer {
          svg {
            background: none;
          }
        }
      }
      .content.nextAccordion {
        padding: 0 1rem;
        background: ${bosColor.white};

        & > div {
          padding-left: 2rem;
        }
      }
      &[open] {
        border-radius: 0.25rem;
        border: 1px solid ${bosColor.neutralPlus};
        background: ${bosColor.white};

        button.btnAccordion:not(.search-button) {
          border: none;
          border-radius: 0.25rem 0.25rem 0 0;

          .labelContainer > div > .iconContainer {
            svg {
              transform: rotate(0);
            }
          }
        }
        .content[open].nextAccordion {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          position: relative;

          > div {
            padding-left: 2rem;
          }
          &:after {
            position: absolute;
            content: '';
            left: 3rem;
            right: 1rem;
            top: 0;
            height: 1px;
            background: ${bosColor.neutralPlus};
          }
        }
      }
    }
    .message-box-info {
      .iconContainer {
        svg {
          transform: scaleX(-1);
          margin-top: 2px;
          path {
            color: ${bosColor.neutralPlus};
            fill: currentColor;
          }
        }
      }
    }

    // FAILURE
    > div.failure {
      border-radius: 0.25rem;
      border: 1px solid ${bosColor.poppy60};

      button.btnAccordion:not(.search-button) {
        border: none;
        background: ${bosColor.lightDestructive};

        .labelContainer > .accordionLabelContainer {
          .iconContainer {
            svg > path {
              fill: ${bosColor.destructive};
            }
          }
        }
      }
      &[open] {
        background: ${bosColor.lightDestructive};
      }
      .content.nextAccordion {
        background: ${bosColor.lightDestructive};
        border-radius: 0;
        padding: 0 1rem;

        & > div {
          padding-left: 2rem;
        }
      }
      .content[open].nextAccordion {
        &:after {
          position: absolute;
          content: '';
          left: 3rem;
          right: 1rem;
          top: 0;
          height: 1px;
          background: ${bosColor.poppy60};
        }
      }
    }

    // SUCCESS
    > div.success {
      border-radius: 0.25rem;
      border: 1px solid ${bosColor.komorebi};

      button.btnAccordion:not(.search-button) {
        border: none;
        background: ${bosColor.komorebi20};

        .labelContainer > .accordionLabelContainer {
          .iconContainer {
            svg > path {
              fill: ${bosColor.komorebi};
            }
          }
        }
      }
      &[open] {
        background: ${bosColor.komorebi20};
      }
      .content.nextAccordion {
        background: ${bosColor.komorebi20};
        border-radius: 0;
        padding: 0 1rem;

        & > div {
          padding-left: 2rem;
        }
      }
      .content[open].nextAccordion {
        &:after {
          position: absolute;
          content: '';
          left: 3rem;
          right: 1rem;
          top: 0;
          height: 1px;
          background: ${bosColor.komorebi};
        }
      }
    }
  }
`;

export const OrderStatusListItemStyled = styled.div`
  background-color: ${bosColor.white};
  display: flex;
  gap: 0.75rem !important;
  padding: 0.75rem 1rem;
  border: 1px solid ${bosColor.neutralPlus};
  border-radius: 0.5rem;

  .iconContainer {
    svg {
      width: 1.5rem;
      height: 1.5rem;
      path {
        fill: ${bosColor.neutralPlus};
      }
    }
  }
  span {
    color: ${bosColor.black};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;
  }
`;

export const StatusMessageEventListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  background: ${bosColor.neutralPlus};

  &.success {
    background: ${bosColor.komorebi};
  }
  &.error {
    background: ${bosColor.poppy60};
  }
  &.warning {
    background: ${bosColor.oker};
  }
`;
export const StatusMessageListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
export const StatusMessageListItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  .reserveHeader {
    h3 {
      color: ${bosColor.black};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold} !important;
      line-height: 175%;
    }
  }
  .ifsDate {
    justify-content: flex-start;
    margin-top: 0;
    > div {
      color: ${bosColor.concrete};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 170%;
    }
  }
`;
export const StatusMessageListItemBody = styled.div`
  display: grid;
  grid-template-columns: 6fr 1.25fr;
  gap: 1.5rem;

  &.singleColumn {
    grid-template-columns: auto;

    .leftContainer {
      .bodyItem {
        .label,
        .value {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
        .label + span {
          justify-content: flex-start;

          .value {
            width: calc(100% - 20ch);
            word-break: normal;
          }
          .tooltip {
            max-width: fit-content;
            left: -8rem;
            top: 2.25rem;
            z-index: 9;
          }
        }
      }
    }
  }
  .leftContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    flex: 1 0 0;
    gap: 1.5rem 0.75rem;

    &.singleRow {
      grid-template-columns: auto;
    }
    .bodyItem {
      .value.textWrap {
        width: calc(100% - 20ch);
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .label + span {
        justify-content: flex-start;

        .tooltip {
          max-width: fit-content;
          left: -8rem;
          top: 2.25rem;
          z-index: 9;
        }
      }
      &.stretchItem {
        grid-column: 1 / span 3;
      }
    }
  }
  .rightContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    gap: 1.5rem;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .bodyItem {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .label {
      color: ${bosColor.concrete};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 170%;
    }
    .value {
      color: ${bosColor.concrete};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold};
      line-height: 175%;
    }
  }
`;

export const PendingConfirmationWrapper = styled.div`
  .message-box-info {
    width: 100%;

    .message-bar-body {
      width: 100%;
    }
  }
  .messageWrapper {
    > div.warning {
      gap: 0;

      button.btnAccordion:not(.search-button) {
        width: 100%;
        padding: 0.75rem 1rem;
        border-radius: 0.25rem;
        border: 1px solid ${bosColor.oker};
        background: ${bosColor.oker20};

        .labelContainer > .accordionLabelContainer {
          display: flex;
          gap: 0.75rem;

          .iconContainer {
            svg {
              width: 1.5rem;
              height: 1.5rem;
              margin-top: 2px;

              path {
                color: ${bosColor.oker};
                fill: currentColor;
              }
            }
          }
        }
        .distanceAndIconContainer {
          svg {
            background: none;
          }
        }
      }
      &[open] {
        border-radius: 0.25rem;
        border: 1px solid ${bosColor.oker};
        background: ${bosColor.oker20};

        button.btnAccordion:not(.search-button) {
          border: none;
          border-radius: 0.25rem 0.25rem 0 0;

          .labelContainer > div > .iconContainer {
            svg {
              transform: rotate(0);
            }
          }
        }
        .content.nextAccordion {
          background: ${bosColor.oker20};
          border-radius: 0;
        }
        .content[open].nextAccordion {
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          position: relative;

          > div {
            padding-left: 2rem;
          }
          &:after {
            position: absolute;
            content: '';
            left: 3rem;
            right: 1rem;
            top: 0;
            height: 1px;
            background: ${bosColor.oker};
          }
        }
      }
    }
  }
`;
export const PendingConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const PendingConfirmationBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  .leftContainer {
    .bodyItem {
      .label,
      .value {
        width: calc(100% - 20ch);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .rightContainer {
    display: flex;
    justify-content: flex-end;

    > span {
      color: ${bosColor.concrete};
      text-align: right;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 170%;
    }
  }
  .bodyItem {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .label {
      color: ${bosColor.concrete};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 170%;
    }
    .value {
      color: ${bosColor.concrete};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold};
      line-height: 175%;
    }
  }
`;
