import { IPagination } from 'next-common';
import { orderBy } from 'lodash';
import {
  IDynamicListColumn,
  IDynamicListConfig,
  IDynamicListContentCount,
  IDynamicListMerTableData,
  IDynamicListProps,
  IDynamicListSort,
} from './IDynamicList';
import { IVehicleStockWithSegregatedTypes } from '../../sales/sales-wizard/vehicleSegregationWorker';
import { DISPLAY_DATA } from './DynamicListMer';

export const formatted = <T>(columns: IDynamicListColumn, value: T, rowData: IDynamicListMerTableData) => {
  return columns?.formatter?.(value, rowData) ?? value;
};

export const filterAndOrderCols = (columns: IDynamicListColumn[], orderKey: string = 'smUp'): IDynamicListColumn[] => {
  return orderBy(
    columns?.filter((column) => !column?.order || column?.order?.[orderKey] >= 0),
    `order.${orderKey}`,
  );
};

export const defaultConfig = (data: IDynamicListMerTableData[]): IDynamicListConfig => ({
  cols: !data || data?.length === 0 ? [] : Object.keys(data?.[0])?.map((key) => ({ key, header: key })),
  noHeader: false,
});

export const getConfig = (props: IDynamicListProps): IDynamicListConfig => {
  return {
    ...defaultConfig(props.data),
    ...props.config,
  };
};

export const sortItems = (data: IDynamicListMerTableData[], sort: IDynamicListSort[]): IDynamicListMerTableData[] => {
  return orderBy(
    data,
    sort?.map((column) => column?.key),
    sort?.map((row) => (row?.reverse ? 'desc' : 'asc')),
  );
};

export const createContentCount = (totalListSize: number, pagination: IPagination): IDynamicListContentCount => {
  const showCount = pagination?.itemsPerPage * pagination?.page;

  return {
    totalNumber: totalListSize,
    showingNumber: Math.min(pagination?.page * pagination?.itemsPerPage, showCount),
  };
};

export const dynamicListItemsPerPageMer = 10;

export const fetchSerialNumberList = (dataVehicleList: IVehicleStockWithSegregatedTypes[], dataSpecificSeller: IVehicleStockWithSegregatedTypes[]) => {
  let serialNumberList = [];
  const filterList = [...dataVehicleList, ...dataSpecificSeller];
  filterList?.forEach(item => serialNumberList.push(item?.serialNumber));
  return serialNumberList;
};

export const fetchDefaultTabId = (exactMatchData, nonExactMatchData) => {
  if (exactMatchData?.length > 0 || exactMatchData?.length >= nonExactMatchData?.length) {
    return DISPLAY_DATA.EXACT_MATCH;
  }
  else if(nonExactMatchData?.length > exactMatchData?.length ){
    return DISPLAY_DATA.NON_EXACT_MATCH;
  }
  return null;
};

export const showCountNumber = (pagination: IPagination) => {
  return pagination?.itemsPerPage * pagination?.page;
};