import React from 'react';
import styles from './Overview.module.scss';
import { OverviewTable } from './OverviewTable';

const Overview: React.FunctionComponent = () => {
  return (
    <div className={styles.MarginsPage}>
      <OverviewTable />
    </div>
  );
};
export default Overview;
