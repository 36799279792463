import { useQuery } from '@apollo/client';
import React from 'react';
import { IBosVehicleOrderStatus } from 'next-common';
import { ERROR_POLICY, vehicleOrderStatusQueryParsed } from '../../../../common';
import { ColLoading } from './ColLoading';

const getOrderStatusMessage = (orderStatus?: IBosVehicleOrderStatus) => {
  if (!orderStatus) {
    return {
      display: '-',
      class: 'unsent',
      title: 'Ordren er ikke sendt til IFS',
    };
  }

  const isSuccess = orderStatus?.status === 'SUCCESS' && orderStatus?.orderNumber;
  if (isSuccess) {
    return {
      title: orderStatus.status,
      display: orderStatus.orderNumber,
      class: 'success',
    };
  } else if (orderStatus?.status === 'SUCCESS' && !orderStatus?.orderNumber) {
    return {
      title: orderStatus.status,
      display: '-',
      class: 'success',
    };
  }
  return {
    title: orderStatus.statusMessage,
    display: 'Feilet!',
    class: 'failure',
  };
};

const getOrderStatusVehicleId = (orderStatus?: IBosVehicleOrderStatus) => {
  if (!orderStatus) return '';
  return orderStatus?.vehicleId ? orderStatus?.vehicleId : 'Fabrikkbestilt';
};

export const VehicleOrder = (props) => {
  const { data, loading } = useQuery(vehicleOrderStatusQueryParsed, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'cache-and-network',
    variables: {
      vehicleOrderId: props.id,
    },
    skip: !props.id,
  });
  if (loading) return <ColLoading />;

  const { findVehicleOrderStatusById }: { findVehicleOrderStatusById: IBosVehicleOrderStatus[] } = data;

  const validOrderTypes = ['ORDER', 'STOCK', 'DEMO'];

  const filteredListWithOrderType = findVehicleOrderStatusById?.filter(
    (vehicleOrderStatusObj) => validOrderTypes.includes(vehicleOrderStatusObj.orderType)
  );

  const statusList = [...(filteredListWithOrderType ?? [])];

  const orderStatus = statusList && statusList.length > 0 ? statusList[0] : null;
  const status = getOrderStatusMessage(orderStatus);
  const vehicleId = getOrderStatusVehicleId(orderStatus);

  return (
    <React.Fragment>
      {props.showVehicleId ? (
        vehicleId
      ) : (
        <span className={`ifs-order ${status.class}`} title={status.title}>
          {status.display}
        </span>
      )}
    </React.Fragment>
  );
};
