import { bosColor, margin, padding, typography, wizardPageStyle } from '@next-components/common-styles';
import styled from 'styled-components';


export const VehiclePickerPageMainStyled = styled.div`
  .mainLoadingSpinnerContainer {
    position:fixed;
    left:0;
    bottom:0;
    z-index:99999;
    display:flex;
    align-item:center;
    justify-content:center;
    height: 100vh;
  }
  > div {
    display: grid;
    gap: 1.5rem;
    padding: 1.5rem 3rem 3rem;

    &.newNext {
      .tabs > ul {
        &:after {
          bottom: 0;
        }
        li {
          font-size: 1.25rem;
        }
      }
      .mainContainer {
        ${padding[0].a};
      }
    }
    .tabs {     
      ul {
        position: relative;
        ${padding['0'].a};
      }
    }
    .vehiclePicker.mainContainer {
      padding: 0;
    }
    
  }
`;

export const VehiclePickerPageStyled = styled.div`
  ${margin['0'].y};
  ${margin['auto'].x};
  ${padding['0'].a};
  ${wizardPageStyle};

  &.collapsibleFilters {
    .filters {
      box-shadow: 5px 0 9px 0 rgba(0, 0, 0, 0.2);
      padding-top: 6rem;
      transform: translateX(-110%);
    }

    .results {
      padding-top: 6rem;
      grid-area: filters-start / filters-start / results-end / results-end;
    }

    &.collapsed {
      .filters {
        transform: translateX(0%);
      }
    }
  }

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    h2 {
      ${margin['0'].a};
      font-size: 1.375rem;
      line-height: 1.5rem;
    }

    button {
      &.callToAction {
        max-width: 15rem;

        .text {
          font-size: 1.125rem;
        }
      }

      &.link {
        max-width: none;
        text-align: right;
        ${padding['0'].r};

        .text {
          align-items: center;
          display: flex;
          font-size: 1rem;

          span {
            font-weight: ${typography.weight.semibold};
            &.icon {
              height: 2rem;
              ${margin['32'].l};
              width: 2rem;

              svg {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  p.subtitle {
    font-weight: ${typography.weight.semibold};
    font-size: 1.125rem;
    line-height: 160%;
    letter-spacing: 0.02rem;
    color: ${bosColor.black};
  }

  .vehicleListModel {
    opacity: 0;
    -webkit-transition: opacity 0.25s linear;
    -o-transition: opacity 0.25s linear;
    transition: opacity 0.25s linear;

    .carPickerModal.carPickerModalSelectable .custom-modal {
      bottom: -70%;
      opacity: 0;
      -webkit-transition: opacity 0.8s ease-out, bottom 0.8s ease-out;
      -moz-transition: opacity 0.8s ease-out, bottom 0.8s ease-out;
      -o-transition: opacity 0.8s ease-out, bottom 0.8s ease-out;
      transition: opacity 0.8s ease-out, bottom 0.8s ease-out;
      -webkit-transform: translate(-0%, -0%);
      -ms-transform: translate(-0%, -0%);
      transform: translate(-0%, -0%);
    }
  }
  .vehicleListModel.showModel {
    display: block;
    opacity: 1;
    .carPickerModal.carPickerModalSelectable .custom-modal {
      bottom: 0;
      opacity: 1;
    }
  }
`;
