import React, { useEffect, useState } from 'react';
import { IBisNodeVehicleMakeModel, ITradeInVehicle, validate, VALIDATION_TYPE } from 'next-common';
import { InputType } from '@next-components/common-types';
import {
  bisnodeVehicleMakeModelQuery,
  removeTradeInMutationParsed,
  saveTradeInMutationParsed,
} from '../../../../../../../common';
import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';
import { removeTradeIn, saveTradeIn } from '../../../../../../../utils';
import { StyledForm, TradeInActions, TradeInSelector } from './TradeInForm.styled';
import { FormRow } from '../NewFormRow';
import { Button } from '@next-components/cta';
import { v4 as uuidv4 } from 'uuid';

interface ITradeInFormProps {
  tradeIns?: ITradeInVehicle[];
  isEditable: boolean;
  orderId: string;

  onUpdateTradeIn?(tradeIn: ITradeInVehicle): void;
  onClose?(): void;
}

const emptyTradeIn: ITradeInVehicle = {
  id: null,
  capital: 0,
  claimant: '',
  debt: 0,
  loan: 0,
  price: 0,
  mileage: 0,
  make: '',
  model: '',
  registrationNumber: '',
};

export const TradeInForm = (props: ITradeInFormProps) => {
  const [bisnodeVehicle, setBisnodeVehicle] = useState<IBisNodeVehicleMakeModel>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [registrationNumber, setRegistrationNumber] = useState(null);
  const [removeTradeInMutation] = useMutation(removeTradeInMutationParsed);
  const [saveTradeInMutation] = useMutation(saveTradeInMutationParsed);

  const [selectedTradeIn, setSelectedTradeIn] = useState(props.tradeIns?.[0] || emptyTradeIn);
  const [make, setMake] = useState(selectedTradeIn?.make);
  const [model, setModel] = useState(selectedTradeIn?.model);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [savedTradeIn, setSavedTradeIn] = useState(props.tradeIns?.length ? props.tradeIns?.[0] : null);

  const [isValidRegNumber, setIsValidRegNumber] = useState<boolean>();
  const [isValidMileage, setIsValidMileage] = useState<boolean>();
  const [isValidPrice, setIsValidPrice] = useState<boolean>();

  const client = useApolloClient();

  const isValidRegistrationNo = () => {
    return validate({ value: selectedTradeIn?.registrationNumber, type: VALIDATION_TYPE.REGISTRATIONNUMBER });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    let updatedTradeIn = emptyTradeIn;
    if (props.tradeIns && props.tradeIns.length > 0) {
      updatedTradeIn =
        props.tradeIns.find((x) => x.id === selectedTradeIn?.id) || props.tradeIns[props.tradeIns.length - 1];
    }
    onSelectTradeIn(updatedTradeIn);
    setSavedTradeIn(updatedTradeIn);
    setIsSaving(false);
  }, [props.tradeIns]);

  // #region  bisnode api call
  const getBisnodeVehicle = async (
    licenceNo: string,
    apolloClient: ApolloClient<object>,
    onSuccess: (value: any) => void,
  ) => {
    if (!isValidRegistrationNo()) return;
    await bisnodeVehicleMakeModelQuery(apolloClient, licenceNo, onSuccess);
  };

  useEffect(() => {
    if (!isValidRegistrationNo()) {
      setMake(' ');
    }
  }, [registrationNumber]);

  useEffect(() => {
    getBisnodeVehicle(selectedTradeIn?.registrationNumber, client, setBisnodeVehicle);
  }, [selectedTradeIn]);
  useEffect(() => {
    setMake(bisnodeVehicle?.brandtext ? bisnodeVehicle.brandtext : null);
    onValueChange(
      'make',
      bisnodeVehicle?.brandtext ? bisnodeVehicle.brandtext : null,
      savedTradeIn?.make,
    );
  }, [bisnodeVehicle]);
  useEffect(() => {
    if (!isValidRegistrationNo()) {
      setModel(' ');
    } else {
      setModel(bisnodeVehicle?.model ? bisnodeVehicle.model : null);
      onValueChange('model', bisnodeVehicle?.model ? bisnodeVehicle.model : null, savedTradeIn?.model);
    }
  }, [make]);
  //#endregion

  const onSelectTradeIn = (tradeIn) => {
    if (tradeIn.id === selectedTradeIn?.id) return;
    setSelectedTradeIn(null);
    setTimeout(() => {
      setSelectedTradeIn(tradeIn);
    }, 100);
  };

  const onUpdateTradeIn = async () => {
    setIsSaving(true);
    setIsChange(false);
    const tradeInToUpdate = selectedTradeIn;
    try {
      if (typeof props.onUpdateTradeIn === 'function') {
        await props.onUpdateTradeIn(tradeInToUpdate);
      } else {
        await saveTradeIn(props.orderId, tradeInToUpdate, saveTradeInMutation);
        if (tradeInToUpdate.id) {
          setIsSaving(false);
        }
      }
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      if (tradeInToUpdate.id) {
        // tslint:disable-next-line:no-console
        console.error('Could not update tradein ID ', tradeInToUpdate.id);
      } else {
        // tslint:disable-next-line:no-console
        console.error('Could not save tradein');
      }
    }
  };

  const onRemoveTradeIn = async () => {
    setIsSaving(true);
    setIsChange(false);
    const tradeInToRemove = selectedTradeIn.id;
    try {
      await removeTradeIn(props.orderId, tradeInToRemove, removeTradeInMutation);
      setSavedTradeIn(emptyTradeIn);
      setSelectedTradeIn(null);
      setBisnodeVehicle(null);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      if (tradeInToRemove) {
        // tslint:disable-next-line:no-console
        console.error('Could not remove tradein ID ', tradeInToRemove);
      } else {
        // tslint:disable-next-line:no-console
        console.error('Could not remove tradein');
      }
    }
  };

  const onValueChange = (field, value, originalValue = null) => {
    if (originalValue !== null && selectedTradeIn?.id && value !== originalValue) {
      setIsChange(true);
    } else {
      setIsChange(false);
    }
    if (field === 'registrationNumber') {
      setIsValidRegNumber(
        (selectedTradeIn.registrationNumber?.length < 7 && selectedTradeIn.registrationNumber !== '') ||
          validate({ value: selectedTradeIn.registrationNumber, type: VALIDATION_TYPE.REGISTRATIONNUMBER }),
      );
      setRegistrationNumber(value);
    }
    setSelectedTradeIn({
      ...selectedTradeIn,
      [field]: value,
    });
    if(bisnodeVehicle && value!==''){
      setMake(bisnodeVehicle?.brandtext);
    }
  };

  const onNumericValueChange = (field, value, originalValue = null) => {
    const parsedNumber = parseFloat(value || 0);
    if (originalValue !== null && selectedTradeIn?.id && parsedNumber !== originalValue) {
      setIsChange(true);
    } else {
      setIsChange(false);
    }
    switch (field) {
      case 'mileage':
        setIsValidMileage(value > 0);
        break;
      case 'price':
        setIsValidPrice(value > 0);
        break;
    }
    setSelectedTradeIn({
      ...selectedTradeIn,
      [field]: parsedNumber,
    });
  };

  const onResetChanges = () => {
    setSelectedTradeIn(savedTradeIn);
    setIsChange(false);
  };

  const isValid = () => {
    return (
      selectedTradeIn?.make &&
      selectedTradeIn?.model &&
      validate({ value: selectedTradeIn.registrationNumber, type: VALIDATION_TYPE.REGISTRATIONNUMBER }) &&
      selectedTradeIn.price > 0 &&
      selectedTradeIn.price > selectedTradeIn.loan &&
      selectedTradeIn.loan >= 0 &&
      selectedTradeIn.mileage > 0
    );
  };

  return (
    <div>
      {props.tradeIns.length > 1 && (
        <TradeInSelector>
          {props.tradeIns.map((tI, idx) => (
            <Button variant="secondary" key={uuidv4()} onClick={() => onSelectTradeIn(tI)}>
              <span>Bil #{idx + 1}</span>
            </Button>
          ))}
          {selectedTradeIn && !selectedTradeIn.id && (
            <Button>
              <span>Ny</span>
            </Button>
          )}
        </TradeInSelector>
      )}
      {selectedTradeIn && (
        <StyledForm onSubmit={onSubmit}>
          <FormRow
            id="registrationNumber"
            label="Registreringsnummer"
            placeholder="AA 11111"
            value={selectedTradeIn.registrationNumber ?? ' '}
            isEditable={props.isEditable}
            onChange={(value) => onValueChange('registrationNumber', value, savedTradeIn?.registrationNumber)}
            isValid={isValidRegNumber}
            validationMessage={isValidRegNumber === false ? "Må ha formen 'AA 11111'" : ''}
            type={InputType.LICENSE_PLATE}
          />
          <FormRow
            id="mileage"
            label="Kilometerstand"
            value={selectedTradeIn.mileage}
            isEditable={props.isEditable}
            onChange={(value) => onNumericValueChange('mileage', value, savedTradeIn?.mileage)}
            type={InputType.INTEGER}
            endAdornment="km"
            valuePostfix=" km"
            isValid={isValidMileage}
            validationMessage={isValidMileage === false ? 'Må være over 0' : ''}
            placeholder="0"
          />
          <FormRow
            id="price"
            label="Estimert innbyttepris"
            value={selectedTradeIn.price}
            isEditable={props.isEditable}
            onChange={(value) => onNumericValueChange('price', value, savedTradeIn?.price)}
            type={InputType.FLOAT}
            endAdornment="kr"
            valuePostfix=" kr"
            isValid={isValidPrice}
            validationMessage={isValidPrice === false ? 'Må være over 0' : ''}
            placeholder="0"
            decimals={2}
          />
          <FormRow
            id="debt"
            label="Gjeld"
            value={selectedTradeIn.debt}
            isEditable={props.isEditable}
            onChange={(value) => onNumericValueChange('debt', value, savedTradeIn?.debt)}
            type={InputType.FLOAT}
            endAdornment="kr"
            valuePostfix=" kr"
            placeholder="0"
            decimals={2}
          />
          <FormRow
            id="claimant"
            label="Fordringshaver"
            value={selectedTradeIn.claimant}
            isEditable={props.isEditable}
            onChange={(value) => onValueChange('claimant', value, savedTradeIn?.claimant)}
            placeholder="Skriv selskapsnavn"
          />
          <FormRow
            id="make"
            label="Merke"
            value={make}
            isEditable={props.isEditable}
            onChange={(value) => onValueChange('make', value, make)}
            type={InputType.TEXT_LINE}
            placeholder="Merke"
          />
          <FormRow
            id="model"
            label="Modell"
            value={model}
            isEditable={props.isEditable}
            onChange={(value) => onValueChange('model', value, model)}
            type={InputType.TEXT_LINE}
            placeholder="Modell"
          />
          <TradeInActions>
            {props.isEditable && !props.tradeIns?.length && (
              <>
                <Button
                  className={'custom-button'}
                  disabled={!isValid()}
                  isLoading={isSaving}
                  onClick={onUpdateTradeIn}>
                  <span className="text">Legg til</span>
                </Button>
                <Button className={'cancel-button'} variant="secondary" isLoading={isSaving} onClick={props.onClose}>
                  <span className="text">Avbryt</span>
                </Button>
              </>
            )}
            {props.isEditable && isChange && selectedTradeIn?.id && (
              <Button className={'custom-button'} disabled={!isValid()} isLoading={isSaving} onClick={onUpdateTradeIn}>
                <span>Lagre</span>
              </Button>
            )}
            {props.isEditable && selectedTradeIn?.id && (
              <Button
                className={'btn-delete'}
                variant="secondary"
                isLoading={isSaving}
                onClick={isChange ? onResetChanges : onRemoveTradeIn}>
                <span className="text">{isChange ? 'Slett endringer' : 'Fjern innbyttebil'}</span>
              </Button>
            )}
          </TradeInActions>
        </StyledForm>
      )}
    </div>
  );
};
