import * as React from 'react';
import { AlertModal } from '@next-components/alert-modal';
import { ModalWrapper } from '../../../common-components/ModalWrapper';
import { CancelOrderModalWrapper } from './CancelOrderModal.styled';

export interface ICancelOrderModalProps {
  show: boolean;
  isOrder: boolean;
  onConfirm(confirmed: boolean): void;
}

export const CancelOrderModal = (props: ICancelOrderModalProps) => {
  if (!props.show) return null;

  const cancelOrderModalData = {
    title: `Er du sikker på at du vil ${props.isOrder ? 'kansellere ordren' : 'slette tilbudet'}?`,
    description: 'Denne handlingen kan ikke angres',
    actionsList: [
      {
        label: 'Bekreft',
        actionMethod: () => props.onConfirm(true),
        actionVariant: 'secondary',
        actionColorVariant: 'secondary',
      },
      {
        label: 'Avbryt',
        actionMethod: () => props.onConfirm(false),
        actionColorVariant: 'secondary',
      },
    ],
    hideIcon: true,
  };

  return (
    <CancelOrderModalWrapper>
      <ModalWrapper isActive={props?.show}>
        <AlertModal isOpen={props?.show} onCloseClick={() => props.onConfirm(false)} {...cancelOrderModalData} />
      </ModalWrapper>
    </CancelOrderModalWrapper>
  );
};
