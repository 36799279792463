import React, { useEffect, useState } from 'react';
import { DynamicListStyled, TableWrapper } from './DynamicListStock.styled';
import { ListHeaderStock, VehicleListItemStock } from './parts';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { EmptyList } from '../DynamicListMer/parts';

const DynamicListDisplayRecord = ({
  props,
  isLoading,
  config,
  onButtonClickDetailPage,
  isRowSelected,
  onElementClick,
  toShow,
  filter,
  selectedSerialNumber,
  removeVehicle,
  isVehicleReserved,
}) => {
  const [items, setItems] = useState(toShow);
  const [startIndex, setStartIndex] = useState(0);
  const headerHeight = 0;
  const rowHeight = 87; // Height of each row
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    if (toShow?.length) {
      if (listRef.current) {
        // @ts-ignore
        listRef.current.resetAfterIndex(0);
      }
      toShow.forEach((toShow) => {
        toShow.size = 87;
        toShow.isOpen = false;
      });
      setItems(toShow);
    } else {
      setItems(toShow);
    }
  }, [toShow]);

  const listRef: any = React.createRef();

  const getItemSize = (index) => {
    let element;
    if (items[index].isOpen) {
      element = document.querySelector(`#listItem_${index}`);
    } else {
      element = document.querySelector(`#listItem_${index}>div>div>button.btnAccordion `);
    }
    if (element) {
      let dynamicHeight;
      if (items[index].isOpen) {
        dynamicHeight = element.clientHeight > items[index].size ? element.clientHeight : items[index].size;
      } else {
        dynamicHeight = element.clientHeight + 1;
      }
      return dynamicHeight;
    }
    return items[index].size;
  };

  const toggleSize = (index: number, size) => {
    if (listRef.current) {
      // @ts-ignore
      listRef.current.resetAfterIndex(index);
    }
    let element;
    if (items[index].isOpen) {
      element = document.querySelector(`#listItem_${index}`);
    } else {
      element = document.querySelector(`#listItem_${index}>div>div>button.btnAccordion`);
    }
    const newItems = [...items];
    if (element) {
      const dynamicHeight = size > element.clientHeight ? element.clientHeight : size;
      newItems[index].size = newItems[index].isOpen ? dynamicHeight : size;
      newItems[index].isOpen = newItems[index].isOpen ? false : true;
    }
    setItems(newItems);
  };

  const renderItem = ({ index, style }) => {
    return (
      <div
        style={{ ...style, minHeight: 65, height: 'fit-content' }}
        key={`list_mer_item_${items[index]?.serialNumber || ''}_${items[index]?.serialNumber}`}
        className={items[index]?.serialNumber === selectedSerialNumber && 'sticky-row'}
        id={`listItem_${index}`}>
        <VehicleListItemStock
          isVehicleReserved={isVehicleReserved}
          data={items[index]}
          cols={config?.cols}
          detailsCols={props?.detailsCols}
          withCheckbox={config?.withCheckbox}
          selectable={config?.selectable}
          selected={isRowSelected(items[index])}
          onElementClick={onElementClick}
          disabled={items[index]?.isDisable}
          withAccordion={config?.withAccordion}
          isLoading={isLoading}
          onButtonClickDetailPage={onButtonClickDetailPage}
          filter={filter}
          isRowSave={items[index]?.serialNumber === selectedSerialNumber}
          removeVehicle={removeVehicle}
          index={index}
          isOpen={items[index]?.isOpen}
          onAccordionClick={(index, size) => toggleSize(index, size)}
          startIndex={startIndex}
        />
      </div>
    );
  };

  const handleItemsRendered = ({ visibleStartIndex }) => {
    setStartIndex(visibleStartIndex);
  };

  useEffect(() => {
    adjustScrollPosition();
  }, [startIndex, scrollTop]);

  const handleScroll = (scrollInfo) => {
    const { scrollOffset } = scrollInfo;
    setScrollTop(scrollOffset);
  };

  const adjustScrollPosition = () => {
    if (listRef.current) {
      const firstVisibleIndex = Math.floor(scrollTop / rowHeight);
      const offset = firstVisibleIndex * rowHeight + headerHeight;
      listRef.current.scrollTo(offset);
    }
  };

  return (
    <DynamicListStyled isNewDynamicList={props?.isNewDynamicList}>
      <TableWrapper
        height={props?.height}
        isNewDynamicList={props?.isNewDynamicList}
        className="tableContainer"
        addPadding={items?.length > 8}>
        <ListHeaderStock
          noHeader={config?.noHeader}
          selectable={config?.selectable}
          withCheckbox={config?.withCheckbox}
          cols={config?.cols}
          withAccordion={config?.withAccordion}
          isNewDynamicList={props?.isNewDynamicList}
        />
        <div className="scrollbarHide">
          {items && items?.length > 0 ? (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  onScroll={handleScroll}
                  onItemsRendered={handleItemsRendered}
                  ref={listRef}
                  height={1000}
                  itemCount={items.length}
                  itemSize={getItemSize}
                  width={width}
                  className={'heightInherit'}>
                  {renderItem}
                </List>
              )}
            </AutoSizer>
          ) : (
            <EmptyList className="sellAllCars" />
          )}
        </div>
      </TableWrapper>
    </DynamicListStyled>
  );
};

export default DynamicListDisplayRecord;
