import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IValidationToastNotification } from '../../common';
import { WarningStyled } from './Warning.styled';

export const FactoryCodeWarning = (props: IValidationToastNotification) => {
  return (
    <WarningStyled>
      <div className="notification-width">
        <Messagebar severityLevel={MessagebarSeverityLevel.ERROR} title="Feilmelding:" onClose={props?.onClose}>
          <p>
            Dette tilbudet inneholder feil grunndata i konfigurasjon og en fabrikkordre vil ikke kunne gjennomføres.
          </p>
          <p>
            Velg enten et serienummer fra lagerliste, eller lage en ny tilbudslinje i CRM og åpne ny konfigurasjon i
            NEXT.
          </p>
        </Messagebar>
      </div>
    </WarningStyled>
  );
};
