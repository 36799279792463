/* eslint-disable import/first */

// Polyfills for IE11
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/array/find';
// Polyfills for IE11

import './styles/main.module.scss';
import { initiateApplication } from './indexApp';

initiateApplication();
