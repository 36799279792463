import { useMutation } from '@apollo/client';
import 'date-fns/locale/nb';
import React from 'react';
import { updateOrderPrerequisiteParsed } from '../../../../../../common';
import { OfferPrerequisiteStyled } from './OrderPrerequisite.styled';

interface IOrderPrerequisiteProps {
  orderId: string;
  prerequisite: string;

  onUpdatePrerequisite(value: boolean): void;
}

export const OrderPrerequisite = (props: IOrderPrerequisiteProps) => {
  const [saveOrderPrerequisite] = useMutation(updateOrderPrerequisiteParsed);
  const handlePrerequisiteChange = (prerequisite: string) => {
    updatePrerequisite(prerequisite);
  };

  const updatePrerequisite = async (prerequisite: string) => {
    props.onUpdatePrerequisite(true);
    try {
      const variables = {
        id: props.orderId,
        prerequisite: prerequisite,
      };
      await saveOrderPrerequisite({
        variables,
      }).then(() => {
        props.onUpdatePrerequisite(false);
      });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const updateAttributes = (e: any) => {
    if (e.target.value !== props?.prerequisite) {
      handlePrerequisiteChange(e.target.value);
    }
  };

  return (
    <OfferPrerequisiteStyled>
      <div className="orderCommentContainer">
        <h3>Forutsetning for tilbudet</h3>
        <textarea
          rows={1}
          className="commentTextArea"
          maxLength={40}
          defaultValue={props.prerequisite}
          placeholder="Skriv tekst her..."
          onBlur={updateAttributes}></textarea>
        <div className="additionalCommentText">
          Her kan du definere en ekstra forutsetning som vil ligge nederst på tilbudet.
        </div>
      </div>
    </OfferPrerequisiteStyled>
  );
};
