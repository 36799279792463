import { useEffect } from 'react';
import { IVehiclePageProps } from '../interfaces';
import { IVehicleConfiguration, isStockSaleOrderToBeProcessed } from 'next-common';
import { useLazyQuery } from '@apollo/client';
import { queryVehicleDataFromPIM } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export interface IUseVehicleResponse {
  vehicle: IVehicleConfiguration;
  useVehicleLoading: boolean;
}
export const useVehicle = (props: IVehiclePageProps): IUseVehicleResponse => {
  const { data, loading } = useVehicleQuery(props);
  return { vehicle: data?.length ? data?.[0]: null, useVehicleLoading: loading };
};

const fetchFlagForAPICall = (props: IVehiclePageProps): boolean => {
  let allowAPIcall = null;
  const isStockSaleOrder = isStockSaleOrderToBeProcessed(
    props.configuration,
    props.source,
    props.serialNo,
    props.make,
  );
  if (props.orderId && props.selectedVariantPath) {
    if (props.source) {
      if (!isStockSaleOrder) allowAPIcall = true;
      else {
        allowAPIcall = false;
      }
    }
  }
  return allowAPIcall;
};

const useVehicleQuery = (props: IVehiclePageProps) => {

  useEffect(() => {
    if (fetchFlagForAPICall(props)) {
      vehicleData();
    }
    // vehicleId field is updated when serial no is changed,
    // this condition is required in order for rules to re-run after order mutation
  }, [
    props.orderId,
    props.pimCode,
    props.vehicleId,
    props.currentStep,
    props.source,
    props.configuration,
    props.serialNo,
    props.selectedVariantPath
  ]);  
  const [vehicleData, { loading, data }] = useLazyQuery(queryVehicleDataFromPIM, {
    variables: {
      input: {
        path: props.selectedVariantPath
      }
    },
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });

  return { data: data?.getVehicleDataFromPIM?.results, loading };
};
