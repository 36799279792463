import { useLazyQuery } from '@apollo/client';
import { IAccessory, IConfiguration, ITireSet, OrderSource, isStockSaleOrderToBeProcessed } from 'next-common';
import { useEffect } from 'react';
import { getVehicleAccessoriesBySerialNumberQuery } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export interface IUseVehicleTyreAccResponse {
  tires: ITireSet[];
  accessories: IAccessory[]
  isTyreAccLoading: boolean;
}

export interface IVehicleTyreAccProps {
  orderId: string;
  source?: OrderSource;
  serialNo?: string;
  make?: string;
  configuration?: IConfiguration,
}

export const useVehicleTyreAccessories = (props: IVehicleTyreAccProps): IUseVehicleTyreAccResponse => {
  const { data, loading } = useVehicleTyreAccessoriesQuery(props);
  return { tires: data?.tires, accessories: data?.accessories, isTyreAccLoading: loading };
};

const useVehicleTyreAccessoriesQuery = (props: IVehicleTyreAccProps) => {

  useEffect(() => {
    const isStockSaleOrder = isStockSaleOrderToBeProcessed(
      props?.configuration,
      props?.source,
      props?.serialNo,
      props?.make,
    );

    if (isStockSaleOrder) {
      vehicleAccessories();
    }
  }, [
    props?.orderId,
    props?.source,
    props?.configuration,
    props?.serialNo
  ]);

  const [vehicleAccessories, { loading, data }] = useLazyQuery(getVehicleAccessoriesBySerialNumberQuery, {
    variables: {
      serialNo: props?.serialNo || '',
    },
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });

  return { data: data?.getVehicleAccessoriesBySerialNumber, loading };
};
