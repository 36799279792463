import { formatNumber } from '@next-components/common-types';

export const leasingDurationFormatter = (value: any, rowData: any, suffix: string = 'mnd') => {
  if (typeof value !== 'number' && !value) return value;
  return `${value} ${suffix}`;
};

export const loanDurationFormatter = (value: any, rowData: any, suffix: string = 'år') => {
  if (typeof value !== 'number' && !value) return value;
  return `${Math.ceil(value / 12)} ${suffix}`;
};

export const rangeFormatter = (value: any, rowData: any, suffix: string = 'km') => {
  if (typeof value !== 'number' && !value) return '';
  return `${formatNumber(value)} ${suffix}`;
};
