import { isStockSaleOrderToBeProcessed } from 'next-common';
import { IVehiclePageProps } from '../interfaces';
import { IUseVehicleResponse } from './useVehicle';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { findVehicleByPimCode } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export const useVehicleDataByPimCode = (props: IVehiclePageProps): IUseVehicleResponse => {
  const { data, loading } = useVehilceDataFromPimQuery(props);
  return { vehicle: data || null, useVehicleLoading: loading };
};

const isValidCall = (props: IVehiclePageProps): boolean => {
  let allowAPIcall = null;
  const isStockSaleOrder = isStockSaleOrderToBeProcessed(
    props.configuration,
    props.source,
    props.serialNo,
    props.make,
  );
  if (props.orderId && props.pimCode) {
    if (props.source) {
      if (!isStockSaleOrder) allowAPIcall = true;
      else {
        allowAPIcall = false;
      }
    }
  }
  return allowAPIcall;
};

const useVehilceDataFromPimQuery = (props: IVehiclePageProps) => {
  useEffect(() => {
    if (isValidCall(props)) {
      vehicleDataFromPim();
    }
  }, [props.orderId, props.source, props.configuration, props.serialNo, props.make, props.pimCode]);

  const [vehicleDataFromPim, { loading, data }] = useLazyQuery(findVehicleByPimCode, {
    variables: {
      pimCode: props?.pimCode,
    },
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });
  return { data: data?.vehicleByPimCode, loading };
};
