import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputField } from '@next-components/input-field';
import { IVehicle } from 'next-common';

import { updateModelMutationParsed } from '../../../../../common';

import { ModelYearStyled } from './ModelYear.styled';

export interface IModelYearProps {
  vehicle: IVehicle;
}

export const ModelYear = (props: IModelYearProps) => {
  const modelYearLength: number = 4;
  const minLength: number = 2000;
  const maxLength: number = new Date().getFullYear() + 3;
  const backSpaceKeyCode = 8;
  const tabKeyCode = 9;
  const enterKeyCode = 13;
  const deleteKeyCode = 46;
  const modelYearErrorMessage: string = 'Vennligst oppgi gyldig årsmodell';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [updateModel] = useMutation(updateModelMutationParsed, { fetchPolicy: 'no-cache' });

  const getModelYear = () => {
    if (props?.vehicle?.model?.year === 0) {
      return '';
    }
    return `${props?.vehicle?.model?.year ?? ''}`;
  };

  const updateVehicle = async (year: string) => {
    if (getModelYear() !== year && isValid) {
      setIsLoading(true);
      const variables = {
        id: props?.vehicle?.model?.id,
        input: {
          year: parseInt(year),
        },
      };
      await updateModel({ variables });
      setIsLoading(false);
    }
  };

  const onModelYearChange = (value) => {
    if (value !== '' && (isNaN(value) || value.length !== modelYearLength || value > maxLength || value < minLength)) {
      setIsValid(false);
      setErrorMessage(modelYearErrorMessage);
    } else {
      setIsValid(true);
      setErrorMessage('');
    }
  };

  const onKeyDown = (e) => {
    let maxLength = e.target.getAttribute('maxlength') - 1;
    if (
      e.target.value.length > maxLength &&
      e.keyCode !== backSpaceKeyCode &&
      e.keyCode !== tabKeyCode &&
      e.keyCode !== enterKeyCode &&
      e.keyCode !== deleteKeyCode
    ) {
      e.target.value = '';
    }
  };

  return (
    <ModelYearStyled>
      <div className="model-year-container">
        <InputField
          onKeyDown={onKeyDown}
          type={'number'}
          maxLength={modelYearLength}
          label="Årsmodell"
          isValid={isValid}
          className="borderLight"
          value={getModelYear()}
          onBlur={(e) => updateVehicle(e.currentTarget.value)}
          onChange={onModelYearChange}
          loading={{ isLoading: isLoading }}
          withErrorMessage={true}
          errorMessage={errorMessage}
          endAdornment=''
        />
      </div>
    </ModelYearStyled>
  );
};
