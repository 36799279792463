import { useLazyQuery } from '@apollo/client';
import { IOrderExpiry } from 'next-common';
import { useEffect } from 'react';
import { allOrderExpiryLengthQuery } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export const useOrderExpiryLength = (): IOrderExpiry[] => {
  const { data } = useOrderExpiryLengthQuery();
  return data;
};

const useOrderExpiryLengthQuery = () => {
  useEffect(() => {
    orderData();
  }, []);

  const [orderData, { loading, data }] = useLazyQuery(allOrderExpiryLengthQuery, {
    variables: {},
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'cache-and-network',
  });

  return { data: data?.allOrderExpiryLength, loading };
};
