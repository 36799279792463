import { CustomerType, IBosCustomer, VALIDATION_TYPE } from 'next-common';
import { InputType } from '@next-components/common-types';

export const getInputType = (validationType: VALIDATION_TYPE = VALIDATION_TYPE.STRING) => {
  switch (validationType) {
    case VALIDATION_TYPE.PHONENUMBER:
      return InputType.PHONE_NUMBER;
    case VALIDATION_TYPE.ZIPCODE:
      return InputType.ZIP_CODE;
    case VALIDATION_TYPE.EMAIL:
      return InputType.EMAIL;
    case VALIDATION_TYPE.STRING:
    default:
      return InputType.TEXT_LINE;
  }
};

export const getUniqueList = (list: string[], value: string) => Array.from(new Set([...list, value]));

const validationTemplate = {
  phoneMobile: VALIDATION_TYPE.PHONENUMBER,
  email: VALIDATION_TYPE.EMAIL,
  countryCode: VALIDATION_TYPE.STRING,
  zipCode: VALIDATION_TYPE.ZIPCODE,
  city: VALIDATION_TYPE.STRING,
};

const validationTemplatePrivate = {
  ...validationTemplate,
  firstName: VALIDATION_TYPE.STRING,
  lastName: VALIDATION_TYPE.STRING,
};

const validationTemplateBusiness = {
  ...validationTemplate,
  organizationName: VALIDATION_TYPE.STRING,
  organizationNo: VALIDATION_TYPE.STRING,
};

export const isAllFieldsValid = (customerType: CustomerType, validFields) => {
  if (customerType === CustomerType.PRIVATE) {
    return Object.keys(validationTemplatePrivate).every((key) => validFields.includes(key));
  }
  if (customerType === CustomerType.BUSINESS) {
    return Object.keys(validationTemplateBusiness).every((key) => validFields.includes(key));
  }
  return false;
};

export function getFieldValidationType(customerType: CustomerType, key: string) {
  return customerType === CustomerType.PRIVATE ? validationTemplatePrivate[key] : validationTemplateBusiness[key];
}

export const fieldKeys = (customerType: CustomerType) => {
  if (customerType === CustomerType.PRIVATE) {
    return Object.keys(validationTemplatePrivate);
  }
  if (customerType === CustomerType.BUSINESS) {
    return Object.keys(validationTemplateBusiness);
  }
};

export const getModifiedFields = (
  customerType: CustomerType,
  validFields: string[],
  formFields: Map<any, any>,
): Partial<IBosCustomer> => {
  return fieldKeys(customerType)
    .filter((key) => validFields.includes(key))
    .reduce((changes, key) => {
      return {
        ...changes,
        customerType: customerType,
        [key]: formFields.get(key),
      };
    }, {});
};

export enum CustomerFormType {
  EXISTING_CUSTOMER,
  BISNODE_CUSTOMER,
  NEW_CUSTOMER,
}

export interface ICustomerForm {
  customer?: IBosCustomer;
  customerType: CustomerType;
  updatedFormData(formData: Partial<IBosCustomer>): void;
  updateValidation(value: boolean): void;
}

export interface ICustomerFormFactory extends ICustomerForm {
  customerFormType: CustomerFormType;
  queryString: string;
}
export interface INewCustomerForm extends ICustomerForm {
  queryString: string;
}
