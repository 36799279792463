import { ContentTransformer } from '@crystallize/reactjs-components/dist/content-transformer';
import { Details, HighlightsItems, IncludedItems } from '@next-components/details';
import { get } from 'lodash';
import placeholderImage from '../../../../../assets/images/placeholder.png';
import { IDescriptionDialogContent } from '../../../../../utils';
import { ModalWrapper } from '../../../../common-components/ModalWrapper';

export interface IVehicleDescription {
  data: IDescriptionDialogContent;
  active: boolean;
  onClose: () => void;
}

const ContentTransformerWrapper = (included: string | any[]): JSX.Element => {
  try {
    const jsonData = typeof included === 'string' ? JSON.parse(included) : included;
    return <ContentTransformer json={jsonData} />;
  } catch (error) {
    console.warn(`VehicleDescription-ContentTransformerWrapper: ${error}`);
  }
};

export const VehicleDescription = (props: IVehicleDescription) => {
  const { data, active, onClose } = props;
  const mappedDescriptionJsx: IncludedItems[] = data?.included?.map((items) => {
    return {
      ...items,
      description: ContentTransformerWrapper(items?.description),
    };
  });
  const mappedHighlightsJsx: HighlightsItems[] = data?.highlights?.map((items) => ({
    ...items,
    image: {
      url: get(items, 'images[0].url', placeholderImage),
      altText: items?.title ?? 'Bilde mangler',
    },
    description: ContentTransformerWrapper(items?.description),
  }));
  const mappedIncludedDescJsx: JSX.Element = ContentTransformerWrapper(data?.includedDesc);
  return (
    <ModalWrapper isActive={active}>
      <Details
        highlights={mappedHighlightsJsx}
        includedTitle={data?.includedTitle}
        includedSub={data?.includedSub}
        includedDesc={mappedIncludedDescJsx}
        included={mappedDescriptionJsx}
        onCloseClick={() => onClose()}
        active={active}
      />
    </ModalWrapper>
  );
};
