import { useMutation } from '@apollo/client';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { ICalculation } from 'next-common';
import React, { useState } from 'react';
import { IValidationToastNotification, updateCalculationMutationParsed, useEmployee } from '../../common';
import { getOriginalNominalInterest } from '../../utils';
import { CommentModal } from '../sales/sales-common/Comments';
import { WarningStyled } from './Warning.styled';
import { Button } from '@next-components/cta';
import { black } from '@next-components/common-styles';
import { getNominalInterest, getRequestedNominalInterest } from '../sales/sales-common/FinancingDialog';

export interface IInterestWarning extends IValidationToastNotification {
  offer: ICalculation;
}

export const InterestWarning = (props: IInterestWarning) => {
  const [showModal, setShowModal] = useState(false);
  const [updateCalculationMutation] = useMutation(updateCalculationMutationParsed);
  const employee = useEmployee();
  const originalInterest = getOriginalNominalInterest(props.offer);
  const updatedInterest = getRequestedNominalInterest(props.offer.financing) || getNominalInterest(props.offer.financing) || 0;
  const [isOpen, setIsOpen] = useState(true);

  const saveComment = async (newComment: string) => {
    try {
      await updateCalculationMutation({
        variables: {
          calculationId: props.offer?.id,
          input: {
            comments: [
              {
                text: newComment,
                userId: employee?.employeeId,
              },
            ],
          },
        },
      });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };
  const onSubmitComment = async (value: string) => {
    await saveComment(value);
    setIsOpen(false);
    setShowModal(false);
  };

  if (!isOpen) return null;

  return (
    <>
      <Messagebar
        severityLevel={MessagebarSeverityLevel.WARNING}
        title={'Rente er endret'}
        isOpen
        onClose={props?.onClose}>
        <WarningStyled>
          <p className={'warningText'}>
            Du har endret renten fra {originalInterest}% til {updatedInterest}% og må derfor legge inn en kommentar til
            DNB ASA. Dette kan gjøres her:
          </p>
          <Button className={'commentLink'} variant={'action'} onClick={() => setShowModal(true)} colorVariant={black}>
            &nbsp;"Kommentar til DNB ASA"
          </Button>
        </WarningStyled>
      </Messagebar>
      <CommentModal
        isOpen={showModal}
        onSubmit={onSubmitComment}
        close={() => setShowModal(false)}
        title={'Kommentar til DNB ASA'}
      />
    </>
  );
};
