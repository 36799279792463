import React from 'react';
import { IUserSubMenu } from '../UserSubMenus';
import { SettingsItemStyled } from './SettingsItem.styled';
import { Icon, IconType } from '@next-components/bos-icon';

export interface ISettingsItemProps {
  item: IUserSubMenu;

  onSelect(): void;
}

export const SettingsItem = (props: ISettingsItemProps) => (
  <SettingsItemStyled>
    <div className="title">{props.item.title}</div>
    <div className="user-body-button">
      <div className="iconStyled" onClick={props.onSelect}>
        <Icon icon={IconType.NewNextArrowRight} aria-hidden className="user-body-icon" />
      </div>
    </div>
  </SettingsItemStyled>
);
