export const EquipmentBundleListMakeStyle = {
    'DEFAULT': {
        'SRT': {
            'equipmentLabel': {
                fontSize: 12, 
                fontWeight: 800,
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 10, 
                fontWeight: 600, 
                paddingRight: 25
            }
        },
        'MER': {
            'equipmentLabel': {
                fontSize: 12,
                fontWeight: 800,
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 10,
                fontWeight: 600,
                paddingRight: 25
            }
        },
        'KIA': {
            'equipmentLabel': {
                fontSize: 12,
                fontWeight: 800,
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 10,
                fontWeight: 600,
                paddingRight: 25
            }
        },
        'PEU': {
            'equipmentLabel': {
                fontSize: 12,
                fontWeight: 800,
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 10,
                fontWeight: 600,
                paddingRight: 25
            }
        }
    },
    'INDEPENDENT_DEALER': {
        'PEU': {
            'equipmentLabel': {
                fontSize: 10,
                fontWeight: 700,
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 8,
                fontWeight: 400,
                paddingRight: 25
            }
        },
        'KIA': {
            'equipmentLabel': {
                fontSize: 12,
                fontWeight: 'bold',
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 10,
                fontWeight: 400,
                paddingRight: 25
            }
        },
        'SRT': {
            'equipmentLabel': {
                fontSize: 12,
                fontWeight: 700,
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 10,
                fontWeight: 400,
                paddingRight: 25
            }
        },
        'MER': {
            'equipmentLabel': {
                fontSize: 12,
                fontWeight: 700,
                marginBottom: 4
            },
            'equipmentValue': {
                fontSize: 10,
                fontWeight: 400,
                paddingRight: 25
            }
        },
    }
}