import React, { useEffect } from 'react';
import { Tabs } from '@next-components/tabs';
import { DynamicListStyled, TableWrapper } from './DynamicList.styled';
import { EmptyList, ListHeaderMer, VehicleListItemMer } from './parts';

const DynamicListDisplayRecord = ({
  tabId,
  props,
  scrollElement,
  isLoading,
  config,
  sortKey,
  onButtonClickDetailPage,
  onSortChange,
  onReverseChange,
  sortReverse,
  isRowSelected,
  onElementClick,
  toShow,
  sortedData,
  onLoadMore,
}) => {
  const intersectionObserver = new IntersectionObserver(entries => {
    if (entries?.[0]?.intersectionRatio <= 0) return;
    if (toShow?.length !== sortedData?.length) {
      onLoadMore();
    }
  });

  useEffect(() => {
    if (toShow?.length) {
      const element = document?.getElementById('load-more');
      if (element) {
        intersectionObserver?.observe(element);
      }
    }
  }, [toShow]);

  return (
    <Tabs.TabPanel tabId={tabId}>
      <DynamicListStyled isNewDynamicList={props?.isNewDynamicList}>
        <TableWrapper
          height={props?.height}
          isNewDynamicList={props?.isNewDynamicList}
          className="tableContainer"
        >
          <ListHeaderMer
            sort={sortKey?.map((sortKeys, index) => ({ key: sortKeys, reverse: sortReverse?.[index] }))}
            noHeader={config?.noHeader}
            selectable={config?.selectable}
            withCheckbox={config?.withCheckbox}
            cols={config?.cols}
            onSortChange={onSortChange}
            onSortReverse={onReverseChange}
            withAccordion={config?.withAccordion}
            isNewDynamicList={props?.isNewDynamicList}
          />
          <div
            id="infiniteScroll"
            className='scrollbarHide'
          >
            {toShow &&
              toShow?.length >= 0 &&
              toShow?.map((item, index) => (
                <React.Fragment key={`list_mer_item_${item?.serialNumber || ''}_${item?.serialNumber}`}>
                  <div id={toShow?.length - 1 === index ? "load-more" : ''}>
                    <VehicleListItemMer
                      data={item}
                      cols={config?.cols}
                      detailsCols={props?.detailsCols}
                      withCheckbox={config?.withCheckbox}
                      selectable={config?.selectable}
                      selected={isRowSelected(item)}
                      onElementClick={onElementClick}
                      disabled={item?.disabled}
                      withAccordion={config?.withAccordion}
                      isLoading={isLoading}
                      onButtonClickDetailPage={onButtonClickDetailPage}
                    />
                  </div>
                </React.Fragment>
              ))}
          </div>
        </TableWrapper>
        {sortedData?.length === 0 && (
          <EmptyList emptyFormatter={props?.emptyFormatter} isNewDynamicList={props?.isNewDynamicList} />
        )}
      </DynamicListStyled>
    </Tabs.TabPanel>
  );
};

export default DynamicListDisplayRecord;
