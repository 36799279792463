import { white } from '@next-components/common-styles';

export const CarInfoMakeStyle = {
    'DEFAULT': {
        'SRT': {
            'make': {
                fontSize: 10
            },
            'model': {
                fontSize: 12, 
                fontWeight: 'bold'
            },
            'InfoSpecLabel': {
                fontSize: 10,
            },
            'InfoSpecValue': {
                fontSize: 10,
                fontWeight: 'bold',
                marginTop: '3px'
            },
            'commonText': {
                fontSize: 10, 
                paddingTop: 10, 
                marginTop: 18, 
                fontWeight: 'bold'
            },
            'totalPriceLabel': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold',
                paddingLeft: '8px'
            },
            'totalPriceValue': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold'
            },
            'totalMVA': {
                fontSize: 8,
                color: white,
                fontWeight: 'normal'
            },
            'priser': {},
            'infoPriceBlock': {
                fontWeight: 600,
                fontSize: 10,
            }
        },
        'MER': {
            'make': {
                fontSize: 10
            },
            'model': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'InfoSpecLabel': {
                fontSize: 10,
            },
            'InfoSpecValue': {
                fontSize: 10,
                fontWeight: 'bold',
                marginTop: '3px'
            },
            'commonText': {
                fontSize: 10,
                paddingTop: 10,
                marginTop: 18,
                fontWeight: 'bold'
            },
            'totalPriceLabel': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold',
                paddingLeft: '8px'
            },
            'totalPriceValue': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold'
            },
            'totalMVA': {
                fontSize: 8,
                color: white,
                fontWeight: 'normal'
            },
            'priser': {},
            'infoPriceBlock': {
                fontWeight: 600,
                fontSize: 10,
            }
        },
        'KIA': {
            'make': {
                fontSize: 10
            },
            'model': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'InfoSpecLabel': {
                fontSize: 10,
            },
            'InfoSpecValue': {
                fontSize: 10,
                fontWeight: 'bold',
                marginTop: '3px'
            },
            'commonText': {
                fontSize: 10,
                paddingTop: 10,
                marginTop: 18,
                fontWeight: 'bold'
            },
            'totalPriceLabel': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold',
                paddingLeft: '8px'
            },
            'totalPriceValue': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold'
            },
            'totalMVA': {
                fontSize: 8,
                color: white,
                fontWeight: 'normal'
            },
            'priser': {},
            'infoPriceBlock': {
                fontWeight: 600,
                fontSize: 10,
            }
        },
        'PEU': {
            'make': {
                fontSize: 10
            },
            'model': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'InfoSpecLabel': {
                fontSize: 10,
            },
            'InfoSpecValue': {
                fontSize: 10,
                fontWeight: 'bold',
                marginTop: '3px'
            },
            'commonText': {
                fontSize: 10,
                paddingTop: 10,
                marginTop: 18,
                fontWeight: 'bold'
            },
            'totalPriceLabel': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold',
                paddingLeft: '8px',
                textTransform: 'uppercase'
            },
            'totalPriceValue': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold'
            },
            'totalMVA': {
                fontSize: 8,
                color: white,
                fontWeight: 'normal'
            },
            'priser': {},
            'infoPriceBlock': {
                fontWeight: 600,
                fontSize: 10,
            }
        }
    },
    'INDEPENDENT_DEALER': {
        'PEU': {
            'make': {
                fontSize: 8
            },
            'model': {
                fontSize: 12,
                fontWeight: 700
            },
            'InfoSpecLabel': {
                fontSize: 7,
            },
            'InfoSpecValue': {
                fontSize: 8,
                fontWeight: 700, 
                marginTop: '3px',
                textTransform: 'uppercase'
            },
            'commonText': {
                fontSize: 6,
                paddingTop: 10,
                marginTop: 18,
                fontWeight: 400
            },
            'totalPriceLabel': {
                fontSize: 10, 
                color: white, 
                fontWeight: 700, 
                paddingLeft: '8px',
                textTransform: 'uppercase'
            },
            'totalPriceValue': {
                fontSize: 10, 
                color: white, 
                fontWeight: 700
            },
            'totalMVA': {
                fontSize: 7, 
                color: white, 
                fontWeight: 400
            },
            'priser': {
                fontSize: 10,
                fontWeight: 700,
                textTransform: 'uppercase'
            },
            'infoPriceBlock': {
                fontWeight: 400,
                fontSize: 8,
            }
        },
        'KIA': {
            'make': {
                fontSize: 10
            },
            'model': {
                fontSize: 14,
                fontWeight: 'bold'
            },
            'InfoSpecLabel': {
                fontSize: 8,
            },
            'InfoSpecValue': {
                fontSize: 10,
                fontWeight: 'bold',
                marginTop: '3px'
            },
            'commonText': {
                fontSize: 8,
                paddingTop: 10,
                marginTop: 18,
                fontWeight: 'normal'
            },
            'totalPriceLabel': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold',
                paddingLeft: '8px'
            },
            'totalPriceValue': {
                fontSize: 12,
                color: white,
                fontWeight: 'bold'
            },
            'totalMVA': {
                fontSize: 8,
                color: white,
                fontWeight: 400
            },
            'priser': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'infoPriceBlock': {
                fontWeight: 'normal',
                fontSize: 10,
            }
        },
        'SRT': {
            'make': {
                fontSize: 10
            },
            'model': {
                fontSize: 14,
                fontWeight: 700
            },
            'InfoSpecLabel': {
                fontSize: 8,
            },
            'InfoSpecValue': {
                fontSize: 10,
                fontWeight: 700,
                marginTop: '3px',
                textTransform: 'uppercase'
            },
            'commonText': {
                fontSize: 8,
                paddingTop: 10,
                marginTop: 18,
                fontWeight: 400
            },
            'totalPriceLabel': {
                fontSize: 12,
                color: white,
                fontWeight: 700,
                paddingLeft: '8px'
            },
            'totalPriceValue': {
                fontSize: 12,
                color: white,
                fontWeight: 700
            },
            'totalMVA': {
                fontSize: 8,
                color: white,
                fontWeight: 400
            },
            'priser': {
                fontSize: 12,
                fontWeight: 700
            },
            'infoPriceBlock': {
                fontWeight: 400,
                fontSize: 10,
            }
        },
        'MER': {
            'make': {
                fontSize: 10
            },
            'model': {
                fontSize: 14,
                fontWeight: 700
            },
            'InfoSpecLabel': {
                fontSize: 8,
            },
            'InfoSpecValue': {
                fontSize: 10,
                fontWeight: 700,
                marginTop: '3px'
            },
            'commonText': {
                fontSize: 8,
                paddingTop: 10,
                marginTop: 18,
                fontWeight: 400
            },
            'totalPriceLabel': {
                fontSize: 12,
                color: white,
                fontWeight: 700,
                paddingLeft: '8px'
            },
            'totalPriceValue': {
                fontSize: 12,
                color: white,
                fontWeight: 700
            },
            'totalMVA': {
                fontSize: 8,
                color: white,
                fontWeight: 400
            },
            'priser': {
                fontSize: 12,
                fontWeight: 700
            },
            'infoPriceBlock': {
                fontWeight: 400,
                fontSize: 10,
            }
        },
    }
}