import { get } from "lodash";
import { IConfiguration, IOrder, OrderStatus, canOrderBeCancelled, isEditable, isFeatureOrderEditOn } from "next-common";
import { isStockOrder } from "../../common";

const customerOrderStatus = ['Invoiced/Closed', 'Fakturert/Avsluttet'];

export const isOrderEditable = (order: IOrder, configuration: IConfiguration): boolean => {
    return isFeatureOrderEditOn(configuration) && isEditable(order?.status?.toString() !== OrderStatus[OrderStatus?.FAILURE]?.toString() ? get(order, 'status', null) : null);
};

export const isOrderInvoiced = (order: IOrder): boolean => {
    return customerOrderStatus.includes(get(order, 'vehicleOrderStatus.customerOrderStatus', null));
};

export const isOrderCancellable = (order: IOrder, configuration: IConfiguration): boolean => {
    const availabelity = (
        canOrderBeCancelled(configuration, order) && (
            get(order, 'status', null) === OrderStatus[OrderStatus?.CONFIRMED] || 
            get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_FAILED] ||
            get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_PENDING] ||
            get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_SUCCESS]
        ) && !isOrderInvoiced(order) && !isStockOrder(order?.lead?.orderType)
    );

    return availabelity;
}