import styled, { css } from 'styled-components';
import { backgroundCard, bosColor, typography } from '@next-components/common-styles';

interface IAgreementAreaProps {
  hideBackground?: boolean;
}

export const SigningAgreementStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  .application-modal {
    h3 {
      text-align: center;
    }
  }
`;

export const AgreementArea = styled.div<IAgreementAreaProps>`
  background-color: ${backgroundCard};
  padding: 1rem;
  transition: background-color 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid ${bosColor.neutralGrey};

  &.tradeInCars {
    gap: 1.5rem;
  }
  h2 {
    color: ${bosColor.black};
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
  .tradeInCarWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  .info {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
  }

  .application {
    display: grid;
    grid-template-columns: 5rem auto;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) =>
    props.hideBackground &&
    css`
      position: relative;
      left: -16px;
      width: calc(100% + 3.2rem);
    `};

  p {
    padding-top: 2px;
  }
  .border-top {
    border-top: 1px solid ${bosColor.neutralPlus};
  }
  .border-bottom {
    border-bottom: 1px solid ${bosColor.neutralPlus};
  }
  .grey-text {
    color: ${bosColor.neutralPlus};
  }
  .bold-text {
    font-size: 1.125rem;
    font-weight: 700;
    color: ${bosColor.black};
  }
  .p-20 {
    padding: 10px 0px;
  }
  .asterisk-mark span {
    position: relative;
  }
  .asterisk-mark span:first-child::after {
    bottom: 0;
    color: ${bosColor.neutralPlus};
    content: '*';
    position: absolute;
    transform: translate(-100%, 0);
  }
`;
