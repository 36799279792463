import styled from 'styled-components';
import { negative, positive, stateInactive, padding, bosColor, typography } from '@next-components/common-styles';

export const SigningContractStyled = styled.div`
  .dropdown {
    input {
      height: inherit;
    }
  }
  display: grid;
  grid-template-columns: none;
  grid-row-gap: 3rem;

  .forsikring {
    .content[open] {
      overflow: initial;
    }
  }
  .input-section {
    padding: 0;
    h3 {
      font-weight: 700;
      font-size: 1.25rem;
    }
    .buttonRow {
      padding: 0;
      place-items: flex-start;
      display: flex;
      place-content: flex-start;
    }

    .finePrint {
      padding-top: 1rem;

      h5 {
        padding-top: 1rem;
        a {
          font-size: inherit;
        }
      }
    }
    .insuranceMessageBarContainer {
      flex-direction: column;
      display: flex;
      align-items: flex-end;
      gap: 0.25rem;

      .small {
        font-size: 0.875rem;
        line-height: 140%;
        max-width: 16.125rem;
        display: flex;
        gap: 0.5rem;

        .iconContainer {
          width: 1.25rem;
          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
        .message-bar-body {
          strong {
            color: ${bosColor.black};
            font-size: 0.875rem;
            font-style: normal;
            font-weight: ${typography.weight.semibold};
            line-height: 140%;
          }
        }
      }

      .borderLight {
        width: 100%;
      }
    }

    &.levering,
    &.annenInformasjon {
      .content[open].nextAccordion {
        border-radius: 0;
      }
    }
    &.annenInformasjon {
      input {
        background-color: ${bosColor.white};
      }
      .concludedSeller input[type='checkbox']:checked {
        background-color: ${bosColor.black};
      }
    }
    .merknaderTextarea {
      max-width: 39.6875rem;
      .inputContainer {
        textarea {
          background-color: ${bosColor.white};
        }
      }
    }

    button {
      height: 3rem;
    }
  }

  .footerText {
    margin-top: 1.5rem;
    font-weight: ${typography.weight.semibold} !important;
  }

  .footerLink {
    height: 1.5rem;
    margin-left: 0.5rem;
    color: ${bosColor.black};
    font-size: 0.875rem;
    font-weight: ${typography.weight.semibold};
  }

  .signeringSection {
    .signeringMessage {
      max-width: 30.625rem;
    }
    .content[open].nextAccordion {
      border-radius: 0;
    }
    .signeringSectionCheckbox {
      display: flex;
      justify-content: flex-start;
      gap: 0.7rem;

      input {
        margin-top: 4px;
      }
      label {
        max-width: 47.5rem;
        line-height: 1.75rem;
        padding: 0;
      }
    }
  }

  .content[open].nextAccordion {
    ${padding[0].x};
    gap: 1.5rem;
  }

  .info-section {
    margin-top: 1.25rem;
  }

  .input-block {
    display: grid;
    grid-template-columns: 19.125rem 19.125rem;
    gap: 1.25rem;

    .inputContainer {
      input,
      textarea {
        background-color: ${bosColor.white};
      }
    }
  }

  .send-order {
    transition: background-color 500ms ease-in-out;

    &.error {
      background-color: ${negative};
    }
  }

  .feedback {
    margin-top: 1.25rem;

    &.error-message {
      color: ${negative};
    }

    &.success-message {
      color: ${positive};
    }
  }
  .check-mark-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .check-mark {
      width: 1.5rem;
      height: 1.5rem;
      color: ${bosColor.white};
      background-color: ${bosColor.komorebi};
      border-radius: 50%;
      padding-block: 2px;
    }
    .check-mark-text {
      color: ${bosColor.black};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold};
      line-height: 165%;
    }
  }
  .tradein-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    span {
      font-size: 1.125rem;
      font-weight: bold;

      &:not(&.value) {
        flex: 1 0 0;
      }
      &.value {
        text-align: right;
      }
    }
  }

  //CheckBox css
  .styled-checkbox {
    display: flex;
    max-width: 41rem;
    padding-left: 28px;

    input {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      // Box.
      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        background: ${stateInactive};
        border-radius: 20px;
        position: absolute;
        left: -40px;
      }

      // Box hover
      &:hover + label:before {
        cursor: pointer;
      }

      // Box focus
      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked + label:before {
        background: #7e72f2;
      }

      // Disabled state label.
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: -29px;
        top: 7px;
        width: 8px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;
