import styled from 'styled-components';
import { responsive, appContentNewMaxWidth, fontWeightExtraBold } from '@next-components/common-styles';

export const FilterBarMarginsStyled = styled.div`
  .dropdown {
    input {
      height: inherit;
    }
  } 
  display: block;
  width: 100%;

  ${responsive.smPlus} {
    .filterContainerRow1 {
      display: grid;
      grid-template-columns: 32% 32% auto;
      grid-template-rows: auto;
      margin-left: auto;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      align-items: end;
      gap: 1%;
      margin-top: 1rem;
      .multiSelectOption .dropdown-container .multi-select  > div {
        height : auto;
      }
    }
    .filterContainerRow2 {
      display: grid;
      grid-template-columns: 32% 32% auto;
      grid-template-rows: auto;
      margin-left: auto;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0;
      align-items: end;
      gap: 1%;
      margin-top: 1rem;
      .multiSelectOption .dropdown-container .multi-select  > div {
        height : auto;
      }
    }
  }

  #filterContainer {
    max-width: ${appContentNewMaxWidth};
    margin: 0 auto;
    min-height: 100vh;
  }

  .label-center {
    justify-content: left;
    display: flex;
  }

  .checkBox {
    margin-right: 1rem;
    font-weight: ${fontWeightExtraBold};
  }

  .filter-checkbox.checkboxMargins {
    padding: 20px 0;
    svg {
      width: 30px;
    }
    .label-back {
      .state {
        margin-right: 0;
        margin-top: 5px;
        margin-left: 17px;
      }
    }
  }

  .styled-checkbox {
    input {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      // Box.
      & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        height: 30px;
        background: #c4c4c4;
        border-radius: 20px;
      }

      // Box hover
      &:hover + label:before {
        cursor: pointer;
      }

      // Box focus
      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked + label:before {
        background: #7e72f2;
      }

      // Disabled state label.
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: '';
        position: absolute;
        left: 27px;
        top: 7px;
        width: 8px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;