import * as React from 'react';
import classNames from 'classnames';
import { filterAndOrderCols, formatted } from '../DynamicListStock.utils';
import { IDynamicListItemProps } from '../IDynamicListStock';
import { VehicleDetail } from './VehicleDetail';
import { Accordion } from '@next-components/accordion';
import { VehicleListStockStyled, checkboxColWidth } from './VehicleListStock.styled';
import { Icon, IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';
import { v4 as uuidv4 } from 'uuid';

export const VehicleListItemStock = (props: IDynamicListItemProps) => {
  const columns = filterAndOrderCols(props.cols);
  const startIndex = props.startIndex;
  const hasCheckboxCol = props.selectable && props.withCheckbox;

  const columnWidth = columns?.map((column) => column?.colWidth ?? '1fr');

  if (hasCheckboxCol) {
    columnWidth?.unshift(checkboxColWidth);
  }

  const handleClick = () => (!props.disabled ? props.onElementClick(props.data) : null);

  const rootElement = React.useRef();

  const getDynamicDifference = () => {
    //@ts-ignore
    const exteriorSelectable = props?.data?.exteriorSelectableFormatter?.length;
    const selectableCount = exteriorSelectable > 2 ? exteriorSelectable - 2 : exteriorSelectable;

    switch (true) {
      case selectableCount <= 2:
        return 110;
      case selectableCount >= 3 && selectableCount <= 5:
        return 85;
      case selectableCount > 5 && selectableCount < 10:
        return 95;
      case selectableCount >= 10:
        return 125;
      default:
        return 0;
    }
  };

  return (
    <div className={`accordionContainer${props?.selected ? ' selected' : ''} ${props?.isRowSave && ' row-selected'}`}>
      <Accordion
        onClick={() => {
          props.onAccordionClick(
            props?.index,
            // @ts-ignore
            rootElement?.current?.clientHeight + getDynamicDifference(),
          );
        }}
        isOpen={props.isOpen}
        hasCheckBox={hasCheckboxCol}
        labelContent={
          <VehicleListStockStyled
            onClick={handleClick}
            columnWidth={columnWidth}
            className={classNames(`item grid-row ${props?.isRowSave && 'row-selected'}`, {
              selected: !!props.selected,
              disabled: !!props.disabled,
            })}
            withAccordion={props.withAccordion}>
            {hasCheckboxCol && (
              <div className="selectable">
                {!props?.disabled ? (
                  <div className={'checkboxRadio'}></div>
                ) : (
                  <div className={'lockIcon'}>
                    <Icon
                      icon={IconType.LockLight}
                      aria-hidden="true"
                      height={22}
                      width={18}
                      color={bosColor.neutralPlus}
                    />
                  </div>
                )}
              </div>
            )}
            {columns?.map((column) => (
              <div className="grid-item" key={`item_${column?.key || ''}_${uuidv4()}`}>
                <div key={`item_${column?.key || ''}_${uuidv4()}`}>
                  {formatted(
                    column,
                    props.data?.[column?.key],
                    props.data,
                    props.index === startIndex ? 'top' : 'center',
                  )}
                </div>
              </div>
            ))}
          </VehicleListStockStyled>
        }>
        {props.withAccordion && props.data && (
          <div ref={rootElement}>
            <VehicleDetail
              isVehicleReserved={props.isVehicleReserved}
              onButtonClickDetailPage={props.onButtonClickDetailPage}
              isLoading={props?.isLoading}
              data={props.data}
              cols={props.detailsCols}
              filter={props.filter}
              selected={props?.isRowSave}
              removeVehicle={props?.removeVehicle}
            />
          </div>
        )}
      </Accordion>
    </div>
  );
};
