import { typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const OfferExpiryDateStyled = styled.div`
  display: grid;
  gap: 0.25rem;
  max-width: 20rem;
  width: 100%;

  h3 {
    font-size: 1rem;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;
  }

  .datepickerWidth {
    border-radius: 8px;
    font-size: 1rem;
  }

  .expiryDateInfoText {
    text-align: right;
    font-size: 0.875rem;
    line-height: 170%;
    font-weight: ${typography.weight.semibold};
    padding-right: 0.5rem;
  }
`;
