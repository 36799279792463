import styled from 'styled-components';
import { stateInactive, typography } from '@next-components/common-styles';

export const OfferPrerequisiteStyled = styled.div`
  h3 {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;
  }
  .datepickerWidth {
    width: 80%;
    border-radius: 8px;
  }
  .expiryDateInfoText {
    font-weight: bolder;
  }
  .commentTextArea {
    max-width: 28rem;
    width: 100%;
    border: 2px solid ${stateInactive};
    resize: none;
    border-radius: 8px;
    padding: 0.625rem;
  }
`;
