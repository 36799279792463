import { HashMap, IInterior, IOrder, IPimVehicleModel, IVarnish, IVehicleConfiguration, IVehicleOptionInput } from 'next-common';
import { IOptionMetaData, IVehicleStock } from '../../common';
import { IVehicleInventoryItem } from '../../components/sales/sales-common/VehicleInventory';
import { IVehicleFactoryItem } from '../../components/sales/sales-vehicle-picker-page';
import get from 'lodash/get';
import { calculateRetailPrice } from './vehicleOrder';

export const getPimVarnishByVarnishHexCode = (
  vehicleConfig: IVehicleConfiguration,
  varnishHexCodeString: string,
): IVarnish => {
  const separator = ' / ';
  const splittedColorString = varnishHexCodeString?.split?.(separator);
  const varnishHexCode = varnishHexCodeString?.includes(separator)
    ? splittedColorString?.[splittedColorString?.length - 1]
    : varnishHexCodeString;
  const match = vehicleConfig?.varnishes
    ? vehicleConfig?.varnishes?.find((varnish) => varnish?.varnishCode === varnishHexCode)
    : null;
  return match || null;
};

export const getPimInteriorByInteriorHexCode = (
  vehicleConfig: IVehicleConfiguration,
  interiorHexCodeString: string,
): IInterior => {
  const match = vehicleConfig?.interiors
    ? vehicleConfig?.interiors?.find((interior) => interior?.interiorCode === interiorHexCodeString)
    : null;
  return match || null;
};

export const getPimInteriorByInteriorCode = (
  vehicleConfig: IVehicleConfiguration,
  interiorCodeString: string,
): IInterior => {
  const separator = ' / ';
  const interiorId = interiorCodeString?.includes(separator)
    ? interiorCodeString?.split?.(separator)?.[1]
    : interiorCodeString;
  const match = vehicleConfig?.interiors
    ? vehicleConfig?.interiors?.find((interior) => interior?.interiorCode === interiorId)
    : null;

  return match || null;
};

export const getHexCodeByVarnishHexCode = (vehicleConfig: IVehicleConfiguration, varnishHexCode: string) => {
  const match = getPimVarnishByVarnishHexCode(vehicleConfig, varnishHexCode);
  return match ? match?.hexCode : null;
};

const getVehicleOptionInput = (item: IVehicleInventoryItem, pimVarnish: IVarnish, pimInterior: IInterior, order: IOrder): IVehicleOptionInput => {
  const description = `${item?.varnishHexCode || ''} - ${item?.interiorHexCode || ''}`;
  const name = `${pimVarnish?.type || ''} - ${pimInterior?.type || ''}`;
  const existingOption = order?.options?.find((option) => option?.description === description) || null;
  const id = existingOption?.id || null;
  const optionInput: IVehicleOptionInput = {
    ...(id ? { id } : {}),
    description,
    name,
    price: (pimVarnish?.additionalPrice || 0) + (pimInterior?.additionalPrice || 0),
    priceExcludingVat:
      (pimVarnish?.additionalPriceExcludingVat || 0) + (pimInterior?.additionalPriceExcludingVat || 0),
    retailMargin: (pimVarnish?.retailMargin || 0) + (pimInterior?.retailMargin || 0),
  };
  return optionInput;
}

export const mapVehicleInventoryItemToVehicleOptionInput = (
  item: IVehicleInventoryItem,
  order: IOrder,
  vehicleConfig: IVehicleConfiguration,
): IVehicleOptionInput => {
  const pimVarnish: IVarnish = getPimVarnishByVarnishHexCode(vehicleConfig, item?.varnishHexCode);
  if (!pimVarnish) {
    return null;
  }
  const pimInterior: IInterior = getPimInteriorByInteriorHexCode(vehicleConfig, item?.interiorHexCode);
  if (pimInterior !== null) {
    return getVehicleOptionInput(item, pimVarnish, pimInterior, order);
  }
  const description = item?.varnishHexCode;
  const existingOption = order?.options?.find((option) => option?.description === description) || null;
  const id = existingOption?.id || null;
  const optionInput: IVehicleOptionInput = {
    ...(id ? { id } : {}),
    description,
    name: pimVarnish?.type,
    price: pimVarnish?.additionalPrice,
    priceExcludingVat: pimVarnish?.additionalPriceExcludingVat,
    retailMargin: pimVarnish?.retailMargin,
  };
  return optionInput;
};

export const mapVehicleInventoryItemToVehicleOptionInputForPickerPage = (
  item: IVehicleStock,
  order: IOrder,
  vehicleConfig: IVehicleConfiguration,
  optionMetaData?: IOptionMetaData,
): IVehicleOptionInput => {
  const varnishName = item?.colorDesc || 'Utilgjengelig fra PIM';
  const varnishHexCode = `${varnishName} / ${item?.colorId || ''}`;
  const interiorHexCode = item?.interiorCode?.toString();

  const pimVarnish: IVarnish = getPimVarnishByVarnishHexCode(vehicleConfig, varnishHexCode);
  if (!pimVarnish) {
    return null;
  }

  let pimInterior: IInterior | null = null;
  let packageInteriorHexCode = null;

  if (optionMetaData?.interiorCode && optionMetaData?.interiorCode !== item?.interiorCode) {
    packageInteriorHexCode = optionMetaData?.interiorCode?.toString();
    pimInterior = getPimInteriorByInteriorHexCode(vehicleConfig, packageInteriorHexCode);
  } else {
    pimInterior = getPimInteriorByInteriorHexCode(vehicleConfig, interiorHexCode);
  }

  const colorPriceExcludingVat = pimVarnish?.additionalPriceExcludingVat || 0;
  const interiorPriceExcludingVat = pimInterior?.additionalPriceExcludingVat || 0;

  if (pimInterior !== null) {
    // Incase of package interior, the code in description would remain same as the vehiclestock (interiorHexCode)
    const description = `${varnishHexCode} - ${interiorHexCode}`;
    const name = `${pimVarnish?.type} - ${pimInterior?.type}`;
    const packageInteriorId = packageInteriorHexCode ?? null;
    const existingOption = order?.options?.find((option) => option?.description === description) || null;
    const id = existingOption?.id || null;
    const optionInput: IVehicleOptionInput = {
      ...(id ? { id } : {}),
      description,
      name,
      packageInteriorId,
      price: (pimVarnish?.additionalPrice || 0) + (pimInterior?.additionalPrice || 0),
      priceExcludingVat: (colorPriceExcludingVat || 0) + (interiorPriceExcludingVat || 0),
      retailMargin: (pimVarnish?.retailMargin || 0) + (pimInterior?.retailMargin || 0),
      colorName: pimVarnish?.name || '',
      colorPrice: pimVarnish?.additionalPrice || 0,
      colorPriceExcludingVat,
      colorVatCode: pimVarnish?.vatCode || null,
      interiorName: pimInterior?.name || '',
      interiorPrice: pimInterior?.additionalPrice || 0,
      interiorPriceExcludingVat,
      interiorVatCode: pimInterior?.vatCode || null,
      interiorRetailPrice: calculateRetailPrice(interiorPriceExcludingVat, pimInterior?.marginPercentage),
      colorRetailPrice: calculateRetailPrice(colorPriceExcludingVat, pimVarnish?.marginPercentage),
      colorId: pimVarnish?.varnishCode || '',
      interiorId: pimInterior?.interiorCode || '',
      colorMarginPercentage: pimVarnish?.marginPercentage || 0,
      interiorMarginPercentage: pimInterior?.marginPercentage || 0,
      colorFactoryCodeId: pimVarnish?.manufacturerComponentId ?? null,
      interiorFactoryCodeId: pimInterior?.manufacturerComponentId ?? null,
    };
    return optionInput;
  }
  const description = varnishHexCode;
  const existingOption = order?.options?.find((option) => option?.description === description) || null;
  const id = existingOption?.id || null;
  const optionInput: IVehicleOptionInput = {
    ...(id ? { id } : {}),
    description,
    name: pimVarnish?.type,
    packageInteriorId: null,
    price: pimVarnish?.additionalPrice || 0,
    priceExcludingVat: colorPriceExcludingVat,
    retailMargin: pimVarnish?.retailMargin,
    colorName: pimVarnish?.type || '',
    colorPrice: pimVarnish?.additionalPrice || 0,
    colorPriceExcludingVat,
    colorVatCode: pimVarnish?.vatCode || null,
    colorRetailPrice: calculateRetailPrice(colorPriceExcludingVat, pimVarnish?.marginPercentage),
    interiorName: null,
    interiorPrice: null,
    interiorPriceExcludingVat: null,
    interiorVatCode: null,
    colorId: pimVarnish?.varnishCode || '',
    interiorId: null,
    colorMarginPercentage: pimVarnish?.marginPercentage || 0,
    interiorMarginPercentage: null,
    colorFactoryCodeId: pimVarnish?.manufacturerComponentId ?? null,
    interiorFactoryCodeId: null,
  };

  return optionInput;
};

export const mapFactoryItemToVehicleOptionInput = (
  item: IVehicleFactoryItem,
  order: IOrder,
  vehicleConfig: IVehicleConfiguration,
): IVehicleOptionInput => {
  const pimVarnish: IVarnish = getPimVarnishByVarnishHexCode(vehicleConfig, item?.color);
  const pimInterior: IInterior = getPimInteriorByInteriorHexCode(vehicleConfig, item?.interiorId);
  if (!pimVarnish) {
    return null;
  }

  const description = `color-${item?.color || ''},interior-${item?.interiorCode || ''}`;
  const existingOption = order?.options?.find((option) => option?.description === description) || null;
  const id = existingOption?.id || null;
  const name = `orderFromFactory - colorCode:${item?.color || ''}, interiorCode:${item?.interiorCode || ''}`;
  const optionInput: IVehicleOptionInput = {
    ...(id ? { id } : {}),
    description,
    name,
    price: get(item, 'colorPrice', 0) + get(item, 'interiorPrice', 0),
    priceExcludingVat: get(item, 'colorPrice', 0) + get(item, 'interiorPrice', 0),
    retailMargin: pimVarnish?.retailMargin,
    colorName: pimVarnish?.name || '',
    colorPrice: get(pimVarnish, 'additionalPrice', 0),
    colorPriceExcludingVat: get(pimVarnish, 'additionalPriceExcludingVat', 0),
    colorVatCode: pimVarnish?.vatCode || null,
    interiorName: pimInterior?.name || '',
    interiorPrice: get(pimInterior, 'additionalPrice', 0),
    interiorPriceExcludingVat: get(pimInterior, 'additionalPriceExcludingVat', 0),
    interiorRetailPrice: calculateRetailPrice(
      get(pimInterior, 'additionalPriceExcludingVat', 0),
      get(pimInterior, 'marginPercentage', 0),
    ),
    colorRetailPrice: calculateRetailPrice(
      get(pimVarnish, 'additionalPriceExcludingVat', 0),
      get(pimVarnish, 'marginPercentage', 0),
    ),
    interiorVatCode: pimInterior?.vatCode || null,
    colorId: pimVarnish?.varnishCode || '',
    interiorId: pimInterior?.interiorCode || '',
    colorMarginPercentage: get(pimVarnish, 'marginPercentage', 0),
    interiorMarginPercentage: get(pimInterior, 'marginPercentage', 0),
    colorFactoryCodeId: get(pimVarnish, 'manufacturerComponentId', null),
    interiorFactoryCodeId: get(pimInterior, 'manufacturerComponentId', null),
  };
  return optionInput;
};

export const removeSkuFromInteriorName = (name: string): string => {
  if (name && name?.lastIndexOf('-') !== -1) {
    return name?.slice(0, name?.lastIndexOf('-'));
  }

  return name;
};

export const createOptions = (vehicleList: IPimVehicleModel[] | IVehicleConfiguration[]): HashMap => {
  const options = {};
    vehicleList?.forEach(vehicle => {
      if (vehicle?.uniqueBrandIdentifier) {
        options[vehicle?.uniqueBrandIdentifier] = vehicle?.uniqueBrandIdentifier;
      }
    })
  return options;
}

export const extractSkuFromFarge = (color: string): string => {
  const match = color?.match(/\(([A-Z0-9]+)\)/);
  return match ? match[1] : "";
};
