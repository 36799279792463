import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@next-components/button';
import { isEmpty } from 'lodash';
import { Decimals, formatNumber } from '@next-components/common-types';
import { SiginingPageFinancingSectionStyle } from './SiginingPageFinancingSection.styled';
import { getPaymentPeriodDataListOptions } from '../../../../utils/order/orderUtils';
import { getPaymetInterval } from '../FinancingDialog/ServiceAgreementForm/ServiceAgreementForm';
import {
  FinancingType,
  ICalculation,
  defaultLeasingInput,
  IOrder,
  IServiceContract,
  IFinancing,
} from 'next-common';

import { ICentralisedProperties, priceOverrunLeasing, Routes, manageSignPageButtonOperation } from '../../../../common';
import { IGetServiceContract } from 'sales-common';
import { CheckBox } from '@next-components/bos-checkbox';
import { getNominalInterest, getAdministrationFee, getResidualValue, getEstablishmentFee, getMonthlyPrice, getRequestedNominalInterest } from '../FinancingDialog/FinancingForm/FinancingForm.utils';

export interface IFinancingDialogProps extends ICentralisedProperties {
  offerId?: string;
  calculation: ICalculation;
  financingType?: FinancingType;
  order?: IOrder;
  serviceContract?: IGetServiceContract;
  serviceContractDb?: IServiceContract;
  isUnavailablePimData?: boolean;
}

export const SiginingPageFinancingSection = (props: IFinancingDialogProps) => {
  const financing: IFinancing = props.calculation?.financing;
  const financingType: FinancingType = props.financingType || props.calculation?.financingType;
  const isLoan = (financingType ?? props?.financingType) === FinancingType.LOAN;
  const isLeasing = (financingType ?? props?.financingType) === FinancingType.LEASING;
  const isCash = (financingType ?? props?.financingType) === FinancingType.CASH;
  const isAlternativeLeasing = (financingType ?? props?.financingType) === FinancingType.ALTERNATIVELEASING;
  const navigate = useNavigate();
  const drivingDistanceText = 'Årlig kjørelengde';
  const durationText = isLoan ? 'Nedbetalingstid' : 'Varighet serviceavtale';

  /**
   * redirect to finance page
   * @returns void;
   */
  const redirect = () => {
    const route = Routes.getFinancePage(props.order?.id);
    navigate(route);
  };


  /**
   * Display the number value based on decimals point and append string at end
   * @param amount
   * @param decimals
   * @param endString
   *
   * @returns string
   */
  const displayNumber = (amount: number, decimals: Decimals = 0, endString: string = 'kr'): string => {
    return amount ? `${formatNumber(amount, decimals)} ${endString}` : '0 kr';
  };

  /**
   * return the dealer amount from serviceContractDb
   * @returns number
   */

  const getDealerDiscount = (): string => {
    const dealerAmount = props.serviceContractDb?.dealerAmount;
    return typeof dealerAmount === 'number' ? `${dealerAmount} Kr` : '0 Kr';
  };

  /**
   * return the saving price from serviceContractDb
   * @returns string
   */
  const getSavingPrice = (): string => {
    return props.serviceContractDb?.totalSavings && props.serviceContractDb?.rebatePercentage
      ? `${formatNumber(props.serviceContractDb?.totalSavings)} kr (${formatNumber(
        props.serviceContractDb?.rebatePercentage,
      )}%)`
      : '0 kr';
  };

  /**
   * Return the paymentPeriod text based on interval selection
   * @returns string
   */
  const paymentPeriodText = (): string => {
    const selectedInterval = getPaymetInterval(props.serviceContract, props.serviceContractDb);
    const options = getPaymentPeriodDataListOptions(
      props.serviceContract?.paymentPeriodDataList,
      selectedInterval?.name,
    );
    let paymentPeriodText = options?.filter(
      (item) => parseInt(item.value) === props.serviceContractDb?.paymentPeriodId,
    );

    return paymentPeriodText?.length > 0 ? paymentPeriodText?.[0]?.label : '';
  };

  /**
   * Return the selected service conteact text
   * @returns string
   */
  const getServiceContractTypeLabel = (): string => {
    const selectedServiceContract = props.serviceContract?.contractTypeList?.filter(
      (item) => item.id === props.serviceContractDb?.contractTypeId,
    );

    return selectedServiceContract?.length > 0 ? selectedServiceContract?.[0]?.name : 'Ingen serviceavtale valgt';
  };

  /**
   * Calculate total price based on finance total cost and service Contract total price if it is included
   * @returns string
   */
  const calculateTotalPrice = (): string => {
    if (props?.serviceContractDb?.isLoanPaymentIntervalSelected) {
      return displayNumber((financing?.totalCost + props?.serviceContractDb?.totalPrice))
    }

    return displayNumber(financing?.totalCost);
  }
  const { disableChangeFinanceBtn, disableChangeServiceContractBtn } = manageSignPageButtonOperation(props?.order?.lead?.orderType);

  return (
    <>
      <SiginingPageFinancingSectionStyle>
        <div className="title">Finansiering</div>
        <div className="financeListItemContainer">
          {(isCash ||  isAlternativeLeasing)&& (
            <div className="financeListItem ">
              <span>Egenkapital </span>
              <span className="sigingPrice">{calculateTotalPrice()}</span>
            </div>
          )}
          {(isLoan || isLeasing) && (
            <div className="financeListItem">
              <span>{isLoan ? 'Låne' : 'Leasing'} Månedspris</span>
              <span className="sigingPrice">{displayNumber(getMonthlyPrice(financing))}</span>
            </div>
          )}
          {(isLoan || isLeasing) && (
            <>
              <div className="financeListItem">
                <span>{isLoan ? 'Egenkapital' : 'Startleie inkl. mva'}</span>
                <span>
                  {financing?.request?.downPayment ? displayNumber(financing?.request?.downPayment) : displayNumber(0)}
                </span>
              </div>
              {/* {!isLoan && (
                <div className="financeListItem">
                  <span>Startleie inkl. mva</span>
                  <span>{displayNumber(getIncVatDownPayment(financing, financing?.downPayment || getOriginalDownPayment(isLoan, request) || 0))}</span>
                </div>
              )} */}
              <div className="financeListItem">
                <span>{durationText}</span>
                <span>{`${financing?.request?.durationMonths} mnd`}</span>
              </div>
              {!isLoan && (
                <div className="financeListItem">
                  <span>{drivingDistanceText}</span>
                  <span>{`${
                    financing?.request?.mileage ? `${financing?.request?.mileage} km` : defaultLeasingInput?.mileage
                  }`}</span>
                </div>
              )}
              <div className="financeListItem">
                <span>Rente</span>
                <span>{`${formatNumber((getRequestedNominalInterest(financing) || getNominalInterest(financing) || 0), 2)} %`}</span>
              </div>
              {!isLoan && (
                <div className="financeListItem">
                  <span>Restverdi</span>
                  <span>{`${displayNumber(getResidualValue(financing))}` || 0}</span>
                </div>
              )}
              <div className="financeListItem">
                <span>Etableringsgebyr</span>
                <span>{displayNumber(getEstablishmentFee(financing), 1)}</span>
              </div>
              {isLoan && (
                <div className="financeListItem">
                  <span>Tinglysingsgebyr</span>
                  <span>{displayNumber(financing?.documentFeeInclVat, 1)}</span>
                </div>
              )}

              <div className="financeListItem">
                <span>Fakturagebyr</span>
                <span>{displayNumber(getAdministrationFee(financing), 1)}</span>
              </div>

              {!isLoan && (
                <div className="financeListItem">
                  <span>Pris overkjørte kilometer</span>
                  <span>{displayNumber(priceOverrunLeasing, 1, 'kr/km')}</span>
                </div>
              )}
            </>
          )}
        </div>
        <Button 
          variant={'secondary'} 
          onClick={redirect} 
          disabled={(props.isUnavailablePimData || disableChangeFinanceBtn)}
        >
          <span>Endre finansiering</span>
        </Button>
      </SiginingPageFinancingSectionStyle>
      <SiginingPageFinancingSectionStyle>
        <div className="title">Serviceavtale</div>
        {isEmpty(props.serviceContractDb) ? (
          <div className="financeListItemContainer">
            <div className="financeListItem">
              <span>Serviceavtale</span>
              <span>{getServiceContractTypeLabel()}</span>
            </div>
          </div>
        ) : (
          <div className="financeListItemContainer">
            {props.serviceContractDb?.isLoanPaymentIntervalSelected && (
              <div className="financeListItem">
                <CheckBox
                  className={'styled-checkbox'}
                  label={isLoan ? 'Inkluder i lån' : 'Inkluder i bilpris'}
                  id={'confirmation-checkbox'}
                  checked={props.serviceContractDb?.isLoanPaymentIntervalSelected}
                  disabled={true}
                />
              </div>
            )}
            <div className="financeListItem">
              <span>Månedspris</span>
              <span className="sigingPrice">
                {props.serviceContractDb?.monthlyPrice !== undefined &&
                  displayNumber(props.serviceContractDb?.monthlyPrice)}
              </span>
            </div>
            {!isEmpty(props.serviceContractDb) && (
              <div className="financeListItem">
                <span>Betalingsintervall</span>
                <span>{paymentPeriodText()}</span>
              </div>
            )}
            <div className="financeListItem">
              <span>Serviceavtale</span>
              <span>{getServiceContractTypeLabel()}</span>
            </div>
            <div className="financeListItem">
              <span>{drivingDistanceText}</span>
              <span>
                {props?.serviceContractDb?.drivingDistance !== undefined &&
                  `${props?.serviceContractDb?.drivingDistance} km`}
              </span>
            </div>
            <div className="financeListItem">
              <span>{durationText}</span>
              <span>
                {props?.serviceContractDb?.duration !== undefined && `${props?.serviceContractDb?.duration} mnd`}
              </span>
            </div>
            {getServiceContractTypeLabel() !== 'Ingen serviceavtale valgt' && (
              <div className="financeListItem">
                <span>Forhandlerrabatt</span>
                <span>{getDealerDiscount()}</span>
              </div>
            )}
            <div className="financeListItem">
              <span>Totalpris serviceavtale</span>
              <span>
                {props?.serviceContractDb?.totalPrice !== undefined &&
                  displayNumber(props?.serviceContractDb?.totalPrice)}
              </span>
            </div>
            <div className="financeListItem">
              <span>Besparelse totalt</span>
              <span>{props?.serviceContractDb?.totalSavings !== undefined && getSavingPrice()}</span>
            </div>
          </div>
        )}
        {!isAlternativeLeasing && <Button 
          variant={'secondary'} 
          onClick={redirect} 
          disabled={(props.isUnavailablePimData || disableChangeServiceContractBtn)}
        >
          <span>Endre tjenester</span>
        </Button>}
      </SiginingPageFinancingSectionStyle>
    </>
  );
};