import { gql } from '@apollo/client';
import {
  addChosenOfferMutation,
  addNewCalculationToOrderMutation,
  addVehiclesToOrderMutation,
  cancelOrderMutation,
  createCustomerMutation,
  getNBFContractMutation,
  pauseOrderMutation,
  removeCalculationFromOrderMutation,
  removeTradeInMutation,
  removeVehiclesFromOrderMutation,
  resumeOrderMutation,
  saveInsuranceMutation,
  saveTradeInMutation,
  sendReserveVehicleOrderMutation,
  signOrderMutation,
  updateCalculationFinancingMutation,
  updateCalculationMutation,
  updateCustomerInfoMutation,
  saveCustomerMutation,
  updateOrderDiscountMutation,
  updateOrderMutation,
  updateReservedVehicleStockStatusBySerialNumberMutation,
  updateVehicleMutation,
  vehicleOrderDraftMutation,
  createExistingOrderMutation,
  updateOrderDraftMutation,
  sendSigningJobMutation,
  updatePrerequisiteToOrderMutation,
  saveServiceContractFlagToOrderMutation,
  updateModelMutation,
  updateOrderForEcomMutation,
  createExistingOrderWithVehicleMutation,
  createExistingOrderWithVehicleAndVehicleEquipmentMutation,
  addVehiclesToOrderAndVehicleEquipmentMutation,
  createMinimalOrderMutation,
  saveOfferExpiryDateToOrderMutation,
  orderFragments,
  sendCancelConfirmOrderMutation,
  sendOrderMutation,
  createAndAddMarginToOrderMutation,
  toggleVehicleAccessoriesOnOrderMutation,
} from 'next-common';

export const createExistingOrderMutationParsed = gql`
  ${createExistingOrderMutation}
`;

export const createExistingOrderWithVehicleMutationParsed = gql`
  ${createExistingOrderWithVehicleMutation}
`;

export const createExistingOrderWithVehicleAndVehicleEquipmentMutationParsed = gql`
  ${createExistingOrderWithVehicleAndVehicleEquipmentMutation}
`;

export const updateOrderMutationParsed = gql`
  ${updateOrderMutation}
`;

export const updateOrderDraftMutationParsed = gql`
  ${updateOrderDraftMutation}
`;

export const updateOrderDiscountMutationParsed = gql`
  ${updateOrderDiscountMutation}
`;

export const updateCalculationMutationParsed = gql`
  ${updateCalculationMutation}
`;

export const updateCalculationFinancingMutationParsed = gql`
  ${updateCalculationFinancingMutation}
`;

export const addNewCalculationToOrderParsed = gql`
  ${addNewCalculationToOrderMutation}
`;

export const removeCalculationFromOrderMutationParsed = gql`
  ${removeCalculationFromOrderMutation}
`;

export const addVehiclesToOrderMutationParsed = gql`
  ${addVehiclesToOrderMutation}
`;

export const addVehiclesToOrderAndVehicleEquipmentMutationParsed = gql`
  ${addVehiclesToOrderAndVehicleEquipmentMutation}
`;

export const removeVehiclesFromOrderMutationParsed = gql`
  ${removeVehiclesFromOrderMutation}
`;

export const updateVehicleMutationParsed = gql`
  ${updateVehicleMutation}
`;

export const pauseOrderMutationParsed = gql`
  ${pauseOrderMutation}
`;

export const cancelOrderMutationParsed = gql`
  ${cancelOrderMutation}
`;

export const resumeOrderMutationParsed = gql`
  ${resumeOrderMutation}
`;

export const addChosenOfferMutationParsed = gql`
  ${addChosenOfferMutation}
`;

export const toggleVehicleAccessoriesOnOrderMutationParsed = gql`
  ${toggleVehicleAccessoriesOnOrderMutation}
`;

export const createVehicleOrderDraftMutationParsed = gql`
  ${vehicleOrderDraftMutation}
`;

export const signOrderParsed = gql`
  ${signOrderMutation}
`;

export const sendSigningJobMutationParsed = gql`
  ${sendSigningJobMutation}
`;

export const saveInsuranceParsed = gql`
  ${saveInsuranceMutation}
`;

export const sendOrderMutationParsed = gql`
  ${sendOrderMutation}
`;

export const createAndAddMarginToOrderMutationParsed = gql`
  ${createAndAddMarginToOrderMutation}
`;

export const saveTradeInMutationParsed = gql`
  ${saveTradeInMutation}
`;

export const removeTradeInMutationParsed = gql`
  ${removeTradeInMutation}
`;

export const createCustomerMutationParsed = gql`
  ${createCustomerMutation}
`;

export const saveCustomerMutationParsed = gql`
  ${saveCustomerMutation}
`;

export const updateCustomerInfoParsed = gql`
  ${updateCustomerInfoMutation}
`;

export const getNBFContractMutationParsed = gql`
  ${getNBFContractMutation}
`;

export const saveOfferExpiryDateToOrderMutationParsed = gql`
  ${saveOfferExpiryDateToOrderMutation}
`;

export const sendReserveVehicleOrderMutationParsed = gql`
  ${sendReserveVehicleOrderMutation}
`;

export const sendCancelConfirmOrderMutationParsed = gql`
  ${sendCancelConfirmOrderMutation}
`;

export const updateReservedVehicleStockStatusBySerialNumberMutationParsed = gql`
  ${updateReservedVehicleStockStatusBySerialNumberMutation}
`;

export const updateOrderPrerequisiteParsed = gql`
  ${updatePrerequisiteToOrderMutation}
`;

export const saveServiceContractFlagToOrderMutationParsed = gql`
  ${saveServiceContractFlagToOrderMutation}
`;

export const updateModelMutationParsed = gql`
  ${updateModelMutation}
`;

export const updateOrderForEcomMutationParsed = gql`
  ${updateOrderForEcomMutation}
`;

export const createMinimalOrderMutationParsed = gql`
  ${createMinimalOrderMutation}
`;

export const addAlternateLeasingToOrderMutationParsed = gql`
mutation addAlternateLeasingToOrder($input: LeasingCompanyInput!, $orderId: ID!) {
  addAlternateLeasingToOrder(input: $input, orderId: $orderId) {
    ...order
    __typename
  }
}
${orderFragments}
`;

export const removeAlternateLeasingFromOrderMutationParsed = gql`
mutation removeAlternateLeasingFromOrder($orderId: ID!) {
  removeAlternateLeasingFromOrder(orderId: $orderId) {
    ...order
    __typename
  }
}
${orderFragments}
`;

