import React from 'react';
import DatePicker from 'react-datepicker';
import { Icon, IconType } from '@next-components/bos-icon';
import { DateFilterInputStyled, DateFilterContainerStyled } from './DatePickerFilterBarStyled.style';

interface IDatePickerFilterBarProps {
  filterLabel?: string;
  selectedDate: Date;
  startDate: Date;
  endDate: Date;
  onChange(date: Date, e: React.FormEvent<HTMLInputElement>): void;
}
export const DatePickerFilterBar = (props: IDatePickerFilterBarProps) => (
  <DateFilterContainerStyled>
    <label>
      <span>{props?.filterLabel}</span>
      <DateFilterInputStyled>
        <div className="iconStyled">
          <Icon icon={IconType.NewNextCalendar} viewBox="4 6 25 25" aria-hidden={true} />
        </div>
        <DatePicker
          selected={props?.selectedDate}
          onChange={props?.onChange}
          startDate={props?.startDate}
          endDate={props?.endDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker={true}
        />
      </DateFilterInputStyled>
    </label>
  </DateFilterContainerStyled>
);
DatePickerFilterBar.defaultProps = {
  filterLabel: '',
};
