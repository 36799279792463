import styled from 'styled-components';
import { disabled, fontWeightSemiBold } from '@next-components/common-styles';

interface IStyledLatestCommentProps {
  isEditable: boolean;
}

export const StyledLatestComment = styled.div<IStyledLatestCommentProps>`
  .commentFooter {
    padding-top: ${(props) => (props.isEditable ? 3 : 0.5)}rem;
    display: flex;
    justify-content: space-between;

    .disabledShowComments {
      color: ${disabled};
      cursor: auto;
    }
    .commentCount {
      font-weight: ${fontWeightSemiBold};
      cursor: pointer;
    }
  }
`;
