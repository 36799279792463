import * as React from 'react';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { VehicleListStockStyled } from './VehicleListStock.styled';
import { IDynamicListContentCount } from '../IDynamicListStock';
import styled from 'styled-components';
import { Button } from '@next-components/cta';

export interface ILoadMoreProps {
  loadingMore: boolean;
  showContentCount?: boolean;
  contentCount?: IDynamicListContentCount;
  isNewDynamicList?: boolean;

  onLoadMore(): void;
}

const Center = styled.div`
  text-align: center;
  width: 100%;
`;

const Inline = styled.div`
  display: inline;
  font-size: small;
`;

export const LoadMore = (props: ILoadMoreProps) => {
  return (
    <VehicleListStockStyled columnWidth={['1fr']} className="item load-more">
      {props.loadingMore && !props.isNewDynamicList ? (
        <Center>
          <LoadingSpinner size={32} />
        </Center>
      ) : props.isNewDynamicList ? (
        <>
          {props.loadingMore ? (
            <Button onClick={props.onLoadMore} isLoading={props.loadingMore}>
              <span>Hent flere biler </span>
            </Button>
          ) : (
            <Button variant={'secondary'} onClick={props.onLoadMore}>
              <span>Hent flere biler </span>
            </Button>
          )}
          {props.showContentCount && (
            <Inline>
              Viser {props.contentCount?.showingNumber} av {props.contentCount?.totalNumber}
            </Inline>
          )}
        </>
      ) : (
        <Center onClick={props.onLoadMore}>
          Hent flere{' '}
          {props.showContentCount && (
            <Inline>
              (Viser {props.contentCount?.showingNumber} av {props.contentCount?.totalNumber})
            </Inline>
          )}
        </Center>
      )}
    </VehicleListStockStyled>
  );
};
