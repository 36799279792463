import { bosColor, negative } from '@next-components/common-styles';
import styled from 'styled-components';

export const StyledFormRow = styled.div`
  align-items: center;

  margin: 1rem 0;
  label + div {
    margin-top: 0.5rem;
  }
  &.large {
    margin: 1rem 0;
  }

  .input-with-warning {
    .inputContainer input {
      font-size: 1rem;
      margin-bottom: 0;
    }
    .inputContainer > .inputAndIconContainer {
      .adornment-container {
        top: 0.75rem;
      }
    }
    &.invalid {
      .inputContainer input {
        border-color: ${negative};
      }
    }
    &.valid {
      .inputContainer input {
        border-color: ${bosColor.concrete};
      }
    }
  }

  .inputContainer {
    textarea,
    input {
      &.startAdornmentInput {
        text-indent: 30px;
      }
    }
  }
`;

export const FormRowValidationWarning = styled.div`
  min-height: 16px;
  p {
    color: ${negative};
    text-align: right;
    font-size: 1rem;
    margin-bottom: 0;
    margin-right: 16px;
  }
`;
