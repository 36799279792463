import { ApolloClient } from '@apollo/client';
import { IBisNodeVehicleMakeModel } from 'next-common';
import { ERROR_POLICY } from '..';
import { queryVehicleMakeModel } from '../../graphql';

export const bisnodeVehicleMakeModelQuery = async (
  client: ApolloClient<object>,
  licenceNo: string,
  onSuccess: (value: IBisNodeVehicleMakeModel) => void,
): Promise<void> => {
  try {
    const { data } = await client.query({
      query: queryVehicleMakeModel,
      variables: {
        licenceNo: licenceNo,
      },
      errorPolicy: ERROR_POLICY,
    });
    const result = data?.getBisnodeVehicleByLicenceNo ? data.getBisnodeVehicleByLicenceNo : null;
    onSuccess(result);
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};
