import styled from 'styled-components';
import { bosColor, stateInactive, typography } from '@next-components/common-styles';

export const CommentBoxModalBody = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 1.5rem;
  background: ${bosColor.white};
`;
export const CommentBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 0.25rem;

  label.commentBoxLabel {
    color: ${bosColor.black};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${typography.weight.semibold};
    line-height: 150%;
    align-self: stretch;
  }
`;
export const CommentBox = styled.textarea`
  min-height: 7.5rem;
  height: 100%;
  width: 100%;
  border: 2px solid ${stateInactive};
  resize: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;

  border-radius: 0.5rem;
  border: 1px solid ${bosColor.neutralPlus};
  background: ${bosColor.white};
`;
