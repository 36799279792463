import styled from 'styled-components';
import { bosColor, typography } from '@next-components/common-styles';

export const SiginingPageFinancingSectionStyle = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px solid ${bosColor.neutralGrey};
  background: ${bosColor.lightGrey};
  .title {
    font-size: 1.125rem;
    line-height: 175%;
    font-weight: ${typography.weight.extrabold};
    color: ${bosColor.black};
  }
  .financeListItemContainer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
  }
  .financeListItem {
    font-size: 1rem;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
    color: ${bosColor.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 1rem;
      font-weight: ${typography.weight.semibold};
      line-height: 175%;
      &.sigingPrice {
        color: ${bosColor.black};
        font-size: 1.5rem;
        font-style: normal;
        font-weight: ${typography.weight.extrabold};
        line-height: 165%;
      }
    }
  }
  button {
    align-items: center;
    min-width: 17.5rem;
    justify-content: center;
    height: 3rem;
    border-width: 2px;
    font-weight: ${typography.weight.extrabold};
  }
`;
