import { responsive } from '@next-components/common-styles';
import styled from 'styled-components';

export const DashboardStyled = styled.div`
  max-width: calc(100% - 6rem);
  margin: 0 auto;
  min-height: 100vh;

  ${responsive.mdMax} {
    max-width: calc(100% - 4rem);
  }
`;
