import styled from 'styled-components';
import { bosBrand, bosColor, black, stateInactive, typography } from '@next-components/common-styles';

export const SalesHeaderWrapper = styled.div`
  height: 6rem;
  transition: max-height 0.3s ease-in-out 0s;

  &.profile-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem;
    background-color: ${bosBrand};
    position: sticky;
    top: 0;
    z-index: 1;

    .profile-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4.5rem;
      color: ${bosColor.white};

      @media only screen and (max-width: 1370px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.75rem;
        div > {
          width: fit-content;
        }
      }
      @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.75rem;
        div > {
          width: fit-content;
        }
      }
      > div {
        display: flex;
        justify-content: start;
        gap: 0.5rem;
        align-items: center;

        &:first-child {
          gap: 0.75rem;
        }
      }
    }

    .profile-right {
      label {
        font-weight: 700;
        margin-left: 0;
        cursor: pointer;
      }
      button {
        border: 1px solid ${bosColor.white};
        color: ${bosColor.white};
        padding: 0.75rem 1rem;
        gap: 0.5rem;
        svg {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
  .next-modal {
    color: ${black};
    header .popup-close {
      margin-right: 0;
    }
  }
  .bill-details {
    position: absolute;
    right: 22rem;
    cursor: pointer;
    .tradeInLabel {
      color: ${bosColor.white};
    }
  }
  .highlight-text {
    color: ${bosColor.white};
  }
`;

export const OnScroll = styled.div`
  width: 100%;
`;

export const TradeInHeaderStyled = styled.div`
  .header {
    padding-bottom: 15px;
    font-size: 1.375rem;
    font-weight: bold;
  }
`;

export const ButtonStyled = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0;

  @media only screen and (max-width: 1370px) {
    flex-direction: column;
  }
  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
    flex-direction: column;
  }
  &.customerDetailModal {
    padding: 0 1.5rem 1.5rem;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 0.75rem 1rem;
    font-weight: ${typography.weight.extrabold};
    &.red {
      gap: 0.5rem;
      span {
        color: ${bosColor.black};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.extrabold};
        line-height: 175%;
        margin: 0;
      }
    }
  }
  .custom-button {
    font-size: 1rem;
    font-weight: 700;
    background-color: ${bosColor.turquoise};
    color: ${black};

    span {
      font-size: 1rem;
      font-weight: 700;
    }
    &:disabled {
      border-color: ${stateInactive};
      opacity: 0.7;
      background-color: ${stateInactive};
    }
    padding: 1.25rem 1.25rem;
  }

  .outlined {
    border: 2px solid ${bosColor.turquoise};
    width: 110px;
  }
  .cancel-button {
    font-size: 1rem;
    font-weight: 700;
  }
  .cancel-button-font .text {
    font-size: 1rem;
    font-weight: 700;
  }
`;
