import { ERROR_POLICY } from '../../../common';
import { useQuery } from '@apollo/client';
import { queryOverviewDetails } from '../../../graphql';

export interface IOverviewDetailsParams {
  dealerId: string;
  fromInvoiceDate?: string;
  toInvoiceDate?: string;
  departmentNumber?: string;
}

export const useOverviewDetails = (props: IOverviewDetailsParams) => {
  const { data, loading } = useQuery(queryOverviewDetails, {
    variables: {
      dealerId: props.dealerId,
      fromInvoiceDate: props.fromInvoiceDate,
      toInvoiceDate: props.toInvoiceDate,
      departmentNumber: props.departmentNumber,
    },
    skip: !props.dealerId,
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });

  return { data, loading };
};
