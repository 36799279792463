import React, { useState } from 'react';
import { DynamicListMer, IDynamicListMerTableData } from '../../common-components/DynamicListMer';
import { IDynamicListConfig } from '@next-components/dynamic-list';
import { changeDelimeter } from '../../../utils';

const externalFormatter = (value: any, vehicleListData: IDynamicListMerTableData) => {
  return (
    <div>
      {value?.map((val, index) => (
        <span
          style={{
            color: vehicleListData?.unAvailableSkus?.includes(val?.sku) ? 'red' : val?.isSelected ? 'black' : 'grey',
          }}>
          {val?.sku} {value?.length !== index + 1 && ','}{' '}
        </span>
      ))}
    </div>
  );
};

const VehicleMerListdata: IDynamicListMerTableData[] = [
  {
    availabilityCode: '817-LA_AL',
    brand: 'MER',
    colorDesc: 'Night black',
    colorId: '696',
    interiorCode: '105',
    isDisable: false,
    location: 'MB Personbil',
    locationId: '817',
    locationName: 'Importer',
    modelDesc: 'EQA 250',
    modelId: 'EQA250',
    modelSeries: 'EQA',
    noCode: '',
    optionCode:
      changeDelimeter('1-01U-0B2-0S3-105-132-13B-13U-14U-16U-17U-1B3-1U3-233-235-242-249-266-270-273-275-286-287-294-2U1-2U8-310-345-351-355-362-365-367-39U-3U6-400-401-409-428-446-458-475-500-501-502-513-51B-51U-537-543-546-548-581-5V4-608-632-63B-666-677-696-6P5-6S8-70B-720-73B-79B-7B4-7U2-803-82B-83B-840-853-859-871-875-877-889-890-897-8B6-8U6-8U8-942-968-986-998-9B6-9U8-B00-B13-B51-B53-B59-BAE-DA2-DBD-EMD-H23-K13-K33-K34-L3E-P17-P47-P49-P55-P59-PAY-PBF-PBO-PDB-PXV-R01-R31-R7H-U01-U10-U12-U35-U55-U59-U60-U62'),
    packageSku: 'DA2',
    priceOptions: changeDelimeter('P55-DA2-DBD'),
    productionDate: '30-SEP-22',
    serialNumber: 202265374,
    status: 'FREE',
    trackTraceCode: 1,
    transferFrom: '',
    transferRequest: false,
    transferTo: '',
    unAvailableSkus: ['950'],
    varnish: [],
    vinNumber: '1254545454545',
    exteriorSelectable: [
      {
        category: 'Exterior',
        id: '63387047521f7bd31b36e8ff',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 0,
        name: 'AMG Line',
        priceExcludingVat: 31395,
        priceIncludingVat: 31395,
        sku: '950',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
      {
        category: 'Exterior',
        id: '63387047521f7bd31b36e8fA',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 0,
        name: 'AMG Line',
        priceExcludingVat: 31395,
        priceIncludingVat: 31395,
        sku: '965',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
    ],
    interiorSelectable: [
      {
        category: 'Interior',
        id: '633871a1521f7bd31b36e956',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 7,
        name: 'Baksetepakke',
        priceExcludingVat: 6615,
        priceIncludingVat: 6152,
        sku: 'DX1',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
      {
        category: 'Interior',
        id: '633871a1521f7bd31b36e957',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 7,
        name: 'Baksetepakke',
        priceExcludingVat: 6615,
        priceIncludingVat: 6152,
        sku: 'DA1',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
    ],
    interior: [
      {
        name: 'Luksuspakke beige - DBD',
        priceExcludingVat: 19635,
        priceIncludingVat: 19635,
        sku: 'DBD',
        type: 'interior',
      },
    ],
    rims: [
      {
        name: 'RWI',
        sku: 'RWI',
        priceIncludingVat: 19855,
        priceExcludingVat: 19855,
        type: 'rims',
      },
    ],
    price: '178596 kr',
    disabled: false,
  },
  {
    availabilityCode: '817-LA_AL',
    brand: 'MER',
    colorDesc: 'Apple color',
    colorId: '696',
    interiorCode: '105',
    isDisable: false,
    location: 'MB Personbil',
    locationId: '817',
    locationName: 'Importer',
    modelDesc: 'EQA 250',
    modelId: 'EQA250',
    modelSeries: 'EQA',
    noCode: '',
    optionCode:
      changeDelimeter('1-01U-0B2-0S3-105-132-13B-13U-14U-16U-17U-1B3-1U3-233-235-242-249-266-270-273-275-286-287-294-2U1-2U8-310-345-351-355-362-365-367-39U-3U6-400-401-409-428-446-458-475-500-501-502-513-51B-51U-537-543-546-548-581-5V4-608-632-63B-666-677-696-6P5-6S8-70B-720-73B-79B-7B4-7U2-803-82B-83B-840-853-859-871-875-877-889-890-897-8B6-8U6-8U8-942-968-986-998-9B6-9U8-B00-B13-B51-B53-B59-BAE-DA2-DBD-EMD-H23-K13-K33-K34-L3E-P17-P47-P49-P55-P59-PAY-PBF-PBO-PDB-PXV-R01-R31-R7H-U01-U10-U12-U35-U55-U59-U60-U62'),
    packageSku: 'DA2',
    priceOptions: changeDelimeter('P55-DA2-DBD'),
    productionDate: '30-SEP-22',
    serialNumber: 202265376,
    status: 'FREE',
    trackTraceCode: 1,
    transferFrom: '',
    transferRequest: false,
    transferTo: '',
    unAvailableSkus: ['960'],
    varnish: [],
    vinNumber: '',
    exteriorSelectable: [
      {
        category: 'Exterior',
        id: '63387047521f7bd31b36e8ff',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 0,
        name: 'AMG Line',
        priceExcludingVat: 31395,
        priceIncludingVat: 31395,
        sku: '950',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
      {
        category: 'Exterior',
        id: '63387047521f7bd31b36e8fA',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 0,
        name: 'AMG Line',
        priceExcludingVat: 31395,
        priceIncludingVat: 31395,
        sku: '960',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
    ],
    interiorSelectable: [
      {
        category: 'Interior',
        id: '633871a1521f7bd31b36e956',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 7,
        name: 'Baksetepakke',
        priceExcludingVat: 6615,
        priceIncludingVat: 6152,
        sku: 'DX1',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
      {
        category: 'Interior',
        id: '633871a1521f7bd31b36e957',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 7,
        name: 'Baksetepakke',
        priceExcludingVat: 6615,
        priceIncludingVat: 6152,
        sku: 'DA1',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
    ],
    interior: [
      {
        name: 'Luksuspakke beige - DBD',
        priceExcludingVat: 19635,
        priceIncludingVat: 19635,
        sku: 'DBD',
        type: 'interior',
      },
    ],
    rims: [
      {
        name: 'RWI',
        sku: 'RWI',
        priceIncludingVat: 19855,
        priceExcludingVat: 19855,
        type: 'rims',
      },
    ],
    price: '15000 kr',
    disabled: true,
  },
  {
    availabilityCode: '817-LA_AL',
    brand: 'MER',
    colorDesc: 'Brown color',
    colorId: '696',
    interiorCode: '105',
    isDisable: false,
    location: 'MB Personbil',
    locationId: '817',
    locationName: 'Importer',
    modelDesc: 'EQA 250',
    modelId: 'EQA250',
    modelSeries: 'EQA',
    noCode: '',
    optionCode:
      changeDelimeter('1-01U-0B2-0S3-105-132-13B-13U-14U-16U-17U-1B3-1U3-233-235-242-249-266-270-273-275-286-287-294-2U1-2U8-310-345-351-355-362-365-367-39U-3U6-400-401-409-428-446-458-475-500-501-502-513-51B-51U-537-543-546-548-581-5V4-608-632-63B-666-677-696-6P5-6S8-70B-720-73B-79B-7B4-7U2-803-82B-83B-840-853-859-871-875-877-889-890-897-8B6-8U6-8U8-942-968-986-998-9B6-9U8-B00-B13-B51-B53-B59-BAE-DA2-DBD-EMD-H23-K13-K33-K34-L3E-P17-P47-P49-P55-P59-PAY-PBF-PBO-PDB-PXV-R01-R31-R7H-U01-U10-U12-U35-U55-U59-U60-U62'),
    packageSku: 'DA2',
    priceOptions: changeDelimeter('P55-DA2-DBD'),
    productionDate: '30-SEP-22',
    serialNumber: 202265377,
    status: 'FREE',
    trackTraceCode: 1,
    transferFrom: '',
    transferRequest: false,
    transferTo: '',
    unAvailableSkus: ['960'],
    varnish: [],
    vinNumber: '',
    exteriorSelectable: [
      {
        category: 'Exterior',
        id: '63387047521f7bd31b36e8ff',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 0,
        name: 'AMG Line',
        priceExcludingVat: 31395,
        priceIncludingVat: 31395,
        sku: '856',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
      {
        category: 'Exterior',
        id: '63387047521f7bd31b36e8fA',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 0,
        name: 'AMG Line',
        priceExcludingVat: 31395,
        priceIncludingVat: 31395,
        sku: '855',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
    ],
    interiorSelectable: [
      {
        category: 'Interior',
        id: '633871a1521f7bd31b36e956',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 7,
        name: 'Baksetepakke',
        priceExcludingVat: 6615,
        priceIncludingVat: 6152,
        sku: 'DAA',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
      {
        category: 'Interior',
        id: '633871a1521f7bd31b36e957',
        isHighlight: false,
        marginAmount: 0,
        marginPercentage: 7,
        name: 'Baksetepakke',
        priceExcludingVat: 6615,
        priceIncludingVat: 6152,
        sku: 'DAB',
        type: 'selectable',
        unavailableForFactoryOrder: false,
      },
    ],
    interior: [
      {
        name: 'Luksuspakke beige - DBD',
        priceExcludingVat: 19635,
        priceIncludingVat: 19635,
        sku: 'AAD',
        type: 'interior',
      },
    ],
    rims: [
      {
        name: 'RWI',
        sku: 'QWE',
        priceIncludingVat: 19855,
        priceExcludingVat: 19855,
        type: 'rims',
      },
    ],
    price: '14458 kr',
    disabled: false,
  },
];

const headerConfig: IDynamicListConfig = {
  cols: [
    { header: 'Farge', key: 'colorDesc', colWidth: ' 10%', noSort: false },
    { header: 'Tillegg', key: 'exteriorSelectable', colWidth: ' 8%', formatter: externalFormatter, noSort: false },
    { header: 'Felger', key: 'rims', colWidth: '6%', formatter: externalFormatter },

    { header: 'Interiør', key: 'interiorCode', colWidth: '6%' },
    { header: 'Tillegg', key: 'interiorSelectable', colWidth: '12%', formatter: externalFormatter },

    { header: 'SerialNo', key: 'serialNumber', colWidth: '10%', noSort: false },
    { header: 'prod.Data', key: 'productionDate', colWidth: '7%' },
    { header: 'TT', key: 'trackTraceCode', colWidth: '5%' },
    { header: 'Sted', key: 'status', colWidth: '5%' },
    { header: 'price', key: 'price', colWidth: '1fr' },
  ],
  withCheckbox: true,
  noHeader: false,
  selectable: true,
  withAccordion: true,
  maxSelected: 1,
};

const TestDynamicListMer = () => {
  const defaultPagination = {
    page: 1,
    itemsPerPage: 2,
    totalItems: VehicleMerListdata.length,
  };
  const [pagination, setPagination] = useState({ ...defaultPagination, totalItems: VehicleMerListdata?.length });

  const onLoadMore = async () => {
    // Simulate 2 second loading time
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await setPagination({ ...pagination, page: ++pagination.page });
  };

  return (
    <>
      <h1> Dynamic List </h1>
      <DynamicListMer
        pagination={pagination}
        showContentCount={true}
        onLoadMore={onLoadMore}
        isNewDynamicList={true}
        config={headerConfig}
        data={VehicleMerListdata}
        selectedRowsKey={'serialNumber'}
        detailsCols={[
          { header: '', key: '', colWidth: ' 1.5rem' },
          { header: 'Chassisnummer', key: 'vinNumber', colWidth: ' minmax(auto, 9%)' },
          { header: 'Lokasjon', key: 'locationId', colWidth: ' minmax(auto,13.75%)' },
          { header: 'PriceOptions', key: 'priceOptions', colWidth: ' minmax(auto, 17.25%)' },
          { header: 'Options', key: 'optionCode', colWidth: ' minmax(auto, 1fr)' },
          { header: '', key: '', colWidth: ' auto' },
        ]}
      />
    </>
  );
};

export default TestDynamicListMer;
