import React, { useState } from 'react';
import { UserSettingsInfo, UserSettingsList } from './UserSettings.styled';
import Scrollbar from 'react-scrollbars-custom';
import { IApiEmployee } from 'next-common';
import { DealerSelect } from './SubMenus';
import { SettingsFooter } from './SettingsFooter';
import { SettingsHeader } from './SettingsHeader';
import { IUserSubMenu, UserSubMenu, userSubMenus } from './UserSubMenus';
import { SettingsItem } from './SettingsItem';

export interface IUserSettingsProps {
  employee: IApiEmployee;

  setModal(value: boolean): void;
}

export const UserSettings = (props: IUserSettingsProps) => {
  const [selected, setSelected] = useState<IUserSubMenu>();

  return (
    <UserSettingsInfo>
      <div className="user-profile-popup">
        <SettingsHeader selected={selected} onClose={() => props.setModal(false)} onReset={() => setSelected(null)} />
        <Scrollbar className="user-profile-body">
          <div className="user-body-content">
            {!selected && (
              <UserSettingsList>
                {userSubMenus.map((item) => (
                  <SettingsItem key={item.key} item={item} onSelect={() => setSelected(item)} />
                ))}
              </UserSettingsList>
            )}
            {selected?.key === UserSubMenu.DEALERS && (
              <DealerSelect employee={props.employee} onSelect={() => setSelected(null)} />
            )}
          </div>
        </Scrollbar>
        <SettingsFooter employee={props.employee} />
      </div>
    </UserSettingsInfo>
  );
};
