import React from 'react';
import { LogOut } from './LogOut';
import { IApiEmployee, IBosApiDealerInfoData } from 'next-common';
import { SettingsFooterStyled, SettingsFooterInfoStyled } from './SettingsFooter.styled';
import { useReactiveVar } from '@apollo/client';
import { selectedDealerId, useDealer } from '../../../../../common';
import { Icon, IconType } from '@next-components/bos-icon';

export interface ISettingsFooterProps {
  employee: IApiEmployee;
}

const SettingFooterInfo = (props) => (
  <SettingsFooterInfoStyled>
    <div className="icon">
      <Icon icon={props.icon} viewBox={props.icon === 'user' ? '0 0 48 48' : '0 0 24 24'} aria-hidden />
    </div>
    <div className="text">{props.text}</div>
  </SettingsFooterInfoStyled>
);

const formatDealerName = (dealer: IBosApiDealerInfoData) => {
  if (!dealer) return 'Ukjent forhandler';
  return dealer.name.replace(/bertel o\.? steen/gi, 'BOS');
};

export const SettingsFooter = (props: ISettingsFooterProps) => {
  const employeeIdent = props.employee?.employeeId;
  const employeeEmail = props.employee?.email;

  const dealerId = useReactiveVar<string>(selectedDealerId);
  const { dealer }: { dealer: IBosApiDealerInfoData } = useDealer({ dealerId });

  return (
    <SettingsFooterStyled>
      <SettingFooterInfo icon={IconType.NewNextEMail} text={employeeEmail?.toLowerCase()} />
      <SettingFooterInfo icon={IconType.NewNextUser} text={employeeIdent?.toLowerCase() ?? 'Ukjent ident'} />
      <SettingFooterInfo icon={IconType.Shop} text={formatDealerName(dealer)} />
      <div className="user-profile-logout">
        <LogOut />
      </div>
    </SettingsFooterStyled>
  );
};
