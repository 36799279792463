import React, { useEffect, useState } from 'react';
import { OverviewListStyled } from './OverviewList.styled';
import 'react-datepicker/dist/react-datepicker.css';
import { DynamicList, IDynamicListColumn, IDynamicListConfig, IDynamicListSort } from '@next-components/dynamic-list';
import { calculateSum } from '../../../../../../utils';
import { IBosApiVehicleMarginCalculationDetails } from 'next-common';
import { formatDate, formatNumber } from '@next-components/common-types';
import { makeIcon } from '../../../../../dashboard/my-overview-page/MyOrders/MyOrders.formatters';
import {
  alignmentFormatter,
  commaFormatter,
  ellipsisTextFormatter,
  emptyFormatter,
  hyperlinkFormatter,
  marginsColumnFormatter,
} from './OverviewList.formatters';

interface IOverviewListProps {
  data: IBosApiVehicleMarginCalculationDetails[];
  loading: boolean;
  filterHidden: boolean;
  onElementClick(element): void;
}

const myOverviewSort: IDynamicListSort[] = [{ key: 'invoiceDate', reverse: true }];

const getMarginPercentage = (marginActualTotal, salesPriceTotal) => {
  if (!salesPriceTotal || salesPriceTotal === 0) {
    return '0,0';
  }
  return `${formatNumber((marginActualTotal / salesPriceTotal) * 100 || 0, 1)} %`;
};

export const OverviewList = (props: IOverviewListProps) => {
  const [data, setData] = useState(props?.data || []);
  useEffect(() => {
    if(props?.data){
      setData(props?.data);
    }
  }, [props?.data]);
  
  const overviewTableConfig: IDynamicListColumn[] = [
    { header: 'Fakturadato', key: 'invoiceDate', formatter: formatDate },
    { header: 'Kundeordre', key: 'orderNumber', formatter: hyperlinkFormatter },
    { header: 'Selger', key: 'salesPerson' },
    { header: 'Avdelingnr', key: 'departmentNo' },
    { header: 'Merke', key: 'make', colWidth: '5rem', formatter: makeIcon },
    { header: 'Modell', key: 'model', formatter: ellipsisTextFormatter },
    { header: 'Kundenr', key: 'customerNo' },
    { header: 'Kunde', key: 'customerName', formatter: ellipsisTextFormatter },
    { header: 'Serienr', key: 'serialNo', formatter: alignmentFormatter, alignment: 'right' },
    { header: 'Salg', key: 'netSalesPrice', formatter: marginsColumnFormatter },
    { header: 'Innkrevd Påkost', key: 'additionalSalesPrice', formatter: marginsColumnFormatter },
    { header: 'Påkost', key: 'otherCharges', formatter: marginsColumnFormatter, alignment: 'right' },
    { header: 'Margin %', key: 'marginPercentage', formatter: commaFormatter, alignment: 'right' },
    { header: 'Margin kr', key: 'marginActual', formatter: marginsColumnFormatter, alignment: 'right' },
    { header: 'Kalkyle', key: 'marginBudget', formatter: marginsColumnFormatter, alignment: 'right' },
    { header: 'DIFF', key: 'marginDifference', formatter: marginsColumnFormatter, alignment: 'right' },
  ];

  const marginActualTotal = calculateSum(data, 'marginActual').toFixed(2);
  const salesPriceTotal = calculateSum(data, 'netSalesPrice').toFixed(2);

  const summedValues = {
    marginActualTotal: marginActualTotal,
    salesPriceTotal: salesPriceTotal,
    purchasePriceTotal: calculateSum(data, 'purchasePrice').toFixed(2),
    importerDiscountTotal: calculateSum(data, 'importerDiscount').toFixed(2),
    otherChargesTotal: calculateSum(data, 'otherCharges').toFixed(2),
    marginBudgetTotal: calculateSum(data, 'marginBudget').toFixed(2),
    marginDifferenceTotal: calculateSum(data, 'marginDifference').toFixed(2),
    marginPercentage: getMarginPercentage(+marginActualTotal, +salesPriceTotal),
  };
  const [config] = useState<IDynamicListConfig>({
    cols: [...overviewTableConfig],
  });

  return (
    <OverviewListStyled>
      <div>
        <div style={{ width: '100%' }}>
          <DynamicList
            data={data}
            sort={myOverviewSort}
            config={config}
            onElementClick={props.onElementClick}
            emptyFormatter={props.loading && emptyFormatter}
          />
        </div>
        {!props.filterHidden && (
          <div className="grid-footer">
            <div>SUM ANTALL: {data.length}</div>
            <div>{marginsColumnFormatter(summedValues.salesPriceTotal)}</div>
            <div />
            <div>{marginsColumnFormatter(summedValues.otherChargesTotal)}</div>
            <div>{alignmentFormatter(summedValues.marginPercentage)}</div>
            <div>{marginsColumnFormatter(summedValues.marginActualTotal)}</div>
            <div>{marginsColumnFormatter(summedValues.marginBudgetTotal)}</div>
            <div>{marginsColumnFormatter(summedValues.marginDifferenceTotal)}</div>
          </div>
        )}
      </div>
    </OverviewListStyled>
  );
};
