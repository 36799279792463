import { bosColor } from '@next-components/common-styles';
import { IOrder, IReserveDetails, VehicleReserveStatus } from 'next-common';
import { ReserveButtonStages } from '@next-components/cart';

const blockingStatuses = [
  VehicleReserveStatus[VehicleReserveStatus.RESERVED],
  VehicleReserveStatus[VehicleReserveStatus.CANCELLATION_FAIL],
  VehicleReserveStatus[VehicleReserveStatus.WAITING],
  VehicleReserveStatus[VehicleReserveStatus.WAITING_CANCELLATION],
];

const blockingResumingStatuses = [
  VehicleReserveStatus[VehicleReserveStatus.WAITING],
  VehicleReserveStatus[VehicleReserveStatus.WAITING_CANCELLATION],
];

const carIsReservedStatuses = [
  VehicleReserveStatus[VehicleReserveStatus.RESERVED],
  VehicleReserveStatus[VehicleReserveStatus.CANCELLATION_FAIL],
];

export const hasReservationBlockingCancellation = (details: IReserveDetails): boolean => {
  if (!details?.vehicleReserveStatus) return false;
  return blockingStatuses?.includes(details?.vehicleReserveStatus?.toString());
};

export const hasReservationWaitingState = (details: IReserveDetails): boolean => {
  if (!details?.vehicleReserveStatus) return false;
  return blockingResumingStatuses?.includes(details?.vehicleReserveStatus?.toString());
};

export const carIsReservedState = (details: IReserveDetails): boolean => {
  if (!details?.vehicleReserveStatus) return false;
  if (details?.quotationNumber) {
    return carIsReservedStatuses?.includes(details?.vehicleReserveStatus?.toString());
  } else {
    return false;
  }
};

export const setCarReservedState = (order: IOrder): ReserveButtonStages => {
  const isWaiting = hasReservationWaitingState(order?.reserveDetails) || !order.customer?.reference?.bosApiId;
  const selected = carIsReservedState(order?.reserveDetails) || !!order?.reserveDetails?.quotationNumber;
  return isWaiting
    ? ReserveButtonStages.WAITING
    : selected
    ? ReserveButtonStages.RESERVED
    : ReserveButtonStages.NO_RESERVE_REQUEST;
};

export const reservationStatus = [
  {
    key: 'WAITING',
    // disabled,
    color: bosColor.oker20,
    borderColor: bosColor.neutral,
    label: 'Venter',
  },
  {
    key: 'RESERVED',
    // neutral,
    color: bosColor.oker20,
    borderColor: bosColor.neutral,
    label: 'Reservert',
  },
  {
    key: 'RESERVATION_FAIL',
    // negative,
    color: bosColor.poppy20,
    borderColor: bosColor.poppy60,
    label: 'Feilet',
  },
  {
    key: 'WAITING_CANCELLATION',
    // disabled,
    color: bosColor.oker20,
    borderColor: bosColor.neutral,
    label: 'Venter avbest',
  },
  {
    key: 'CANCELLATION_FAIL',
    // negative,
    color: bosColor.poppy20,
    borderColor: bosColor.poppy60,
    label: 'Feilet',
  },
  {
    key: 'RESERVATION_CANCELED',
    // disabled,
    color: bosColor.poppy20,
    borderColor: bosColor.poppy60,
    label: 'Kansellert',
  },
];
