import React, { useState, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOverviewDetails, IOverviewDetailsParams, useEmployee, selectedDealerId } from '../../../../../common';
import { getEndOfMonth, getStartOfMonth, compareDates } from '../../../../../utils';
import { OverviewTableStyled } from './OverviewTableStyled.styled';
import { IFilterValues, IFilterBarProps } from 'next-common';
import { IDropdownOption } from '@next-components/common-types';
import { format } from 'date-fns';
import { FilterBarMargins } from './../FilterBarMargins';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { primary } from '@next-components/common-styles';
import { OverviewList } from './OverviewList';
import { DatePickerFilterBar } from '../DatePickerFilterBar';
import { useReactiveVar } from '@apollo/client';
import get from 'lodash/get';
import { Button } from '@next-components/cta';
import {
  resetAllSyncFiltersAction,
  resetDepartmentNumberFiltersAction,
  resetMakeFiltersAction,
  resetModelFiltersAction,
  resetMonthFiltersAction,
  resetSalesPersonFiltersAction,
  resetSerialNumberFiltersAction,
  setAllSyncFiltersAction,
  setDepartmentNumberFiltersAction,
  toggleHideWithoutSalesFiltersAction,
  setMakeFiltersAction,
  setModelFiltersAction,
  setMonthFiltersAction,
  setSalesPersonFiltersAction,
  setSerialNumberFiltersAction,
  marginsReducer,
  initialMarginsFiltersState,
} from './OverviewState';

export const OverviewTable = () => {
  const endOfMonth = getEndOfMonth(new Date());
  const startOfMonth = getStartOfMonth(new Date());
  const [dealerId, setDealerId] = useState('');
  const [startDate, setStartDate] = useState<Date>(startOfMonth);
  const [endDate, setEndDate] = useState<Date>(endOfMonth);
  const location = useLocation();
  const navigate = useNavigate();

  const employee = useEmployee();

  const cachedDealerId = useReactiveVar<string>(selectedDealerId);
  useEffect(() => {
    const chosenDealerId = cachedDealerId ?? employee?.departmentNumber;
    setDealerId(chosenDealerId);
  }, [cachedDealerId, employee]);
  const overviewParams: IOverviewDetailsParams = {
    dealerId: dealerId,
    fromInvoiceDate: format(new Date(startDate), 'yyyy-MM-dd'),
    toInvoiceDate: format(new Date(endDate), 'yyyy-MM-dd'),
    departmentNumber: '',
  };

  const { data: fetchedOverviewDetails, loading: loadingOverviewDetails } = useOverviewDetails(overviewParams);

  const [syncFilterState, dispatch] = useReducer(marginsReducer, initialMarginsFiltersState);

  useEffect(() => {
    if (get(location, 'state.filterValues') && get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget')) {
      // for the first api response after routing back from margin-details, to restore the last filters.

      const cachedFilters: IFilterValues = get(location, 'state.filterValues', {});
      dispatch(
        setAllSyncFiltersAction({
          fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
          monthsSelectedFilters: get(cachedFilters, 'selectedMonths', [] as IDropdownOption<string>[]),
          makesSelectedFilters: get(cachedFilters, 'selectedMakes', [] as IDropdownOption<string>[]),
          modelsSelectedFilters: get(cachedFilters, 'selectedModels', [] as IDropdownOption<string>[]),
          serialNumbersSelectedFilters: get(cachedFilters, 'selectedSerialNumbers', [] as IDropdownOption<number>[]),
          departmentNumbersSelectedFilters: get(
            cachedFilters,
            'selectedDepartmentNumbers',
            [] as IDropdownOption<number>[],
          ),
          salesPersonsSelectedFilters: get(cachedFilters, 'selectedSalesPersons', [] as IDropdownOption<string>[]),
          hideWithoutSales: get(cachedFilters, 'isSelectedHideWithoutSales', false),
        }),
      );

      resetLocation();
    } else {
      dispatch(
        resetAllSyncFiltersAction({
          fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
        }),
      );
    }
  }, [get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget')]);

  const onMonthChange = (visibleSelectedFilters: IDropdownOption<string>[]) => {
    get(visibleSelectedFilters, 'length', 0)
      ? dispatch(
          setMonthFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
            visibleSelectedFilters,
          }),
        )
      : dispatch(
          resetMonthFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
          }),
        );
  };

  const onMakeChange = (visibleSelectedFilters: IDropdownOption<string>[]) => {
    get(visibleSelectedFilters, 'length', 0)
      ? dispatch(
          setMakeFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
            visibleSelectedFilters,
          }),
        )
      : dispatch(
          resetMakeFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
          }),
        );
  };

  const onserialNoChange = (visibleSelectedFilters: IDropdownOption<number>[]) => {
    get(visibleSelectedFilters, 'length', 0)
      ? dispatch(
          setSerialNumberFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
            visibleSelectedFilters,
          }),
        )
      : dispatch(
          resetSerialNumberFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
          }),
        );
  };

  const ondeptNoChange = (visibleSelectedFilters: IDropdownOption<number>[]) => {
    get(visibleSelectedFilters, 'length', 0)
      ? dispatch(
          setDepartmentNumberFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
            visibleSelectedFilters,
          }),
        )
      : dispatch(
          resetDepartmentNumberFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
          }),
        );
  };

  const onModelChange = (visibleSelectedFilters: IDropdownOption<string>[]) => {
    get(visibleSelectedFilters, 'length', 0)
      ? dispatch(
          setModelFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
            visibleSelectedFilters,
          }),
        )
      : dispatch(
          resetModelFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
          }),
        );
  };

  const onsalesPersonChange = (visibleSelectedFilters: IDropdownOption<string>[]) => {
    get(visibleSelectedFilters, 'length', 0)
      ? dispatch(
          setSalesPersonFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
            visibleSelectedFilters,
          }),
        )
      : dispatch(
          resetSalesPersonFiltersAction({
            fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
          }),
        );
  };

  const onSalesChange = (hideWithoutSales: boolean) => {
    dispatch(
      toggleHideWithoutSalesFiltersAction({
        hideWithoutSales,
        fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
      }),
    );
  };

  // set date filtervalues
  useEffect(() => {
    if (get(location, 'state.filterValues.startDate')) {
      setStartDate(get(location, 'state.filterValues.startDate'));
    } else {
      setStartDate(startOfMonth);
    }
    if (get(location, 'state.filterValues.endDate')) {
      setEndDate(get(location, 'state.filterValues.endDate'));
    } else {
      setEndDate(endOfMonth);
    }
  }, []);

  // Reset history and filters
  const resetLocation = () => {
    if (location?.state?.filterValues) {
      location.state.filterValues = null;
    }
  };

  // Reset everything on button click
  const resetData = () => {
    setStartDate(startOfMonth);
    setEndDate(endOfMonth);
    dispatch(
      resetAllSyncFiltersAction({
        fetchedMargins: get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget', []),
      }),
    );
    navigate(
      { pathname: '', search: '' },
      {
        state: '',
      },
    );
    resetLocation();
  };

  const filterVal: IFilterValues = {
    startDate: startDate,
    endDate: endDate,
    selectedMakes: get(syncFilterState, 'makes.selectedFilters'),
    selectedModels: get(syncFilterState, 'models.selectedFilters'),
    selectedSerialNumbers: get(syncFilterState, 'serialNumbers.selectedFilters'),
    selectedDepartmentNumbers: get(syncFilterState, 'departmentNumbers.selectedFilters'),
    selectedSalesPersons: get(syncFilterState, 'salesPersons.selectedFilters'),
    isSelectedHideWithoutSales: get(syncFilterState, 'hideWithoutSales'),
    selectedMonths: get(syncFilterState, 'months.selectedFilters'),
  };

  const forwardPage = (page, params, data) => {
    navigate(
      { pathname: page, search: new URLSearchParams(params).toString() },
      {
        state: {
          detail: data,
          filterValues: filterVal,
        },
      },
    );
  };

  const dateErrorMessage = (startDateValue, endDateValue) => {
    alert(
      `Startdato(${format(new Date(startDateValue), 'MM-yyyy')}) er større enn sluttdato (${format(
        new Date(endDateValue),
        'MM-yyyy',
      )})`,
    );
  };

  const setStartDateValue = (dateValue) => {
    if (compareDates(getStartOfMonth(dateValue), endDate)) {
      resetLocation();
      setStartDate(getStartOfMonth(dateValue));
    } else {
      dateErrorMessage(dateValue, endDate);
    }
  };
  const setEndDateValue = (dateValue) => {
    if (compareDates(startDate, getEndOfMonth(dateValue))) {
      resetLocation();
      setEndDate(getEndOfMonth(dateValue));
    } else {
      dateErrorMessage(startDate, dateValue);
    }
  };

  const filterBarProps: IFilterBarProps = {
    selected: {
      selectedMonths: get(syncFilterState, 'months.visibleSelectedFilters'),
      selectedMakes: get(syncFilterState, 'makes.visibleSelectedFilters'),
      selectedModels: get(syncFilterState, 'models.visibleSelectedFilters'),
      selectedSerialNumbers: get(syncFilterState, 'serialNumbers.visibleSelectedFilters'),
      selectedDepartmentNumbers: get(syncFilterState, 'departmentNumbers.visibleSelectedFilters'),
      selectedSalesPersons: get(syncFilterState, 'salesPersons.visibleSelectedFilters'),
      isSelectedHideWithoutSales: get(syncFilterState, 'hideWithoutSales'),
    },
    onChange: {
      onMonthChange: onMonthChange,
      onMakeChange: onMakeChange,
      onModelChange: onModelChange,
      onSerialNumberChange: onserialNoChange,
      onDepartmentNumberChange: ondeptNoChange,
      onSalesPersonChange: onsalesPersonChange,
      onHideWithoutSalesChange: onSalesChange,
    },
    options: {
      monthOptions: get(syncFilterState, 'months.availableOptions'),
      makeOptions: get(syncFilterState, 'makes.availableOptions'),
      modelOptions: get(syncFilterState, 'models.availableOptions'),
      serialNumberOptions: get(syncFilterState, 'serialNumbers.availableOptions'),
      departmentNumberOptions: get(syncFilterState, 'departmentNumbers.availableOptions'),
      salesPersonOptions: get(syncFilterState, 'salesPersons.availableOptions'),
    },
  };

  const onElementClick = (item) => {
    forwardPage(
      '/marginer/detaljer',
      {
        serialNo: item.serialNo,
        orderNo: item.orderNumber,
        dealerId: dealerId,
      },
      item,
    );
  };

  const onFromDateChange = (date, e) => {
    e.preventDefault();
    setStartDateValue(date);
  };

  const onToDateChange = (date, e) => {
    e.preventDefault();
    setEndDateValue(date);
  };

  const filterHidden = !get(fetchedOverviewDetails, 'getVehicleMarginCalculationBudget.length', 0);

  return (
    <OverviewTableStyled>
      <div className="filter-container header-container">
        <h1>Oversikt, Forhandler</h1>
        <div className="headerText">Regnskapsperiode</div>
        <div className="calendar-filter">
          <DatePickerFilterBar
            filterLabel={'Fra'}
            selectedDate={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onFromDateChange}
          />
          <DatePickerFilterBar
            filterLabel={'Til'}
            selectedDate={endDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onToDateChange}
          />
          <div className="reset-button">
            <Button onClick={resetData}>Nullstille</Button>
          </div>
        </div>
      </div>
      <div>
        {loadingOverviewDetails ? (
          <div className={'loading centered'}>
            <LoadingSpinner size={48} color={primary} />
          </div>
        ) : (
          <div>
            <div>
              <div className="filter-container">{!filterHidden && <FilterBarMargins {...filterBarProps} />}</div>
              <div>
                <OverviewList
                  data={get(syncFilterState, 'syncFilteredMargins')}
                  loading={loadingOverviewDetails}
                  filterHidden={filterHidden}
                  onElementClick={onElementClick}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </OverviewTableStyled>
  );
};
