export enum UserSubMenu {
  DEALERS = 'DEALERS',
}

export interface IUserSubMenu {
  key: UserSubMenu;
  title: string;
}

export const userSubMenus = [
  {
    key: UserSubMenu.DEALERS,
    title: 'Velg forhandler',
  },
];
