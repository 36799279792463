import { bosBrand, primary } from '@next-components/common-styles';
import styled from 'styled-components';

export const DateFilterInputStyled = styled.div`
  position: relative;
  input {
    border-radius: 25px;
    &::placeholder {
      color: ${bosBrand};
      font-size: 22px;
      font-weight: 600;
    }
  }
  .iconStyled {
    position: absolute;
    top: 15px;
    width: 23px;
    height: 21px;
    z-index: 1;
    right: 15px;
    path {
      fill: ${primary};
      stroke: ${primary};
    }
  }
`;

export const DateFilterContainerStyled = styled.div`
  flex: 0 0 auto;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  label {
    display: flex;
    align-items: center;
  }
  span {
    padding-right: 1rem;
  }
`;
