export const serialNumbersToExclude = [
  202111287, 202111288, 202111293, 202111318, 202111573, 202114372, 202114451, 202114989, 202132830, 202132920,
  202141557, 202141602, 202141981, 202142013, 202142022, 202142039, 202142098, 202143813, 202144330, 202147647,
  202150970, 202151027, 202151910, 202153074, 202154114, 202154228, 202154260, 202154450, 202156817, 202157553,
  202159985, 202160298, 202160501, 202160539, 202160558, 202160576, 202163117, 202164727, 202164803, 202164828,
  202166740, 202167974, 202168156, 202168157, 202168158, 202168161, 202168804, 202169973, 202171320, 202171347,
  202171583, 202174347, 202174355, 202174374, 202179373, 202200123, 202201856, 202202777, 202204547, 202204573,
  202204631, 202204648, 202204654, 202204777, 202204831, 202204851, 202205727, 202211235, 202211779, 202213743,
  202213745, 202213779, 202213781, 202214397, 202214982, 202215082, 202215108, 202215120, 202215122, 202215128,
  202215173, 202215334, 202215336, 202215373, 202215663, 202215690, 202215766, 202220316, 202220325, 202220339,
  202220465, 202220499, 202221047, 202221063, 202221097, 202221135, 202221144, 202221210, 202224853, 202224860,
  202224874, 202224876, 202224903, 202225063, 202225083, 202225098, 202225745, 202227042, 202227612, 202230280,
  202230281, 202232779, 202232833, 202232850, 202235532, 202235565, 202235651, 202235660, 202235675, 202235707,
  202235727, 202235733, 202235743, 202235754, 202235756, 202235759, 202235760, 202235762, 202235764, 202235767,
  202235769, 202235774, 202235776, 202235778, 202235780, 202235781, 202235782, 202235787, 202235792, 202235794,
  202235796, 202235797, 202235803, 202235810, 202235813, 202235814, 202235818, 202235820, 202235824, 202235833,
  202235836, 202235839, 202235840, 202235843, 202235845, 202237969, 202238052, 202238079, 202240141, 202240143,
  202240146, 202240148, 202240164, 202240193, 202240194, 202240197, 202240198, 202240200, 202240207, 202240208,
  202240210, 202240211, 202240215, 202240219, 202240237, 202240269, 202240325, 202240328, 202240351, 202240353,
  202240357, 202240361, 202240362, 202240363, 202240365, 202240393, 202240396, 202240409, 202240410, 202240425,
  202240426, 202240434, 202240436, 202240438, 202240442, 202240443, 202240445, 202240446, 202240447, 202240566,
  202247237, 202248819, 202249705, 202249763, 202249926, 202249931, 202249939, 202249967, 202250173, 202250198,
  202250426, 202250432, 202250435, 202250437, 202250452, 202250458, 202250716, 202250750, 202250751, 202250752,
  202250765, 202250768, 202250776, 202250779, 202250781, 202250783, 202250786, 202250787, 202250793, 202250820,
  202250835, 202251488, 202251654, 202251707, 202252519, 202252523, 202252524, 202252525, 202253224, 202259527,
  202259536, 202260129, 202271774,
];
