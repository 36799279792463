import { Icon, IconType } from '@next-components/bos-icon';
import { CustomModal } from '@next-components/custom-modal';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MBConfigModalWrapper, ModalInner, ModalActions } from '../modal.styled';
import { formatDate } from '../../../../utils';

interface IMBConfigModalProps {
  setShowMbAPIPopup(value: boolean): void;
  setIsFactoryOrderClicked(value: boolean): void;
  setIsCalled(value: boolean): void;
  showMbAPIPopup?: boolean;
  isFactoryOrderClicked?: boolean;
  onFactoryOrder(value: string): void;
  expectedProductionDate?: Date;
  mbAPIErrorCode?:number
}

export const MBConfigModal = (props: IMBConfigModalProps) => {
  return (
    <MBConfigModalWrapper>
      <CustomModal
        onCloseClick={() => {
          props?.setShowMbAPIPopup(false);
          props?.setIsFactoryOrderClicked(false);
        }}
        isModelCloseAllowedOutside={false}
        active={props?.showMbAPIPopup}>
        <header>
          <Icon icon={IconType.Warning} color="#edbb5a" />
          <h3>Engangsavgift kunne ikke kalkuleres</h3>
        </header>
        <ModalInner>
          {props.mbAPIErrorCode === 400 || props.mbAPIErrorCode === 404 ? (
            <p className="modalText">
              Det er funnet feil med konfigureringen. Meld inn sak eller kontakt produktavdelingen.
            </p>
          ) : (
            <>
              <p className="modalText">
                Vi har for øyeblikket problemer med et av våre systemer, så engangsavgiften kunne ikke kalkuleres
                nøyaktig.{' '}
              </p>
              <p className="modalText">
                Du kan fortsatt opprette og laste ned tilbud med estimert engangsavgift, men kalkuleringen må gjøres på
                nytt før kontrakt kan skrives.
              </p>
            </>
          )}
        </ModalInner>
        <footer>
          <ModalActions items={1}>
            <Button
              onClick={() => {
                if (props?.isFactoryOrderClicked) {
                  props?.onFactoryOrder(formatDate(props?.expectedProductionDate));
                } else {
                  props?.setIsCalled(true);
                }
              }}>
              OK
            </Button>
          </ModalActions>
        </footer>
      </CustomModal>
    </MBConfigModalWrapper>
  );
};
