import { useMutation } from '@apollo/client';
import { addDays, format, isAfter, isBefore, isEqual, isSameDay, startOfDay } from 'date-fns';
import 'date-fns/locale/nb';
import React, { useEffect, useState } from 'react';
import { saveOfferExpiryDateToOrderMutationParsed, maxExpiryLength } from '../../../../../../common';
import { OfferExpiryDateStyled } from './OfferExpiryDate.styled';
import { daysUntil, findExpiresAt } from '../../../../../../utils';
interface IOfferExpiryDateProps {
  orderId: string;
  createdAt: string;
  expiresAt: string;
  isUnavailablePimData?: boolean;
  onUpdateExpiryDate(value: boolean): void;
}

export const OfferExpiryDate = (props: IOfferExpiryDateProps) => {
  const createdDate = new Date(props.createdAt);
  const currentDate = new Date();

  const defaultDate = addDays(createdDate, maxExpiryLength); // Default 60 days

  const defaultHasPassed = isBefore(defaultDate, new Date());

  const [expirationDate, setExpirationDate] = useState(props.expiresAt ? new Date(props.expiresAt) : defaultDate);

  const [saveOfferExpiryDate] = useMutation(saveOfferExpiryDateToOrderMutationParsed);

  const handleDateChange = (date: string | number) => {
    const newDate = new Date(date);
    if ((isEqual(newDate, createdDate) || isAfter(newDate, createdDate)) && !isSameDay(newDate, expirationDate)) {
      setExpirationDate(newDate);
      updateOfferExpiryDate(newDate);
    }
  };

  const updateOfferExpiryDate = async (date: Date) => {
    props.onUpdateExpiryDate(true);
    try {
      const variables = {
        id: props.orderId,
        expiresAt: format(startOfDay(date), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
      saveOfferExpiryDate({ variables }).then(() => {
        props.onUpdateExpiryDate(false); // must wait till expiry date is updated AND order is refreshed
      });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (!props.expiresAt) updateOfferExpiryDate(defaultDate);
  }, [props.expiresAt]);

  const updateAttributes = (e: any) => {
    handleDateChange(e.target.value);
  };

  // Calculate total no of days for expiry
  const calculateDateDifference = (expiresAt: string, createdAt: string) => {
    const expiry = findExpiresAt(expiresAt, createdAt);
    return daysUntil(expiry);
  };

  return (
    <OfferExpiryDateStyled>
      <h3>{defaultHasPassed ? 'Utløpsdato' : 'Velg utløpsdato'}</h3>
      <input
        className="input-block datepickerWidth"
        type="date"
        onChange={updateAttributes}
        value={format(expirationDate, 'yyyy-MM-dd')}
        min={format(currentDate, 'yyyy-MM-dd')}
        max={format(defaultDate, 'yyyy-MM-dd')}
        placeholder="Velg dato"
        readOnly={defaultHasPassed}
        onKeyDown={(e) => e.preventDefault()}
        disabled={props.isUnavailablePimData}
      />
      {!defaultHasPassed && (
        <div className="expiryDateInfoText">
          Varighet: {calculateDateDifference(props.expiresAt, props.createdAt)} dager
        </div>
      )}
    </OfferExpiryDateStyled>
  );
};
