import React, { useLayoutEffect, useState } from 'react';
import { IOrder, IVehicleConfiguration } from 'next-common';
import { SalesHeaderWrapper } from './SalesHeader.styled';
import { NewHeaderWrapper } from '../../sales-common/NewHeader';

export const SalesHeader = (props: any) => {
  const [displaySalesPersonInfo] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const order: IOrder = props?.order;
  const vehicle: IVehicleConfiguration = props?.vehicle;

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (!displaySalesPersonInfo) {
        if (isCollapsed && window.scrollY < 40) setIsCollapsed(false);
        else if (document.body.scrollHeight - document.body.clientHeight > 450) setIsCollapsed(window.scrollY >= 80);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <SalesHeaderWrapper className={'profile-info'}>
      <NewHeaderWrapper
        {...props}
        order={order}
        vehicle={vehicle}
        serviceContract={props?.serviceContract}
        showCustomerPopup={props?.showCustomerPopup}
        setShowCustomerPopup={props?.setShowCustomerPopup}
      />
    </SalesHeaderWrapper>
  );
};
