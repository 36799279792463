import * as React from 'react';
import { CommentPaneHeaderStyled, CommentPaneStyled, CommentsList } from './CommentPane.styled';
import { useDisableScroll, useEmployee, updateOrderMutationParsed } from '../../../../common';
import { useMutation } from '@apollo/client';
import { findComments } from '../SalesOrderView';
import { ITypedComment, Comment, CommentModal } from '../../sales-common/Comments';
import { Button } from '@next-components/cta';

const noOfLatestComments = 3;

export interface IOrderCommentPaneProps {
  comments: ITypedComment[];
  orderId: string;
}

export const CommentPane = (props: IOrderCommentPaneProps) => {
  const [comments, setComments] = React.useState(props.comments || []);
  const [maxComments, setMaxComments] = React.useState(props?.comments?.length);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [updateOrderMutation] = useMutation(updateOrderMutationParsed);
  const employee = useEmployee();

  const employeeName = `${employee?.firstName} ${employee?.lastName}`;
  useDisableScroll(showAddModal);

  const saveComment = async (newComment: string) => {
    try {
      const { data } = await updateOrderMutation({
        variables: {
          id: props.orderId,
          input: {
            comments: [
              {
                text: newComment,
                userId: employee?.employeeId,
              },
            ],
          },
        },
      });
      setComments(findComments(data?.updateOrder));
      setMaxComments(findComments(data?.updateOrder)?.length);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const onSubmitComment = async (value: string) => {
    await saveComment(value);
    setShowAddModal(false);
  };

  return (
    <CommentPaneStyled>
      <CommentPaneHeaderStyled>
        <div className="topWrapper">
          <h3 className="title">Kommentarer</h3>
          <Button className="newComment" onClick={() => setShowAddModal(true)}>
            Skriv ny kommentar
          </Button>
        </div>
      </CommentPaneHeaderStyled>
      <CommentsList>
        {!comments || comments.length < 1 ? (
          <div className="noRecords">Ingen kommentarer</div>
        ) : (
          <>
            {comments.slice(0, maxComments).map((c) => (
              <Comment key={c.id} comment={c} employeeName={employeeName}  />
            ))}
          </>
        )}
      </CommentsList>
      <CommentModal
        isOpen={showAddModal}
        onSubmit={onSubmitComment}
        close={() => setShowAddModal(false)}
        title={'Skriv ny kommentar'}
      />
    </CommentPaneStyled>
  );
};
