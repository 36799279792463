import { isEmpty } from 'lodash';
import { bosColor } from '@next-components/common-styles';
import { IDynamicListMerTableData } from '../../../common-components/DynamicListMer';
import { ISalesPackageOptionValue } from '../../sales-wizard/vehicleSegregationWorker';
import { Tooltip } from '@next-components/tooltip';
import { extractSkuFromFarge, removeSkuFromInteriorName } from '../../../../utils';
import { v4 as uuidv4 } from 'uuid';
import { IVehicleFilterConfig, getTTCodeStatusName, ITilleggSelectable, IVehicleListItems } from '../../../../common';
import { Tag, StatusTagSeverityLevel } from '@next-components/tag'
import { IDynamicListStockTableData, IRowPosition } from '../../../common-components/DynamicListStock';

export const NoData = () => <div>-</div>;

export const vehicleDetailCols = [
  { header: '', key: '', colWidth: ' 2rem' },
  { header: 'Chassisnummer', key: 'vinNumber', colWidth: ' minmax(auto, 16.5rem)' },
  { header: '...', key: 'status', colWidth: ' minmax(auto, 6.35rem)' },
  { header: 'Prisede opsjoner', key: 'priceOptions', colWidth: ' minmax(auto, 20rem)' },
];

export const kiaConfig = {
  cols: [
    { header: 'Farge', key: 'farge', colWidth: ' minmax(auto,16.5rem)', noSort: false },
    { header: 'Interior', key: 'interiorCode', colWidth: 'minmax(auto, 6.25rem)', noSort: true },
    { header: 'Serienummer', key: 'serialNumber', colWidth: 'minmax(auto, 8.25rem)', noSort: false },
    { header: 'Prod.dato', key: 'prod', colWidth: 'minmax(auto, 7.5rem)', noSort: true },
    { header: 'TT', key: 'tt', colWidth: 'minmax(auto, 4.25rem)', noSort: true },
    { header: 'Sted', key: 'locationId', colWidth: 'minmax(auto, 6rem)' },
  ],
  withCheckbox: true,
  noHeader: false,
  selectable: true,
  maxSelected: 1,
  withAccordion: true,
};

const vehicleListPopUpStyles = {
  isSelected: {
    color: bosColor.black,
    background: bosColor.neutralGrey,
    border: `1px solid ${bosColor.neutralPlus}`,
    tagStatus: StatusTagSeverityLevel.INFORMATION
  },
  isUnavailable: {
    color: bosColor.black,
    background: bosColor.poppy20,
    border: `1px solid ${bosColor.poppy60}`,
    tagStatus: StatusTagSeverityLevel.ERROR
  },
  isNotSelected: {
    color: bosColor.black,
    background: bosColor.oker20,
    border: `1px solid ${bosColor.oker}`,
    tagStatus: StatusTagSeverityLevel.WARNING
  },
};

export const fetchStyle = (item: ITilleggSelectable, directSelect: string = '') => {
  if (directSelect !== '' && !item?.isUnavailable) {
    if (vehicleListPopUpStyles[directSelect]) {
      return vehicleListPopUpStyles[directSelect];
    }
  }
  if (item?.isSelected && !item?.isUnavailable) {
    return vehicleListPopUpStyles?.isSelected || null;
  }
  if (item?.isUnavailable) {
    return vehicleListPopUpStyles?.isUnavailable || null;
  }
  return vehicleListPopUpStyles?.isNotSelected || null;
};

const RenderSpanComponent = (
  item: ITilleggSelectable,
  label: string | number,
  fetchStyleName: string = '',
  vehicleListData?: IDynamicListMerTableData
) => {
  const { tagStatus } = fetchStyle(item, fetchStyleName);
  const moreOptions = label?.toString()?.startsWith('+')
  return (
    <Tag
      className={!moreOptions ? 'rounded' : 'tagGroup'}
      value={label?.toString()}
      key={uuidv4()}
      statusTagSeverity={tagStatus}
      disabled={vehicleListData?.isDisable}
    />
  );
};

const RenderToolTip = (
  item: ITilleggSelectable,
  toolTipLabel: string,
  label: string | number,
  fetchSpecificStyle: string = ' ',
  showText?: string,
  vehicleListData?: IDynamicListMerTableData,
  rowPosition?: IRowPosition,
) => {
  return (
    <div className={`${rowPosition === "top" ? "initialRow" : ""} fargeText`}>
      <Tooltip
        key={uuidv4()}
        text={
          <span className="tooltip-container">
            {RenderSpanComponent(item, label, fetchSpecificStyle, vehicleListData)}
            <p className='tooltipLabel'>{toolTipLabel}</p>
          </span>
        }>
        {RenderSpanComponent(item, label, fetchSpecificStyle, vehicleListData)}
      </Tooltip>
      {showText && <span className='additionalText'>{showText}</span>}
    </div>
  );
};

/**
 * It is responsible for Eksteriør -> Ekstrautstyr and Interiør -> Ekstrautstyr data visibility on header row.
 * Logic of expanded section is written in tilleggFormatterDetails
 * @param tillegSelectable
 * @returns
 */
export const TilleggFormatterComponent = (
  tillegSelectable: ITilleggSelectable[],
  vehicleListData?: IDynamicListMerTableData,
  filter?: IVehicleFilterConfig,
  rowPosition?: IRowPosition,
) => {
  const skuList = fetchSKUList(tillegSelectable);
  let showList = showTilleggSKUOnHeader(skuList);
  return (
    <div>
      {skuList?.length === 0 ? (
        <NoData />
      ) : (
        tillegSelectable?.map((tillegg, index) => {
          if (showList[index]) {
            if (showList[index]?.startsWith('+')) {
              return RenderSpanComponent(tillegg, showList[index], '', vehicleListData);
            } else {
              return RenderToolTip(
                tillegg,
                tillegg?.name,
                showList[index],
                filter?.exteriorSelectableFormatter?.includes(tillegg?.sku) ? 'isSelected' : 'isNotSelected',
                '',
                vehicleListData,
                rowPosition,
              );
            }
          } else {
            return null;
          }
        })
      )}
    </div>
  );
};

export const chassisNumberFormatter = (chassisNumber: string) => {
  if (chassisNumber === '') {
    return <NoData />;
  } else {
    return chassisNumber;
  }
};

/**
 * It is responsiable for Eksteriør -> Ekstrautstyr and Interiør -> Ekstrautstyr data visibility on expanded section.
 * Logic of header row section is written in TilleggFormatterComponent
 * @param tillegSelectable
 * @returns
 */
export const tilleggFormatterDetails = (tillegSelectable: ITilleggSelectable[]) => {
  const skuList = fetchSKUList(tillegSelectable);
  let showList = showTilleggSKUOnExpandedSection(skuList);
  const updatedTillegSelectables = tillegSelectable?.filter((item) => showList.includes(item?.sku));
  return (
    <div>
      {showList?.length > 0 &&
        updatedTillegSelectables?.map((tillegg, index) => {
          if (showList[index]) {
            return RenderToolTip(tillegg, tillegg?.name, showList[index], '');
          } else {
            return null;
          }
        })}
    </div>
  );
};

export const felgerFormatter = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};

export const TTFormatterComponent = (traceCode: number, vehicleBrand: string) => {
  const traceCodeStatus: string = getTTCodeStatusName(vehicleBrand, traceCode);
  return RenderToolTip(null, traceCodeStatus, traceCode, 'isSelected');
};

//Sted
export const StedFormatterComponent = (locationId: string, vehicleListData: IDynamicListMerTableData, filter?: IVehicleFilterConfig, rowPosition?: IRowPosition) => {
  let locationName = '';
  if (vehicleListData?.locationId === locationId) {
    locationName = vehicleListData?.location;
  }
  return RenderToolTip(
    null,
    locationName,
    locationId,
    filter?.locationId?.includes(locationId) ? 'isSelected' : 'isNotSelected',
    '',
    vehicleListData,
    rowPosition,
  );
};

//TT for stock
export const TTFormatterComponentStock = (traceCode: number, vehicleBrand: string, filter?: IVehicleFilterConfig, vehicleListData?: IDynamicListStockTableData, rowPosition?: IRowPosition) => {
  const traceCodeStatus = getTTCodeStatusName(vehicleBrand, traceCode);
  return RenderToolTip(
    null,
    traceCodeStatus,
    traceCode,
    filter?.trackTraceCode?.includes(traceCodeStatus) ? 'isSelected' : 'isNotSelected',
    "",
    vehicleListData,
    rowPosition,
  );
};

//Status
export const LagerKodeFormatterComponent = (status: string, vehicleListData: IDynamicListMerTableData, rowPosition?: IRowPosition) => {
  let lagerKodeValue = '';
  if (vehicleListData?.status === status) {
    lagerKodeValue = vehicleListData?.status;
  }
  return RenderToolTip(null, lagerKodeValue, status, 'isSelected', '', vehicleListData, rowPosition);
};

//Farge
export const fargeFormatterComponent = (colorId: string, vehicleListData: IVehicleListItems, filter?: IVehicleFilterConfig, rowPosition?: IRowPosition) => {
  const fargeSku = extractSkuFromFarge(colorId);
  const chosenFormatter = vehicleListData?.fargeFormatter?.[0];
  return RenderToolTip(
    chosenFormatter,
    colorId,
    fargeSku,
    filter?.colorId?.includes(fargeSku) ? 'isSelected' : 'isNotSelected',
    ` ${colorId?.split?.('(')?.[0]}`,
    vehicleListData,
    rowPosition,
  );
};
//Interior fpr stock 
export const InteriorFormatterComponent = (colorId: string, vehicleListData: IVehicleListItems, filter?: IVehicleFilterConfig, rowPosition?: IRowPosition) => {
  const chosenFormatter = vehicleListData?.interiorFormatter?.[0];
  return RenderToolTip(
    chosenFormatter,
    chosenFormatter?.name,
    colorId,
    filter?.interiorCode?.includes(colorId) ? 'isSelected' : 'isNotSelected',
    '',
    vehicleListData,
    rowPosition,
  );
};
//Felger
export const rimsFormatterComponent = (rims: ISalesPackageOptionValue[], props, rowPosition?: IRowPosition) => {
  const rimsName = rims?.[0]?.name || '';
  let fetchStyleName = '';
  if (rims?.[0]?.sku === props?.selectedRims?.sku) {
    fetchStyleName = 'isSelected';
  } else {
    fetchStyleName = 'isNotSelected';
  }
  return <div>{rims?.length === 0 ? <NoData /> : RenderToolTip(null, rimsName, rims?.[0]?.sku, fetchStyleName, null, null, rowPosition)}</div>;
};

const fetchInteriorName = (vehicleListData: IDynamicListMerTableData, interiorCode: string) => {
  let interiorName = '';
  if (vehicleListData?.interior?.length) {
    interiorName = vehicleListData?.interiorCode === interiorCode && vehicleListData?.interior?.[0]?.name;
  } else {
    interiorName = removeSkuFromInteriorName(vehicleListData?.interiorName);
  }
  return interiorName;
};

//Interior
export const interiorCodeFormatterComponent = (interiorCode: string, vehicleListData: IDynamicListMerTableData) => {
  let interiorName = fetchInteriorName(vehicleListData, interiorCode);
  const emptyInteriorCheck = ['', undefined, null];

  const emptyInteriorName = emptyInteriorCheck.includes(interiorName);
  const renderInteriorData = () => (
    <span
      style={{
        background: vehicleListData?.unAvailableSkus?.includes(interiorCode) ? bosColor.poppy20 : '',
        border: vehicleListData?.unAvailableSkus?.includes(interiorCode)
          ? `1px solid ${bosColor.poppy60}`
          : !emptyInteriorName && `1px solid ${bosColor.neutralPlus}`,
        padding: emptyInteriorName && '2px 0.75rem',
        borderRadius: emptyInteriorName && '0.75rem',
        opacity: vehicleListData?.unAvailableSkus?.includes(interiorCode) && '0.65',
      }}>
      {interiorCode}
    </span>
  );

  if (interiorCode === '') {
    return (
      <div>
        <NoData />
      </div>
    );
  } else {
    if (emptyInteriorName) {
      return <div>{renderInteriorData()}</div>;
    } else {
      return (
        <div>
          <Tooltip
            text={
              <span className="tooltip-container">
                {renderInteriorData()}
                <p>{removeSkuFromInteriorName(interiorName)}</p>
              </span>
            }>
            {renderInteriorData()}
          </Tooltip>
        </div>
      );
    }
  }
};

//Listverk - check using Smart order
export const listverkFormatterComponent = (listverkSelectable: ITilleggSelectable[]) => {
  const list = fetchSKUList(listverkSelectable);
  return (
    <div>
      {list?.length === 0 ? (
        <NoData />
      ) : (
        listverkSelectable?.map((listverk) => {
          return RenderToolTip(listverk, listverk?.name, listverk?.sku, '');
        })
      )}
    </div>
  );
};

/**
 * Extract SKU's from the list
 * @param tillegg It will receive an array with type ITilleggSelectable.
 * @returns Array of SKU in string e.g : ['123','456','789']
 */
export const fetchSKUList = (tillegg: ITilleggSelectable[]): string[] => {
  const skuList = [];
  tillegg?.forEach((item) => skuList?.push(item?.sku));
  return skuList;
};

/**
 * Calculate how many records needs to be shown in header list for tillegg
 * @param listOfSKU It will receive SKU list like ['123','456','789']
 * @returns calculated list
 */
export const showTilleggSKUOnHeader = (listOfSKU: string[]): string[] => {
  let result = [];
  if (listOfSKU?.length >= 3) {
    result = listOfSKU?.slice(0, 2);
    result?.push(`+${listOfSKU?.length - result?.length}`);
  } else {
    result = listOfSKU;
  }
  return result;
};

/**
 * * Calculate how many records needs to be shown in expanded section for tillegg
 * @param listOfSKU It will receive SKU list like ['123','456','789']
 * @returns calculated list
 */
export const showTilleggSKUOnExpandedSection = (listOfSKU: string[]): string[] => {
  let result = [];
  if (listOfSKU?.length > 3) {
    result = listOfSKU?.splice(2, listOfSKU?.length);
  }
  return result;
};
// Render the span inside the vehicle detail section in vehicle popup
const RenderSpanForVehicleDetailComponent = (
  item: ITilleggSelectable,
  label: string | number,
  fetchStyleName: string = '',
  labelText: string,
) => {
  const { tagStatus } = fetchStyle(item, fetchStyleName);
  return (
    <div className="vehicleDetailTagContainer">
      <Tag
        className='rounded'
        value={label?.toString()}
        key={uuidv4()}
        statusTagSeverity={tagStatus}
      />
      <span className="itemValue">{labelText}</span>
    </div>
  );
};

/**
 * It is responsiable for display the data inside the vehicle detail based on input values
 * @param itemList
 * @returns HTMLELEMENT | string
 */
export const displayDataForVehicleDetails = (itemList: ITilleggSelectable) => {
  if (itemList)
    if (!isEmpty(itemList?.sku) && (!isEmpty(itemList?.name) || itemList?.isUnavailable)) {
      return RenderSpanForVehicleDetailComponent(
        itemList,
        itemList?.sku,
        itemList?.isSelected ? 'isSelected' : 'isNotSelected',
        itemList?.name,
      );
    }
  return '-';
};
