import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputField } from '@next-components/input-field';
import { InputType } from '@next-components/common-types';
import {
  FaunaMapper,
  getEmptyReference,
  IInsurance,
  IInsuranceInput,
  InsuranceType,
} from 'next-common';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';
import { isDemoOrder, isStockOrder, saveInsuranceParsed } from '../../../../../common';
import { debounce } from 'lodash';

const insuranceInput = (insurance): IInsuranceInput => ({
  organisationNumber: insurance?.organisationNumber || '',
  company: insurance?.company || '',
  policyId: insurance?.policyId || '',
  insuranceType: InsuranceType.NONE,
  mileagePerYear: insurance?.mileagePerYear || null,
  reference: {
    ...getEmptyReference(),
    ...insurance?.reference,
  },
  transfer: {
    company: insurance?.transfer?.company || '',
    organisationNumber: insurance?.transfer?.organisationNumber || '',
    registrationNumber: insurance?.transfer?.registrationNumber || '',
  },
});

const DEBOUNCE_DELAY = 500;

export interface IInsuranceProps {
  orderId: string;
  insurance?: IInsurance;
  input?: IInsurance;
  orderType?: string;
}

interface MutationProps {
  invoke: boolean;
  variables: IInsuranceProps;
}

const MutationFragment = (props: MutationProps) => {
  const { invoke, variables } = props;
  const [saveInsurance] = useMutation(saveInsuranceParsed, { fetchPolicy: 'no-cache' });
  const updateMutation = async () => {
    try {
      await saveInsurance({ variables });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (invoke && variables) updateMutation();
  }, [invoke, variables]);
  return null;
};

export const Insurance = (props: IInsuranceProps) => {
  const [insurance, setInsurance] = useState<IInsuranceInput>(insuranceInput(props.insurance));
  const [insurancePayload, setInsurancePayload] = useState<IInsuranceProps>(null);
  const [invokeMutation, setInvokeMutation] = useState<boolean>(false);


  useEffect(() => {
    setInsurance(insuranceInput(props.insurance));
  }, [props.insurance]);


  const updateInsurance = (customInsurance?: IInsuranceInput) => {
    let insurancePayload = customInsurance ? customInsurance : insurance;
    setInsurancePayload({
      orderId: props.orderId,
      input: FaunaMapper.mapFaunaInput({
        ...insurancePayload,
      }),
    });
  };

  const onInsuranceCompanyChange = (value: any) => {
    let newInsurance = { ...insurance };
    newInsurance.company = value;
    setInsurance(newInsurance);
    updateInsurance(newInsurance);
  };

  const onInsuranceTransferCompanyChange = (value: any) => {
    let newInsurance = { ...insurance };
    newInsurance.transfer.company = value;
    setInsurance(newInsurance);
    updateInsurance(newInsurance);
  };

  const onInsuranceMileageChange = (value: any) => {
    let newInsurance = { ...insurance };
    newInsurance.mileagePerYear = value ? parseFloat(value) : 0;
    setInsurance(newInsurance);
  };

  const onInsuranceTransferChange = async (field: string, value: any) => {
    let newInsurance = { ...insurance };
    newInsurance.transfer[field] = value;
    setInsurance(newInsurance);
  };

  useEffect(() => {
    if (insurancePayload) setInvokeMutation(true);
  }, [insurancePayload]);

  return (
    <OrderAccordion label="Forsikring" className="forsikring" isDisabled={isStockOrder(props?.orderType) || isDemoOrder(props?.orderType)}>
      <div className="input-section">
        <MutationFragment invoke={invokeMutation} variables={insurancePayload} />
        <div className="input-block">
          <InputField
            id="insuranceCompany"
            label="Forsikringsselskap"
            className="borderLight"
            type={InputType.TEXT_LINE}
            value={insurance?.company}
            onChange={debounce((value) => onInsuranceCompanyChange(value), DEBOUNCE_DELAY)} 
            placeholder="Velg selskap"
          />
          <InputField
            id="annualDistance"
            label="Årlig kjørelengde"
            className="borderLight"
            type={InputType.INTEGER}
            endAdornment="km"
            value={insurance?.mileagePerYear?.toString()}
            onChange={onInsuranceMileageChange}
            placeholder=""
            onBlur={() => updateInsurance()}
          />
          <div className="insuranceMessageBarContainer">
            <InputField
              id="bonusTransferredFrom"
              className="borderLight"
              onChange={(value) => onInsuranceTransferChange('registrationNumber', value)}
              value={insurance?.transfer?.registrationNumber}
              label="Bonus overføres fra (reg. nr)"
              placeholder=""
              type={InputType.LICENSE_PLATE}
              onBlur={() => updateInsurance()}
            />
            <Messagebar
              messageBoxInfo
              severityLevel={MessagebarSeverityLevel.INFORMATION}
              className="small"
              title="Dersom reg. nr ikke fylles ut blir startbonusen automatisk 40 %."
            />
          </div>
          <InputField
            id="insuranceCompanyBonus"
            label="Nåværende forsikringsselskap"
            className="borderLight"
            type={InputType.TEXT_LINE}
            value={insurance?.transfer?.company}
            onChange={debounce((value) => onInsuranceTransferCompanyChange(value), DEBOUNCE_DELAY)} 
          />
        </div>
      </div>
    </OrderAccordion>
  );
};
