import styled from 'styled-components';
import { appContentNewMaxWidth, black, veryLightGray } from '@next-components/common-styles';

export const FinancePageStyled = styled.div`
  margin: auto;
  max-width: ${appContentNewMaxWidth};

  .showThreeDots {
    .select-vehicle {
      .header {
        grid-template-columns: 13rem 6.75rem 7.75rem 6.75rem 3rem 6rem 4rem 7rem 9.75rem 2.875rem;
        column-gap: 1rem;
      }
      .item.empty {
        column-gap: 0.625rem;
        grid-template-columns: 1fr;
      }
      .item {
        grid-template-columns: 13rem 6.75rem 7.75rem 6.75rem 3rem 6rem 4rem 7rem 9.75rem 2.875rem;
        column-gap: 1rem;
        div:last-child {
          header {
            height: 2.625rem;
            padding: 0 1.25rem;
            .tinyFont {
              font-size: 1rem;
            }
          }

          header ~ div {
            height: 3rem;
            min-width: 11.875rem;
            padding: 0 1.25rem;
          }
          .icon {
            height: 1.6rem;
            width: 1.6rem;
            margin: 0 0.625rem 0 0;
            position: relative;
            top: -0.2rem;
          }
        }
      }
    }
  }

  .select-vehicle {
    margin: 0 0 4rem;
    padding-top: 48px;
    .callToAction {
      width: 8.75rem;
      height: 1.875rem;
      font-weight: 400;
    }

    .header {
      grid-template-columns: 3.75rem 1fr 1fr 1fr 8.75rem 3.75rem 1fr 3.75rem 1fr 6rem 8.75rem 1.875rem;
      column-gap: 0.625rem;
    }
    .item.empty {
      cursor: default;
      column-gap: 0.625rem;
      grid-template-columns: 1fr;
    }
    .item {
      grid-template-columns: 3.75rem 1fr 1fr 1fr 8.75rem 3.75rem 1fr 3.75rem 1fr 6rem 8.75rem 1.875rem;
      column-gap: 0.625rem;
      div:last-child {
        header {
          height: 2.625rem;
          padding: 0 1.25rem;
          .tinyFont {
            font-size: 1rem;
          }
        }

        header ~ div {
          height: 3rem;
          min-width: 11.875rem;
          padding: 0 1.25rem;
        }
        .icon {
          height: 1rem;
          margin: 0 0.625rem 0 0;
          position: relative;
          width: 1rem;
        }
      }
    }

    .factoryVehicleList {
      .header {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1.875rem;
        column-gap: 1rem;
      }
      .item {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1.875rem;
        column-gap: 1rem;
      }
    }

    .reserve-cancel-modal-footer {
      button {
        width: inherit;
      }
    }
  }

  .container-fluid {
    padding: 0;
  }

  .empty-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    button {
      width: auto;
    }
  }
  .main-sign-btn {
    padding: 5px 0;
    display: flex;
    button {
      pointer-events: none;
      height: 2.5rem;
      width: auto;
      padding: 0.5rem 2.5rem;
      color: ${black};
      background-color: ${veryLightGray};
      border: 3px solid ${veryLightGray};
      :hover {
        border: 3px solid ${veryLightGray};
        background-color: ${veryLightGray};
      }
    }
  }
`;
