import React from 'react';
import { ICustomer, IUser, MAKE, OrderStatus, VehicleReserveStatus } from 'next-common';
import { formatDateInput } from '@next-components/common-types';
import {
  getCustomerName,
  hasReservationBlockingCancellation,
  hasReservationWaitingState,
  offerStati,
  reservationStatus,
} from '../../../../utils';
import { hexToRgba } from '../../../../utils/hexToRgba';
import { bosColor, negative, typography } from '@next-components/common-styles';
import { Alignement, IMenuItem, Menu } from '@next-components/menu';
import { isOffer } from '../../../sales/sales-order-view/OrderPane';
import { isAfter } from 'date-fns';
import { Icon, IconType } from '@next-components/bos-icon';

export const CANCEL_RESERVATION_TEXT = 'Slett reservasjon';
export const DELETE_OFFER_TEXT = 'Slett';
export const RESUME_ORDER_TEXT = 'Gjennoppta';
export const SHOW_OFFER_TEXT = 'Vis kundekort';

export const customerFormatter = (customer: Partial<ICustomer>) => {
  const bosCustomer = customer?.bosCustomer;
  return getCustomerName(bosCustomer) ?? '-';
};

export const salesPersonFormatter = (salesPerson: Partial<IUser>) => {
  const fullName =
    salesPerson?.firstName && salesPerson?.lastName && `${salesPerson.firstName} ${salesPerson.lastName}`;
  const userIdent = salesPerson?.ident;
  const userEmail = salesPerson?.email && salesPerson?.email !== 'null' ? salesPerson.email : null;

  return (fullName || userIdent || userEmail) ?? '-';
};

export const dateFormatter = (timestamp?: string) => {
  if (!timestamp) return '-';
  return formatDateInput(timestamp).formatted;
};

const statusFormatter = (label: string, color: string, borderColor?: bosColor | string, key?: string) => {
  const renderStatusFormat = () => {
    switch (key) {
      case 'SIGNED':
      case 'RESERVED':
        return (
          <div style={{ backgroundColor: bosColor.white }}>
            <Icon icon={IconType.NewNextCheckFilled} color={bosColor.komorebi} width={24} height={24} />
          </div>
        );
      case 'RESERVATION_CANCELED':
        return (
          <div style={{ backgroundColor: bosColor.white }}>
            <Icon icon={IconType.NewNextCancelSolid} color={bosColor.poppy} width={24} height={24} />
          </div>
        );
      default:
        return (
          <div
            style={{
              backgroundColor: label ? hexToRgba(color) : '',
              width: 'fit-content',
              borderRadius: '62.4375rem',
              border: label ? `1px solid ${borderColor}` : '',
              padding: '0rem 0.75rem',
            }}>
            <div
              style={{
                color: bosColor.black,
                fontSize: label ? '0.875rem' : '1rem',
                fontWeight: typography.weight.semibold,
                lineHeight: '170%',
                paddingTop: '1px',
              }}>
              {label ?? '-'}
            </div>
          </div>
        );
    }
  };
  return renderStatusFormat();
};

export const offerStatusFormatter = (code: string) => {
  const status = offerStati.find((s) => s.key === code) || {
    label: code,
    color: offerStati[0].color,
    borderColor: offerStati[0].borderColor,
  };
  return statusFormatter(status.label, status.color, status?.borderColor);
};

export const menuButtonsFormatter = (menuButtons: IMenuItem[], actionCallback = null, value: any, rowData: any) => {
  menuButtons = menuButtons
    .filter(({ text }) => {
      const orderStatus: OrderStatus = OrderStatus[rowData.status as string];
      const reserveStatus: VehicleReserveStatus =
        VehicleReserveStatus[rowData?.reserveDetails?.vehicleReserveStatus as string];

      switch (text) {
        case CANCEL_RESERVATION_TEXT:
          // Remove `remove reservation` button if no vehicle is reserved
          return reserveStatus === VehicleReserveStatus.RESERVED;
        case DELETE_OFFER_TEXT:
          // Remove `delete offer` button if not offer or has reservation
          return isOffer(orderStatus) && !hasReservationBlockingCancellation(rowData?.reserveDetails);
        case RESUME_ORDER_TEXT:
          // Remove `continue offer` button if not offer or has reservation
          return isOffer(orderStatus) && !hasReservationWaitingState(rowData?.reserveDetails);
        default:
          return true;
      }
    })
    .map((item) => ({ ...item, context: rowData }));

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
      <Menu
        callback={actionCallback}
        items={menuButtons}
        align={Alignement.RIGHT}
        icon={IconType.NewNextChevronRight}
      />
    </div>
  );
};

export const expiryDateFormatter = (momentDate?: Date) => {
  const hasExpired = isAfter(new Date(), new Date(momentDate));
  return (
    <div style={hasExpired ? { color: negative } : {}}>
      {momentDate ? formatDateInput(momentDate.toISOString()).formatted : ''}
    </div>
  );
};

export const makeIcon = (make: MAKE) => {
  const iconProps = { width: 25, height: 25 };
  switch (make) {
    case MAKE.Mercedes:
      return <Icon icon={IconType.MercedesBenz} {...iconProps} />;
    case MAKE.Kia:
      return <Icon icon={IconType.Kia} {...iconProps} />;
    case MAKE.Peugeot:
      return <Icon icon={IconType.Peugeot} {...iconProps} />;
    case MAKE.Citroen:
      return <Icon icon={IconType.Citroen} {...iconProps} />;
    case MAKE.Opel:
      return <Icon icon={IconType.Opel} {...iconProps} />;
    case MAKE.Smart:
      return <Icon icon={IconType.Smart} {...iconProps} />;
    default:
      return make?.substring(0, 3) ?? null;
  }
};

export const reservationStatusFormatter = (code: string) => {
  const status = reservationStatus.find((s) => s.key === code) || {
    label: code,
    color: offerStati[0].color,
    borderColor: offerStati[0].borderColor,
    key: code,
  };
  return statusFormatter(status.label, status.color, status.borderColor, status.key);
};
