import * as React from 'react';
import { IOrder } from 'next-common';
import { CustomerInfo } from '../../sales-common/NewHeader/CustomerInfo';
import { SalesHeaderWrapper } from '../../sales-wizard/sales-header/SalesHeader.styled';
import { PageSteps } from '../../sales-common/NewHeader';

interface ISalesOrderBannerProp {
  order: IOrder;
}

export const SalesOrderBanner = (props: ISalesOrderBannerProp) => {  
  return (
    <SalesHeaderWrapper className={'profile-info'}>
      <CustomerInfo orderId={props?.order?.id} order={props?.order} activeStep={PageSteps.START} />
    </SalesHeaderWrapper>
  );
};
