import { Tabs } from '@next-components/tabs';
import { isFunction } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { getConfig, showCountNumber, sortItems } from './DynamicList.utils';
import DynamicListDisplayRecord from './DynamicListDisplayRecord';
import {
  IDynamicListConfig,
  IDynamicListMerTableData,
  IDynamicListProps,
} from './IDynamicList';

export enum DISPLAY_DATA {
  EXACT_MATCH,
  NON_EXACT_MATCH,
}

export const DynamicListMer = (props: IDynamicListProps) => {
  const [toShowExactMatch, setToShowExactMatch] = useState([]);
  const [toShowNonExactMatch, setToShowNonExactMatch] = useState([]);
  const [config, setConfig] = useState<IDynamicListConfig>(getConfig(props));
  const [sortKey, setSortKey] = useState(props.sort?.map((column) => column?.key));
  const [sortReverse, setSortReverse] = useState(props.sort?.map((column) => column?.reverse));
  const [sortedExactMatch, setSortedExactMatch] = useState([]);
  const [sortedNonExactMatch, setSortedNonExactMatch] = useState([]);
  const [selectedRows, setSelectedRows] = useState<IDynamicListMerTableData[]>(props.selectedRows);
  const scrollElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!props?.paginationExactMatch) return;
    const showCount = showCountNumber(props?.paginationExactMatch);
    setToShowExactMatch([...sortedExactMatch || []]?.slice(0, showCount));
  }, [props?.paginationExactMatch]);

  useEffect(() => {
    if (!props?.paginationNonExactMatch) return;
    const showCount = showCountNumber(props?.paginationNonExactMatch);
    setToShowNonExactMatch([...sortedNonExactMatch || []]?.slice(0, showCount));
  }, [props?.paginationNonExactMatch]);

  // Setting the current value of "sort" to reflect recently changed value
  useEffect(() => {
    setSortKey(props?.sort?.map((column) => column?.key));
  }, [props?.sort]);

  useEffect(() => {
    setConfig(getConfig(props));
  }, [props?.data, props?.config]);

  // On sort change or data change, following effect would execute
  useEffect(() => {
    const updatedExactMatchList = props?.data?.filter((item) =>
      props?.exactMatchSerialNo?.includes(item?.serialNumber),
    );
    const updatedNonExactMatchList = props?.data?.filter((item) =>
      props?.nonExactMatchSerialNo?.includes(item?.serialNumber),
    );
    const sortedDataExactMatch = sortItems(
      updatedExactMatchList,
      sortKey?.map((row, index) => ({ key: row, reverse: sortReverse?.[index] })),
    ).sort(
      (nextStatus, previousStatus) => Number(nextStatus?.disabled ?? false) - Number(previousStatus?.disabled ?? false),
    );
    const sortedDataNonExactMatch = sortItems(
      updatedNonExactMatchList,
      sortKey?.map((row, index) => ({ key: row, reverse: sortReverse?.[index] })),
    ).sort(
      (nextStatus, previousStatus) => Number(nextStatus?.disabled ?? false) - Number(previousStatus?.disabled ?? false),
    );
    setSortedExactMatch(sortedDataExactMatch);
    setSortedNonExactMatch(sortedDataNonExactMatch);

    if (props?.paginationExactMatch || props?.paginationNonExactMatch) {
      const showCountExactMatch = showCountNumber(props?.paginationExactMatch);
      const showCountNonExactMatch = showCountNumber(props?.paginationNonExactMatch);
      setToShowExactMatch([...sortedDataExactMatch || []]?.slice(0, showCountExactMatch));
      setToShowNonExactMatch([...sortedDataNonExactMatch || []]?.slice(0, showCountNonExactMatch));
    } else {
      setToShowExactMatch(sortedDataExactMatch);
      setToShowNonExactMatch(sortedDataNonExactMatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, sortKey, sortReverse]);

  useEffect(() => {
    setSelectedRows(props?.selectedRows);
  }, [props?.selectedRows]);

  const onSortChange = (value: string) => {
    const allowedSortKeys = props?.config?.cols?.filter((row) => !row?.noSort)?.map((column) => column?.key);
    if (allowedSortKeys?.includes(value)) {
      if (value === sortKey?.[0]) {
        setSortReverse([!sortReverse?.[0]]);
      } else {
        setSortKey([value]);
        setSortReverse([false]);
      }
    }
  };

  const onReverseChange = (value: boolean) => {
    setSortReverse([value]);
  };

  const exactMatchLoadMore = async () => {
    if (props?.onLoadMoreExactMatch) {
      props?.onLoadMoreExactMatch();
    }
  };

  const nonExactMatchLoadMore = async () => {
    if (props?.onLoadMoreNonExactMatch) {
      props?.onLoadMoreNonExactMatch();
    }
  };

  const onSelectRow = (data: IDynamicListMerTableData) => {
    if (typeof config?.maxSelected === 'number') {
      let selected = [...selectedRows];
      const index = selected?.findIndex((row) => row === data);
      if (index === -1) {
        if (config?.maxSelected === 1) {
          selected = [data];
        } else if (selectedRows?.length < config?.maxSelected) {
          selected = [...selectedRows, data];
        }
      } 
      else if (config?.maxSelected === 1) {
        selected = [];
      } else {
        selected?.splice(index, 1);
      }
      setSelectedRows(selected);
      if (isFunction(props.onSelectedRows)) {
        props.onSelectedRows(selected);
      }
    }
  };

  const onElementClick = (data: IDynamicListMerTableData) => {
    if (config?.selectable) {
      onSelectRow(data);
    }
    if (isFunction(props?.onElementClick)) {
      props?.onElementClick(data);
    }
  };

  const isRowSelected = (rowData: IDynamicListMerTableData) => {
    if (!config?.selectable) {
      return null;
    }
    if (props?.selectedRowsKey) {
      const key = props?.selectedRowsKey;
      return selectedRows?.some(
        (row: IDynamicListMerTableData) =>
          row?.hasOwnProperty(key) && rowData?.hasOwnProperty(key) && row?.[key] === rowData?.[key],
      );
    }
    return selectedRows?.some((row) => row === rowData);
  };

  useEffect(() => {
    props?.fetchingExactMatchLength(sortedExactMatch?.length)
    props?.fetchingNonExactMatchLength(sortedNonExactMatch?.length)
  }, [sortedExactMatch?.length, sortedNonExactMatch?.length])


  return (
    <div className="tabsList">
      <Tabs defaultTabId={props?.defaultTabId} GetActiveTabId={(tabId) => props?.setActiveTabId(tabId)}>
        <Tabs.TabList>
          <Tabs.Tab tabId={DISPLAY_DATA.EXACT_MATCH}>{`Like biler (${sortedExactMatch?.length})`}</Tabs.Tab>
          <Tabs.Tab
            tabId={DISPLAY_DATA.NON_EXACT_MATCH}>{`Nesten like biler (${sortedNonExactMatch?.length})`}</Tabs.Tab>
        </Tabs.TabList>
        <DynamicListDisplayRecord
          onButtonClickDetailPage={props?.onButtonClickDetailPage}
          isLoading={props?.isLoading}
          tabId={DISPLAY_DATA.EXACT_MATCH}
          props={props}
          config={config}
          isRowSelected={isRowSelected}
          onElementClick={onElementClick}
          onReverseChange={onReverseChange}
          onSortChange={onSortChange}
          scrollElement={scrollElement}
          sortKey={sortKey}
          sortReverse={sortReverse}
          sortedData={sortedExactMatch}
          toShow={toShowExactMatch}
          onLoadMore={exactMatchLoadMore}
        />
        <DynamicListDisplayRecord
          onButtonClickDetailPage={props?.onButtonClickDetailPage}
          isLoading={props?.isLoading}
          tabId={DISPLAY_DATA.NON_EXACT_MATCH}
          props={props}
          config={config}
          isRowSelected={isRowSelected}
          onElementClick={onElementClick}
          onReverseChange={onReverseChange}
          onSortChange={onSortChange}
          scrollElement={scrollElement}
          sortKey={sortKey}
          sortReverse={sortReverse}
          sortedData={sortedNonExactMatch}
          toShow={toShowNonExactMatch}
          onLoadMore={nonExactMatchLoadMore}
        />
      </Tabs>
    </div>
  );
};

DynamicListMer.defaultProps = {
  config: {
    withCheckbox: true,
    selectable: false,
    maxSelected: null,
    withAccordion: false,
  },
  showContentCount: false,
  sort: [{ key: '', reverse: false }],
  selectedRows: [],
  selectedRowsKey: null,
  isNewDynamicList: false,
};
