import React, { useEffect } from 'react';
import { PageSteps } from '../../sales-common/NewHeader';
import { Button } from '@next-components/button';
import { Tooltip, TooltipPositionsEnum } from '@next-components/tooltip';
import { IOrder } from 'next-common';
import { OtherDealerVehicleReserveOrSignValidationMsg, VehicleContext } from '../../../../utils';
import { IStepMenuData, menuTitles } from './SalesWizardMenu';
import { MissingItem } from '../../sales-common/DynamicCols/MissingItem';
import { ICentralisedProperties, isDemoOrder, isStockOrder } from '../../../../common';
import { Icon, IconType } from '@next-components/bos-icon';
import { ValidationErrorCode, getErrorMessageForError } from '../../../notifications';
const _ = require('lodash');

export interface ISalesWizardButtonMenu extends ICentralisedProperties {
  order?: IOrder;
  calculationId?: string;
  activeStep: number;
  isUnavailablePimData: boolean;
  orderId: string;
  menuLoading: boolean;
  isStockSaleOrder?: boolean;
  stepData?: IStepMenuData;
  isVehicleValidForReserveOrSign?: boolean;
  vehicleAvailabilityCode?: string;
  bottomButton?: boolean;
  onClickSigning?(stepData?: IStepMenuData): void;
  onClick(stepData?: IStepMenuData | any): void;
  isChange?: boolean
  isFactoryOrder?: boolean;
}

export const SalesWizardButtonMenu = (props: ISalesWizardButtonMenu) => {
  const [missingItems, setMissingItems] = React.useState<string[]>([]);
  const [validationText, setValidationText] = React.useState<string>('');
  const { getVehicleData } = React.useContext(VehicleContext) ?? {};
  const isOrderStockType = isStockOrder(props?.order?.lead?.orderType);
  const isOrderDemoType = isDemoOrder(props?.order?.lead?.orderType);

  useEffect(() => {
    const items: string[] = [];
    if (!props.order?.customer?.bosCustomer) items?.push(MissingItem.Customer);
    if (!props.order?.vehicles || props.order?.vehicles?.length < 1) items?.push(MissingItem.Car);
    if (!props.calculationId && !props?.order?.chosenOffer?.id) items?.push(MissingItem.Offer);
    setMissingItems(items);
  }, [props.order, props.calculationId]);

  useEffect(() => {
    if(isOrderStockType){
      if(!props?.order?.chosenOffer?.id) {
        setValidationText(getErrorMessageForError(ValidationErrorCode.SIGNING_VEHICLE_VALIDATION))
      }
    } else if(!props?.isVehicleValidForReserveOrSign) {
        setValidationText(OtherDealerVehicleReserveOrSignValidationMsg(props.vehicleAvailabilityCode))
      } else if(missingItems?.length) {
        setValidationText(`Velg ${missingItems?.join(' og ')} før du kan signere`)
      } else 
        setValidationText(null)
  }, [missingItems,  props.isVehicleValidForReserveOrSign]);

  const showButton = [
    PageSteps.START,
    PageSteps.DETAILS,
    PageSteps.PACKAGES,
    PageSteps.FINANACE,
  ];

  const isSelectedVehicleAvailable = () => {
    if (props.isStockSaleOrder) {
      return true;
    }
    if (props?.orderId) {
      return getVehicleData()?.modelId && getVehicleData()?.variantId;
    }
    return false;
  };

  const getClassName = (): string =>
    (props.activeStep === PageSteps.FINANACE && missingItems?.length) ||
    (props.activeStep === PageSteps.START && !isSelectedVehicleAvailable())
      ? 'main-sign-btn'
      : 'main-btn';

  const getValueAtIndex = (jsonObject, index) => {
    const values = _.values(jsonObject);
    return values[index];
  };

  let nextStepName = '';
  let nextStepNameIndex = showButton.indexOf(props?.activeStep);
  if (nextStepNameIndex !== -1) {
    nextStepNameIndex = nextStepNameIndex !== 0 ? nextStepNameIndex : nextStepNameIndex + 1;
    nextStepName = getValueAtIndex(menuTitles, nextStepNameIndex);
  }

  return (
    <div className="btn-grp">
      {showButton?.includes(props?.activeStep) && (
        <div className={`${getClassName()} nextButton`}>
          {props?.activeStep === PageSteps.FINANACE || isOrderStockType || (props?.activeStep === PageSteps.PACKAGES && isOrderDemoType) ? (
            <Tooltip
              text={validationText}
              enabledCondition={isOrderStockType ? !props?.order?.chosenOffer?.id : missingItems?.length > 0 || !props.isVehicleValidForReserveOrSign}
              className={`small ${props.bottomButton ? 'bottomButton' : ''}`}
              tooltipPosition={props?.bottomButton ? TooltipPositionsEnum.TOP : TooltipPositionsEnum.BOTTOM}
              >
                <Button
                colorVariant={"secondary"}
                isLoading={!props?.order}
                disabled={isOrderStockType ? (props?.isChange && props?.isFactoryOrder) ? true : !props?.order?.chosenOffer?.id :
                  props?.isUnavailablePimData || missingItems?.length > 0 || !props.isVehicleValidForReserveOrSign}                
                onClick={() => props?.onClickSigning(props.stepData)}
                newNextButton
              >
                Steg 4: Signering
                <Icon icon={IconType.NewNextArrowRight} />
              </Button>
            </Tooltip>
          ) : (
            <Button
              colorVariant={"secondary"}
              disabled={props?.isUnavailablePimData}
              isLoading={!props?.order ? true : props?.menuLoading}
              onClick={props?.onClick}
              newNextButton
              >
              {`Steg ${nextStepNameIndex + 1}: ${nextStepName}`}
              <Icon icon={IconType.NewNextArrowRight} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
