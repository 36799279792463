import { gql } from '@apollo/client';
import { findUsersByDepartmentIdsQuery, findUserByEmail } from 'next-common';

export const queryAllUsersByDepartmentIds = gql`
  ${findUsersByDepartmentIdsQuery}
`;

export const queryUserByEmail = gql`
  ${findUserByEmail}
`;
