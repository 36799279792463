import * as React from 'react';
import { VehicleListMerStyled } from './VehicleListMer.styled';
import { isFunction } from 'lodash';

interface IEmptyList {
  emptyFormatter?: () => React.ReactNode;
  loading?: boolean;
  className?: string;
  isNewDynamicList?: boolean;
  onEmptyElementClick?: () => void;
}

export const EmptyList = (props: IEmptyList) => {
  const onEmptyElementClick = () => {
    if (isFunction(props.onEmptyElementClick)) {
      props.onEmptyElementClick();
    }
  };

  const emptyValue = isFunction(props.emptyFormatter) ? props.emptyFormatter() : 'Tom liste';

  return (
    <VehicleListMerStyled
      columnWidth={['1fr']}
      className={`item empty ${props.className ?? ''}`}
      onClick={onEmptyElementClick}
      isNewDynamicList={props.isNewDynamicList}>
      <div style={{ textAlign: 'center', width: '100%' }}>{props.loading ? 'Loading...' : emptyValue}</div>
    </VehicleListMerStyled>
  );
};
