import { bosColor } from '@next-components/common-styles';
import styled from 'styled-components';

export const ModelWrapperContainer = styled.div`
  & > .deleteAlert {
    .custom-modal {
      border-radius: 0.5rem;
      .inner-container .content-container {
        div > button:first-child {
          background-color: ${bosColor.poppy};
          border-color: ${bosColor.poppy};
          color: ${bosColor.white};
        }
      }
    }
  }
`;
