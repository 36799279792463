import React, { useState } from 'react';
import { IOrder, ISigningJobInfo } from 'next-common';
import { SmallLink } from '@next-components/link';
import { getSignedServiceContractPdf } from '../ServiceContractPDF';
import { IconType } from '@next-components/bos-icon';

export interface IContractPdfProps {
  order: IOrder;
  signingJob?: ISigningJobInfo;
  linkText?: string;
  handleOnClick?: () => any;
  isLoading?: boolean;
}

export const ServiceContractPDF = (props: IContractPdfProps) => {
  const [processing, setProcessing] = useState(false);

  const previewServiceContractClick = async () => {
    setProcessing(true);
    try {
      await getSignedServiceContractPdf(props.order.id, props.signingJob?.signedDocumentName).finally(() => {
        setProcessing(false);
      });
    } catch (error) {
      setProcessing(false);
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const text = 'Last ned signert kontrakt';
  return (
    <div className="icon-container">
      <SmallLink
        isLoading={props?.isLoading ?? processing}
        iconLeft={IconType.NewNextDocument}
        onLinkClick={props?.handleOnClick ? props.handleOnClick : previewServiceContractClick}
        text={processing ? '' : props?.linkText ?? text}
      />
    </div>
  );
};
