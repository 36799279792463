import { bosColor } from '@next-components/common-styles';
import styled from 'styled-components';

export const SalesOfferPdfContainer = styled.div`
  width: 100%;
  .icon-container {
    font-family: inherit !important;

    .downloadOffers {
      width: 100%;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      align-self: stretch;
      padding: 1rem;
      gap: 1rem;
      border-radius: 0.25rem;
      border: 1px solid ${bosColor.blue};
      background: ${bosColor.blue20};

      &.noWrapper {
        border: none;
        padding: 0;
      }
      span {
        margin: 0;
      }
      > .icon-container {
        div > svg + div {
          .lds-ellipsis {
            height: auto;

            > div {
              top: -0.5rem;
            }
          }
        }
      }
    }
  }
`;
