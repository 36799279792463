import styled from 'styled-components';
import { black, stateInactive, fontWeightBold, disabled } from '@next-components/common-styles';

export const SettingsFooterInfoStyled = styled.div`
  display: grid;
  grid-template-columns: 15px auto;
  column-gap: 5px;
  height: 18px;
  .icon {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 100%;
      width: 100%;
      path {
        fill: ${disabled};
      }
    }
  }
  .text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const SettingsFooterStyled = styled.div`
  padding: 10px 20px;
  border-top: 1px solid ${stateInactive};

  .user-profile-logout {
    font-size: 16px;
    margin-top: 15px;
    > div {
      height: 25px;
      margin: 0;

      a {
        height: 25px;
        color: ${black};
        text-decoration: none;
        font-weight: ${fontWeightBold};
        line-height: 20px;
      }
    }
  }
`;
