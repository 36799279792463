import { bosColor } from '@next-components/common-styles';

export const offerStati = [
  {
    key: 'CREATED',
    // disabled,
    color: bosColor.neutralGrey,
    borderColor: bosColor.neutralGrey,
    label: 'Opprettet',
  },
  {
    key: 'DRAFT_CREATED',
    // disabled,
    color: bosColor.neutralGrey,
    borderColor: bosColor.neutralGrey,
    label: 'Opprettet',
  },
  {
    key: 'PAUSED',
    // neutral,
    color: bosColor.oker20,
    borderColor: bosColor.neutral,
    label: 'På vent',
  },
  {
    key: 'CONFIRMED',
    // primary,
    color: bosColor.komorebi20,
    borderColor: bosColor.komorebi60,
    label: 'Sendt',
  },
  {
    key: 'SIGNED',
    // positive,
    color: bosColor.komorebi20,
    borderColor: bosColor.komorebi60,
    label: 'Signert',
  },
  {
    key: 'FAILURE',
    // negative,
    color: bosColor.poppy20,
    borderColor: bosColor.poppy60,
    label: 'Feilet',
  },
  {
    key: 'EXPIRED',
    // negative,
    color: bosColor.poppy20,
    borderColor: bosColor.poppy60,
    label: 'Utløpt',
  },
  {
    key: 'COMPLETED',
    // positive,
    color: bosColor.komorebi20,
    borderColor: bosColor.komorebi60,
    label: 'Utlevert',
  },
  {
    key: 'CANCEL_ORDER_SUCCESS',
    // positive,
    color: bosColor.komorebi20,
    borderColor: bosColor.komorebi60,
    label: 'fullført',
  },
  {
    key: 'CANCEL_ORDER_PENDING',
    // negative,
    color: bosColor.oker20,
    borderColor: bosColor.oker60,
    label: 'Pågår',
  },
  {
    key: 'CANCEL_ORDER_FAILED',
    // negative,
    color: bosColor.poppy20,
    borderColor: bosColor.poppy60,
    label: 'Feilet',
  },
];
