import React from 'react';
import {
  PendingConfirmationBody,
  PendingConfirmationWrapper,
  StatusMessageListItemHeader,
} from '../OrderStatusList.styled';
import { IBosVehicleOrderStatus, IConfiguration, IOrder, OrderStatus, OrderType, checkStatus } from 'next-common';
import { isOrderEditable } from '../../../../../../utils';
import { DateTimeBlock } from '../../../../sales-common/DateTimeBlock';
import { Accordion } from '@next-components/accordion';
import { Icon } from '@next-components/bos-icon';
import { getDynamicAccordionIcon } from '../ReservedCarInfo';

export const hasPendingConfirmation = (
  order: IOrder,
  lastStatus: IBosVehicleOrderStatus,
  configuration: IConfiguration,
) => {
  const excludedOrderTypes = [
    OrderType[OrderType.ORDER],
    OrderType[OrderType.STOCK],
    OrderType[OrderType.DEMO],
  ];

  if (!isOrderEditable(order, configuration)) {
    if (order?.status?.toString() !== OrderStatus[OrderStatus.SIGNED]) return false;
    return !lastStatus || !excludedOrderTypes.includes(lastStatus.orderType);
  } else {
    if (lastStatus && !checkStatus([OrderStatus.CONFIRMED, OrderStatus.FAILURE], order?.status)) {
      return true;
    } else {
      if (!lastStatus) {
        return true;
      }
      if (order?.status?.toString() !== OrderStatus[OrderStatus.SIGNED]) {
        return false;
      }
    }
    return false;
  }
};

export interface IPendingConfirmationProps {
  reservationStatus: any;
}

export const PendingConfirmation = (props: IPendingConfirmationProps) => {
  return (
    <PendingConfirmationWrapper className="PendingConfirmationWrapper">
      <div className="messageWrapper">
        <Accordion
          nextAccordion
          className={'warning'}
          labelContent={
            <div className="accordionLabelContainer">
              <div className={'iconContainer'}>
                <Icon icon={getDynamicAccordionIcon('warning')} />
              </div>
              <StatusMessageListItemHeader>
                <div className={`reserveHeader`}>
                  <h3>IFS Ordre</h3>
                </div>
                <div className="ifsDate">
                  <DateTimeBlock dateTime={new Date().toISOString()} shortDate />
                </div>
              </StatusMessageListItemHeader>
            </div>
          }>
          <PendingConfirmationBody className="PendingConfirmationBody">
            <div className="leftContainer">
              <div className="bodyItem">
                <span className="label">Status</span>
                <p className="value">{props.reservationStatus?.status}</p>
              </div>
            </div>
            <div className="rightContainer">
              <span>Sjekker automatisk igjen hvert 15 sekund</span>
            </div>
          </PendingConfirmationBody>
        </Accordion>
      </div>
    </PendingConfirmationWrapper>
  );
};
