import { MessagebarSeverityLevel } from '@next-components/messagebar';
import React from 'react';
import { ToastAreaStyled } from './ToastArea.styled';
import classNames from 'classnames';

export const ToastArea = (props) => {
  const isFailNotificationIncluded = props?.notifications?.findIndex(
    (notify) => notify?.props?.severityLevel === MessagebarSeverityLevel.ERROR,
  );

  return props?.notifications?.length ? (
    <ToastAreaStyled
      className={classNames({
        errorMessageOverlay: isFailNotificationIncluded !== -1,
      })}>
      {props?.notifications?.map((notification) => notification)}
    </ToastAreaStyled>
  ) : null;
};
