import styled from 'styled-components';
import { bosColor, disabled } from '@next-components/common-styles';

export const SalesPersonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  place-items: center;
  height: 100%;
  margin-left: 0.625rem;

  .icon {
    width: 21px;
  }

  .user-icon {
    color: ${bosColor.white};
  }
  .salesPerson {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const SalesPersonInfo = styled.div`
  display: block;
  text-align: left;
  cursor: pointer;

  .name {
    margin: 0 0.45rem 0 0.45rem;
    font-weight: 700;
  }

  .bottomLine {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .dealers {
      margin-left: 0.75rem;
      margin-top: 0.5rem;
    }

    .ident {
      color: ${disabled};
      padding-top: 2px;
    }
  }
`;
