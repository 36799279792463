import styled from 'styled-components';
import { black, bosColor, stateInactive } from '@next-components/common-styles';

export const StyledForm = styled.form`
  width: 100%;

  .button {
    border-radius: 0.5rem;
    height: 3.625rem;
    width: 7.5rem;

    .text {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }
`;

export const TradeInSelector = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  .button {
    width: 100%;
  }
  margin-bottom: 2rem;
`;

export const TradeInActions = styled.div`
  padding-top: 2rem;
  width: 100%;
  display: flex;
  grid-column-gap: 2rem;
  justify-content: flex-start;
  place-items: center;

  .custom-button {
    background-color: ${bosColor.turquoise};
    color: ${black};
    &:disabled {
      border-color: ${stateInactive};
      background-color: ${stateInactive};
    }
    .text {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  .outlined {
    border: 2px solid ${bosColor.turquoise};
    .text {
      font-size: 1.125rem;
    }
  }

  .btn-delete {
    border: 2px solid ${bosColor.turquoise};
    .text {
      font-size: 1.125rem;
    }
    .lds-ellipsis div {
      background: ${black};
    }
  }
  .cancel-button {
    .text {
      font-size: 1.125rem;
      font-weight: 700;
    }
    .lds-ellipsis div {
      background: ${black};
    }
  }
`;
