import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { InputField } from '@next-components/input-field';
import { InputType } from '@next-components/common-types';
import { CheckBox } from '@next-components/bos-checkbox';
import { IOrder } from 'next-common';
import { updateOrderMutationParsed } from '../../../../../common';

import { ModelYear } from './ModelYear';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';

export interface IContractNoteProps {
  order: IOrder;
}

interface ContractInput {
  contractNote?: string;
  isConcludedAtSeller?: boolean;
}

interface ContractProps {
  id: string;
  input: any;
}

interface MutationProps {
  invoke: boolean;
  variables: ContractProps;
}

const findIsConcludedAtSeller = (order: IOrder) => {
  if (!order) return false;
  return order.isConcludedAtSeller ?? false;
};

const MutationFragment = (props: MutationProps) => {
  const { invoke, variables } = props;
  const [saveOrder] = useMutation(updateOrderMutationParsed, { fetchPolicy: 'no-cache' });
  const updateMutation = async () => {
    try {
      await saveOrder({ variables });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (invoke && variables) updateMutation();
  }, [invoke, variables]);
  return null;
};

export const ContractNote = (props: IContractNoteProps) => {
  const [contractNote, setContractNote] = useState(props.order?.contractNote?.trim());
  const [isConcludedAtSeller, setIsConcludedAtSeller] = useState(findIsConcludedAtSeller(props.order));
  const [contractPayload, setContractPayload] = useState<ContractProps>(null);
  const [invokeMutation, setInvokeMutation] = useState<boolean>(false);

  useEffect(() => {
    setContractNote(props.order?.contractNote?.trim());
  }, [props.order]);

  const updateOrder = async (input: ContractInput) => {
    const variables = {
      input,
      id: props.order?.id,
    };
    setContractPayload(variables);
  };

  const onContractNoteChange = (value) => {
    if (value.trim().length === 0) value = ' ';
    setContractNote(value);
  };

  const onIsConcludedAtSellerChange = (value) => {
    setIsConcludedAtSeller(value);
    updateOrder({ isConcludedAtSeller: value });
  };

  useEffect(() => {
    if (contractPayload) setInvokeMutation(true);
  }, [contractPayload]);

  return (
    <OrderAccordion label="Annen informasjon">
      <MutationFragment invoke={invokeMutation} variables={contractPayload} />
      <ModelYear vehicle={props.order?.vehicles?.[0]} />
      <InputField
        id = 'merknaderTextarea'
        maxLength={400}
        label="Merknader"
        className="borderLight merknaderTextarea"
        type={InputType.TEXT_AREA}
        rows={5}
        onChange={(value) => onContractNoteChange(value)}
        defaultValue={contractNote}
        placeholder=""
        onBlur={() => {
          updateOrder({ contractNote: contractNote });
        }}
      />
      <CheckBox
        id="isConcludedAtSeller"
        className="dark small concludedSeller"
        label="Kontrakt er inngått på annet sted enn selgers forretningssted"
        checked={isConcludedAtSeller}
        onChange={(e) => onIsConcludedAtSellerChange(e.target.checked)}
      />
    </OrderAccordion>
  );
};
