import styled from 'styled-components';
import { black, darkerGrey, backgroundSite, bosBrand, secondary } from '@next-components/common-styles';
import { EclipsTextStyled } from '../../../Assets';

export const OverviewListStyled = styled(EclipsTextStyled)`
  display: block;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  .header,
  .item {
    column-gap: 0.625rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 3.125rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .item {
    margin: 0.1875rem 0;
    padding: 0.3125rem;
  }
  .empty {
    grid-template-columns: 1fr;
  }
  .tblFull {
    tr {
      th,
      td {
        font-size: 12px;
        font-weight: 600;
      }
      th {
        color: ${black};
        font-size: 15px;
      }
      td {
        color: ${darkerGrey};
      }
    }
    tbody tr {
      cursor: pointer;
      &:nth-of-type(odd) {
        background-color: ${backgroundSite};

        td:last-child {
          border-radius: 0 10px 10px 0;
        }
      }

      &:nth-of-type(odd) td:first-child {
        border-radius: 10px 0 0 10px;
      }
    }

    .devider td {
      border-top: 1px solid ${bosBrand};
      margin: 0;
      padding: 0;
    }
  }
  .grid-footer {
    border-top: 1px solid ${black};
    display: grid;
    grid-auto-rows: auto;
    -webkit-column-gap: 0.625rem;
    column-gap: 0.625rem;
    grid-template-columns: 18.7fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    white-space: nowrap;
  }

  .primary-link {
    color: ${secondary};
    .text {
      font-size: 1rem;
    }
    height: 2.625rem;
    &:after {
      background: ${secondary};
      border-color: ${secondary};
    }
  }
`;
