import { useState } from 'react';
import { InputField } from '@next-components/input-field';
import { InputType } from '@next-components/common-types';
import {
  CardInputDescription,
  CardInputPrice,
  CardInstruction,
  CardButtonSection,
} from './CustomAccessory.styled';
import { Button } from '@next-components/button';
import { StyledCard } from '../../sales/sales-vehicle-accessories-page/VehicleAccessoriesPage.styled';

interface ICustomAccessoryProps {
  id?: string;
  name?: string;
  price?: number;
  isIncludeMVA?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  isElectric?: boolean;

  onRemove?(id: string): void;
  onSave?(id: string, name: string, price: number, isIncludeMVA?: boolean): void;
}

export const CustomAccessory = (props: ICustomAccessoryProps) => {
  const [customName, setCustomName] = useState<string>(props.name || '');
  const [customPrice, setCustomPrice] = useState<number>(props?.price ?? 0);
  const [isIncludeMVA, setIsIncludeMVA] = useState<boolean>(props.isIncludeMVA || false);

  const getCustomAccessoryLabel = (isIncludeMVA: boolean): string => {
    return isIncludeMVA
      ? 'Legg inn pris eks mva.  Mva blir likevel  beregnet dersom dersom grense overskrides'
      : 'Legg inn pris inkl mva';
  };

  const resetStates = (): void => {
    setCustomName('')
    setCustomPrice(0);
    setIsIncludeMVA(false);
  }

  const handleSave = (): void => {
    if (typeof props.onSave === 'function') {
      props.onSave(props.id, customName, customPrice, isIncludeMVA);
      resetStates();
    }
  };

  return (
    <StyledCard isCustom={true} className='customAccessory'>
        <CardInstruction> Egendefinert Utstyr </CardInstruction>
        <CardInputDescription>
          <InputField
            id="inputDescription"
            className="inputDescriptionText"
            label="Beskrivelse"
            type={InputType.TEXT_LINE}
            disabled={props.isSelected}
            placeholder={'Beskriv produktet'}
            defaultValue={customName}
            onChange={(input: string) => setCustomName(input)}
          />
        </CardInputDescription>
        <CardInputPrice>
          <div className="inputFieldContainer">
            <InputField
              id="inputPrice"
              type={InputType.FLOAT}
              disabled={props.isSelected}
              placeholder="Pris"
              defaultValue={customPrice}
              endAdornment="kr"
              onChange={(input: string) => {
                const asFloat = parseFloat(input ?? '0');
                setCustomPrice(asFloat);
              }}
            />
            <label>{getCustomAccessoryLabel(isIncludeMVA)}</label>
          </div>
        </CardInputPrice>

        <CardButtonSection>
          <div className="footerInnerContainer">
          {props.isElectric && (
            <div className="switchButtonContainer">
              <label className="switch">
                <input
                  id="includeMva"
                  type="checkbox"
                  disabled={props.isSelected}
                  checked={isIncludeMVA}
                  onChange={(value: React.ChangeEvent<HTMLInputElement>) => setIsIncludeMVA(value?.target?.checked)}
                />
                <span className="slider round"></span>
              </label>
              <p className="lableText">Fritatt for mva</p>
            </div>
            )}
            {props.isSelected ? (
              <Button onClick={() => props.onRemove(props.id)} variant="action" disabled={props.disabled}>
                <span>{'Fjern'}</span>
              </Button>
            ) : (
              <Button
                onClick={handleSave}
                variant="action"
                disabled={props.disabled || !customPrice || !customName}>
                <span>{'Legg til'}</span>
              </Button>
            )}
          </div>
        </CardButtonSection>
    </StyledCard>
  );
};
