import { gql } from '@apollo/client';
import { getVehicleModelsFromPIMQuery } from 'next-common';
import { vehicleConfigurationFields, vehicleShortConfigurationFields, vehicleTireAccessoriesFields } from '../types';

export const queryVehicleDataFromPIM = gql`
query getVehicleDataFromPIM($input: VehicleDataQueryInput) {
  getVehicleDataFromPIM(input: $input) {     
    results {
      ${vehicleShortConfigurationFields}
    }
  }
}
`;

export const queryVehiclesModels = gql`
  ${getVehicleModelsFromPIMQuery}
  
`;

export const findVehicleById = gql`
  query vehicleById($id: ID!) {
    vehicleById(id: $id) {
      ${vehicleConfigurationFields}
    }
  }
`;

export const findVehicleByPimCode = gql`
  query vehicleByPimCode($pimCode: ID!) {
    vehicleByPimCode(pimCode: $pimCode) {
      ${vehicleShortConfigurationFields}
    }
  }
`;


export const dealerNameQuery = gql`
query($dealerId: String)  {
  dealer(dealerId: $dealerId) {
    name
  }
}
`;

export const getVehicleAccessoriesBySerialNumberQuery = gql`
  query getVehicleAccessoriesBySerialNumber($serialNo: String!) {
    getVehicleAccessoriesBySerialNumber(serialNo: $serialNo) {
      ${vehicleTireAccessoriesFields}
    }
  }
`;