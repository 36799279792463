import { gql } from '@apollo/client';
import {
  bosDealerQuery,
  bosEmployeeQuery,
} from 'next-common';

export const queryDealer = gql`
  ${bosDealerQuery}
`;

export const queryEmployee = gql`
  ${bosEmployeeQuery}
`;

export const queryEquipmentList = gql`
query getEquipmentListForStockSaleOrder($serialNo: String!) {
  getEquipmentListForStockSaleOrder(serialNo: $serialNo) 
}
`;