import styled from 'styled-components';
import {
  appContentNewMaxWidth,
  backgroundCard,
  bosColor,
  disabled,
  typography,
  wizardPageStyle,
} from '@next-components/common-styles';
import { InfoBlockStyled } from '../sales-common/InfoBlock/InfoBlock.styled';

export const SalesOrderWrapperStyled = styled.div`
  height: min-content;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 2em;
  background-color: ${backgroundCard};
`;

export const SalesOrderViewStyled = styled.div`
  ${wizardPageStyle}
  height: min-content;
  max-width: ${appContentNewMaxWidth};
  margin: 0 auto;
  background-color: ${backgroundCard};
  padding: 1.25rem 0 3.125rem;
  position: relative;
  transition: all 1s ease-in;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tabs > ul {
    li {
      font-size: 1rem;
    }
  }

  .tabs + div {
    ${InfoBlockStyled}:not(.smallText) {
      span {
        color: ${bosColor.black};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.semibold};
        line-height: 175%;

        &.label {
          flex: 1 0 0;
        }
        .vat {
          color: ${disabled};
          margin-left: 15px;
        }
        &.value {
          text-align: right;
        }
      }
    }
  }
`;
