import * as React from 'react';
import { Icon, IconType } from '@next-components/bos-icon';
import { IDynamicListHeaderProps } from '../IDynamicList';
import { filterAndOrderCols } from '../DynamicList.utils';
import classNames from 'classnames';
import { checkboxColWidth, VehicleListMerStyled } from './VehicleListMer.styled';
import { upperFirst } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const ListHeaderMer = (props: IDynamicListHeaderProps) => {
  if (props.noHeader) return null;

  const columns = filterAndOrderCols(props.cols);
  const hasCheckboxCol = props.selectable && props.withCheckbox;
  const columnWidth = columns?.map((column) => column?.colWidth ?? '1fr');
  if (hasCheckboxCol) {
    columnWidth?.unshift(checkboxColWidth);
  }

  return (
    <VehicleListMerStyled
      columnWidth={columnWidth}
      className={classNames('header', {
        headerRow: props.isNewDynamicList,
        disabled: props.isHeaderDisable,
      })}
      withAccordion={props.withAccordion}>
      <div className="headerContainer">
        <div className="headerTopContainer">
          <div className="grid-row header-top ">
            <div>Eksteriør</div>
            <div></div>
            <div className="br-1"></div>

            <div>Interiør</div>
            <div></div>
            <div className="br-1"></div>

            <div>Annen info</div>
            <div></div>
            <div></div>
            <div></div>
            {/* Kept this code for price change*/}
            {/* <div></div> */}
          </div>
          <div></div>
        </div>
      </div>
      <div className="headerContainer">
        <div className="headerBottomContainer">
          <div className="grid-row header-inner">
            {hasCheckboxCol && <div className="selectable"></div>}
            {columns?.map((column) => (
              <div
                className={classNames({
                  sortable: !column?.noSort,
                })}
                key={`header_${column?.key || ''}_${uuidv4()}`}
                onClick={() => (column?.noSort ? {} : props.onSortChange(column?.key))}>
                {upperFirst(column?.header)}
                {column?.key === props?.sort?.[0]?.key && (
                  <div style={{ maxWidth: 15, marginLeft: 5, display: 'inline-block' }}>
                    <Icon
                      icon={props?.sort?.[0]?.reverse ? IconType.TableArrowUp : IconType.TableArrowDown}
                      height={10}
                      width={10}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div></div>
        </div>
      </div>
    </VehicleListMerStyled>
  );
};

ListHeaderMer.defaultProps = {
  isHeaderDisable: false,
};
