
export const FinanceMakeStyle = {
    'DEFAULT': {
        'SRT': {
            'heading': {
                fontSize: 16, 
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 12, 
                fontWeight: 'bold'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 10, 
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 10
            },
            'optionPriceValue': {
                fontSize: 14,
                fontWeight: 'extrabold'
            },
            'serviceavtaleHead': {
                fontSize: 8,
                fontWeight: 'bold',
                padding: '0 20 0 20'
            },
            'financeLanHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeLeasingHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeCashHead': {
                fontSize: 16,
                fontWeight: 700,
                padding: '10 20 20 20'
            },
            'legalText': {
                fontSize: 7
            }
        },
        'MER': {
            'heading': {
                fontSize: 16,
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 10,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 10
            },
            'optionPriceValue': {
                fontSize: 14,
                fontWeight: 'extrabold'
            },
            'serviceavtaleHead': {
                fontSize: 8,
                fontWeight: 'bold',
                padding: '0 20 0 20'
            },
            'financeLanHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeLeasingHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeCashHead': {
                fontSize: 16,
                fontWeight: 700,
                padding: '10 20 20 20'
            },
            'legalText': {
                fontSize: 7
            }
        },
        'KIA': {
            'heading': {
                fontSize: 16,
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 10,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 10
            },
            'optionPriceValue': {
                fontSize: 14,
                fontWeight: 'extrabold'
            },
            'serviceavtaleHead': {
                fontSize: 8,
                fontWeight: 'bold',
                padding: '0 20 0 20'
            },
            'financeLanHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeLeasingHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeCashHead': {
                fontSize: 16,
                fontWeight: 700,
                padding: '10 20 20 20'
            },
            'legalText': {
                fontSize: 7
            }
        },
        'PEU': {
            'heading': {
                fontSize: 16,
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 10,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 10
            },
            'optionPriceValue': {
                fontSize: 14,
                fontWeight: 'extrabold'
            },
            'serviceavtaleHead': {
                fontSize: 8,
                fontWeight: 'bold',
                padding: '0 20 0 20'
            },
            'financeLanHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeLeasingHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeCashHead': {
                fontSize: 16,
                fontWeight: 700,
                padding: '10 20 20 20'
            },
            'legalText': {
                fontSize: 7
            }
        }
    },
    'INDEPENDENT_DEALER': {
        'SRT': {
            'heading': {
                fontSize: 12,
                fontWeight: 700,
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 10,
                fontWeight: 700
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 700
            },
            'optionPriceLabel': {
                fontSize: 8,
                color: '#D7E600'
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 700
            },
            'serviceavtaleHead': {
                fontSize: 8, 
                fontWeight: 700, 
                padding: '0 20 0 20'
            },
            'financeLanHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeLeasingHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeCashHead': {
                fontSize: 12,
                fontWeight: 700,
                padding: '10 20 20 20'
            },
            'legalText': {
                fontSize: 6
            }
        },
        'MER': {
            'heading': {
                fontSize: 12,
                fontWeight: 700,
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 10,
                fontWeight: 700
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 700
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 700
            },
            'serviceavtaleHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '0 20 0 20'
            },
            'financeLanHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeLeasingHead': {
                fontSize: 8,
                fontWeight: 700,
                padding: '12 20 0 20'
            },
            'financeCashHead': {
                fontSize: 12,
                fontWeight: 700,
                padding: '10 20 20 20'
            },
            'legalText': {
                fontSize: 6
            }
        },
        'KIA': {
            'heading': {
                fontSize: 12,
                fontWeight: 'bold',
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 10,
                fontWeight: 'bold'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 'bold'
            },
            'serviceavtaleHead': {
                fontSize: 8,
                fontWeight: 'bold',
                padding: '0 20 0 20'
            },
            'financeLanHead': {
                fontSize: 8,
                fontWeight: 'bold',
                padding: '12 20 0 20'
            },
            'financeLeasingHead': {
                fontSize: 8,
                fontWeight: 'bold',
                padding: '12 20 0 20'
            },
            'financeCashHead': {
                fontSize: 12,
                fontWeight: 'bold',
                padding: '10 20 20 20'
            },
            'legalText': {
                fontSize: 6
            }
        },
        'PEU': {
            'heading': {
                fontSize: 10,
                fontWeight: 700,
                margin: '0 5 5'
            },
            'optionLabel': {
                fontSize: 8,
                fontWeight: 700,
                textTransform: 'uppercase'
            },
            'optionElementLabel': {
                fontSize: 7
            },
            'optionElementValue': {
                fontSize: 10,
                fontWeight: 700,
                textTransform: 'uppercase'
            },
            'optionPriceLabel': {
                fontSize: 7
            },
            'optionPriceValue': {
                fontSize: 16,
                fontWeight: 700
            },
            'serviceavtaleHead': {
                fontSize: 7,
                fontWeight: 700,
                padding: '0 20 0 20',
                textTransform: 'uppercase'
            },
            'financeLanHead': {
                fontSize: 7,
                fontWeight: 700,
                padding: '12 20 0 20',
                textTransform: 'uppercase'
            },
            'financeLeasingHead': {
                fontSize: 7,
                fontWeight: 700,
                padding: '12 20 0 20',
                textTransform: 'uppercase'
            },
            'financeCashHead': {
                fontSize: 10,
                fontWeight: 700,
                padding: '10 20 20 20',
                textTransform: 'uppercase'
            },
            'legalText': {
                fontSize: 5
            }
        }
    }
}