import { IApiEmployee, IUserInput, getDepartmentIdFromIdent, getUniqueItemsByKey } from 'next-common';

export const mapEmployeeToUser = (employee: IApiEmployee, selectedDealer: string): Partial<IUserInput> => {
  const identity = employee?.employeeId;
  return {
    departmentId: selectedDealer || getDepartmentIdFromIdent(identity),
    email: employee?.email,
    employeeId: employee?.id,
    firstName: employee?.firstName,
    lastName: employee?.lastName,
    ident: identity,
    mobilePhone: employee?.phone,
  };
};

export const getEmployeeDealerIds = (employee: IApiEmployee): string[] => {
  const employeeDealerIds: string[] = employee?.employeeRoles
    ? getUniqueItemsByKey(employee?.employeeRoles, 'dealerId')?.map((role) => role?.dealerId)
    : [];

  if (employee?.departmentNumber && !employeeDealerIds?.includes(employee?.departmentNumber)) {
    employeeDealerIds?.push(employee?.departmentNumber);
  }

  return employeeDealerIds;
};
