import styled from 'styled-components';
import { backgroundCard, disabled, secondary } from '@next-components/common-styles';

export const DetailsInfoStyled = styled.div`
  .infosection {
    background-color: ${backgroundCard};
    padding-top: 2.5rem;
    color: $black;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 40px;

    .infoTitle {
      font-size: 30px;
    }

    .infodetails {
      font-size: 15px;
      font-weight: 400;
      p {
        color: ${disabled};
        font-weight: normal;
        margin: 0;
      }
      span {
        font-size: 15px;
        font-weight: 600;
        padding-right: 0.5rem;
      }
    }
  }
  .info-title-container {
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .span-container {
    margin-bottom: 0.5rem;
    display: flex;
  }
  .detail-info-bg {
    background-color: ${backgroundCard};
  }
  .primary-link {
    color: ${secondary};
    .text {
      font-size: 1rem;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
`;
