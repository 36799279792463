import { AccessoryType, IAccessory, ITireSet, IVehicleAccessory } from 'next-common';
import { formatNumber } from '@next-components/common-types';
import React from 'react';
import { InfoBlock } from '../../../sales-common/InfoBlock';
import { AgreementArea } from '../SigningAgreement.styled';
import { v4 as uuidv4 } from 'uuid';

export interface IAddOnsProps {
  addOns?: IVehicleAccessory[];
  recommendedTireSets?: ITireSet[];
  recommendedAccessories?: IAccessory[];
}

export const AddOns = (props: IAddOnsProps) => {
  if (!props.addOns) return null;
  return (
    <AgreementArea>
      <h2>Tilbehør</h2>
      {props.addOns.map((addOn) => {
        const addOnPimId = addOn?.customId?.split('_')?.[1];
        const isRecommendedTire = props.recommendedTireSets?.map((tire) => tire.id)?.includes(addOnPimId);
        const isRecommendedAccessory = props.recommendedAccessories
          ?.map((accessory) => accessory.id)
          ?.includes(addOnPimId);

        return (
          <InfoBlock
            key={uuidv4()}
            label={addOn.name}
            value={
              addOn.price === 0 || isRecommendedTire || isRecommendedAccessory
                ? 'Inkludert'
                : `+ ${formatNumber(addOn.price)} kr`
            }
          />
        );
      })}
    </AgreementArea>
  );
};
