import { useState, useEffect } from 'react';
import { eventEmitter, Events } from '../../utils';

export const useDisableSkus = () => {
  const [disableSku, setDisableSku] = useState(new Map());
  const [onloadDisableSku, setOnloadDisableSku] = useState(new Map());

  const handleDisableSku = (skus) => {
    setDisableSku(skus);
  };

  const handleOnLoadDisableSku = (skus) => {
    setOnloadDisableSku(skus);
  };

  const isDisableSKUonLoad = (sku: string): boolean => {
    return onloadDisableSku?.has(sku);
  };

  const isDisableSKUonChange = (sku: string): boolean => {
    return disableSku?.has(sku);
  };

  useEffect(() => {
    eventEmitter.subscribe(Events.DISABLE_SKU, handleDisableSku);
    eventEmitter.subscribe(Events.ONLOAD_DISABLE_SKU, handleOnLoadDisableSku);
    return () => {
      eventEmitter.unsubscribe(Events.DISABLE_SKU);
      eventEmitter.unsubscribe(Events.ONLOAD_DISABLE_SKU);
    };
  }, []);

  return { disableSku, isDisableSKUonChange, onloadDisableSku, isDisableSKUonLoad };
};
