import React from 'react';
import { IOrder, IVatCalculation, IVehicleConfiguration } from 'next-common';
import { SigningAgreementStyled } from './SigningAgreement.styled';
import { TradeIn } from './parts';
import { SiginingPageFinancingSection } from '../../sales-common/SiginingPageFinancingSection';
import { ICartProps } from '@next-components/cart';
import { ICentralisedProperties } from '../../../../common';
import { IGetServiceContract } from 'sales-common';

export interface ISigningAgreement extends ICentralisedProperties {
  order: IOrder;
  vehicle?: IVehicleConfiguration;
  serviceContract?: IGetServiceContract;
  cartData?: ICartProps;
  finalPrice?: IVatCalculation;
  isUnavailablePimData?: boolean;
}

export const SigningAgreement = (props: ISigningAgreement) => {
  const offer = props.order?.chosenOffer || null;
  return (
    <>
      {!offer && <div>Tilbud mangler</div>}
      {offer && (
        <SigningAgreementStyled>
          <SiginingPageFinancingSection
            calculation={offer}
            financingType={offer?.financingType}
            order={props.order}
            serviceContract={props.serviceContract}
            serviceContractDb={offer?.serviceContract}
            offerId={offer.id}
            isUnavailablePimData={props.isUnavailablePimData}
          />
          <TradeIn tradeInCars={props.order?.tradeInVehicles} financingType={offer?.financingType} />
        </SigningAgreementStyled>
      )}
    </>
  );
};
