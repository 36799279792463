import { gql } from '@apollo/client';
import { orderFragmentsWithBos } from 'next-common';
import { createServiceContractMutation as createDarsContract } from 'sales-common';

export const createServiceContractMutation = gql`
  mutation createServiceContract($orderId: ID!, $input: [InitialServiceContractInput!]!, $nextArticles: [NextArticleInput!]) {
    createServiceContract(orderId: $orderId, input: $input, nextArticles: $nextArticles) {
      ...order
    }
  }
  ${orderFragmentsWithBos}
`;

export const updateServiceContractMutation = gql`
  mutation updateServiceContract($orderId: ID!, $input: [UpdateServiceContractInput!]!, $nextArticles: [NextArticleInput!]) {
    updateServiceContract(orderId: $orderId, input: $input, nextArticles: $nextArticles) {
      ...order
    }
  }
  ${orderFragmentsWithBos}
`;

export const darsCreateContractMutation = gql`
  ${createDarsContract}
`;

export const removeServiceContractMutation = gql`
  mutation removeServiceContract($orderId: ID!, $id: String!, $nextArticles: [NextArticleInput!]) {
    removeServiceContract(orderId: $orderId, id: $id, nextArticles: $nextArticles) {
      ...order
    }
  }
  ${orderFragmentsWithBos}
`;
