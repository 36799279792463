import {
  AccessoryType,
  IAccessory,
  IOrder,
  IReferenceInput,
  ITireSet,
  IVehicleAccessory,
  IVehicleAccessoryInput,
  IVehicleConfiguration,
  IVehicleImage,
  mapAccessoryVatCode,
  getSeperatedIdFromAccessoryCustomId,
  mapAccessoryToVehicleAccessory,
  mapTireSetToVehicleAccessory,
} from 'next-common';
import { defaultTo, groupBy, orderBy } from 'lodash';

export enum AccessoryChangeAction {
  ADD = 1,
  REMOVE = -1,
}

export interface ICustomAccessoryProps extends IVehicleAccessory {
  isSelected?: boolean;
  isManualInput?: boolean;
  images?: IVehicleImage;
  category?: string;
  priority?: number;
  pimId?: string;
  pimSku?: string;
  packageId?: string;
}

const getNewCustomId = (accessory: IVehicleAccessory) =>{
  const customIdWithPackageId = `${accessory?.customId?.split('_')?.[0]}_${accessory.sku}`;
  return customIdWithPackageId;
}

const mapTire = (tireSet: ITireSet, order: IOrder) => {
  const customId = `${order?.id}_${tireSet?.pimSku}`;
  const isSelected = order?.accessories?.some((accessory) => {
    const customIdWithPackageId = getNewCustomId(accessory);
    return accessory?.customId === customId || customIdWithPackageId === customId;
  });

  return {
    customId,
    description: defaultTo(tireSet?.description, null),
    id: customId,
    images: tireSet?.images?.length ? tireSet?.images?.[0] : null,
    isSelected,
    pimId: defaultTo(tireSet?.id, null),
    pimSku: defaultTo(tireSet?.pimSku, null),
    price: defaultTo(tireSet?.additionalPrice, 0),
    priceExcludingVat: defaultTo(tireSet?.additionalPriceExcludingVat, 0),
    name: defaultTo(tireSet?.name, null),
    isRecommended: defaultTo(tireSet?.isRecommended, null),
    vatCode: defaultTo(tireSet?.vatCode, null),
    elWithoutVat: defaultTo(tireSet?.elWithoutVat, false),
    category: defaultTo(tireSet?.category, null),
    priority: defaultTo(tireSet?.priority, null),
    packageId: defaultTo(tireSet?.nobos, null),
    retailPriceExcludingVat: defaultTo(tireSet?.retailPriceExcludingVat, null),
  };
};

export const getTireSetData = (
  vehicle: IVehicleConfiguration,
  order: IOrder,
  winterWheel: boolean,
  tiresForStock: ITireSet[], 
  isStockSaleOrder: boolean
): Partial<ICustomAccessoryProps>[] => {
  const tiresObject = isStockSaleOrder ? tiresForStock : vehicle?.tires;
  const tires = tiresObject?.filter((tire) => tire?.winterWheel === winterWheel) || [];
  const tireSets: ITireSet[] = orderBy(
    tires,
    ['isRecommended', 'priority', 'additionalPriceExcludingVat'],
    ['desc', 'asc', 'asc'],
  );
  return tireSets?.map((tireSet: ITireSet) => mapTire(tireSet, order));
};

const mapAccessory = (orderId: string, accessory: IAccessory, orderAccessories: ICustomAccessoryProps[]) => {
  const customId = `${orderId}_${accessory?.pimSku}`;

  const isSelected = orderAccessories?.some((accessory) => {
    const customIdWithPackageId =  getNewCustomId(accessory);
    return accessory?.customId === customId || customIdWithPackageId === customId;
  });
  
  return {
    id: customId,
    isSelected,
    name: defaultTo(accessory?.name, null),
    description: defaultTo(accessory?.description, null),
    sku: defaultTo(accessory?.sku, null),
    pimId: defaultTo(accessory?.id, null),
    pimSku: defaultTo(accessory?.pimSku, null),
    price: defaultTo(accessory?.price, 0),
    priceExcludingVat: defaultTo(accessory?.priceExcludingVat, 0),
    vatCode: defaultTo(accessory?.vatCode, null),
    elWithoutVat: defaultTo(accessory?.elWithoutVat, false),
    category: defaultTo(accessory?.category, null),
    priority: defaultTo(accessory?.priority, null),
    images: accessory?.images?.length ? accessory?.images?.[0] : null,
    retailPriceExcludingVat: defaultTo(accessory?.retailPriceExcludingVat, null),
  };
};

export const getAccessoryData = (vehicle: IVehicleConfiguration, order: IOrder, accessoriesForStock: IAccessory[], 
  isStockSaleOrder: boolean): Partial<ICustomAccessoryProps>[] => {
  const accessoriesObject = isStockSaleOrder ? accessoriesForStock : vehicle?.accessories;
  const pimAccessories =
  accessoriesObject?.map((accessory) => mapAccessory(order?.id, accessory, order?.accessories || [])) || [];

  return [...(pimAccessories || [])];
};

export const getMappedAccessoryData = (
  accessories: Partial<ICustomAccessoryProps>[],
): Partial<ICustomAccessoryProps> => {
  if (!accessories?.length) {
    return null;
  }
  const filteredAccessories = accessories?.filter((accessory) => accessory?.category);
  const mappedAccessories = groupBy(filteredAccessories, 'category');
  if (!Object.keys(mappedAccessories).length) return null;
  Object.keys(mappedAccessories)?.forEach((category) => {
    const accessorySet = mappedAccessories[category];
    mappedAccessories[category] = orderBy(accessorySet, ['priority', 'priceExcludingVat'], ['asc', 'asc']);
  });
  return mappedAccessories;
};

export const getMappedCustomAccessoryData = (order: IOrder): Partial<ICustomAccessoryProps>[] => {
  const customAccessories = order?.accessories?.filter(
    (accessory) => accessory?.accessoryType.toString() === AccessoryType[AccessoryType.CUSTOM],
  );
  return customAccessories?.map((accessory) => {
    return {
      id: accessory?.customId,
      isSelected: true,
      name: accessory?.name,
      isManualInput: true,
      vatCode: accessory?.vatCode,
      price: accessory?.price,
      priceExcludingVat: accessory?.priceExcludingVat,
      isCustomIncludeMVA: accessory?.isCustomIncludeMVA,
      sku: accessory?.sku,
    };
  });
};

export const getDefaultOpenedCategoryForAccessories = (accessories: Partial<ICustomAccessoryProps>): string[] => {
  const accessoriesOpenList = [];
  Object.keys(accessories ?? {})?.forEach((accessoryCatName) => {
    if (accessories?.[accessoryCatName]?.some((accData) => accData?.isSelected)) {
      accessoriesOpenList.push(accessoryCatName);
    }
  });
  return accessoriesOpenList;
};

export interface IAccessoryChange {
  accessoryId: string;
  action: AccessoryChangeAction;
  manualData?: Partial<ICustomAccessoryProps>;
}

export class AccessoryTool {
  order: IOrder;
  vehicle: IVehicleConfiguration;
  tireSetData: Partial<ICustomAccessoryProps>[];
  accessoryData: Partial<ICustomAccessoryProps>[];
  mappedAccessories: Partial<ICustomAccessoryProps>;
  mappedCustomAccessories: Partial<ICustomAccessoryProps>[];
  tiresForStock: ITireSet[];
  accessoriesForStock: IAccessory[];
  isStockSaleOrder: boolean;

  constructor(order: IOrder, vehicle: IVehicleConfiguration, tires: ITireSet[], accessories: IAccessory[], isStockSaleOrder: boolean) {
    this.order = order;
    this.vehicle = vehicle;
    this.tiresForStock = tires;
    this.accessoriesForStock = accessories;
    this.isStockSaleOrder = isStockSaleOrder;
    this.tireSetData = getTireSetData(vehicle, order, true, tires, isStockSaleOrder);
    this.accessoryData = getAccessoryData(vehicle, order, accessories, isStockSaleOrder);
    this.mappedAccessories = getMappedAccessoryData(this.accessoryData);
    this.mappedCustomAccessories = getMappedCustomAccessoryData(this.order);
  }

  getRemoveInput = (changes: IAccessoryChange[]): string[] => {
    const vehicleAccessories = changes
      ?.map((accessory) => this.getOrderAccessoryByCustomId(accessory?.accessoryId))
      ?.filter((orderAccessory) => !!orderAccessory);
    return vehicleAccessories?.map((accessory) => accessory?.id);
  };

  getAddInput = (changes: IAccessoryChange[], order: IOrder): IVehicleAccessoryInput[] => {
    const vehicleAccessories = changes?.map((change) => {
      const accessory = this.getVehicleAccessoryInputById(change?.accessoryId);
      const accessoryType = this.getAccessoryType(change?.accessoryId, !!change?.manualData);
      return {
        ...accessory,
        accessoryType,
        customId: change?.accessoryId,
        name: change?.manualData ? change?.manualData?.name : accessory?.name,
        price: change?.manualData ? change?.manualData?.price : accessory?.price,
        priceExcludingVat: change?.manualData ? change?.manualData.priceExcludingVat : accessory?.priceExcludingVat,
        isCustomIncludeMVA: change?.manualData ? change?.manualData?.isCustomIncludeMVA : false,
        vatCode: change?.manualData ? change?.manualData?.vatCode : accessory?.vatCode,
        sku: accessory?.sku ?? null,
        ...(change?.manualData?.isCustomIncludeMVA && { vat: 0 }),
      };
    });
    return vehicleAccessories;
  };

  getTireAccessorCardItemById = (id: string): Partial<ICustomAccessoryProps> => {
    return this.tireSetData?.find((tireSet) => tireSet?.id === id);
  };

  getAccessoryCardItemById = (id: string): Partial<ICustomAccessoryProps> => {
    return this.accessoryData?.find((accessory) => accessory?.id === id);
  };

  getAccessoryCardItem = (id: string): Partial<ICustomAccessoryProps> =>
    this.getTireAccessorCardItemById(id) || this.getAccessoryCardItemById(id) || null;

  getInputReference = (id: string): IReferenceInput => {
    const cardProps = this.getAccessoryCardItem(id);
    return cardProps?.pimId ? { pimId: cardProps?.pimId, pimCode: cardProps?.pimSku } : undefined;
  };

  getOrderAccessoryByCustomId = (customId: string): IVehicleAccessory => {
    return this.order.accessories?.find((accessory) => accessory?.customId === customId || getNewCustomId(accessory) === customId);
  }

  getTireSetById = (id: string): ITireSet => {
    const [, tireSku] = getSeperatedIdFromAccessoryCustomId(id);
    const tyresObj = this.isStockSaleOrder ? this.tiresForStock : this.vehicle?.tires;
    return tyresObj?.find((tire) => tire?.pimSku === tireSku) || null;
  };

  getTireSetInputById = (id: string): IVehicleAccessoryInput => {
    const tireSet = this.getTireSetById(id);
    if (!tireSet) return null;
    tireSet.vatCode = mapAccessoryVatCode(tireSet)
    return {
      ...mapTireSetToVehicleAccessory(tireSet, this.order),
      reference: this.getInputReference(id),
    };
  };

  getAccessoryById = (id: string): IAccessory => {
    const [, accessorySku] = getSeperatedIdFromAccessoryCustomId(id);
    const accessoryObj = this.isStockSaleOrder ? this.accessoriesForStock : this.vehicle?.accessories;

    return (
      accessoryObj?.find((accessory) => {
        return accessorySku === accessory?.pimSku;
      }) || null
    );
  };

  getAccessoryInputById = (id: string): IVehicleAccessoryInput => {
    const accessory = this.getAccessoryById(id);
    if (!accessory) return null;
    accessory.vatCode = mapAccessoryVatCode(accessory)
    return {
      ...mapAccessoryToVehicleAccessory(accessory, this.order),
      reference: this.getInputReference(id),
    };
  };

  getVehicleAccessoryInputById = (id: string): IVehicleAccessoryInput =>
    this.getTireSetInputById(id) || this.getAccessoryInputById(id);

  getAccessoryType = (id: string, isManualInput = false): AccessoryType => {
    if (isManualInput) return AccessoryType.CUSTOM;
    const tire = this.getTireAccessorCardItemById(id);
    if (tire) return AccessoryType.TYRE;
    return AccessoryType.ACCESSORY;
  };
}
