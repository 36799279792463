import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './AppHeader.module.scss';
import { Icon, IconType } from '@next-components/bos-icon';
import { Routes, serviceNowSupportUrl } from '../../../common';
import { Logo } from './NewLogo';
import { SalesPerson } from './SalesPerson';
import { isNil } from 'lodash';

const cx = classNames.bind(styles);

interface IAppHeaderProps {}

interface IMenuLinkProps {
  active: boolean;
  menuIcon: IconType; 
  title: string;
  route?: string; 
  serviceNowSupportUrl?: string; 
}

const LogoLink = () => (
  <div className={styles.logo}>
    <Link to={Routes.getHomePage()} className={styles.AppLink}>
      <Logo />
    </Link>
  </div>
);

const MenuLink = (props: IMenuLinkProps) => {
  const { active, menuIcon, title, route, serviceNowSupportUrl } = props;
  return (
    <span
      className={cx({
        menuItem: true,
        active: active,
      })}
      style={{ cursor: 'pointer' }} 
    >
      <Icon icon={menuIcon} aria-hidden height={24} width={24} />
      {!isNil(serviceNowSupportUrl) ? (
        <a href={serviceNowSupportUrl} target='_blank'>{title}</a> 
      ) : (
        <NavLink to={route}>{title}</NavLink> 
      )}
    </span>
  );
};

const Menu = () => {
  const isHomePage = () => window.location.pathname === Routes.getHomePage();

  return (
    <div className={styles.menu}>
      <MenuLink active={isHomePage()} route={Routes.getHomePage()} title="Min oversikt" menuIcon={IconType.NewNextList} />
      <MenuLink active={isHomePage()} title="Support" menuIcon={IconType.NewNextSupport} serviceNowSupportUrl={serviceNowSupportUrl}/>
      <SalesPerson />
    </div>
  );
};

const AppHeader = (props: IAppHeaderProps) => {
  return (
    <div className={styles.appHeader}>
      <LogoLink />
      <Menu />
    </div>
  );
};

export default AppHeader;
