import React, { useEffect, useState } from 'react';
import {
  FinancingType,
  IConfiguration,
  IOrder,
  MBApiCalculationFlag,
  OrderStatus,
  OrderType,
  checkStatus,
  isEditable,
  isFeatureOrderEditOn,
} from 'next-common';
import { SigningContractStyled } from './SigningContract.styled';
import { ContractNote, Delivery, Insurance, TradeIn } from './parts';
import { AnalogSigningModal, DigitalSigningModal } from './modals';
import { ModalActions } from '../../sales-common/modal.styled';
import { useMutation } from '@apollo/client';
import {
  createVehicleOrderDraftMutationParsed,
  INotificationPageProps,
  IVehiclePageProps,
  Routes,
  signOrderParsed,
  updateReservedVehicleStockStatusBySerialNumberMutationParsed,
  AxiosHeaderEnum,
  apimEndpoint,
  useDisableScroll,
  isStockOrder,
  sendOrderMutationParsed,
  manageSignPageButtonOperation,
  isDemoOrder,
} from '../../../../common';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import axios, { AxiosRequestConfig } from 'axios';
import { findOrderByIdWithBosCustomer } from '../../../../graphql';
import { NextErrorNotification } from '../../../notifications';
import { CheckMark } from '../../sales-common/CheckMark';
import { CheckBox } from '@next-components/bos-checkbox';
import { Button } from '@next-components/cta';
import { getHeader, isOrderEditable } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { OrderAccordion } from '../../sales-common/OrderAccordion';
import { Link } from '@next-components/link';

export interface ISigningContractProps extends IVehiclePageProps, INotificationPageProps {
  order: IOrder;
  hasWarnings: boolean;
  softLockForMinutes?: number;
  validCustomer?: boolean;
  vehicleUnavailable?: boolean;
  configuration?: IConfiguration;
  isTestUser?: boolean;
}

enum PROCESSING {
  NONE,
  DIGITAL,
  MANUAL,
  STOCK,
}

const GENERIC_ERROR_MESSAGE = 'Noe gikk galt med signering. Vennligst prøv igjen.';

export const SigningContract = (props: ISigningContractProps) => {
  const offer = props.order?.chosenOffer || null;
  const order: IOrder = props?.order;
  const tradeInVehicles = order?.tradeInVehicles || null;
  const isCash = offer?.financingType === FinancingType.CASH;
  const isAlternativeLeasing = offer?.financingType === FinancingType.ALTERNATIVELEASING;
  const show = offer?.financingType === FinancingType.LOAN || offer?.financingType === FinancingType.LEASING;
  const [processing, setProcessing] = useState(PROCESSING.NONE);
  const [showDigitalModal, setShowDigitalModal] = useState(false);
  const [showAnalogModal, setShowAnalogModal] = useState(false);
  const [orderError, setOrderError] = useState(null);
  const isModelOpen = showDigitalModal || showAnalogModal;
  useDisableScroll(isModelOpen);

  const [acceptedTerms, setAcceptedTerms] = useState({
    creditScore: isCash,
    santander: isCash || isAlternativeLeasing,
    validCustomer: props?.validCustomer,
  });
  const [orderCreated, setOrderCreated] = useState(false);
  const [manualOrderCreated, setManualOrderCreated] = useState(false);
  const [signOrder] = useMutation(signOrderParsed);
  const navigate = useNavigate();
  const [createVehicleOrderDraft] = useMutation(createVehicleOrderDraftMutationParsed, {
    update(cache) {
      const cacheOrder: any = cache.readQuery({
        query: findOrderByIdWithBosCustomer,
        variables: {
          id: props.orderId,
        },
      });
      let orderData: typeof cacheOrder = {};
      if (!cacheOrder?.findOrderById) {
        if (props?.order) {
          orderData = props?.order;
        }
      } else {
        orderData = cacheOrder.findOrderById;
      }

      cache.writeQuery({
        query: findOrderByIdWithBosCustomer,
        data: {
          findOrderById: {
            ...orderData,
            status: OrderStatus[OrderStatus.DRAFT_CREATED],
          },
        },
        variables: {
          id: props.orderId,
        },
      });
    },
  });

  const { disableDigitalSignBtn, disableManualSignBtn, disableStockOrderBtn, showStockOrderBtn } =
    manageSignPageButtonOperation(props?.order?.lead?.orderType);
  const [softLockVehicleMutation] = useMutation(updateReservedVehicleStockStatusBySerialNumberMutationParsed);
  const [sendOrderMutation] = useMutation(sendOrderMutationParsed);

  const redirectToCustomerPage = () => {
    setTimeout(() => {
      navigate(Routes.getViewPage(order.id));
    }, 2000);
  };

  const executeSigning = async () => {
    const variables = { id: order?.id ?? '' };
    const { data } = await signOrder({ variables });
    if (data?.signOrder.__typename === 'NextError') {
      props.raiseNotification(
        <NextErrorNotification
          key={'executeSignFail'}
          orderId={order?.id ?? ''}
          errorCode={data?.signOrder?.errorCode}
          onClose={() => props?.clearNotifications('executeSignFail')}
        />,
      );
      setOrderCreated(false);
    } else {
      await softReserveVehicle(props.softLockForMinutes);
      setOrderCreated(true);
      redirectToCustomerPage();
    }
  };

  const executeCreationOfVehicleOrder = async () => {
    const customerBosApiId = order?.customer?.reference?.bosApiId || null;
    const variables = {
      orderId: order?.id ?? '',
      ...(customerBosApiId ? { customerBosApiId } : {}),
    };
    try {
      await createVehicleOrderDraft({ variables });
      await softReserveVehicle(props.softLockForMinutes);
      return null;
    } catch (error) {
      return error;
    }
  };

  const openManualSigningModal = async () => {
    try {
      setProcessing(PROCESSING.MANUAL);
      setShowAnalogModal(true);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      //@ts-ignore
      setOrderError(getErrorMessage(error));
      setTimeout(() => setOrderError(null), 10000);
    } finally {
      setProcessing(PROCESSING.NONE);
    }
  };

  const onDigitalConfirm = async (confirmed) => {
    setShowDigitalModal(false);
    if (!confirmed) {
      return;
    }
    setProcessing(PROCESSING.DIGITAL);
    try {
      await executeSigning();
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      //@ts-ignore
      setOrderError(getErrorMessage(error));
    } finally {
      setProcessing(PROCESSING.NONE);
    }
  };

  const onSubmit = async () => {
    setProcessing(PROCESSING.STOCK);
    try {
      await executeCreationOfVehicleOrder();
      const { data } = await sendOrderMutation({
        variables: { id: props?.order?.id },
      });
      if (!data) return Promise.reject(new Error('Failed to send Order details to DB'));
      navigate(Routes.getViewPage(order.id));
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      //@ts-ignore
      setOrderError(getErrorMessage(error));
    } finally {
      setProcessing(PROCESSING.NONE);
    }
  };

  function getErrorMessage(error: Error): string {
    const message = error.message;
    if (message.includes('reserved in IFS')) {
      return message;
    }
    return GENERIC_ERROR_MESSAGE;
  }

  const onAnalogConfirm = async (confirmed: boolean, files: File[]) => {
    setShowAnalogModal(false);
    if (!confirmed) {
      return;
    }
    setProcessing(PROCESSING.MANUAL);
    try {
      const HEADER_FORMDATA: AxiosRequestConfig = await getHeader(AxiosHeaderEnum.HEADER_FORMDATA);
      const file = files[0];
      if (!file) return;
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post(
          `${apimEndpoint ?? window.location.origin}/api/completeManualSigning/${props?.order?.id}`,
          formData,
          HEADER_FORMDATA,
        )
        .then(() => {
          setManualOrderCreated(true);
          redirectToCustomerPage();
        });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      // @ts-ignore
      const errorMessage = error?.message || error;
      setOrderError(errorMessage);
      setProcessing(PROCESSING.NONE);
    }
  };

  const softReserveVehicle = async (reserveForMinutes: number): Promise<boolean> => {
    const serialNo = order?.vehicles?.[0]?.serialNo;
    const { ident: salesPersonId, departmentId: dealerId } = order?.user || {};

    const response: any = await softLockVehicleMutation({
      variables: {
        serialNo,
        salesPersonId,
        dealerId,
        orderId: order?.id,
        reserveForMinutes,
      },
    });

    return response;
  };

  useEffect(() => {
    setAcceptedTerms({ ...acceptedTerms, validCustomer: props?.validCustomer });
  }, [props?.validCustomer]);

  useEffect(() => {
    if (props?.vehicleUnavailable || props.hasWarnings) {
      setShowAnalogModal(false);
      setShowDigitalModal(false);
    }
  }, [props?.vehicleUnavailable, props.hasWarnings]);

  const isSigningButtonDisabled = () => {
    return isOrderEditable(props?.order, props.configuration)
      ? isEmpty(props?.order?.vehicleOrderStatus?.status) ||
          !acceptedTerms?.creditScore ||
          !acceptedTerms?.santander ||
          props?.hasWarnings ||
          !acceptedTerms?.validCustomer ||
          props?.vehicleUnavailable ||
          !props?.isVehicleValidForReserveOrSign ||
          props?.isUnavailablePimData ||
          props?.order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED
      : !acceptedTerms?.creditScore ||
          !acceptedTerms?.santander ||
          props?.hasWarnings ||
          !acceptedTerms?.validCustomer ||
          props?.vehicleUnavailable ||
          props?.isUnavailablePimData ||
          !props?.isVehicleValidForReserveOrSign ||
          props?.order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED;
  };

  return (
    <div>
      {!offer && <div>Tilbud mangler</div>}
      <SigningContractStyled>
        {tradeInVehicles?.length > 0 && (
          <TradeIn tradeIns={order?.tradeInVehicles} financingType={offer.financingType} order={order} />
        )}

        <div className="input-section levering">
          <Delivery order={props.order} isCash={isCash} />
        </div>
        <Insurance orderId={order?.id} insurance={order?.insurance} orderType={order?.lead?.orderType} />
        <div className="input-section annenInformasjon">
          <ContractNote order={order} />
        </div>
        <div className="signeringSection">
          <OrderAccordion label="Signering" isOpen>
            {isFeatureOrderEditOn(props.configuration) &&
              (isEditable(props.order?.status) || checkStatus([OrderStatus.DRAFT_CREATED], props.order?.status)) &&
              !isEmpty(props.order?.vehicleOrderStatus) && [
                OrderType[OrderType.ORDER],
                OrderType[OrderType.DEMO].includes(props.order?.vehicleOrderStatus?.orderType),
              ] && (
                <Messagebar
                  messageBoxInfo
                  severityLevel={MessagebarSeverityLevel.WARNING}
                  className="signeringMessage small"
                  title="Det finnes allerede en signert kontrakt på denne avtalen.
              Er du sikker på at du vil signere en ny avtale?"
                />
              )}
            {!isCash && (
              <>
                <div className={'filter'}>
                  <CheckBox
                    id="confirm-credit"
                    className="dark small signeringSectionCheckbox"
                    label="Jeg bekrefter at kunden er informert om at kredittsjekk vil utføres."
                    onChange={() => setAcceptedTerms({ ...acceptedTerms, creditScore: !acceptedTerms.creditScore })}
                  />
                </div>
                {show && (
                  <div className={'filter'}>
                    <CheckBox
                      id="confirm-santander"
                      className="dark small signeringSectionCheckbox"
                      label="Kunden samtykker til at Bertel O. Steen deler personopplysninger med DNB ASA slik at de kan behandle søknad om finansiering (*)."
                      onChange={() => setAcceptedTerms({ ...acceptedTerms, santander: !acceptedTerms.santander })}
                    />
                  </div>
                )}
              </>
            )}
          </OrderAccordion>
        </div>
        <div className="input-section">
          <ModalActions items={isStockOrder(props?.order?.lead?.orderType) ? 3 : 2} className={'buttonRow'}>
            {orderCreated && !isStockOrder(props?.order?.lead?.orderType) ? (
              <CheckMark text={'SMS sendt til kunde'} />
            ) : (
              <Button
                onClick={() => setShowDigitalModal(true)}
                isLoading={processing === PROCESSING.DIGITAL}
                disabled={disableDigitalSignBtn || isSigningButtonDisabled()}>
                {processing === PROCESSING.DIGITAL ? '' : 'Signer digitalt'}
              </Button>
            )}
            {manualOrderCreated ? (
              <CheckMark text={'Kontrakt lastet opp'} />
            ) : (
              !props?.isTestUser && ( //props?.isTestUser is true then user is test user and we hide manual signing button.
                <Button
                  className={processing === PROCESSING.MANUAL && 'loader-button'}
                  variant={'secondary'}
                  colorVariant="primary"
                  onClick={openManualSigningModal}
                  isLoading={processing === PROCESSING.MANUAL}
                  disabled={disableManualSignBtn || isSigningButtonDisabled()}>
                  {processing === PROCESSING.MANUAL ? '' : 'Signer på papir'}
                </Button>
              )
            )}
            {showStockOrderBtn && (
              <Button
                className={processing === PROCESSING.STOCK && 'loader-button'}
                variant={'secondary'}
                colorVariant="primary"
                onClick={onSubmit}
                isLoading={processing === PROCESSING.STOCK}
                disabled={disableStockOrderBtn || isSigningButtonDisabled()}>
                {'Bestill til lager'}
              </Button>
            )}
          </ModalActions>
          {orderError && <div className={'feedback error-message'}>{orderError}</div>}
        </div>
        <div className="finePrint">
          {(show && !isStockOrder(order?.lead?.orderType)) && (
            <h5>
              Signering vil generere NBF-kontrakt.
              {!isDemoOrder(order?.lead?.orderType) && ' Gjelder bilsalget lån eller leasing vil det sendes søknad til DNB ASA.'}
            </h5>
          )}
          {show && (
            <h5 className="footerText">
              * Dette innebærer at det vil bli foretatt en kredittvurdering med elektronisk utsendelse av gjenpartsbrev.
              For mer informasjon om hvordan DNB ASA behandler dine personopplysninger se
              <Link
                className="footerLink"
                href="https://www.dnb.no/om-oss/personvern"
                target="_blank"
                rel="noopener noreferrer"
                variant="action">
                https://www.dnb.no/om-oss/personvern/
              </Link>
            </h5>
          )}
        </div>
      </SigningContractStyled>
      <AnalogSigningModal
        order={order}
        show={offer && showAnalogModal}
        onConfirm={onAnalogConfirm}
        createOrder={executeCreationOfVehicleOrder}
        softLockForMinutes={props.softLockForMinutes}
      />

      <DigitalSigningModal
        order={order}
        show={offer && showDigitalModal}
        onConfirm={onDigitalConfirm}
        softLockForMinutes={props.softLockForMinutes}
        isTestUser={props.isTestUser}
      />
    </div>
  );
};

SigningContract.defaultProps = {
  softLockForMinutes: 15,
};
