import { bosColor, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const MarginDetailsStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .infoContainer {
    border: solid;
    border-left: none;
    border-right: none;
    border-width: 0.1rem;
    border-color: ${bosColor.neutralPlus};
    font-weight: 600;
    padding: 1rem 0;

    div > span {
      font-weight: ${typography.weight.extrabold} !important;
    }
  }
  .marginInformation {
    color: red;
  }
`;
