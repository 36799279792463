import { PivotField } from '@next-components/pivot-field';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IDropdownOption, InputType, formatNumberInput } from '@next-components/common-types';
import { useState } from 'react';
import { DropDownValues, IDiscountFieldProps, dropDownOptions, getValidity, validateDiscount } from './MarginCartUtils';
import { OnChangeValue } from 'react-select';
import { defaultTo, get } from 'lodash';
import { ICalculatePricesBasedOnArticleListResult } from 'sales-common-calculations';
import { IAuxiliaryCalculationsInput, getVatCalculationFromNextOrder, prepareArticlesFromNextOrder } from 'next-common';

export const CalculateImporterDiscount = (props: IDiscountFieldProps) => {
    const [inputType, setInputType] = useState<InputType>(InputType.INTEGER);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const onCampaignImporterChange = (
        input: string | number,
        dropDownValue?: OnChangeValue<IDropdownOption<any>, false>,
        formatted?: string,
        event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ): void => {
        if (event.type === 'blur') {
            return null;
        }
        const isChanged = getImporterDiscountValue('latestMargin') !== Number(input);
        setHasChanged(isChanged);
        props.setIsValid(validateDiscount(Number(input), dropDownValue?.label as DropDownValues, 25));
        let vatCalculation: ICalculatePricesBasedOnArticleListResult = null;
        let auxillaryInput: IAuxiliaryCalculationsInput = null;
        if (inputType === InputType.INTEGER) {
            auxillaryInput = {
                discount: props.activeMargin?.campaignDiscount,
                discountPercentage: props.activeMargin?.campaignDiscountPercentage,
                importerDiscount: Number(input),
                importerPercentage: props.activeMargin?.campaignImporterPercentage,
            };
            console.log("Margin articles", prepareArticlesFromNextOrder(props.order, auxillaryInput));
            vatCalculation = getVatCalculationFromNextOrder(props.order, auxillaryInput);
        } else {
            auxillaryInput = {
                discount: props.activeMargin?.campaignDiscount,
                discountPercentage: props.activeMargin?.campaignDiscountPercentage,
                importerDiscount: props.activeMargin?.campaignImporter,
                importerPercentage: Number(input),
            };
            console.log("Margin articles", prepareArticlesFromNextOrder(props.order, auxillaryInput));
            vatCalculation = getVatCalculationFromNextOrder(props.order, auxillaryInput);
            props.setIsValid(validateDiscount(Number(input), DropDownValues.PERCENTAGE));
        }
        console.log(vatCalculation);
        props?.setActiveMargin({
            ...get(props, 'activeMargin', []),
            campaignImporter: vatCalculation.calculatedImportContribution,
            ...(inputType === InputType.FLOAT ? { campaignImporterPercentage: Number(input) } : {}),
            marginValue: vatCalculation?.marginAmount,
            marginPercentage: vatCalculation?.marginPercentage,
        });
    };

    const getImporterDiscountValue = (marginObject: string = 'activeMargin'): number => {
        if (inputType === InputType.INTEGER) {
            return get(props, `${marginObject}.campaignImporter`, 0)
        }
        return get(props, `${marginObject}.campaignImporterPercentage`, 0);
    };

    const handleOnChangeDropDown = (dropDownValue: OnChangeValue<IDropdownOption<any>, false>): void => {
        switch (dropDownValue.label) {
            case DropDownValues.KR:
                setInputType(InputType.INTEGER);
                break;
            case DropDownValues.PERCENTAGE:
                setInputType(InputType.FLOAT);
                break;
        }
    };

    return (
        <div className="importerSupportDropdown">
            <label className='pivotFieldLabel'>Importørstøtte</label>
            <PivotField
                hideValidationIcon
                dropDownOptions={dropDownOptions}
                onChangeDropDown={handleOnChangeDropDown}
                onChange={(input, drop, formatted, event) => onCampaignImporterChange(input, drop, formatted, event)}
                value={`${getImporterDiscountValue()}`}
                disabled={false}
                type={inputType}
                decimals={2}
                {...getValidity(hasChanged, getImporterDiscountValue() !== 0 ? props.isValid : null, props.vehicleConfiguration)}
            />
            {defaultTo(props.pimSnapshot.importerSupportExcludingVat, 0) !== 0 &&
                (< Messagebar
                    className='small'
                    messageBoxInfo
                    severityLevel={MessagebarSeverityLevel.INFORMATION}
                    title={`Importørstøtte: ${formatNumberInput(Math.abs(get(props, 'pimSnapshot.importerSupportExcludingVat', 0))?.toString(), inputType, 2)?.formatted || 0} kr`}
                />)
            }
        </div>
    )
};