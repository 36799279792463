import { gql } from '@apollo/client';
import { findServiceContractBySkuQuery, findServiceContractQuery } from 'sales-common';

export const findServiceContractBySku = gql`
  ${findServiceContractBySkuQuery}
`;

export const findServiceContract = gql`
  ${findServiceContractQuery}
`;
