import React, { useState, useEffect } from 'react';

import { IDetailsInfoParams, useDetailsInfo, IDetailsOwnerParams, useDetailsOwner } from '../../../../../common';
import { DetailsInfoStyled } from './DetailsInfoStyled.styled';
import { copyWithIFSUrl } from '../../../../../utils';
import classNames from 'classnames';
import { IBosApiVehicleOwner, IVehicleStatusBosEntity, IOverviewInfoDetails } from 'next-common';

export interface IDeatilsInfoProps {
  infoDetail: IOverviewInfoDetails;
  serialNumber: string;
}

export const DetailsInfo = (props: IDeatilsInfoProps) => {
  const [infoDetails, setInfoDetails] = useState<IVehicleStatusBosEntity>();
  const [ownerDetails, setOwnerDetails] = useState<IBosApiVehicleOwner>();

  const overviewInfoDetails: IOverviewInfoDetails = props.infoDetail; // set info section data from overview
  const infoParams: IDetailsInfoParams = {
    serialNumber: props.serialNumber,
  };

  const dataInfoSection = useDetailsInfo(infoParams);
  useEffect(() => {
    if (dataInfoSection?.getVehicleStatusBosEntity) {
      setInfoDetails(dataInfoSection.getVehicleStatusBosEntity); // set info section data
    }
  }, [dataInfoSection]);
  const ownerParams: IDetailsOwnerParams = {
    id: infoDetails?.vin,
  };
  const dataOwner = useDetailsOwner(ownerParams);
  useEffect(() => {
    if (dataOwner?.getOwnerForCustomer) {
      setOwnerDetails(dataOwner.getOwnerForCustomer); // set eier(Owner)
    }
  }, [dataOwner, infoDetails]);

  // @ts-ignore
  const ifsUrl = window._env_?.REACT_APP_IFS_MARGINS_CUSTOMER_ORDER_URL;
  const url = `${ifsUrl}?WEBCTOPAGE=CUSTOMER_ORDER&ORDER_NO=`;
  return (
    <DetailsInfoStyled>
      <div className="detail-info-bg">
        <div className="container">
          <div className="row-container">
            <div className="full-column info-padding">
              <div className="infosection">
                <div className="row-container">
                  <div className="full-column">
                    <h3 className="infoTitle">Info</h3>
                  </div>
                </div>
                <div className="row-container infodetails">
                  <div className="info-title-container">
                    <div className="span-container">
                      <span>Kunde: </span> <p>{overviewInfoDetails?.customerName}</p>
                    </div>
                    <div className="span-container">
                      <span>Kundenr: </span> <p>{overviewInfoDetails?.customerNo}</p>
                    </div>
                    <div className="span-container">
                      <span>Serienummer: </span> <p>{overviewInfoDetails?.serialNo}</p>
                    </div>
                    <div className="span-container">
                      <span>OEM Ordrenummer: </span> <p>{infoDetails?.oemOrderNo}</p>
                    </div>
                  </div>
                  <div className="info-title-container">
                    <div className="span-container">
                      <span>Merke: </span> <p>{overviewInfoDetails?.make}</p>
                    </div>
                    <div className="span-container">
                      <span>Chassisnr: </span> <p>{infoDetails?.vin}</p>
                    </div>
                    <div className="span-container">
                      <span>Eier: </span>
                      <p>
                        {ownerDetails?.organizationName
                          ? ownerDetails.organizationName
                          : overviewInfoDetails?.customerName}
                      </p>
                    </div>
                    <div className="span-container">
                      <span>Reg. Nr: </span> <p>{infoDetails?.registrationNo}</p>
                    </div>
                  </div>
                  <div className="half-section">
                    <div className="span-container">
                      <span>Artikkel: </span> <p>{infoDetails?.partNo}</p>
                    </div>
                    <div className="span-container">
                      <span>Artikkel beskrivelse: </span> <p>{infoDetails?.partDescription}</p>
                    </div>
                    <div className="span-container">
                      <span>Kundeordre: </span>
                      <span
                        className={classNames(
                          {
                            link: true,
                            'primary-link': true,
                          },
                          'cursor-pointer',
                        )}
                        title={overviewInfoDetails?.orderNumber}
                        key={overviewInfoDetails?.orderNumber}
                        onClick={(e) => {
                          copyWithIFSUrl(overviewInfoDetails?.orderNumber, url, e);
                        }}>
                        {overviewInfoDetails?.orderNumber}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DetailsInfoStyled>
  );
};
export default DetailsInfo;
