import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes } from '../../../../common';
import { DetailsInfo, IDeatilsInfoProps } from './DetailsInfo';
import { DetailsTable, IDetailsTableProps } from './DetailsTable';
import { DetailsStyled } from './DetailsStyled.styled';
import classNames from 'classnames';
import { format } from 'date-fns';
import { IOverviewInfoDetails } from 'next-common';
import { Icon, IconType } from '@next-components/bos-icon';
import { SmallLink } from '@next-components/link';

export const Details = () => {
  const strGoBack: string = 'Gå Tilbake';

  const [overviewInfoDetails, setOverviewInfoDetails] = useState<IOverviewInfoDetails>();

  const params = new URLSearchParams(window.location.search);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setOverviewInfoDetails(location?.state?.detail); // set info section data from overview
    }
  }, [location]);

  const navigate = useNavigate();
  const forwardPage = (page, queryParams) => {
    navigate(
      { pathname: page, search: new URLSearchParams(queryParams).toString() },
      {
        state: { filterValues: location?.state?.filterValues },
      },
    );
  };

  const detailsInfoProps: IDeatilsInfoProps = {
    serialNumber: params.get('serialNo'),
    infoDetail: overviewInfoDetails,
  };

  const detailsTableProps: IDetailsTableProps = {
    companyId: location?.state?.detail?.company,
    dealerId: params.get('dealerId'),
    departmentNo: location?.state?.detail?.departmentNo,
    filterValuesStartDate: format(new Date(location?.state?.filterValues?.startDate), "yyyy-MM-dd'T'hh:mm:ss"),
    filterValuesEndDate: format(new Date(location?.state?.filterValues?.endDate), "yyyy-MM-dd'T'hh:mm:ss"),
    infoDetail: overviewInfoDetails,
    orderNumber: params.get('orderNo'),
    serialNumber: params.get('serialNo'),
  };

  return (
    <DetailsStyled>
      <div className="back-strip">
        <div className="container">
          <div className="inner-container">
            <div className="backLink">
              <div className="iconStyled" onClick={() => forwardPage(Routes.getMarginsPage(), '')}>
                <Icon icon={IconType.NewNextArrowLeft} aria-hidden />
              </div>
              <SmallLink
                className={classNames({
                  link: true,
                  'primary-link': true,
                })}
                onLinkClick={() => forwardPage(Routes.getMarginsPage(), '')}
                text={strGoBack}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <DetailsInfo {...detailsInfoProps} />
        <DetailsTable {...detailsTableProps} />
      </div>
    </DetailsStyled>
  );
};
export default Details;
