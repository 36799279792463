import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IValidationToastNotification } from '../../common';
import { WarningStyled } from './Warning.styled';

interface IErrorNotification extends IValidationToastNotification {
  invalidItems?: string[]
}

const getWarningMessage = (invalidItems: string[]) => (
  <ul>{invalidItems.map((item) => {
    return <li key={item}>{item}</li>
  })}</ul>
)

export const ProdDateWarning = (props: IErrorNotification) => (
  <WarningStyled>
    <div className='notification-area'>
    <Messagebar
      severityLevel={props.severityLevel}
      title='Validering av produksjonsdato'
      onClose={props.onClose}
    >{getWarningMessage(props.invalidItems)}</Messagebar>
    </div>
  </WarningStyled>
);

ProdDateWarning.defaultProps = {
  severityLevel: MessagebarSeverityLevel.ERROR,
};
