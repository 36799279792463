import styled, { css } from 'styled-components';
import { bosColor, fontWeightBold, margin, padding, typography } from '@next-components/common-styles';

const centeredFlexContent = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardTitle = styled.h3`
  color: ${bosColor.black};
  ${margin['0'].a};
  font-size: 1.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${fontWeightBold};
  grid-area: cardTitle;
`;

export const CardDescription = styled.div`
  grid-area: description;
  font-weight: ${typography.weight.semibold};
  font-size: 0.875rem;
  line-height: 1.125rem;
  ${margin['8'].y};
  ${margin['0'].x};
`;

export const CardDetails = styled.div`
  grid-area: carDetails;
  ${padding['0'].y};
  ${padding['24'].x};
  .linkBtn {
    a {
      color: ${bosColor.concrete};
      &:visited {
        color: ${bosColor.concrete};
      }
    }
  }
`;

export const CardPrice = styled.div`
  grid-area: price;
  display: flex;
  flex-direction: column;
  ${padding['0'].y};
  ${padding['24'].x};

  .mainContent {
    ${centeredFlexContent}
    margin-bottom: 1.25rem;
    line-height: 70%;

    .description {
      .label {
        position: relative;
      }
    }

    h4 {
      font-size: 1rem;
      font-weight: ${fontWeightBold};
      ${padding['0'].a};
    }
  }

  .secondaryContent {
    ${centeredFlexContent}
    margin-bottom: 0.625rem;
    div {
      color: ${bosColor.concrete};
    }
    h4 {
      ${margin['0'].a};
      font-weight: ${fontWeightBold};
      font-size: 1rem;
    }
  }
`;

export const CardContent = styled.section`
  display: grid;
  gap: 1.375rem 0;

  > div {
    .cardRightContainer {
      h3.title,
      div.sub-card-header > span.sub-title {
        font-size: 1rem;
      }
    }
    .swatchContainer > div {
      height: auto;
      display: grid;
      grid-template-columns: 6.25rem auto;
      gap: 0;
      justify-content: flex-start;
      align-items: center;
      place-items: initial;

      .single-swatch {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        ${margin['8'].t};
        ${padding['0'].a};

        .checked-icon {
          ${margin['12'].t};
          bottom: initial;
          left: initial;
          transform: rotate(0);
        }
      }
      .child-container {
        ${margin['0'].a};
        display: block;

        h4.title {
          font-size: 1.5rem;
          line-height: 2.25rem;
          letter-spacing: 0.01em;
        }
        p.subtitle {
          display: none;
        }
      }
    }
    .carDescriptionText {
      font-weight: ${typography.weight.semibold};
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: ${bosColor.black};
      padding-left: 6.25rem;
      ${margin['0'].b};
    }
    .secondaryContent {
      padding-left: 6.25rem;
      justify-content: flex-start;
      div,
      h4 {
        font-weight: ${typography.weight.semibold};
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: ${bosColor.black};
      }
    }
    .totalPriceLabel {
      padding-left: 6.25rem;
      margin: 0;
      span {
        font-size: 1.125rem;
        line-height: 1.875rem;
      }
    }
  }
`;
