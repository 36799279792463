import { bosColor, negative, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const StyledFormRow = styled.div`
  .adornment-container {
    top: 0.75rem;
  }
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  gap: 0.25rem;
  margin: 0.5rem 0;
  & > label.labelChild {
    display: flex;
    div {
      padding: 0 4px;
      & > a {
        border-bottom-color: ${bosColor.turquoise};
        color: ${bosColor.black};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.semibold};
        line-height: 175%;
      }
    }
  }

  &.large {
    margin: 1rem 0;
  }

  > * {
    margin-bottom: 0;
  }

  .input-with-warning {
    width: 100%;
    .inputContainer input {
      font-size: 1.125rem;
      margin-bottom: 0;
    }
    &.invalid {
      .inputContainer input {
        border-color: ${negative};
        outline-color: ${negative};
        &:focus {
          border: 1px solid ${bosColor.neutral};
        }
      }
    }
    &.valid {
      .inputContainer input {
        border-color: ${bosColor.concrete};
      }
    }
    .errorMessage {
      display: none;
    }
    label.smallFont {
      font-weight: ${typography.weight.semibold};
      line-height: 175%;
      color: ${bosColor.black};
      margin-bottom: 0.25rem;
    }
  }

  p,
  h1 {
    text-align: right;
  }

  h1 {
    margin: 0;
    padding: 0;
  }

  .inputContainer {
    textarea,
    input {
      font-weight: 500;
      &.startAdornmentInput {
        text-indent: 30px;
        padding: 0.75rem 1rem;
      }
    }
  }

  .loader > div {
    display: flex;
    justify-content: end;

    .lds-ellipsis {
      display: flex;
      justify-content: center;
      height: 60px;
      width: 60px;
    }
  }
`;

export const FormRowValidationWarning = styled.div`
  min-height: 16px;
  p {
    color: ${negative};
    text-align: right;
    font-size: 1rem;
    margin-bottom: 0;
    margin-right: 16px;
  }
`;
