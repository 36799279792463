import React from 'react';
import { Icon, IconType } from '@next-components/bos-icon';

export interface ICheckMarkProps {
  text: string;
}

export const CheckMark = (checkMarkProps: ICheckMarkProps) => (
  <div className={'check-mark-container'}>
    <Icon icon={IconType.NewNextCheck} className={'check-mark'} />
    <div className={'check-mark-text'}>{checkMarkProps.text}</div>
  </div>
);
