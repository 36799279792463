import React, { useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from '../../config/authentication';
import { InteractionStatus } from '@azure/msal-browser';

const MsalAuth = (props: any) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((error) => {
        console.log(error);
      });
    }
  }, [isAuthenticated, instance, inProgress]);

  return <>{props.children}</>;
};

export default MsalAuth;
