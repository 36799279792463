import React from 'react';
import { InfoBlockStyled } from './InfoBlock.styled';
import { formatNumber } from '@next-components/common-types';

export interface IInfoBlockProps {
  label: string;
  value?: string | number;
  children?: React.ReactNode;
  className?: string;
}

export const InfoBlock = (props: IInfoBlockProps) => {
  const getFormattedValue = () => {
    if (typeof props.value === 'number') {
      return formatNumber(props.value);
    } else {
      return props.value || '-';
    }
  };

  return (
    <InfoBlockStyled className={props.className}>
      <span className="label">{props.label}</span>
      {props.value && <span className="value">{getFormattedValue()}</span>}
      {props.children}
    </InfoBlockStyled>
  );
};
