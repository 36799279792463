import { bosColor, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const CommentPaneStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CommentPaneHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  > div.topWrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;

    button.newComment {
      padding: 0.75rem 1.5rem;
      color: ${bosColor.black};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold};
      line-height: 150%;
    }
  }
  h3.title {
    color: ${bosColor.black};
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
`;

export const CommentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .noRecords {
    padding: 0.75rem 1.5rem;
    background: white;
    border: 1px solid ${bosColor.neutralPlus};
    border-radius: 0.25rem;
    text-align: center;
    color: ${bosColor.black};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;
  }
`;
