import styled from 'styled-components';
import { bosColor, disabled, typography } from '@next-components/common-styles';

export const OverviewHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1.5rem;

  .titleSection {
    display: flex;
    flex-direction: column;
  }
  .button .text {
    font-size: 18px;
  }
  .icon {
    margin-top: -5px;
    margin-left: -5px;
    height: 21px;
    width: 21px;
  }
  .name {
    margin-left: 2px;
    font-size: 16px;
  }
  .ident {
    font-size: 16px;
    margin-left: 5px;
    color: ${disabled};
  }
  .dealerIds {
    margin-left: 5px;
  }
  .newHeader {
    margin: 0;
    color: ${bosColor.black};
    font-size: 2rem;
    font-weight: ${typography.weight.extrabold};
    line-height: 139%;
  }
  .newSubHeader {
    margin: 0;
    color: ${bosColor.black};
    font-size: 1.125rem;
    font-weight: ${typography.weight.semibold};
  }
  #sellerContainer {
    min-width: 18rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;

    .addNewOrder {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .dropdown-container {
      width: 18rem;
    }
  }
`;

export const SellerStyled = styled.div`
  height: 35px;
  display: flex;
  place-items: center flex-start;
`;
