import React from 'react';
import styles from './ForbiddenPage.module.scss';
import { Button } from '@next-components/cta';

const openInNewTab = (url: string) => {
  const win = window.open(url, '_blank');
  win.focus();
};

const openTicketNow = () => {
  openInNewTab(
    'https://bos.service-now.com/sp?id=sc_cat_item_new_ux&sys_id=efd0f51fdb08a8108cf4cd4d0b9619e7&sc_category=343c30fe4fd512004e1bfcb28110c740',
  );
};

const ForbiddenPage = () => {
  return (
    <div className={styles.forbidden}>
      <div className={styles.center}>
        <h1>Din bruker har ingen tilgang</h1>
        <p>Dette kan skyldes en av følgende årsaker:</p>
        <ul>
          <li>Din forhandler har ikke tilgang til denne nettsiden.</li>
          <li>Du er ikke logget inn med din Bertel O Steen Microsoft-konto.</li>
        </ul>
        <Button onClick={openTicketNow}>Be om tilgang</Button>
        <p className={styles.link}>
          Hvis knappen ikke fungerer, kan du melde inn i{' '}
          <a href="https://bos.service-now.com" target="_blank">
            ServiceNow
          </a>{' '}
          under <b>Meld sak IT-hjelp / Next</b>.
        </p>
      </div>
    </div>
  );
};

export default ForbiddenPage;
