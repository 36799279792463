import styled from 'styled-components';

export const EclipsTextStyled = styled.div`
  .eclipsText {
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    width: 70px;
    height: 20px;
    font-size: 14px;
  }
  .eclipsText-big {
    width: 297px;
  }
`;
