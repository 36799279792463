import styled, { css } from 'styled-components';
import { fontWeightBold, white, bosColor, margin, typography, padding } from '@next-components/common-styles';

const inputField = css`
  &:disabled {
    background-color: ${white};
  }
`;

const includeMva = css`
  margin-top: 0.625rem;
`;

const inputLabel = css`
  font-weight: bold;
`;

export const CardInstruction = styled.h4`
  grid-area: instruction;
  font-weight: ${fontWeightBold};
  font-size: 1.125rem;
  line-height: 1.6875rem;
  ${margin['0'].a};
`;

export const CheckBoxContainer = styled.section`
  grid-area: checkBox-container;
  text-align: left;
  position: absolute;
  left: -0.875rem;
}
  
`;
export const CardInputDescription = styled.section`
  grid-area: input-description;
  text-align: left;
  .inputDescriptionText {
    .inputContainer {
      margin-bottom: 1.125rem;
      label {
        font-size: 0.875rem;
        line-height: 1.25rem;
        display: block;
        margin-bottom: 1.125rem;
        ${margin['8'].t};
      }
    }
  }
  textarea#inputDescription {
    ${inputField}
    height: 7.5rem;
  }

  .descriptionText {
    font-weight: ${typography.weight.semibold};
    font-size: 1.125rem;
    line-height: 1.8125;
    letter-spacing: 0.02rem;
    ${margin['8'].y};
    ${margin['0'].x};
    ${padding['0'].a};
  }
`;
export const CardInputPrice = styled.section`
  grid-area: input-price;
  text-align: left;

  .inputFieldContainer {
    label {
      font-size: 0.875rem;
    }
  }
  .filter {
    ${includeMva}
  }

  > label {
    ${inputLabel}
  }
  small {
    font-size: 0.875rem;
    display: block;
    ${margin['4'].t};
    color: ${bosColor.concrete};
  }

  .priceTextContainer {
    .priceText {
      font-weight: ${typography.weight.semibold};
      font-size: 1.125rem;
      line-height: 1.8125;
      letter-spacing: 0.02rem;
      ${margin['8'].y};
      ${margin['0'].x};
    }
  }

  .inputFieldContainer {
    .inputContainer {
      label {
        line-height: 1.125rem;
        display: block;
        padding-bottom: 0.5rem;
      }
    }
    label {
      margin-top: 0.5rem;
      display: flex;
      line-height: 1.25rem;
    }
  }

  > div:last-child {
    ${margin['4'].t};
  }
`;

export const CardButtonSection = styled.section`
  grid-area: Button-Section;
  text-align: left;
  margin-top: 0.875rem;

  .footerInnerContainer {
    display: flex;
    justify-content: space-between;

    .switchButtonContainer {
      display: flex;
      flex-direction: column;

      p.lableText {
        ${margin['8'].t};
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: ${typography.weight.semibold};
        ${padding['0'].b};
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 2.8125rem;
        height: 1.375rem;

        input {
          opacity: 0;
          width: 0;
          height: 0;
          &:checked + .slider:before {
            -webkit-transform: translateX(1.375rem);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
          }
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: 0.4s;
          transition: 0.4s;
          width: 2.8125rem;
          height: 1.375rem;
          border: 0.125rem solid ${bosColor.black};
          border-radius: 2.25rem;
          background: transparent;
          &:before {
            position: absolute;
            content: '';
            height: 0.6875rem;
            width: 0.6875rem;
            left: 0.25rem;
            bottom: 0.25rem;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            background: ${bosColor.black};
            border-radius: 2.25rem;
          }
        }
      }
    }

    > button {
      ${padding['0'].x};
      ${padding['0'].t};
      ${padding['8'].b};
      height: 2.5rem;
      border-bottom: 0.1875rem solid ${bosColor.black};
      display: inline-flex;
      width: auto;

      &:after {
        display: none;
      }
    }
  }
  input[type='checkbox']#includeMva:disabled + span.slider.round {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;
