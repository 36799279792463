import { actions, initialState } from './actions';

export const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case actions.VEHICLE_CHANGE:
      return {
        ...state,
        modelId: action?.payload?.modelId,
        variantId: action?.payload?.variantId,
      };
    default: {
      return state;
    }
  }
};
