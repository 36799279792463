import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { formatNumber, InputType } from '@next-components/common-types';
import { InputField } from '@next-components/input-field';
import { FinancingType, IOrder, ITradeInVehicle } from 'next-common';

import { saveTradeInMutationParsed } from '../../../../../common';
import { saveTradeIn } from '../../../../../utils';
import { AgreementArea } from '../../sales-signing-agreement/SigningAgreement.styled';
import { InfoBlock } from '../../../sales-common/InfoBlock';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';

const emptyTradeIn: ITradeInVehicle = {
  id: null,
  capital: 0,
  claimant: '',
  debt: 0,
  loan: 0,
  price: 0,
  mileage: 0,
  make: '',
  model: '',
  registrationNumber: '',
};

export interface ITradeInProps {
  financingType: FinancingType;
  order: IOrder;
  tradeIns: ITradeInVehicle[];
}

interface MutationProps {
  invoke: boolean;
  order: IOrder;
  tradeIn: ITradeInVehicle;
}

const MutationFragment = (props: MutationProps) => {
  const { invoke, order, tradeIn } = props;
  const [saveTradeInMutation] = useMutation(saveTradeInMutationParsed, { fetchPolicy: 'no-cache' });
  const updateMutation = async () => {
    try {
      await saveTradeIn(order.id, tradeIn, saveTradeInMutation);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (invoke && tradeIn) updateMutation();
  }, [invoke, tradeIn]);
  return null;
};

export const TradeIn = (props: ITradeInProps) => {
  const [selectedTradeIn, setSelectedTradeIn] = useState(props.tradeIns[0] || emptyTradeIn);
  const [tradeInPayload, setTradeInPayload] = useState<ITradeInVehicle>(null);
  const [invokeMutation, setInvokeMutation] = useState<boolean>(false);

  const onUpdateTradeIn = async () => {
    const tradeInToUpdate = selectedTradeIn;
    setTradeInPayload(tradeInToUpdate);
  };

  const onValueChange = (field, value) => {
    setSelectedTradeIn({
      ...selectedTradeIn,
      [field]: value,
    });
  };

  const onNumericValueChange = (field, value) => {
    const parsedNumber = parseFloat(value || 0);
    setSelectedTradeIn({
      ...selectedTradeIn,
      [field]: parsedNumber,
    });
  };

  const downPayment = props.order?.chosenOffer?.financing?.request?.downPayment || 0;
  const totalCost = props.order?.chosenOffer?.financing?.request?.investedAmount || 0;
  const resultSwapCar = selectedTradeIn.price - selectedTradeIn.debt;
  const paidByFinancing = props.order?.chosenOffer?.financingType !== FinancingType.CASH ? totalCost - downPayment : 0;
  const paidUponDelivery = totalCost - resultSwapCar - paidByFinancing;
  const sum = resultSwapCar + paidByFinancing + paidUponDelivery;

  useEffect(() => {
    if (tradeInPayload) setInvokeMutation(true);
  }, [tradeInPayload]);

  return (
    <OrderAccordion label='Betalingsinformasjon'>
      <MutationFragment invoke={invokeMutation} order={props.order} tradeIn={tradeInPayload} />
      <div className="input-section">
        <div className="input-block">
          <InputField
            id="price"
            label="Estimert innbyttepris"
            value={selectedTradeIn.price}
            onChange={(value) => onNumericValueChange('price', value)}
            type={InputType.FLOAT}
            className="borderLight"
            endAdornment="kr"
            placeholder="0"
            decimals={2}
            onBlur={onUpdateTradeIn}
          />
          <InputField
            id="debt"
            label="Gjeld (valgfritt)"
            value={selectedTradeIn.debt}
            onChange={(value) => onNumericValueChange('debt', value)}
            type={InputType.FLOAT}
            className="borderLight"
            endAdornment="kr"
            placeholder="0"
            decimals={2}
            onBlur={onUpdateTradeIn}
          />
          <InputField
            id="claimant"
            className="borderLight"
            label="Fordringshaver"
            value={selectedTradeIn.claimant}
            onChange={(value) => onValueChange('claimant', value)}
            placeholder="Skriv selskapsnavn"
            onBlur={onUpdateTradeIn}
          />
        </div>
        <div className="info-section">
          <AgreementArea>
            <InfoBlock label="Resultat innbyttebil" value={`${formatNumber(resultSwapCar)} kr`} />
            <InfoBlock label="Betales med finansiering" value={`${formatNumber(paidByFinancing)} kr`} />
            <div className="tradein-info-container">
              <span>Betales ved levering</span>
              <span className="value">{`${formatNumber(paidUponDelivery)} kr`}</span>
            </div>
            <InfoBlock label="SUM" value={`${formatNumber(sum)} kr`} />
          </AgreementArea>
        </div>
      </div>
    </OrderAccordion>
  );
};
