import styled from 'styled-components';
import {
  appContentNewMaxWidth,
  black,
  bosColor,
  fontWeightExtraBold,
  margin,
  typography,
  white,
} from '@next-components/common-styles';

export const CustomerSelectedStyled = styled.div`
  display: grid;
  justify-content: left;
  height: min-content;
  max-width: ${appContentNewMaxWidth};
  margin: 0 auto;
  padding: 0;
  gap: 1.5rem;

  .customer-type-container {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-column-gap: 1rem;
    margin-top: 3rem;
    .checkBox {
      margin-right: 1rem;
      font-weight: ${fontWeightExtraBold};
    }
  }

  .confirmation-checkbox > div {
    display: flex;
    padding: 0 1.5rem;
    align-items: center;
    gap: 0.5rem;

    [type='checkbox']:checked + label,
    [type='checkbox']:not(:checked) + label {
      padding: 0;
    }
    input {
      padding: 0px;
      margin-top: 2px;
    }
    label {
      padding: 0;
      margin-bottom: 0;
      color: ${bosColor.black};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 150%;
    }
  }
`;

export const FormHeader = styled.div`
  ${margin[0].a};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25.1875rem;
  align-self: stretch;
  border-bottom: 1px solid ${bosColor.neutralGrey};
  background: ${bosColor.white};
  gap: 2rem;
  padding: 1rem 1.5rem;

  .headerText {
    font-size: 1.25rem;
    color: ${bosColor.black};
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 150%;
  }
`;

export const CustomerFormStyled = styled.div`
  display: flex;
  padding: 0 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  color: ${black};

  .inputGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 1.5rem;
    width: 100%;

    &.threeGrid {
      grid-template-columns: 1fr 2fr 1fr;
    }
  }

  input {
    margin-bottom: 0;
  }
  .adornment-container {
    top: 1.05rem;
    margin-top: -1px;
  }
  .inputContainer {
    textarea,
    input {
      &.startAdornmentInput {
        text-indent: 30px;
      }
    }
    .inputAndIconContainer {
      input {
        font-size: 1rem;
      }
      span {
        font-size: 1rem;
      }
    }
  }

  .single-textbox {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column-gap: 2rem;
  }
`;

export const RadioButtonStyled = styled.div`
  display: flex;
  grid-column-gap: 2rem;
  margin-bottom: 10px;

  > div {
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      background: ${white};
    }
  }
`;
