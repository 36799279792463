import { FetchPolicy, useQuery } from '@apollo/client';
import { IBosApiDealerInfoData } from 'next-common';
import { dealerNameQuery, queryDealer } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export interface IDealerParams {
  dealerId: string;
  fetchPolicy?: FetchPolicy;
}

export interface IDealerResult {
  dealer: IBosApiDealerInfoData;
  loading: boolean;
}

export const useDealer = (params: IDealerParams, overWriteQuery = false): IDealerResult => {
  const query = overWriteQuery ? dealerNameQuery : queryDealer
  const { data, loading } = useQuery(query, {
    variables: { dealerId: params.dealerId },
    skip: !params.dealerId,
    errorPolicy: ERROR_POLICY,
    fetchPolicy: params.fetchPolicy || 'cache-first',
  });
  return { dealer: data?.dealer, loading };
};
