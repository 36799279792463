import React from 'react';
import { IComment } from 'next-common';
import { Comment } from './Comment';
import { orderBy, isEmpty } from 'lodash';
import { StyledLatestComment } from './LatestComment.styled';
import { Routes } from '../../../../common';
import { OrderViewTabs } from '../enums';
import { useNavigate } from 'react-router-dom';
import { SmallLink } from '@next-components/link';

export interface ILatestCommentPaneProps {
  type: string;
  orderId: string;
  comments: IComment[];
}

const LatestCommentPane = (props: ILatestCommentPaneProps) => {
  const comments = orderBy(props.comments || [], ['timestamp'], ['desc']).map((c) => ({ ...c, type: props.type }));
  const navigate = useNavigate();

  const onShowComments = () => {
    const route = Routes.getViewPage(props.orderId);
    navigate(`${route}?tab=${OrderViewTabs.COMMENTS}`);
  };

  return (
    <StyledLatestComment>
      {comments.length > 0 && <Comment comment={comments[0]} />}
      <div className={'commentFooter'}>
        {isEmpty(comments) && <p className={'disabledShowComments'}>Ingen kommentarer</p>}
        {!isEmpty(comments) && (
          <div className={'commentCount'}>
            <SmallLink
              onLinkClick={onShowComments}
              text={`Vis ${comments.length} ${comments.length === 1 ? 'kommentar' : 'kommentarer'}`}
            />
          </div>
        )}
      </div>
    </StyledLatestComment>
  );
};

export default LatestCommentPane;
