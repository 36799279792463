import React from 'react';
import { View, Text, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { ITradeInVehicle, MAKE } from 'next-common';
import { formatNumber } from '@next-components/common-types';
import {
  HorizontalWarpableListStyled,
  LegalStuffStyled,
  OptionDataStyled,
  OptionPriceStyled,
  SectionStyled,
} from './common-styles';
import { backgroundCard, bosBrand, kiaColor, lightGray, mercedesColor, peugeotColor, srtColor } from '@next-components/common-styles';
import { textToTitleCase } from '../salesOfferPdf.utils';
import { TradeInVehiclesMakeStyle } from './TradeInVehicles.style';

Font.registerHyphenationCallback((word) => [word]);

const OptionElementsStyled: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
};

const OptionElementsTradeInStyled: Style = {
  padding: '14 6 6 6',
  backgroundColor: backgroundCard,
  width: '25%',
};

const OptionBrandStyled: Style = {
  padding: '14 6 6 16',
  backgroundColor: backgroundCard,
  width: '25%',
};

const OptionElement = (props) => (
  <View style={props.label === 'Merke' ? OptionBrandStyled : OptionElementsTradeInStyled}>
    <Text style={TradeInVehiclesMakeStyle[props.dealerRole][props.make]['optionElementLabel']}>{props.label}</Text>
    <Text style={TradeInVehiclesMakeStyle[props.dealerRole][props.make]['optionElementValue']}>{props.value}</Text>
  </View>
);

const getBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.black;
      case MAKE.Mercedes:
        return mercedesColor.black;
      case MAKE.Peugeot:
        return peugeotColor.black;
      case MAKE.Kia:
        return kiaColor.black;
      default:
        return bosBrand;
    }
  } else {
    return bosBrand;
  }
};

const getLegalBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    return backgroundCard;
  } else {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.silver;
      default:
        return lightGray;
    }
  }
};

const OptionPrice = (props) => {
  const bgColor = getBgColor(props?.dealerRole, props?.make)
  return (
    <View style={{ ...OptionPriceStyled, backgroundColor: bgColor }}>
      <Text style={TradeInVehiclesMakeStyle[props.dealerRole][props.make]['optionPriceLabel']}>{props.label}</Text>
      <Text style={TradeInVehiclesMakeStyle[props.dealerRole][props.make]['optionPriceValue']}>{props.value}</Text>
    </View>
  )
};

const LegalTradIn = (props) => (
  <View style={{ ...LegalStuffStyled, backgroundColor: getLegalBgColor(props.dealerRole, props?.make) }}>
    <Text style={{ fontSize: 7 }}>Estimert innbyttepris forbeholdt taksering av innbyttebilen</Text>
  </View>
);

export interface ITradeInProps extends ITradeInVehicle {
  dealerRole: string;
  makeName: string;
}

const TradeIn = (props: ITradeInProps) => (
  <View style={OptionDataStyled}>
  <View style={{ flexDirection: 'column', width: '75%', height: '100%' }}>
    <View style={OptionElementsStyled}>
      <OptionElement value={textToTitleCase(props.make)} label="Merke" dealerRole={props?.dealerRole} make={props?.makeName} />
      <OptionElement value={textToTitleCase(props.model)} label="Modell" dealerRole={props?.dealerRole} make={props?.makeName} />
      <OptionElement value={props.registrationNumber} label="Registreringsnr." dealerRole={props?.dealerRole} make={props?.makeName} />
      <OptionElement value={`${formatNumber(props.mileage)} km`} label="Km-stand" dealerRole={props?.dealerRole} make={props?.makeName} />
    </View>
    <LegalTradIn dealerRole={props?.dealerRole} make={props?.makeName} />
  </View>
  <OptionPrice value={`${formatNumber(props.price || 0)} kr`} label="Estimert innbyttepris" dealerRole={props?.dealerRole} make={props?.makeName} />
</View>
);

interface ITradeInVehiclesProps {
  tradeIns: ITradeInVehicle[];
  dealerRole?: string;
  make?: string;
}

export const TradeInVehicles = (props: ITradeInVehiclesProps) => {
  if (!props.tradeIns || props.tradeIns?.length < 1) return null;
  return (
    <View style={SectionStyled} break={true}>
      <Text 
        style={TradeInVehiclesMakeStyle[props.dealerRole][props.make]['tradInLabel']}
      >
        Innbyttebil{props.tradeIns?.length !== 1 ? 'er' : ''}
      </Text>
      <View style={HorizontalWarpableListStyled}>
        {props.tradeIns.map((tI) => (
          <TradeIn key={tI?.registrationNumber} {...tI} dealerRole={props?.dealerRole} makeName={props?.make} />
        ))}
      </View>
    </View>
  );
};
