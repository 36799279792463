import Axios from 'axios';
import { AccessoryType, IVehicleAccessory, IVehicleConfiguration, IVehicleImage } from 'next-common';
import { IAccessoryConfig } from './SalesOfferPdf';
import accessoryPlaceholder from './imgs/fragilebox.png';
import carPlaceholder from './imgs/coveredcar.png';
import { SourceObject } from '@react-pdf/types';
import { Buffer } from 'buffer';

export const getImageSource = async (images: IVehicleImage[], type: string): Promise<SourceObject> => {
  const mainImg = images && images.length > 0 ? images.find((x) => !!x.url) : null;
  const placeholder = type === 'vehicle' ? carPlaceholder : accessoryPlaceholder;
  if (!mainImg) return placeholder;
  const variantImg =
    type !== 'vehicle' ? mainImg.variants.find((x) => x.width === 100 && x.url.includes('.jpeg')) : null;
  const image = variantImg || mainImg;
  const format = image.url.split('.').pop() === 'png' ? 'png' : 'jpg';
  try {
    const imgResult = await Axios.get(image.url + `?latestVersion=${+new Date()}`, { responseType: 'arraybuffer' });
    if (imgResult.status !== 200) {
      return placeholder;
    }

    // @react-pdf for some reason doesn't render some png images, so added following
    // condition which internally takes care of the format
    if (format === 'png') {
      return Buffer.from(imgResult?.data || '');
    }

    return { data: Buffer.from(imgResult.data), format };
  } catch (error) {
    return placeholder;
  }
};

const findConfigAccessory = (vehicle: IVehicleConfiguration, accessoryId: string) => {
  return vehicle?.accessories?.find((x) => x.id === accessoryId);
};

const findConfigTireSet = (vehicle: IVehicleConfiguration, accessoryId: string) => {
  return vehicle?.tires?.find((x) => x.id === accessoryId);
};

export const findConfigValues = async (
  vehicleConfig: IVehicleConfiguration,
  vehicleAccessory: IVehicleAccessory,
): Promise<IAccessoryConfig> => {
  const accessoryId = vehicleAccessory?.customId?.split('_')[1];
  const accessory = findConfigAccessory(vehicleConfig, accessoryId);
  if (accessory) {
    const image = await getImageSource(accessory.images, 'accessory');
    return {
      id: vehicleAccessory.customId,
      type: AccessoryType.ACCESSORY,
      name: accessory.name,
      description: accessory.description,
      price: vehicleAccessory.price,
      priceExcludingVat: vehicleAccessory.priceExcludingVat,
      image,
      priceIncludingVatIce: accessory.priceIncludingVatIce,
      priceIncludingVatEl: accessory.priceIncludingVatEl,
      priceExcludingVatIce: accessory.priceExcludingVatIce,
      priceExcludingVatEl: accessory.priceExcludingVatEl,
      calculatedPriceBusiness: vehicleAccessory?.calculatedPriceBusiness,
      calculatedPricePrivate: vehicleAccessory?.calculatedPricePrivate,
    };
  }
  const tireSet = findConfigTireSet(vehicleConfig, accessoryId);
  if (tireSet) {
    const image = await getImageSource(tireSet.images, 'tireSet');
    return {
      id: vehicleAccessory.customId,
      type: AccessoryType.TYRE,
      name: `${tireSet.isRecommended ? 'Standard' : 'Alternative'} vinterhjul`,
      description: tireSet.description,
      price: vehicleAccessory.price,
      priceExcludingVat: vehicleAccessory.priceExcludingVat,
      image,
      priceIncludingVatIce: tireSet.priceIncludingVatIce,
      priceIncludingVatEl: tireSet.priceIncludingVatEl,
      priceExcludingVatIce: tireSet.priceExcludingVatIce,
      priceExcludingVatEl: tireSet.priceExcludingVatEl,
      calculatedPriceBusiness: vehicleAccessory?.calculatedPriceBusiness,
      calculatedPricePrivate: vehicleAccessory?.calculatedPricePrivate,
    };
  }
  return {
    name: vehicleAccessory.name,
    type: AccessoryType.CUSTOM,
    description: vehicleAccessory.description,
    price: vehicleAccessory.price,
    priceExcludingVat: vehicleAccessory.priceExcludingVat,
    image: accessoryPlaceholder,
    id: vehicleAccessory.customId,
    accessoryType: vehicleAccessory.accessoryType,
    isCustomIncludeMVA: vehicleAccessory.isCustomIncludeMVA,
    calculatedPriceBusiness: vehicleAccessory?.calculatedPriceBusiness,
    calculatedPricePrivate: vehicleAccessory?.calculatedPricePrivate,
  };
};

export const textToTitleCase = (text: string) => {
  return (
    text
      ?.toLowerCase()
      ?.split(' ')
      ?.map((title) => title?.charAt(0)?.toUpperCase() + title?.substring(1))
      ?.join(' ')
      ?.replace(/\-[a-z]/g, (match) => match?.toUpperCase()) || ''
  );
};
