import styled from 'styled-components';
import { bosColor, typography } from '@next-components/common-styles';

export const CommentStyled = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid ${bosColor.neutralPlus};
  background: ${bosColor.white};

  .commentInner {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
  }
  .commentHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .type {
      color: ${bosColor.black};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold};
      line-height: 175%;
    }
    .timeStamp {
      color: ${bosColor.concrete};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 170%;
    }
  }
  .commentBody {
    color: ${bosColor.black};
    font-size: 1rem;
    font-style: normal;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;
    word-break: break-word;
  }
  .commentFooter {
    display: flex;
    justify-content: space-between;
    color: ${bosColor.concrete};
    margin-top: 0.5rem;
    margin-bottom: 2rem;

    .commentFooterItem {
      display: flex;
      gap: 0.25rem;
    }
    .icon {
      margin-top: -2px;
      width: 1.25rem;
      height: 1.25rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .authorName {
      color: ${bosColor.concrete};
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 170%;
    }
  }
`;
