import React, { useState } from 'react';
import { CustomModal } from '@next-components/custom-modal';
import { ModalActions } from '../modal.styled';
import { CommentBox, CommentBoxContainer, CommentBoxModalBody } from './CommentBox.styled';
import { Button } from '@next-components/cta';
import { CustomModalWrapper } from '../../../layout/CustomModalWrapper.styled';
import { ModalWrapper } from '../../../common-components/ModalWrapper';
import { FormHeader } from '../NewHeader/CustomerInfo/CustomerPage/CustomerPage.styled';

export interface ICommentModalProps {
  onSubmit(value: string): void;
  close(): void;
  isOpen: boolean;
  title: string;
}

export const CommentModal = (props: ICommentModalProps) => {
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
    setIsLoading(true);
    await props.onSubmit(comment);
    setComment('');
    setIsLoading(false);
  };

  const handleModalClose = () => {
    setComment('');
    props.close();
  };

  if (!props.isOpen) return null;

  return (
    <CustomModalWrapper className="backdrop smModal width32 commentModal" active={props.isOpen}>
      <ModalWrapper isActive={props?.isOpen}>
        <CustomModal onCloseClick={handleModalClose} active={props.isOpen} isModelCloseAllowedOutside={false}>
          <FormHeader>
            <div className="headerText">{props?.title || ''}</div>
          </FormHeader>

          <CommentBoxModalBody>
            <CommentBoxContainer>
              <label className="commentBoxLabel">Din kommentar</label>
              <CommentBox
                className={'commentTextArea'}
                onChange={(event) => setComment(event.currentTarget.value)}
                onBlur={(event) => setComment(event.currentTarget.value)}
                disabled={isLoading}
                value={comment}
              />
            </CommentBoxContainer>

            <ModalActions items={2} className="commentBoxModal">
              <Button disabled={!comment.trim()} onClick={onSubmit} isLoading={isLoading}>
                Lagre kommentar
              </Button>
              <Button onClick={handleModalClose} variant={'secondary'}>
                Avbryt
              </Button>
            </ModalActions>
          </CommentBoxModalBody>
        </CustomModal>
      </ModalWrapper>
    </CustomModalWrapper>
  );
};
