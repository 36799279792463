import { IBosApiVehicleMarginCalculationDetails } from 'next-common';
import get from 'lodash/get';
import { IAction } from '../../../../../../../common';
import { marginsActionTypes } from './../action-types';
import {
  IResetAllSyncFiltersAction,
  IResetDepartmentNumberFiltersAction,
  IResetMakeFiltersAction,
  IResetModelFiltersAction,
  IResetMonthFiltersAction,
  IResetSalesPersonFiltersAction,
  IResetSerialNumberFiltersAction,
  ISetAllSyncFiltersAction,
  ISetDepartmentNumberFiltersAction,
  IToggleHideWithoutSalesFiltersAction,
  ISetMakeFiltersAction,
  ISetModelFiltersAction,
  ISetMonthFiltersAction,
  ISetSalesPersonFiltersAction,
  ISetSerialNumberFiltersAction,
} from './../actions';
import { IDropdownOption } from 'sales-common';
import { deriveNewSelectedOnUserFocused } from '../../../../../../../utils';
import { FieldHierarchy, computeReducedState } from './../../OverviewState';

export interface IMarginsSyncFiltersState {
  syncFilteredMargins: IBosApiVehicleMarginCalculationDetails[];
  months: {
    selectedFilters: IDropdownOption<string>[];
    availableOptions: IDropdownOption<string>[];
    visibleSelectedFilters: IDropdownOption<string>[];
  };
  makes: {
    selectedFilters: IDropdownOption<string>[];
    availableOptions: IDropdownOption<string>[];
    visibleSelectedFilters: IDropdownOption<string>[];
  };
  models: {
    selectedFilters: IDropdownOption<string>[];
    availableOptions: IDropdownOption<string>[];
    visibleSelectedFilters: IDropdownOption<string>[];
  };
  serialNumbers: {
    selectedFilters: IDropdownOption<number>[];
    availableOptions: IDropdownOption<number>[];
    visibleSelectedFilters: IDropdownOption<number>[];
  };
  departmentNumbers: {
    selectedFilters: IDropdownOption<number>[];
    availableOptions: IDropdownOption<number>[];
    visibleSelectedFilters: IDropdownOption<number>[];
  };
  salesPersons: {
    selectedFilters: IDropdownOption<string>[];
    availableOptions: IDropdownOption<string>[];
    visibleSelectedFilters: IDropdownOption<string>[];
  };
  hideWithoutSales: boolean;
}

export const initialMarginsFiltersState: IMarginsSyncFiltersState = {
  syncFilteredMargins: [],
  months: {
    selectedFilters: [],
    availableOptions: [],
    visibleSelectedFilters: [],
  },
  makes: {
    selectedFilters: [],
    availableOptions: [],
    visibleSelectedFilters: [],
  },
  models: {
    selectedFilters: [],
    availableOptions: [],
    visibleSelectedFilters: [],
  },
  serialNumbers: {
    selectedFilters: [],
    availableOptions: [],
    visibleSelectedFilters: [],
  },
  departmentNumbers: {
    selectedFilters: [],
    availableOptions: [],
    visibleSelectedFilters: [],
  },
  salesPersons: {
    selectedFilters: [],
    availableOptions: [],
    visibleSelectedFilters: [],
  },
  hideWithoutSales: false,
};

export const marginsReducer = (state: IMarginsSyncFiltersState, action: IAction) => {
  switch (get(action, 'type')) {
    case marginsActionTypes.SET_ALL_SYNC_FILTERS:
      return setAllSyncFiltersReducer(state, action as ISetAllSyncFiltersAction);
    case marginsActionTypes.RESET_ALL_SYNC_FILTERS:
      return resetAllSyncFiltersReducer(state, action as IResetAllSyncFiltersAction);
    case marginsActionTypes.SET_MONTH_FILTER:
      return setMonthFilterReducer(state, action as ISetMonthFiltersAction);
    case marginsActionTypes.RESET_MONTH_FILTER:
      return resetMonthFilterReducer(state, action as IResetMonthFiltersAction);
    case marginsActionTypes.SET_MAKE_FILTER:
      return setMakeFilterReducer(state, action as ISetMakeFiltersAction);
    case marginsActionTypes.RESET_MAKE_FILTER:
      return ResetMakeFilterReducer(state, action as IResetMakeFiltersAction);
    case marginsActionTypes.SET_MODEL_FILTER:
      return setModelFilterReducer(state, action as ISetModelFiltersAction);
    case marginsActionTypes.RESET_MODEL_FILTER:
      return ResetModelFilterReducer(state, action as IResetModelFiltersAction);
    case marginsActionTypes.SET_SERIAL_NUMBER_FILTER:
      return setSerialNumberFilterReducer(state, action as ISetSerialNumberFiltersAction);
    case marginsActionTypes.RESET_SERIAL_NUMBER_FILTER:
      return ResetSerialNumberFilterReducer(state, action as IResetSerialNumberFiltersAction);
    case marginsActionTypes.SET_DEPARTMENT_NUMBER_FILTER:
      return setDepartmentNumberFilterReducer(state, action as ISetDepartmentNumberFiltersAction);
    case marginsActionTypes.RESET_DEPARTMENT_NUMBER_FILTER:
      return resetDepartmentNumberFilterReducer(state, action as IResetDepartmentNumberFiltersAction);
    case marginsActionTypes.SET_SALES_PERSON_FILTER:
      return setSalesPersonFilterReducer(state, action as ISetSalesPersonFiltersAction);
    case marginsActionTypes.RESET_SALES_PERSON_FILTER:
      return ResetSalesPersonFilterReducer(state, action as IResetSalesPersonFiltersAction);
    case marginsActionTypes.TOGGLE_HIDE_WITHOUT_SALES_FILTER:
      return toggleHideWithoutSalesFilterReducer(state, action as IToggleHideWithoutSalesFiltersAction);
    default:
      return {
        ...state,
      };
  }
};

const setAllSyncFiltersReducer = (
  state: IMarginsSyncFiltersState,
  action: ISetAllSyncFiltersAction,
): IMarginsSyncFiltersState => {
  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(action, 'payload.hideWithoutSales'),
    monthsSelectedFilters: get(action, 'payload.monthsSelectedFilters'),
    monthFieldHierarchy: FieldHierarchy.dependent,
    makesSelectedFilters: get(action, 'payload.makesSelectedFilters'),
    makesFieldHierarchy: FieldHierarchy.dependent,
    modelsSelectedFilters: get(action, 'payload.modelsSelectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.dependent,
    serialNumbersSelectedFilters: get(action, 'payload.serialNumbersSelectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters: get(action, 'payload.departmentNumbersSelectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters: get(action, 'payload.salesPersonsSelectedFilters'),
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const resetAllSyncFiltersReducer = (
  state: IMarginsSyncFiltersState,
  action: IResetAllSyncFiltersAction,
): IMarginsSyncFiltersState => {
  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: false,
    monthsSelectedFilters: [],
    monthFieldHierarchy: FieldHierarchy.dependent,
    makesSelectedFilters: [],
    makesFieldHierarchy: FieldHierarchy.dependent,
    modelsSelectedFilters: [],
    modelsFieldHierarchy: FieldHierarchy.dependent,
    serialNumbersSelectedFilters: [],
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters: [],
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters: [],
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const setMonthFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: ISetMonthFiltersAction,
): IMarginsSyncFiltersState => {
  const newMonthsSelectedFilters = deriveNewSelectedOnUserFocused({
    oldSelected: get(state, 'months.selectedFilters'),
    oldVisible: get(state, 'months.visibleSelectedFilters'),
    newVisible: get(action, 'payload.visibleSelectedFilters'),
    key: 'value',
  });

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: newMonthsSelectedFilters,
    monthFieldHierarchy: FieldHierarchy.focused,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.dependent,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.dependent,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters: get(state, 'departmentNumbers.selectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters: get(state, 'salesPersons.selectedFilters'),
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const resetMonthFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: IResetMonthFiltersAction,
): IMarginsSyncFiltersState => {
  let monthsSelectedFilters: IDropdownOption<string>[] = [];
  let makesSelectedFilters: IDropdownOption<string>[] = [];
  let modelsSelectedFilters: IDropdownOption<string>[] = [];
  let serialNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let departmentNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let salesPersonsSelectedFilters: IDropdownOption<string>[] = [];

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters,
    monthFieldHierarchy: FieldHierarchy.focused,
    makesSelectedFilters,
    makesFieldHierarchy: FieldHierarchy.dependent,
    modelsSelectedFilters,
    modelsFieldHierarchy: FieldHierarchy.dependent,
    serialNumbersSelectedFilters,
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters,
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters,
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const setMakeFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: ISetMakeFiltersAction,
): IMarginsSyncFiltersState => {
  const newMakesSelectedFilters = deriveNewSelectedOnUserFocused({
    oldSelected: get(state, 'makes.selectedFilters'),
    oldVisible: get(state, 'makes.visibleSelectedFilters'),
    newVisible: get(action, 'payload.visibleSelectedFilters'),
    key: 'value',
  });

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: newMakesSelectedFilters,
    makesFieldHierarchy: FieldHierarchy.focused,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.dependent,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters: get(state, 'departmentNumbers.selectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters: get(state, 'salesPersons.selectedFilters'),
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const ResetMakeFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: IResetMakeFiltersAction,
): IMarginsSyncFiltersState => {
  let makesSelectedFilters: IDropdownOption<string>[] = [];
  let modelsSelectedFilters: IDropdownOption<string>[] = [];
  let serialNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let departmentNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let salesPersonsSelectedFilters: IDropdownOption<string>[] = [];

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters,
    makesFieldHierarchy: FieldHierarchy.focused,
    modelsSelectedFilters,
    modelsFieldHierarchy: FieldHierarchy.dependent,
    serialNumbersSelectedFilters,
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters,
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters,
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const setModelFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: ISetModelFiltersAction,
): IMarginsSyncFiltersState => {
  const newModelsSelectedFilters = deriveNewSelectedOnUserFocused({
    oldSelected: get(state, 'models.selectedFilters'),
    oldVisible: get(state, 'models.visibleSelectedFilters'),
    newVisible: get(action, 'payload.visibleSelectedFilters'),
    key: 'value',
  });

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: newModelsSelectedFilters,
    modelsFieldHierarchy: FieldHierarchy.focused,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters: get(state, 'departmentNumbers.selectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters: get(state, 'salesPersons.selectedFilters'),
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const ResetModelFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: IResetModelFiltersAction,
): IMarginsSyncFiltersState => {
  let modelsSelectedFilters: IDropdownOption<string>[] = [];
  let serialNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let departmentNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let salesPersonsSelectedFilters: IDropdownOption<string>[] = [];

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters,
    modelsFieldHierarchy: FieldHierarchy.focused,
    serialNumbersSelectedFilters,
    serialNumbersFieldHierarchy: FieldHierarchy.dependent,
    departmentNumbersSelectedFilters,
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters,
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const setSerialNumberFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: ISetSerialNumberFiltersAction,
): IMarginsSyncFiltersState => {
  const newSerialNumbersSelectedFilters = deriveNewSelectedOnUserFocused({
    oldSelected: get(state, 'serialNumbers.selectedFilters'),
    oldVisible: get(state, 'serialNumbers.visibleSelectedFilters'),
    newVisible: get(action, 'payload.visibleSelectedFilters'),
    key: 'value',
  });

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.ancestor,
    serialNumbersSelectedFilters: newSerialNumbersSelectedFilters,
    serialNumbersFieldHierarchy: FieldHierarchy.focused,
    departmentNumbersSelectedFilters: get(state, 'departmentNumbers.selectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters: get(state, 'salesPersons.selectedFilters'),
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const ResetSerialNumberFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: IResetSerialNumberFiltersAction,
): IMarginsSyncFiltersState => {
  let serialNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let departmentNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let salesPersonsSelectedFilters: IDropdownOption<string>[] = [];

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.ancestor,
    serialNumbersSelectedFilters,
    serialNumbersFieldHierarchy: FieldHierarchy.focused,
    departmentNumbersSelectedFilters,
    departmentNumbersFieldHierarchy: FieldHierarchy.dependent,
    salesPersonsSelectedFilters,
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const setDepartmentNumberFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: ISetDepartmentNumberFiltersAction,
): IMarginsSyncFiltersState => {
  const newDepartmentNumbersSelectedFilters = deriveNewSelectedOnUserFocused({
    oldSelected: get(state, 'departmentNumbers.selectedFilters'),
    oldVisible: get(state, 'departmentNumbers.visibleSelectedFilters'),
    newVisible: get(action, 'payload.visibleSelectedFilters'),
    key: 'value',
  });

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.ancestor,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.ancestor,
    departmentNumbersSelectedFilters: newDepartmentNumbersSelectedFilters,
    departmentNumbersFieldHierarchy: FieldHierarchy.focused,
    salesPersonsSelectedFilters: get(state, 'salesPersons.selectedFilters'),
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const resetDepartmentNumberFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: IResetDepartmentNumberFiltersAction,
): IMarginsSyncFiltersState => {
  let departmentNumbersSelectedFilters: IDropdownOption<number>[] = [];
  let salesPersonsSelectedFilters: IDropdownOption<string>[] = [];

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.ancestor,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.ancestor,
    departmentNumbersSelectedFilters,
    departmentNumbersFieldHierarchy: FieldHierarchy.focused,
    salesPersonsSelectedFilters,
    salesPersonsFieldHierarchy: FieldHierarchy.dependent,
  });
};

const setSalesPersonFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: ISetSalesPersonFiltersAction,
): IMarginsSyncFiltersState => {
  const newSalesPersonsSelectedFilters = deriveNewSelectedOnUserFocused({
    oldSelected: get(state, 'salesPersons.selectedFilters'),
    oldVisible: get(state, 'salesPersons.visibleSelectedFilters'),
    newVisible: get(action, 'payload.visibleSelectedFilters'),
    key: 'value',
  });

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.ancestor,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.ancestor,
    departmentNumbersSelectedFilters: get(state, 'departmentNumbers.selectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.ancestor,
    salesPersonsSelectedFilters: newSalesPersonsSelectedFilters,
    salesPersonsFieldHierarchy: FieldHierarchy.focused,
  });
};

const ResetSalesPersonFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: IResetSalesPersonFiltersAction,
): IMarginsSyncFiltersState => {
  let salesPersonsSelectedFilters: IDropdownOption<string>[] = [];

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales: get(state, 'hideWithoutSales'),
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.ancestor,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.ancestor,
    departmentNumbersSelectedFilters: get(state, 'departmentNumbers.selectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.ancestor,
    salesPersonsSelectedFilters,
    salesPersonsFieldHierarchy: FieldHierarchy.focused,
  });
};

const toggleHideWithoutSalesFilterReducer = (
  state: IMarginsSyncFiltersState,
  action: IToggleHideWithoutSalesFiltersAction,
): IMarginsSyncFiltersState => {
  const hideWithoutSales = get(action, 'payload.hideWithoutSales');

  return computeReducedState({
    state,
    fetchedMargins: get(action, 'payload.fetchedMargins'),
    hideWithoutSales,
    monthsSelectedFilters: get(state, 'months.selectedFilters'),
    monthFieldHierarchy: FieldHierarchy.ancestor,
    makesSelectedFilters: get(state, 'makes.selectedFilters'),
    makesFieldHierarchy: FieldHierarchy.ancestor,
    modelsSelectedFilters: get(state, 'models.selectedFilters'),
    modelsFieldHierarchy: FieldHierarchy.ancestor,
    serialNumbersSelectedFilters: get(state, 'serialNumbers.selectedFilters'),
    serialNumbersFieldHierarchy: FieldHierarchy.ancestor,
    departmentNumbersSelectedFilters: get(state, 'departmentNumbers.selectedFilters'),
    departmentNumbersFieldHierarchy: FieldHierarchy.ancestor,
    salesPersonsSelectedFilters: get(state, 'salesPersons.selectedFilters'),
    salesPersonsFieldHierarchy: FieldHierarchy.ancestor,
  });
};
