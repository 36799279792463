import { bosColor, secondary, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const MyOrdersStyled = styled.div`
  margin: 1.5rem 0;

  > div {
    padding: 0;
    border: 1px solid ${bosColor.neutralGrey};
    border-radius: 0.5rem;

    .header {
      column-gap: 0;
      padding: 0;
      border-bottom: 1px solid ${bosColor.neutralGrey};
      & > div {
        display: flex;
        height: 3.5rem;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0.75rem 1rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.extrabold};
        line-height: 175%;
        gap: 0.25rem;

        & > div {
          margin-left: 0 !important;
          max-width: inherit !important;
          display: flex !important;
          align-items: center;
          justify-content: flex-start;
        }
      }
    }
    .item {
      display: grid;
      min-height: 3.5rem;
      height: 100%;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 0;
      column-gap: 0;
      border: none;
      border-bottom: 1px solid ${bosColor.neutralGrey};

      &:not(.load-more):not(.empty) {
        &:hover {
          border-color: ${bosColor.neutralGrey};
        }
        &.selected {
          border: 2px solid ${secondary};
        }
        > div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 0.75rem 1rem;
          font-size: 1rem;
          font-style: normal;
          font-weight: ${typography.weight.semibold};

          > svg {
            width: 100%;
            height: 2rem;
          }
          div > .menuToggle {
            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
      &.load-more,
      &.empty {
        border-radius: 0 0 0.5rem 0.5rem;

        &.selected,
        &:hover {
          border-bottom: 1px solid ${bosColor.neutralGrey};
        }
        > div {
          font-size: 1rem;
          font-style: normal;
          font-weight: ${typography.weight.semibold};
          line-height: 175%;
        }
      }
      &.empty {
        cursor: default;
      }
      &.load-more {
        background: ${bosColor.turquoise20};

        .loadMoreRow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0.75rem 1rem;
          gap: 0.5rem;

          button {
            width: 1.5rem;
            height: 1.5rem;

            svg {
              padding-block: 3px;
            }
          }
          span {
            color: ${bosColor.black};
            font-size: 1rem;
            font-style: normal;
            font-weight: ${typography.weight.extrabold};
            line-height: 175%;
          }
        }
      }
      div:last-child {
        button {
          height: 1.375rem;
          width: 1.375rem;
        }
        header {
          height: 2.625rem;
          padding: 0 1.25rem;
          .tinyFont {
            font-size: 1rem;
          }
        }

        header ~ div {
          height: 3rem;
          min-width: 11.875rem;
          padding: 0 1.25rem;
        }
        .icon {
          margin: 0 0.625rem 0 0;
          position: relative;
          svg {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .item {
      max-height: 100%;
      height: 100%;
    }
    .item:not(&.empty) > div {
      font-size: 14px;
      width: 100%;
      span.tooltip {
        top: 40px;
        &,
        &:after {
          background: ${bosColor.black};
          border: ${bosColor.black};
          color: ${bosColor.white};
        }
        &:after {
          top: -0.5rem;
        }
      }
      > label {
        width: 100%;
      }
      > span {
        font-size: 14px;
      }
    }
  }
`;
