export const OverviewDetails = `
dealerNumber
invoiceDate
orderNumber  
salesPerson
make
model
customerNo
customerName
serialNo
salesPrice
purchasePrice
importerDiscount
otherCharges
marginPercentage
marginActual
marginBudget
marginDifference
discount
netSalesPrice
departmentNo
additionalSalesPrice
company
`;

export const DetailsActualsSection = `
company
serialNumber
voucherType
voucherNo
rowNo
accountingYear
accountingPeriod
voucherDate
year_period_key
account
codeB
codeC
codeD
codeE
codeG
codeH
codeI
codeJ
identity
taxCode
transCode
amount
referenceNumber
referenceType
`;

export const DetailsBudgetSection = `
serialNumber
orderNo
customerNumber
customerName
salesPersonId
make
partNumber
dealerId    
sale{
  carPrice
  deliveryCharges
  discount
  optionFactoryPrice
  optionLocals{
    optionLocalText
    optionLocalPrice
  }
}
purchase{
  additionalCost
  carPrice
  deliveryCharges
  importerDiscount
  optionFactoryPrice
  optionLocals{
    optionLocalText
    optionLocalPrice
  }
}
totalPurchasePrice
totalSalePrice
marginBudgetPrice
marginBudgetPercent
companyId
`;

export const DetailsInfoSection = `
trackTraceCode
vin
registrationNo
partNo
partDescription
oemOrderNo
`;

export const DetailsOwner = `
organizationName
`;

export const VendorInvoice = `
creatorsReference
fileUrl
invoiceNumber
`;
