import React from 'react';
import { Search } from '@next-components/search';
import { FilterBarStyled } from './FilterBar.styled';
import { IApiEmployee, IConfiguration, IOrderFiltersInput } from 'next-common';
import { CategoryFilter } from './CategoryFilter';
import { OrderCategory } from '../orderQuery.utils';

export interface IFilterBarProps {
  configuration?: IConfiguration;
  filter: IOrderFiltersInput;
  category: OrderCategory;
  employee: IApiEmployee;

  onChange(field: string, value: any): void;
  onCategoryChange(value: OrderCategory): void;
}

export const FilterBar = (props: IFilterBarProps) => {
  const handleCategoryChange = (value: OrderCategory) => {
    props.onCategoryChange(value);
  };

  const handleSearchChange = (searchString: string) => {
    if(searchString) props.onChange('searchQuery', searchString);
  };

  return (
    <FilterBarStyled>
      <div id="searchContainer">
        <p className="searchLabel">Søk etter serienummer</p>
        <Search onSearch={handleSearchChange} />
      </div>
      <div id="categoryContainer">
        <CategoryFilter onChange={handleCategoryChange} configuration={props.configuration} />
      </div>
    </FilterBarStyled>
  );
};
