import { WatchQueryFetchPolicy, gql, useQuery } from '@apollo/client';
import { IBosApiVehicleStatusMinimal } from 'next-common';
import { ERROR_POLICY } from '../constants';

export interface IVehicleStatusParams {
  serialNo: string;
  fetchPolicy: WatchQueryFetchPolicy;
}

export interface IVehicleStatusResult {
  status: IBosApiVehicleStatusMinimal;
  loading: boolean;
}

export const vehicleStatusQuery = gql`
  query vehicleStatusBySerialNo($serialNo: String!) {
    vehicleStatusBySerialNo(serialNo: $serialNo) {
      ...vehicleStatus
    }
  }
  fragment vehicleStatus on BosApiVehicleStatus {
    actualProductionDate
    bodyStyle
    brand
    color
    engineType
    importerLocation
    option {
      characteristicId
      optionId
    }
    productionDate
    registrationNo
    serialNo
    site
    trackTraceCode
    vehicleType
  }
`;

export const useVehicleStatus = (params: IVehicleStatusParams): IVehicleStatusResult => {
  const { data, loading } = useQuery(vehicleStatusQuery, {
    variables: { serialNo: params.serialNo },
    skip: !params.serialNo,
    errorPolicy: ERROR_POLICY,
    fetchPolicy: params.fetchPolicy || 'cache-only',
  });
  const status = data?.vehicleStatusBySerialNo || {};
  return { status, loading };
};
