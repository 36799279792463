import { bosColor, margin, padding, white, typography, responsive } from '@next-components/common-styles';
import { IDynamicListTableWrapperProps } from './IDynamicListStock';
import styled from 'styled-components';

interface IStyledDynamicListProps {
  isNewDynamicList?: boolean;
}

export const DynamicListStyled = styled.div<IStyledDynamicListProps>`
  #contentCount {
    ${padding['16'].t};
    text-align: center;
  }

  .tableContainer + .item.load-more {
    ${(props) =>
      props?.isNewDynamicList &&
      `border: 0;
      ${padding['32'].t};
      ${padding['0'].x};
      ${margin['0'].a};
      position: relative;
      cursor: initial;
      > div {
        font-size: 1rem;
        color: ${bosColor.concrete};
        position: absolute;
        bottom: -3.375rem;
        font-weight: ${typography.weight.semibold};
      }    
    `}
  }

  .tableContainer {
    scroll-behavior: smooth;
    &::-webkit-scrollbar,
    .scrollbarHide::-webkit-scrollbar {
      display: none;
    }

    .accordionContainer {
      background-color: ${bosColor.white};
      div > .btnAccordion {
        .item.grid-row {
          line-height: 175%;
          align-items: center;
          grid-template-columns:
            3rem minmax(12.5rem, 1fr) minmax(12.5rem, 1fr) minmax(10.5rem, 1fr) minmax(10.5rem, 1fr)
            minmax(10.5rem, 1fr) minmax(10.5rem, 1fr) minmax(5.625rem, 1fr) minmax(5.625rem, 1fr) minmax(8.25rem, 1fr);
          .grid-item {
            > div {
              ${responsive.lgMax} {
                font-size: 0.875rem;
                line-height: 120%;
              }
              > div span.moreOptions {
                display: grid;
                line-height: 175%;
                align-items: center;
              }
            }
            span.additionalText {
              font-weight: ${typography.weight.semibold};
              ${responsive.lgMax} {
                font-size: 0.875rem;
                line-height: 120%;
              }
            }
          }
        }
      }
      &.selected,
      &.row-selected {
        background: ${bosColor.komorebi20};
        &.row-selected {
          item .selectable .checkboxRadio:before {
            position: absolute;
            content: '';
            width: 1.375rem;
            height: 1.375rem;
            border: 2px solid #191919;
            border-radius: 1.25rem;
            left: 0;
            top: -0.625rem;
            box-sizing: border-box;
          }
          .item.row-selected .selectable .checkboxRadio:after {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            background: #191919;
            position: absolute;
            top: -0.3125rem;
            left: 0.3125rem;
            border-radius: 100%;
            box-sizing: border-box;
          }
        }
        div > .btnAccordion {
          position: relative;
          .labelContainer > .item {
            &.grid-row {
              background: none;
            }
          }
        }
        > div {
          &[open] {
            border-bottom: 1px solid ${bosColor.neutralGrey};
            background: none;
          }
        }
      }

      background-color: ${white};
      > div {
        border-bottom: none;

        button {
          ${padding['0'].a};
          border-bottom: 1px solid ${bosColor.neutralGrey};
          display: grid;
          gap: 0rem;
          .item.itemRow {
            border-bottom: none;
          }

          > div + div {
            justify-content: flex-end;
            ${margin['16'].r};
            svg {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
          & + div {
            ${padding['16'].x};
            &.content[open] {
              > div {
                background-color: transparent;
              }

              padding: 1rem;
              border-bottom: 1px solid ${bosColor.lightGrey};
            }
          }
          .caption {
            font-size: 1rem;
            line-height: 1.375rem;
            color: ${bosColor.black};
            font-weight: ${typography.weight.semibold};
            margin-right: 0.25rem;
          }

          &.btnAccordion:not(.search-button) {
            grid-template-columns: 1fr 2.25rem;
          }
        }
      }

      > div {
        &[open] {
          border-bottom: 1px solid ${bosColor.neutralGrey};
          background: ${bosColor.lightGrey};
          button {
            border-bottom: 1px solid ${bosColor.neutralGrey};
            &.btnAccordion {
              .item.grid-row {
                background-color: transparent;
              }
            }
            &.red {
              border-bottom: 2px solid ${bosColor.poppy};
              justify-content: center;
              align-items: center;
            }
          }
        }

        &.selected {
          background: ${bosColor.komorebi20};
          // button {
          //   border-bottom: 0;
          //   &.btnAccordion {
          //     .item.grid-row {
          //       background-color: ${bosColor.lightGrey};
          //     }
          //   }
          // }
        }
      }
    }
  }
`;

export const TableWrapper = styled.div<IDynamicListTableWrapperProps>`
  max-height: calc(100vh - 18rem);
  overflow: ${(props) => (props.isNewDynamicList ? 'scroll' : 'inherit')};
  border: 1px solid ${bosColor.neutralGrey};
  border-radius: 0.5rem;
  .tooltip {
    top: -6.6rem !important;
  }
  .scrollbarHide {
    .heightInherit {
      height: auto !important;
      max-height: calc(100vh - 21.5rem);
      padding-bottom: ${(props) => (props?.addPadding ? '65px' : '0px')};
    }
  }
`;
