export enum ValidationErrorCode {
  PACKAGE_SKU_NOT_IN_PIM,
  COLOR_OR_INTERIOR_NOT_IN_PIM,
  ADD_VEHICLE_COLOR_VALIDATION,
  PRODUCTION_DATE_VALIDATION,
  SIGNING_VEHICLE_VALIDATION,
  COLOR_INTERIOR_NOT_SELECTED,
  RESERVED_CAR_VALIDATION,
  UNSAVE_ACCESSORY_VALIDATION,
  MISSING_CUSTOMER_DETAILS,
  COLOR_OR_INTERIOR_NOT_SELECTED,
  OFFER_CREATION_ERROR,
  VEHICLE_STOCK_CONFIGURATION_HAS_CHANGED,
  VAT_VALIDATION,
  SERIAL_NUMBER_VALIDATION,
  VEHICLE_VARIANT_MISSING_IN_PIM,
  UNABLE_TO_UNSELECT_TIRES,
  INVALID_TIME_VALUE,
  OTHER_DEALER_VEHICLE_RESERVE_OR_SIGN_VALIDATION,
  OTHER_DEALER_VEHICLE_LIST_VALIDATION,
  ONE_OFF_TAX_NOT_FOUND_FROM_CONVERTING_TABLE,
  PRESELECTION_ERROR,
  DELIVERY_CHARGE_WARNING,
}

export const getErrorMessageForError = (errorCode: number): string => {
  switch (errorCode) {
    case ValidationErrorCode.PACKAGE_SKU_NOT_IN_PIM:
      return 'Valgt tillegg for kjøretøy er ikke tilgjengelig';
    case ValidationErrorCode.COLOR_OR_INTERIOR_NOT_IN_PIM:
      return 'Valgt farge eller interiør er ikke tilgjengelig';
    case ValidationErrorCode.ADD_VEHICLE_COLOR_VALIDATION:
      return 'Farge utilgjengelig fra PIM';
    case ValidationErrorCode.PRODUCTION_DATE_VALIDATION:
      return 'Lakk eller interiør vil ikke være tilgjengelig på valgt produksjonsdato';
    case ValidationErrorCode.SIGNING_VEHICLE_VALIDATION:
      return 'Velg bil før du kan signere';
    case ValidationErrorCode.COLOR_INTERIOR_NOT_SELECTED:
      return 'Vennligst velg farge og interiør før du fortsetter til fabrikkbestilling';
    case ValidationErrorCode.RESERVED_CAR_VALIDATION:
      return 'Hvis du vil bytte bil må du fjerne reservasjonen først';
    case ValidationErrorCode.UNSAVE_ACCESSORY_VALIDATION:
      return 'Vennligst lagre eller slett de gjeldende endringene dine før du fortsetter';
    case ValidationErrorCode.MISSING_CUSTOMER_DETAILS:
      return 'Kundedetaljer mangler';
    case ValidationErrorCode.COLOR_OR_INTERIOR_NOT_SELECTED:
      return 'Farge og interiør er obligatorisk for tilbudsoppretting';
    case ValidationErrorCode.OFFER_CREATION_ERROR:
      return 'Det har oppstått en feil under opprettelsen av tilbudet';
    case ValidationErrorCode.VEHICLE_STOCK_CONFIGURATION_HAS_CHANGED:
      return 'Konfigurasjonen av valgt serienummer er endret. Vennligst velg nytt serienummer';
    case ValidationErrorCode.VAT_VALIDATION:
      return 'Det oppsto en feil under beregningen av mva.';
    case ValidationErrorCode.UNABLE_TO_UNSELECT_TIRES:
      return 'Alle leasingavtaler må fjernes for at vinterdekk skal velges bort.';
    case ValidationErrorCode.SERIAL_NUMBER_VALIDATION: return 'Valgt serienummer vil nå bli fjernet fra tilbudet.';
    case ValidationErrorCode.VEHICLE_VARIANT_MISSING_IN_PIM:
      return 'Finner ikke informasjon om bilen. Meld in sak til brukerstøtte';
    case ValidationErrorCode.OTHER_DEALER_VEHICLE_RESERVE_OR_SIGN_VALIDATION:
      return 'Denne bilen tilhører annen forhandler. Bilen må tildeles din forhandler av importør før du kan signere.';
    case ValidationErrorCode.OTHER_DEALER_VEHICLE_LIST_VALIDATION:
      return 'Bilene under tilhører andre forhandlere. Bilen du velger må tildeles din forhandler av importør før du kan signere.';
    case ValidationErrorCode.ONE_OFF_TAX_NOT_FOUND_FROM_CONVERTING_TABLE:
      return 'Konfigurasjonen kan ikke bestilles fra fabrikk';
    case ValidationErrorCode.PRESELECTION_ERROR:
      return 'Konfigurasjon feiler. Kontakt produkteam';
    case ValidationErrorCode.DELIVERY_CHARGE_WARNING:
      return 'Vi kunne ikke hente leveringskost fra kilden, leveringskost er derfor satt til et standardbeløp pålydende 14900 i tilbudet';
    default:
      return 'En ukjent feil oppstod';
  }
}