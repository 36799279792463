import { ApolloClient, useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { getConfigurationQuery } from "../../graphql";
import { ERROR_POLICY } from "../constants";
import { IConfiguration } from "next-common";

const configurationQuery = async (
    client: ApolloClient<object>,
    onSuccess: (value: any) => void,
): Promise<void> => {
    try {
        const { data }: { data: any } = await client.query({
            query: getConfigurationQuery,
            errorPolicy: ERROR_POLICY,
            fetchPolicy: 'network-only',
        });
        const result = data?.getConfiguration;
        onSuccess(result);
    } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
    }
};


const getConfigurations = (client: ApolloClient<object>, onSuccess: (value: IConfiguration) => void): any => {
    configurationQuery(client, onSuccess).then(() => {
        // tslint:disable-next-line:no-console
        console.log("Fetched configuration")
    }).catch(err => {
        const errorMsg = `Could not get configurations`;
        // tslint:disable-next-line:no-console
        console.error(errorMsg, err);
    });
};


export const useConfigurations = (): any => {
    const client = useApolloClient();
    const [configurations, setConfigurations] = useState<IConfiguration>(null);

    useEffect(() => {
        getConfigurations(client, setConfigurations);
    }, []);

    return configurations;
};
