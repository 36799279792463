import { fontWeightBold, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const WarningStyled = styled.div`
  p.warningText {
    display: inline;
    font-size: 0.875rem;
    font-weight: ${typography.weight.semibold};
  }

  .commentLink {
    font-size: 0.875rem;
    font-weight: ${fontWeightBold};
  }

  #information {
    display: inline;
  }
  
  #event-link {
    display: inline;
    text-decoration: underline;
    cursor: pointer;
  }

  .notification-area > div {
    width: auto;
    min-width: 20rem;
  }

  .notification-width > div {
    width: 55rem;
  }
`;
