import React from 'react';
import { AgreementArea } from '../SigningAgreement.styled';
import { InfoBlock } from '../../../sales-common/InfoBlock';
import { FinancingType, ITradeInVehicle } from 'next-common';
import { formatLicensePlateInput, formatNumber } from '@next-components/common-types';
import { v4 as uuidv4 } from 'uuid';

interface ITradeInProps {
  car: ITradeInVehicle;
  financingType: FinancingType;
}

export interface ITradeInCarsProps {
  tradeInCars?: ITradeInVehicle[];
  financingType: FinancingType;
}

const TradeInCar = (props: ITradeInProps) => (
  <div className="tradeInCarWrapper">
    <InfoBlock
      label="Reg.nr."
      value={props.car?.registrationNumber && formatLicensePlateInput(props.car.registrationNumber).formatted}
    />
    <InfoBlock label="Kilometerstand" value={`${formatNumber(props.car.mileage)} km`} />
    <InfoBlock label="Avtalt innbyttepris" value={`${formatNumber(props.car.price)} kr`} />
    <InfoBlock label="Heftelser" value={`${formatNumber(props.car.debt)} kr`} />
    <InfoBlock label="Merke" value={props.car.make} />
    <InfoBlock label="Model" value={props.car.model} />
  </div>
);

export const TradeIn = (props: ITradeInCarsProps) => {
  if (!props.tradeInCars || props.tradeInCars.length < 1) return null;
  return (
    <AgreementArea>
      <h2>Innbyttebil</h2>
      {props.tradeInCars.map((t) => (
        <TradeInCar key={uuidv4()} car={t} financingType={props.financingType} />
      ))}
    </AgreementArea>
  );
};
