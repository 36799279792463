import * as React from 'react';
import { format } from 'date-fns';
import { CommentStyled } from './Comment.styled';
import { IComment } from 'next-common';
import { Icon, IconType } from '@next-components/bos-icon';

export interface ITypedComment extends IComment {
  type: string;
}

export interface ICommentItemProps {
  comment: ITypedComment;
  employeeName?: string;
}

export const Comment = (props: ICommentItemProps) => (
  <CommentStyled>
    <div className="commentInner">
      <div className="commentHeader">
        <div className="type">{props.comment.type !== 'sale' ? 'Kommentar til DNB ASA' : 'Selgerkommentar'}</div>
        <div className="timeStamp">{format(props?.comment?.timestamp, 'dd. MMM yyyy, HH:mm')}</div>
      </div>
      <div className="commentBody">{props.comment.text}</div>
    </div>
    <div className="commentFooter">
      <div className="commentFooterItem">
        <div className="icon">
          <Icon icon={IconType.NewNextUser} aria-hidden />
        </div>
        <div className="autherName">{props.employeeName ?? 'Ukjent'}</div>
      </div>
    </div>
  </CommentStyled>
);
