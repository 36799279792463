export const actions = {
  VEHICLE_CHANGE: 'VEHICLE_CHANGE',
};

export interface IVehicleCtxProps {
  modelId: string;
  variantId: string;
}

export const initialState: IVehicleCtxProps = {
  modelId: null,
  variantId: null,
};
