import { CustomModal } from "@next-components/custom-modal";
import { IRulesPopup, useDisableScroll } from "../../../common";
import { CustomModalWrapper } from "../../layout/CustomModalWrapper.styled";
import { RulesPopupWrapper } from "./RulesPopup.styled";
import { RulesInfo } from "./RulesInfo";
import { RulesActionButtonContainer } from "./RulesActionButtonContainer";

export const RulesPopup = (props: IRulesPopup) => {
    const {
        isOpen,
        ruleAddVaules,
        ruleRemoveVaules,
        ruleDefaultValues,
        popupPrice,
        selectableName,
    } = props;
    useDisableScroll(isOpen);

    const handleModalClose = () => {
        props.handleClose();
    };

    const handleConfirm = () => {
        props.handleConfirm();
    };

    return (
        <CustomModalWrapper className="backdrop smModal" active={isOpen}>
            <RulesPopupWrapper className="rulesModel">
                <CustomModal onCloseClick={handleModalClose} active={isOpen} isModelCloseAllowedOutside={false}>
                    <header className="modelHeader">
                        <h2>Oppdatering av konfigurasjon</h2>
                    </header>
                    <div className="rulesDetailContainer">
                        <div className="detailMessage">
                            <span className="skuName">{selectableName}</span> krever følgende tilpasninger:
                        </div>
                        <RulesInfo
                            price={popupPrice}
                            ruleAddVaules={ruleAddVaules}
                            ruleRemoveVaules={ruleRemoveVaules}
                            ruleDefaultValues={ruleDefaultValues}
                        />
                    </div>
                    <RulesActionButtonContainer
                        onCancel={() => handleModalClose()}
                        onConfirm={() => handleConfirm()}
                    />
                </CustomModal>
            </RulesPopupWrapper>
        </CustomModalWrapper>
    )
};