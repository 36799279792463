import React from 'react';
import { format } from 'date-fns';
import { DateTimeBlockStyled } from './DateTimeBlock.styled';

export interface IDateTimeBlockProps {
  dateTime: string | number;
  shortDate?: boolean;
}

export const DateTimeBlock = (props: IDateTimeBlockProps) => {
  return (
    <DateTimeBlockStyled className="dateTime">
      {props?.shortDate ? (
        <>
          {typeof props.dateTime !== 'string'
            ? format(new Date(props.dateTime * 1000), 'dd. MMM yyyy, HH:mm')
            : format(new Date(props.dateTime), 'dd. MMM yyyy, HH:mm')}
        </>
      ) : (
        <>
          {typeof props.dateTime !== 'string'
            ? format(new Date(props.dateTime * 1000), 'dd.MM.yyyy HH.mm')
            : format(new Date(props.dateTime), 'dd.MM.yyyy HH.mm')}
        </>
      )}
    </DateTimeBlockStyled>
  );
};
