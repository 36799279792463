import styled, { css } from 'styled-components';
import { backgroundCard, black, disabled, primary, stateInactive } from '@next-components/common-styles';

interface IStyledStateProps {
  empty?: boolean;
  success?: boolean;
}

export const StyledState = styled.div<IStyledStateProps>`
  height: 45rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${backgroundCard};
  padding: 1rem;
  text-align: center;

  .icon-container {
    height: 48px;
    width: 48px;
    border-radius: 25px;
  }

  h3 {
    margin-top: 25px;
    color: ${disabled};
    font-weight: 700;
    line-height: 25px;
  }

  ${(props) =>
    props.empty &&
    css`
      border: 2px dashed ${stateInactive};

      .icon-container {
        border: 1px solid ${stateInactive};
        background-color: ${stateInactive};
        padding: 10px;
        svg {
          path {
            fill: ${black};
          }
        }
      }

      h3 {
        width: 65%;
      }
    `}

  ${(props) =>
    props.success &&
    css`
      border: 2px solid ${primary};

      .icon-container {
        border: 1px solid ${primary};
        .circle-checkbox {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 100%;
            height: 100%;
            path {
              fill: ${primary};
            }
          }
        }
      }
    `}
`;
