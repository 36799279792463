import {
  backgroundSite,
  black,
  bosColor,
  veryLightGray,
  margin,
  padding,
  typography,
} from '@next-components/common-styles';
import styled from 'styled-components';
import { zIndexes } from '../../../common';

export const SalesWizardStyled = styled.div`
  .hiddenDiv {
    position: absolute;
    height: 300px;
    background: ${backgroundSite};
    width: 100%;
    z-index: -1;
  }
  .full-width .collapsed + div {
    position: fixed;
    z-index: ${zIndexes.zIndexFixed};
  }
  .next-modal {
    max-width: 100%;
    min-height: 0;
    color: ${black};
    margin-top: 0.5rem;
    &.inventory {
      .container-fluid {
        max-height: calc(100vh - 50rem);
        overflow: auto;
      }
    }

    .content p {
      font-size: 1rem;
    }

    header button {
      margin-top: -0.625rem;
      .popup-close {
        margin-right: 0;
      }
    }
    header .add-vehicle {
      margin-top: 3.2rem;
    }
  }
  .footerMenu {
    padding: 0;
    .main-btn {
      button {
        height: 3rem;
        width: auto;
        padding: 0.75rem 1rem;
        color: ${black};
        &:not(.newNextButton) {
          background-color: ${bosColor.turquoise};
        }
        .lds-ellipsis {
          height: 2rem;
          top: -6px;
          div {
            background: ${black};
          }
        }
      }
      .next-btn {
        width: inherit;
        padding: 0.5rem 1.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
      }
    }
    .tooltip {
      left: 45%;
      max-width: max-content;
      &::after {
        right: initial;
      }
    }
    .btn-grp {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .main-sign-btn {
      padding: 5px 0;
      display: flex;
      button {
        pointer-events: none;
        height: 3.25rem;
        width: auto;
        padding: 0.75rem 1rem;
        color: ${black};
        background-color: ${veryLightGray};
        border: 3px solid ${veryLightGray};
        :hover {
          border: 3px solid ${veryLightGray};
          background-color: ${veryLightGray};
        }
      }
      .tooltip {
        left: 0;
      }
    }
  }
`;

export const SalesWizardContent = styled.div`
  background-color: ${bosColor.white};
  min-height: calc(100vh - 28rem);
  .cartFooter {
    .disableAccordian {
      button {
        pointer-events: none;

        div:nth-child(2) {
          display: none;
        }
      }
    }
    .btnGroupRow {
      .small.arialLabel {
        .tooltip {
          max-width: 18.75rem;
        }
      }
    }
  }
  .mainContainer {
    display: grid;
    gap: 1.5rem;
    min-height: 100vh;
    height: auto;
    grid-template-columns: [leftContainer]minmax(auto, 100%) [cartItems]minmax(auto, 32.1875rem);
    grid-template-rows: minmax(min-content, max-content);
    grid-template-areas: 'leftContainer cartItems ';
    justify-content: space-between;
    position: relative;
    transition: all 1s ease-in;
    width: 100%;
    ${padding['48'].x};
    ${padding['24'].y};
    ${margin['0'].y};
    ${margin['auto'].x};
    max-width: inherit;

    .leftContainer {
      padding-bottom: 0px;
      gap: 3rem;

      grid-area: leftContainer;
      min-height: calc(100vh - 2 * 3.125rem);
      overflow-x: hidden;
      overflow-y: auto;
      ${padding['0'].a};
      width: 100%;
      background: none;

      display: flex;
      flex-direction: column;

      .messageBarInfo {
        &.customMessageBar {
          display: grid;
          grid-template-columns: max-content auto;
          gap: 0.5rem;
          max-width: 43rem;
          .iconContainer {
            gap: 0.5rem;
          }
          .message-bar-body {
            span {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .cartItems {
      grid-area: cartItems;
      min-width: inherit;
      ${padding['0'].x};
      ${padding['0'].b};
      background: none;
      .stickyContainer {
        position: sticky;
        right: 0;
        top: 12rem;

        div > .cartItemsList {
          .cartAccordionWrapper:hover {
            > .btnAccordion {
              background: ${bosColor.lightGrey};
            }
          }
        }
      }
      .cartFooter {
        ${padding[0].x};
        ${padding[24].b};
        ${padding[32].t};
        .detailsSection {
          padding: 0rem 2rem;
          .title,
          .productionDate {
            ${padding[0].x};
          }
          .title {
            line-height: 175%;
          }
          &.nonFactoryOrder {
            h3,
            p {
              padding: 0;
            }
          }
        }
        .confirmationCheckbox {
          button,
          button span {
            font-size: 0.875rem;
          }
        }
        .title,
        .smallText,
        .serialNo,
        .productionDate,
        .btnGroupRow,
        .cartFooterAccordion,
        .factoryBlock {
          ${padding[0].b};
          ${padding[32].x};

          .headerInfo > div {
            h1 {
              margin: 0;
              padding: 0;
            }
          }
          .actionWrapper {
            align-items: center;
          }
        }

        button.btnAccordion {
          ${padding[0].b};
        }
        div[open] button.btnAccordion {
          padding-bottom: 1rem;
        }
        p {
          font-weight: ${typography.weight.semibold};
        }
      }
    }
  }
`;

export const HeaderWithSalesSteps = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
`;
