import React from 'react';
import { OrderStatusListItemStyled } from '../OrderStatusList.styled';
import { Icon, IconType } from '@next-components/bos-icon';

export const NoOrderStatus = () => (
  <OrderStatusListItemStyled className="OrderStatusListItemStyled">
    <div className="iconContainer">
      <Icon icon={IconType.NewNextHistory} />
    </div>
    <span>IFS har ikke mottatt ordren.</span>
  </OrderStatusListItemStyled>
);
