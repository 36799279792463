import React from 'react';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';
import { IVehicleColor, VehicleColor } from './VehicleColor';
import { VehicleRim, IVehicleRims } from './VehicleRims';
import { IEquipmentTypes, MAKE, VehicleEquipmentCategoryEnum } from 'next-common';
import { VehicleSelectableEquipment } from './VehicleSelectableEquipment';
import { ICommonRulesProps, IListverkChangeProps } from '../ResultGrid';
import { VehicleTaklakk } from './VehicleTaklakk';
import { VehicleConfig } from '../../../../../utils';

export interface ISectionExterior extends IVehicleColor, IVehicleRims, ICommonRulesProps {
  isOpen: boolean;
  isDisabled: boolean;
  selectedEquipmentIds?: IEquipmentTypes[];
  onSelectEquipmentChange?: (
    data: IEquipmentTypes[],
    removeId?: string,
    isOptimisticUpdate?: boolean,
    configType?: VehicleConfig | VehicleEquipmentCategoryEnum,
    dealerPriceExclVat?: number,
    effectedSku?: string,
  ) => void;
  vehicleBrand?: MAKE;
  selectedTaklakk?: IEquipmentTypes;
  rulesPayload?: string;
  onTaklakkChange?: (data: IListverkChangeProps) => void;
  setShowRulesPopup?: (show: boolean) => void;
}

export const SectionExterior = (props: ISectionExterior) => {
  const {
    data,
    selectedColorId,
    isOpen,
    isDisabled,
    customerType,
    disabled,
    selectedRims,
    onColorChange,
    onRimChange,
    onSelectEquipmentChange,
    selectedEquipmentIds,
    updatePrices,
    onTaklakkChange,
    vehicleBrand,
    selectedTaklakk,
  } = props;

  const commonProps = {
    data,
    disabled,
    updatePrices,
  };

  return (
    <OrderAccordion label="Eksteriør" isOpen={isOpen} isDisabled={isDisabled}>
      <VehicleColor {...commonProps} selectedColorId={selectedColorId}
        onColorChange={onColorChange}
        setCurrentlySelectedSku={props.setCurrentlySelectedSku}
        setCurrentSelectionDetails={props.setCurrentSelectionDetails}
      />
      <VehicleSelectableEquipment
        {...commonProps}
        selectedEquipmentIds={selectedEquipmentIds}
        onSelectEquipmentChange={onSelectEquipmentChange}
        customerType={customerType}
        equipmentCategory={VehicleEquipmentCategoryEnum.Exterior}
        setShowRulesPopup={props.setShowRulesPopup}
        setCurrentlySelectedSku={props.setCurrentlySelectedSku}
        setCurrentSelectionDetails={props.setCurrentSelectionDetails}
        rulesPayload={props.rulesPayload}
      />
      <VehicleRim {...commonProps} selectedRims={selectedRims} onRimChange={onRimChange} customerType={customerType}
        setCurrentlySelectedSku={props.setCurrentlySelectedSku}
        setCurrentSelectionDetails={props.setCurrentSelectionDetails}
      />
      {vehicleBrand === MAKE.Smart && (
        <VehicleTaklakk {...commonProps} selectedTaklakk={selectedTaklakk} onTaklakkChange={onTaklakkChange}
          setCurrentlySelectedSku={props.setCurrentlySelectedSku}
          setCurrentSelectionDetails={props.setCurrentSelectionDetails}
        />
      )}
    </OrderAccordion>
  );
};
