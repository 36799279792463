import styled from 'styled-components';
import { backgroundWidget, bosColor, typography, white } from '@next-components/common-styles';
import { EventLevel } from '../orderEventItem';
import { IEventLevelStyleProps } from './EventItem';

const eventLevelStyleProps = (level: EventLevel): Partial<IEventLevelStyleProps> => {
  switch (level) {
    case EventLevel.Error:
      return {
        iconColor: bosColor.poppy,
        eventBackgroundColor: bosColor.lightDestructive,
        eventBorderColor: bosColor.poppy,
      };
    case EventLevel.Warning:
      return {
        iconColor: bosColor.oker,
        eventBackgroundColor: bosColor.oker20,
        eventBorderColor: bosColor.oker,
      };
    default:
      return { iconColor: bosColor.komorebi, eventBackgroundColor: white, eventBorderColor: bosColor.neutralPlus };
  }
};

export const EventItemStyled = styled.div<{ eventLevel?: string }>`
  display: grid;
  grid-template-columns: 30px auto;
  gap: 0.25rem;

  .eventTimeline {
    display: grid;
    height: calc(100% + 1.5rem);
    grid-template-rows: 30px auto;
    place-self: self-start;

    div.icon {
      width: 2rem;
      height: 2rem;
      transform: matrix(1, -1.2246467991473532e-16, -1.2246467991473532e-16, -1, 0, 0);
      color: ${({ eventLevel }) => eventLevelStyleProps(eventLevel)?.iconColor};
    }
    .dashes {
      background-image: radial-gradient(${backgroundWidget} 35%, transparent 20%);
      background-size: 5px 10px;
      background-repeat: repeat-y;
      background-repeat-x: no-repeat;
      background-position: center;
      margin-top: 4px;
    }
  }
  .eventInner {
    background-color: ${({ eventLevel }) => eventLevelStyleProps(eventLevel)?.eventBackgroundColor};
    padding: 0.75rem 1.5rem;
    min-height: 5rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${({ eventLevel }) => eventLevelStyleProps(eventLevel)?.eventBorderColor};
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .eventHeader {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    gap: 1rem;

    .type-failed {
      color: #fb1919;
      font-weight: bold;
    }
    .eventDetail {
      display: flex;
      flex-direction: column;

      .type {
        color: ${bosColor.black};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.bold};
        line-height: 175%;
      }
      .dateTime {
        color: ${bosColor.concrete};
        font-size: 0.875rem;
        font-style: normal;
        font-weight: ${typography.weight.semibold};
        line-height: 170%;
      }
      .note {
        label {
          color: ${bosColor.black};
          font-size: 1rem;
          font-style: normal;
          font-weight: ${typography.weight.semibold};
          line-height: 175%;
          margin-right: 0.5rem;
          margin-bottom: 0;
        }
      }
    }
    .note {
      word-break: break-word;
    }
  }
  div.eventDoneBy {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    div.icon {
      width: 1.25rem;
      height: 1.25rem;
      color: ${bosColor.concrete};
      display: flex;
      svg {
        height: 100%;
        width: 100%;
        color: ${bosColor.concrete};
        path {
          fill: currentcolor;
        }
      }
    }
  }
  .failed {
  }
`;
