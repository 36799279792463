import React, { useState } from 'react';
import { useEmployee } from '../../../common';
import { bosColor, disabled } from '@next-components/common-styles';
import { SalesPersonInfo, SalesPersonWrapper } from './SalesPerson.styled';
import { UserSettings } from './UserSettings';
import { Icon, IconType } from '@next-components/bos-icon';

export const SalesPerson = () => {
  const employee = useEmployee();
  const [open, setOpen] = useState<boolean>(false);

  const salesPersonName =
    employee?.firstName && employee?.lastName ? `${employee.firstName} ${employee.lastName}` : null;

  const setModal = (value: boolean) => {
    setOpen(value);
  };

  return (
    <SalesPersonWrapper>
      <div className="salesPerson">
        <div className="icon">
          <Icon icon={IconType.NewNextUserSettings} aria-hidden height={24} width={24} className="user-icon" />
        </div>
        <SalesPersonInfo>
          <p
            className="name"
            style={{ color: salesPersonName ? bosColor.white : disabled }}
            onClick={() => setModal(true)}>
            {salesPersonName ?? 'Ukjent selger'}
          </p>
        </SalesPersonInfo>
        {open && <UserSettings employee={employee} setModal={setModal} />}
      </div>
    </SalesPersonWrapper>
  );
};
