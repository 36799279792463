import React from 'react';
import { CustomerType, IOrder, MAKE } from 'next-common';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { findExpiresAt, getCustomerName } from '../../../../../utils';
import { backgroundCard, srtColor } from '@next-components/common-styles';
import { format } from 'date-fns';
import { SubHeaderMakeStyle } from './SubHeader.style';

const SubHeaderStyle: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '10 20',
  backgroundColor: backgroundCard,
};

const SubHeaderElement = (props) => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <View>
        <Text style={SubHeaderMakeStyle[props.dealerRole][props.make][props.styleKey]}>{props.label}:</Text>
        <Text style={SubHeaderMakeStyle[props.dealerRole][props.make][props.styleKey]}>{props.value}</Text>
      </View>
    </View>
  );
}

export interface ISubHeaderProps extends IOrder {
  dealerRole: string;
}

const getBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    return backgroundCard;
  } else {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.silver;
      default:
        return backgroundCard;
    }
  }
};

export const SubHeader = (props: ISubHeaderProps) => {
  const bgColor = getBgColor(props?.dealerRole, props?.lead?.make)
  return (
    <>
      <View style={{ ...SubHeaderStyle, backgroundColor: bgColor }}>
        {props.customer?.customerType !== CustomerType.BUSINESS && (
          <>
            <SubHeaderElement 
              styleKey="kunde" 
              label="Kunde" 
              value={getCustomerName(props.customer?.bosCustomer)} 
              dealerRole={props?.dealerRole} 
              make={props?.lead?.make}
            />
            <SubHeaderElement 
              styleKey="telefon" 
              label="Telefon" 
              value={props.customer?.bosCustomer?.phoneMobile} 
              dealerRole={props?.dealerRole}
              make={props?.lead?.make}
            />
            <SubHeaderElement 
              styleKey="epost" 
              label="E-post" 
              value={props.customer?.bosCustomer?.email} 
              dealerRole={props?.dealerRole}
              make={props?.lead?.make}
            />
            <SubHeaderElement
              styleKey="adresse"
              label="Adresse"
              value={
                props.customer &&
                `${props.customer?.bosCustomer?.address1}, ${props.customer?.bosCustomer?.zipCode} ${props.customer?.bosCustomer?.city}`
              }
              dealerRole={props?.dealerRole}
              make={props?.lead?.make}
            />
          </>
        )}
        {props.customer?.customerType === CustomerType.BUSINESS && (
          <>
            <SubHeaderElement 
              styleKey="kunde" 
              label="Kunde" 
              value={getCustomerName(props.buyer?.bosCustomer)} 
              dealerRole={props?.dealerRole}
              make={props?.lead?.make}
            />
            <SubHeaderElement 
              styleKey="telefon" 
              label="Telefon" 
              value={props.buyer?.bosCustomer?.phoneMobile} 
              dealerRole={props?.dealerRole}
              make={props?.lead?.make}
            />
            <SubHeaderElement 
              styleKey="epost" 
              label="E-post" 
              value={props.buyer?.bosCustomer?.email} 
              dealerRole={props?.dealerRole}
              make={props?.lead?.make}
            />
            <SubHeaderElement
              styleKey="adresse"
              label="Adresse"
              value={
                props.buyer &&
                `${props.buyer?.bosCustomer?.address1}, ${props.buyer?.bosCustomer?.zipCode} ${props.buyer?.bosCustomer?.city}`
              }
              dealerRole={props?.dealerRole}
              make={props?.lead?.make}
            />
          </>
        )}
        <SubHeaderElement
          styleKey="gyldigtil"
          label="Gyldig til"
          value={format(findExpiresAt(props.expiresAt, props.createdAt), 'dd.MM.yyyy')}
          dealerRole={props?.dealerRole}
          make={props?.lead?.make}
        />
      </View>
    </>
  )
};
