import { Text, View, Image } from '@react-pdf/renderer';
import { pdfMessageBarWrapper, pdfMessageBarText, pdfMessageIconStyle, pdfMessageBarContent } from './common-styles';
import React from 'react';
import info from './imgs/info.png';
import KIAInfo from './imgs/independent-dealer/info/kia-info.png';
import MERInfo from './imgs/independent-dealer/info/mer-info.png';
import PEUInfo from './imgs/independent-dealer/info/peu-info.png';
import SRTInfo from './imgs/independent-dealer/info/srt-info.png';
import { bosColor } from '@next-components/common-styles';
import { MAKE } from 'next-common';

interface MessageBar {
  text: string;
  make?: string;
  dealerRole?: string;
}

enum IndependentBackgroundColor {
  SMART = '#d7e6001a',
  MERCEDES = '#E6F5FF',
  PEUGEOT = '#0074e81f',
  KIA = '#5d7d2b1a'
}

enum IndependentBorderColor {
  SMART = '#d7e60099',
  MERCEDES = '#00ADEF',
  PEUGEOT = '#0074e81f',
  KIA = '#5d7d2b1a'
}

const getBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return IndependentBackgroundColor.SMART;
      case MAKE.Mercedes:
        return IndependentBackgroundColor.MERCEDES;
      case MAKE.Peugeot:
        return IndependentBackgroundColor.PEUGEOT;
      case MAKE.Kia:
        return IndependentBackgroundColor.KIA;
    }
  } else {
    return bosColor.blue20;
  }
};

const getBrderColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return `1px solid ${IndependentBorderColor.SMART}`;
      case MAKE.Mercedes:
        return `1px solid ${IndependentBorderColor.MERCEDES}`;
      case MAKE.Peugeot:
        return `1px solid ${IndependentBorderColor.PEUGEOT}`;
      case MAKE.Kia:
        return `1px solid ${IndependentBorderColor.KIA}`;
    }
  } else {
    return `1px solid ${bosColor.blue}`;
  }
};

const getFontStyle = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return {
          fontSize: '10px',
          fontWeight: 400,
        };
      case MAKE.Mercedes:
        return {
          fontSize: '10px',
          fontWeight: 400,
        };
      case MAKE.Peugeot:
        return {
          fontSize: '8px',
          fontWeight: 400,
        };
      case MAKE.Kia:
        return {
          fontSize: '10px',
          fontWeight: 400,
        };
    }
  } else {
    switch (brand) {
      case MAKE.Smart:
        return {
          fontSize: '10px',
          fontWeight: 600,
        };
      case MAKE.Mercedes:
        return {
          fontSize: '10px',
          fontWeight: 600,
        };
      case MAKE.Peugeot:
        return {
          fontSize: '10px',
          fontWeight: 600,
        };
      case MAKE.Kia:
        return {
          fontSize: '10px',
          fontWeight: 600,
        };
    }
  }
};

const getInfoImage = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return SRTInfo;
      case MAKE.Mercedes:
        return MERInfo;
      case MAKE.Peugeot:
        return PEUInfo;
      case MAKE.Kia:
        return KIAInfo;
    }
  } else {
    return info;
  }
}

export const PdfMessagebar = (props: MessageBar) => {
  return (
    <View style={pdfMessageBarWrapper}>
      <View style={{
        ...pdfMessageBarContent,
        backgroundColor: getBgColor(props?.dealerRole, props?.make),
        border: getBrderColor(props?.dealerRole, props?.make),
      }}>
        <View style={pdfMessageIconStyle}>
          <Image source={getInfoImage(props?.dealerRole, props?.make)} style={{ height: 12, width: 12 }} />
        </View>
        <View style={{
          ...pdfMessageBarText,
          ...getFontStyle(props?.dealerRole, props?.make)
        }}>
          <Text>{props?.text}</Text>
        </View>
      </View>
    </View>
  );
};
