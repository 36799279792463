import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { OrderValidation } from 'next-common';
import React from 'react';
import { IValidationToastNotification } from '../../common';
import { WarningStyled } from './Warning.styled';
import { parseValidationError } from './FinanceWarning.utils';

export interface IFinanceWarningProps extends IValidationToastNotification {
  validationErrors: OrderValidation[];
}

export const FinanceWarning = (props: IFinanceWarningProps) => {
  return (
    <Messagebar
      severityLevel={MessagebarSeverityLevel.ERROR}
      title={'Problem med finansiering'}
      isOpen
      onClose={props?.onClose}>
      <WarningStyled>
        <ul>
          {props.validationErrors.map((x) => (
            <li key={x}>{parseValidationError(x)}</li>
          ))}
        </ul>
      </WarningStyled>
    </Messagebar>
  );
};

export const ServiceContractWarning = (props) => {
  return (
    <Messagebar
      severityLevel={MessagebarSeverityLevel?.ERROR}
      title={'Problem med servicekontrakten'}
      isOpen
      onClose={props?.onClose}>
      <WarningStyled>
        <ul>
          <li>{props.validationErrors}</li>
        </ul>
      </WarningStyled>
    </Messagebar>
  );
};
