import { ERROR_POLICY } from '../../../common';
import { useQuery } from '@apollo/client';
import { queryDetailsBudgetSection } from '../../../graphql';
export interface IBudgetDataParams {
  serialNo: string;
  orderNo: string;
  dealerId: string;
}

export const useBudgetData = (props: IBudgetDataParams) => {
  const { data, loading } = useQuery(queryDetailsBudgetSection, {
    variables: {
      serialNumber: props.serialNo,
      orderNo: props.orderNo,
      dealerId: props.dealerId,
    },
    skip: !props.serialNo || !props.dealerId || !props.orderNo,
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });
  return { data, loading };
};
