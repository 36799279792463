import { bosBrand, bosColor, secondary, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const OrderButtonsStyled = styled.div`
  .button {
    &.callToAction,
    &.outlined {
      width: auto;
      padding: 0 2rem;
    }
  }

  .flexWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;

    button {
      width: 100%;
      justify-content: center;
    }
    > div {
      width: 100%;

      button {
        justify-content: center;
      }
    }
  }
`;

export const FileButtonContainer = styled.div`
  padding: 2rem 0 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .file-icon {
    display: flex;
    &.pt-2 {
      padding-top: 2rem;
    }
    .icon-container {
      cursor: pointer;
      div > svg + div {
        .lds-ellipsis {
          height: auto;

          > div {
            top: -0.5rem;
          }
        }
      }
      a {
        font-family: 'Mulish', Helvetica, Arial, sans-serif;
        color: ${bosColor.black};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.semibold};
        line-height: 175%;
        border-bottom-width: 1px;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
      }
    }
    button {
      span.icon-container {
        background: ${secondary};
        padding: 6px;
        border-radius: 50%;
        line-height: 0;
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: #fff;
          }
        }
        &:after {
          background: ${bosBrand};
          border-color: ${bosBrand};
        }
      }
    }
  }
`;
export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid ${bosColor.blue};
  background: ${bosColor.blue20};
`;

export const ActionButtonContainer = styled.div`
  display: grid;
  padding: 0rem 0rem 2rem;
  grid-template-columns: max-content max-content max-content;
  gap: 2rem;
  padding: 2rem 0 0;

  button {
    &.red {
      color: ${bosColor.black};
      border-radius: 0.5rem;
      border: 2px solid ${bosColor.poppy};
      gap: 0.5rem;

      span {
        color: ${bosColor.black};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.extrabold};
        line-height: 175%;
        margin: 0;
      }
      &:hover {
        background-color: ${bosColor.blue20};
      }
    }
  }
`;
