import { bosColor } from '@next-components/common-styles';
import styled from 'styled-components';


export const CustomModalAction = styled.div`
  padding-top: 4rem;
  width: 100%;
  display: flex;
  grid-template-rows: none;
  grid-template-columns: repeat(2,6fr);
  grid-column-gap: 2rem;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: start;
`;


export const CreateOrderErrorMessage = styled.div`
  font-size: 0.875rem;
  color: ${bosColor.poppy};
  padding-top: 0.5rem;
  text-align: left;
`