import React from 'react';
import classNames from 'classnames';
import {
  IBosApiVehicleMarginCalculationActuals,
  IBosApiVehicleMarginCalculationSales,
  IOverviewInfoDetails,
  ReferenceType,
} from 'next-common';
import { formatNumber } from '@next-components/common-types';
import { useLazyQuery } from '@apollo/client';
import { ERROR_POLICY } from '../../../../../common';
import { queryVendorInvoice } from '../../../../../graphql';
import {
  copyToClipboard,
  copyWithIFSUrl,
  getVoucherDate,
  sum,
  uniqueByAccounts,
  uniqueByAccountsAndReferenceNumber,
  uniqueByAccountStart,
} from '../../../../../utils';
import { v4 as uuidv4 } from 'uuid';

export interface IBuyTableProps {
  actualsDetails: IBosApiVehicleMarginCalculationActuals[];
  budgetDetails: IBosApiVehicleMarginCalculationSales;
  overviewInfoDetails: IOverviewInfoDetails;
}

export const BuyTable = (props: IBuyTableProps) => {
  const [getEyeshareUrls] = useLazyQuery(queryVendorInvoice, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      /* istanbul ignore next */if (data?.getVendorInvoiceForEyeshare) {
        copyToClipboard(data?.getVendorInvoiceForEyeshare?.fileUrl);
        alert('Kopiert ' + data?.getVendorInvoiceForEyeshare?.invoiceNumber + '. Vennligst åpne IE og lim inn URL');
      }
    },
  });

  const eyeshareCopyLinkClick = (paramsData) => {
    const paramsId = {
      id: `${paramsData.company}-${paramsData.referenceNumber}-${paramsData.identity}`,
    };
    getEyeshareUrls({ variables: paramsId });
  };

  const importerInvoiceReferenceClick = (e, currentValue) => {
    copyWithIFSUrl(currentValue.referenceNumber, urlForImporterInvoice, e);
  };

  const pakostUrlClick = (e, data) => {
    switch (data.referenceType) {
      case ReferenceType.SERVICE_ORDER:
        return copyWithIFSUrl(data.referenceNumber, url, e);
      case ReferenceType.EYESHARE_INVOICE:
        return eyeshareCopyLinkClick(data);
      case ReferenceType.IFS_INTERNAL_INVOICE:
        return copyWithIFSUrl(data.referenceNumber, urlForImporterInvoice, e);
      /* istanbul ignore next */default:
        return null;
    }
  };

  // @ts-ignore
  const ifsUrl = window._env_?.REACT_APP_IFS_MARGINS_URL;
  const url = `${ifsUrl}?WO_NO=`;

  // @ts-ignore
  const ifsUrlForImporterInvoice = window._env_?.REACT_APP_IFS_MARGINS_IMPORTER_INVOICE_URL;
  const urlForImporterInvoice = `${ifsUrlForImporterInvoice}?INVOICE_NO=`;

  const påkostAccounts = ['4307', '4308', '4374', '4500', '4600', '4990', '4991', '4501'];
  const innkjøpBruktbilAccounts = ['4301', '4371'];
  const innkjøpAccounts = ['4300', '4370', '4373'];

  return (
    <div className="row-container">
      <table className="table mainTbl">
        <thead>
          <tr>
            <th>Kjøp</th>
            <th className="center-content">Kalkyle</th>
            <th className="right-content">Virkelig Resultat</th>
            <th className="text-center">Ref</th>
            <th className="text-center">Dato</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bil</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(Math.round(props.budgetDetails?.purchase?.carPrice))}
            </td>
            <td className="right-content" />
            <td className="right-content" colSpan={2} />
          </tr>
          <tr>
            <td>Opsjoner</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(Math.round(props.budgetDetails?.purchase?.optionFactoryPrice))}
            </td>
            <td className="right-content" />
            <td className="right-content" colSpan={2} />
          </tr>
          <tr>
            <td>Imp. rabatt</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(Math.round(props.budgetDetails?.purchase?.importerDiscount))}
            </td>
            <td className="right-content" />
            <td className="right-content" colSpan={2} />
          </tr>
          {props.budgetDetails?.purchase?.optionLocals?.map((x) => (
            <tr
              key={props.budgetDetails?.purchase?.optionLocals?.findIndex(
                (el) => el.optionLocalText === x.optionLocalText,
              )}>
              <td>
                <div className="eclipsText eclipsText-big" title={x.optionLocalText}>
                  {x.optionLocalText}
                </div>
              </td>
              <td className="center-content">{formatNumber(Math.round(x.optionLocalPrice))}</td>
            </tr>
          ))}
          <tr>
            <td>Leveringsomkost</td>
            <td className="center-content">
              {formatNumber(Math.round(props.budgetDetails?.purchase?.deliveryCharges))}
            </td>
            <td className="right-content" />

            <td className="right-content" colSpan={2} />
          </tr>

          <tr>
            <td>Forventet Påkost</td>
            <td className="center-content">
              {formatNumber(Math.round(props.budgetDetails?.purchase?.additionalCost))}
            </td>
            <td className="right-content" />

            <td className="right-content" colSpan={2} />
          </tr>

          <tr>
            <td className="table-padding" colSpan={3} />
          </tr>
          {uniqueByAccounts(props.actualsDetails, innkjøpAccounts, true, ReferenceType.IMPORTER_INVOICE)?.map(
            (currentValue) => (
              <tr key={uuidv4()}>
                <td className="eclipsText eclipsText-big" title="Faktura importor">
                  Faktura importor
                </td>
                <td className="center-content" />
                <td className="right-content ">{formatNumber(Math.round(+currentValue.amount))}</td>
                <td className="text-center boldFont" colSpan={1}>
                  <span
                    className={classNames(
                      {
                        link: true,
                        'primary-link': true,
                      },
                      'cursor-pointer',
                    )}
                    title={currentValue.referenceNumber}
                    key={currentValue.referenceNumber}
                    onClick={(e) => importerInvoiceReferenceClick(e, currentValue)}>
                    {currentValue.referenceNumber}
                  </span>
                </td>
                <td className="right-content ">{getVoucherDate(currentValue.voucherDate)}</td>
              </tr>
            ),
          )}
          {uniqueByAccounts(props.actualsDetails, innkjøpAccounts, false, ReferenceType.IMPORTER_INVOICE)?.map(
            (currentValue) => (
              <tr key={uuidv4()}>
                <td className="eclipsText eclipsText-big" title="Innkjøp">
                  Innkjøp
                </td>
                <td className="center-content" />
                <td className="right-content ">{formatNumber(Math.round(+currentValue.amount))}</td>
                <td className="text-center boldFont" colSpan={1}>
                  {currentValue.referenceNumber}
                </td>
                <td className="right-content ">{getVoucherDate(currentValue.voucherDate)}</td>
              </tr>
            ),
          )}
          {uniqueByAccounts(props.actualsDetails, innkjøpBruktbilAccounts, false, null)?.map((currentValue) => (
            <tr key={uuidv4()}>
              <td className="eclipsText eclipsText-big" title="Innkjøp bruktbil">
                Innkjøp bruktbil
              </td>
              <td className="center-content" />
              <td className="right-content ">{formatNumber(Math.round(+currentValue.amount))}</td>
              <td className="text-center boldFont" colSpan={1}>
                {currentValue.referenceType === ReferenceType.EYESHARE_INVOICE ? (
                  <span
                    className={classNames(
                      {
                        link: true,
                        'primary-link': true,
                      },
                      'cursor-pointer',
                    )}
                    title={currentValue.referenceNumber}
                    key={currentValue.referenceNumber}
                    onClick={(e) => pakostUrlClick(e, currentValue)}>
                    {currentValue.referenceNumber}
                  </span>
                ) : (
                  currentValue.referenceNumber
                )}
              </td>
              <td className="right-content ">{getVoucherDate(currentValue.voucherDate)}</td>
            </tr>
          ))}
          <tr>
            <td className="table-padding" colSpan={3} />
          </tr>
          {uniqueByAccountsAndReferenceNumber(props.actualsDetails, påkostAccounts, '')?.map((currentValue) => (
            <tr key={uuidv4()}>
              <td className="eclipsText eclipsText-big" title="Påkost">
                Påkost
              </td>
              <td className="center-content" />
              <td className="right-content ">{formatNumber(Math.round(+currentValue.amount))}</td>
              <td className="text-center boldFont" colSpan={1}>
                {currentValue.referenceType === ReferenceType.SERVICE_ORDER ||
                currentValue.referenceType === ReferenceType.EYESHARE_INVOICE ||
                currentValue.referenceType === ReferenceType.IFS_INTERNAL_INVOICE ? (
                  <span
                    className={classNames(
                      {
                        link: true,
                        'primary-link': true,
                      },
                      'cursor-pointer',
                    )}
                    title={currentValue.referenceNumber}
                    key={currentValue.referenceNumber}
                    onClick={(e) => pakostUrlClick(e, currentValue)}>
                    {currentValue.referenceNumber}
                  </span>
                ) : (
                  currentValue.referenceNumber
                )}
              </td>
              <td className="right-content ">{getVoucherDate(currentValue.voucherDate)}</td>
            </tr>
          ))}
          {uniqueByAccountsAndReferenceNumber(props.actualsDetails, påkostAccounts, null)?.map(
            (currentValue) => (
              <tr key={uuidv4()}>
                <td className="eclipsText eclipsText-big" title="Påkost manuelt bilag">
                  Påkost manuelt bilag
                </td>
                <td className="center-content" />
                <td className="right-content">{formatNumber(Math.round(+currentValue.amount))}</td>
                <td className="text-center boldFont" colSpan={1}>
                  {currentValue.referenceNumber}
                </td>
                <td className="right-content ">{getVoucherDate(currentValue.voucherDate)}</td>
              </tr>
            ),
          )}
          <tr>
            <td className="table-padding" colSpan={3} />
          </tr>
          <tr>
            <th>Oppsummering</th>
            <th className="center-content bottom-content">Kalkyle</th>
            <th className="right-content bottom-content">Virkelig Resultat</th>
            <th />
          </tr>
          <tr>
            <td>Salg Totalt</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(
                Math.round(
                  props.budgetDetails?.sale?.carPrice +
                    props.budgetDetails?.sale?.optionFactoryPrice +
                    props.budgetDetails?.sale?.deliveryCharges +
                    props.budgetDetails?.sale?.discount +
                    sum(props.budgetDetails?.sale?.optionLocals, 'optionLocalPrice'),
                ),
              )}
            </td>
            <td className="right-content">
              {formatNumber(
                Math.abs(
                  Math.round(
                    +(
                      sum(uniqueByAccountStart(props.actualsDetails, '3', false), 'amount') +
                      sum(uniqueByAccountStart(props.actualsDetails, '3', true), 'amount')
                    ),
                  ),
                ),
              )}
            </td>
            <td className="right-content" colSpan={1} />
          </tr>
          <tr>
            <td>Innkjøp Bil</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(
                Math.round(
                  +(
                    props.budgetDetails?.purchase?.carPrice +
                    props.budgetDetails?.purchase?.optionFactoryPrice +
                    props.budgetDetails?.purchase?.importerDiscount
                  ),
                ),
              )}
            </td>
            <td className="right-content">
              {formatNumber(
                Math.round(
                  +(
                    sum(
                      uniqueByAccounts(props.actualsDetails, innkjøpAccounts, true, ReferenceType.IMPORTER_INVOICE),
                      'amount',
                    ) +
                    sum(
                      uniqueByAccounts(props.actualsDetails, innkjøpAccounts, false, ReferenceType.IMPORTER_INVOICE),
                      'amount',
                    ) +
                    sum(uniqueByAccounts(props.actualsDetails, innkjøpBruktbilAccounts, false, null), 'amount')
                  ),
                ),
              )}
            </td>
            <td className="right-content" colSpan={2} />
          </tr>
          <tr>
            <td>Påkost</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(
                Math.round(
                  sum(props.budgetDetails?.purchase?.optionLocals, 'optionLocalPrice') +
                    props.budgetDetails?.purchase?.deliveryCharges +
                    props.budgetDetails?.purchase?.additionalCost,
                ),
              )}
            </td>
            <td className="right-content">
              {formatNumber(
                Math.round(
                  +(
                    sum(uniqueByAccountsAndReferenceNumber(props.actualsDetails, påkostAccounts, ''), 'amount') +
                    sum(uniqueByAccountsAndReferenceNumber(props.actualsDetails, påkostAccounts, null), 'amount')
                  ),
                ),
              )}
            </td>
            <td className="right-content" colSpan={2} />
          </tr>
          <tr>
            <td>Fortjeneste</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(Math.round(+props.budgetDetails?.marginBudgetPrice))}
            </td>
            <td className="right-content">{formatNumber(Math.round(props.overviewInfoDetails?.marginActual))}</td>
            <td className="right-content" colSpan={2} />
          </tr>
          <tr>
            <td>Fortjeneste %</td>
            <td className="center-content eclipsText eclipsText-big">
              {formatNumber(+props.budgetDetails?.marginBudgetPercent, 2)} %
            </td>
            <td className="right-content">{formatNumber(props.overviewInfoDetails?.marginPercentage, 2)} %</td>
            <td className="right-content" colSpan={2} />
          </tr>
          <tr className="devider">
            <td colSpan={4} />
          </tr>
          <tr>
            <td>Foreslått avsetning</td>
            <td className="center-content" />
            <td className="right-content">
              {formatNumber(
                Math.round(+props.budgetDetails?.marginBudgetPrice - props.overviewInfoDetails?.marginActual),
              )}
            </td>
            <td className="right-content" colSpan={2} />
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default BuyTable;
