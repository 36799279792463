import React from 'react';
import { IUserSubMenu } from '../UserSubMenus';
import { Icon, IconType } from '@next-components/bos-icon';

export interface ISettingHeaderItemProps {
  item: IUserSubMenu;

  onReset(): void;
}

export const SettingHeaderItem = (props: ISettingHeaderItemProps) => (
  <>
    <div className="iconStyled" onClick={() => props.onReset()}>
      <Icon icon={IconType.NewNextArrowLeft} aria-hidden className="user-head-icon" />
    </div>
    <div className="profile-header-text">{props.item.title}</div>
  </>
);
