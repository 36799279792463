import { InputField } from '@next-components/input-field';
import { FinancingType, ICalculation, IFinancing } from 'next-common';
import { formatNumber, InputType } from '@next-components/common-types';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { FormRow } from '../../FormRow';
import { fieldIds } from './FinancingForm';
import { get } from 'lodash';
import { SmallLink } from '@next-components/link';
import { getNominalInterest, getRequestedNominalInterest } from './FinancingForm.utils';

export interface IInterestFormRowProps {
  type?:number;
  calculation: ICalculation;
  isEditable?: boolean;
  isLoading?: boolean;
  onInterestChange(e: SyntheticEvent, field: string, originalValue: any);
  isRenteVisible?:boolean;
  setIsRenteVisible?(isRenteVisible:boolean):void;
}

const getInterestValue = (financing: IFinancing) => {
  return getRequestedNominalInterest(financing) || getNominalInterest(financing) || 0;
}

export const InterestFormRow = (props: IInterestFormRowProps) => {
  const [interest, setInterest] = useState(getInterestValue(props.calculation?.financing).toString());
  const [interestLocked , setInterestLocked] = useState<boolean>(true);
  const hasComponentMounted = useRef<boolean>(false)

  useEffect(() => {
    if (!props.calculation) return;
    const current = formatNumber(getInterestValue(props.calculation?.financing), 2);
    setInterest(current);
  }, []);

  useEffect(() => {
    const newInterest = formatNumber(getInterestValue(props.calculation?.financing), 2);
    setInterest(newInterest);
  }, [props.calculation]);

  useEffect(() => {
    if (hasComponentMounted.current && !props.isRenteVisible && interestLocked) {
      const current = getInterestValue(props.calculation?.financing);
      setInterest(`${current}`);
      const e = { currentTarget: { value: current.toString() } };
      onInputChange(e);
    } else {
      hasComponentMounted.current = true;
    }
  }, [props.isRenteVisible, interestLocked]);


  const onInputChange = (e) => {  
    let valueString: string = e?.currentTarget?.value ?? e.target.value;
    valueString = valueString?.replace(',', '.');
    const value: number = parseFloat(valueString);
    setInterest(formatNumber(value, 2));
    props.onInterestChange(e, fieldIds.manualNominalInterest, interest);
  };  
  
  const handleClick = ()=>{
    props.setIsRenteVisible(!props.isRenteVisible);
    setInterestLocked(!interestLocked)
  }
  
  if (!props.isEditable) {
    return (
      <FormRow
        id={fieldIds.manualNominalInterest}
        isValid
        isEditable={false}
        label="Rente"
        value={interest}
        valuePostfix={' %'}
        type={InputType.FLOAT}
        labelChild={
          <>
            {' ('}
            {interestLocked ? (
              <SmallLink className="interestLink" onLinkClick={() => handleClick()} text={'overstyrt'} />
            ) : (
              'overstyrt'
            )}
            ,
            {!interestLocked ? (
              <SmallLink className="interestLink" onLinkClick={() => handleClick()} text={'tilbakestill rente'} />
            ) : (
              'tilbakestill rente'
            )}
            {' )'}
          </>
        }
      />
    );
  }

  return (
    <>
      {props.isRenteVisible && (
        <div className={`form-row interest ${props.type === FinancingType.LOAN ? 'narrowWidth' : ''}`}>
          <label className={'smallFont'} htmlFor={fieldIds.manualNominalInterest}>
            Overstyr rente
          </label>
          <InputField
            className="borderLight"
            decimals={2}
            disabled={props.isLoading}
            id={fieldIds.manualNominalInterest}
            type={InputType.FLOAT}
            endAdornment="%"
            key={`${fieldIds.manualNominalInterest}_${get(props, 'calculation.id')}`}
            value={interest}
            onChange={(input: string, formatted?: string, e?: React.ChangeEvent<any>) => onInputChange(e)}
            onKeyDown={(e: React.KeyboardEvent<any>) => {
              if (e.key === 'Enter') {
                onInputChange(e);
              }
            }}
          />
          <FormRow
            id={fieldIds.manualNominalInterest}
            isValid
            isEditable={false}
            label="Standard Rente: "
            value={getNominalInterest(props.calculation?.financing)?.toString()}
            valuePostfix={' %'}
            type={InputType.FLOAT}
            collapsedWarning
            decimals={2}
          />
        </div>
      )}
    </>
  );
};
