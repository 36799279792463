import React, { useEffect } from 'react';
import { CustomerType, IOrder } from 'next-common';
import { Buffer } from 'buffer';
import { InfoBlock } from '../../../sales-common/InfoBlock';
import { ModalInner, SignerFooterBtnContainer } from '../../../sales-common/modal.styled';
import { DigitalSigningModalStyled } from './DigitalSigningModal.styled';
import { CustomerInputs } from '../../../sales-common/CustomerInputs';
import { getNBFContractMutationParsed } from '../../../../../common';
import { useMutation } from '@apollo/client';
import { getCustomerName } from '../../../../../utils';
import { Button } from '@next-components/cta';
import { previewServiceContractClick } from '../../../sales-common/ServiceContractPDF';
import { CustomModal } from '@next-components/custom-modal';
import { CustomModalWrapper } from '../../../../layout/CustomModalWrapper.styled';
import get from 'lodash/get';

export interface IDigitalSigningModalProps {
  order: IOrder;
  show: boolean;
  softLockForMinutes?: number;

  onConfirm(confirmed: boolean): void;
  isTestUser?: boolean;
}

export const DigitalSigningModal = (props: IDigitalSigningModalProps) => {
  const [ssnValid, setSsnValid] = React.useState(true);
  const [mobileValid, setMobileValid] = React.useState(true);
  const [serviceContractValid, setServiceContractValid] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [serviceContractPdfProcessing, setServiceContractPdfProcessing] = React.useState(false);
  const [generateContract] = useMutation(getNBFContractMutationParsed);
  const isServiceContract = !!props.order?.chosenOffer?.serviceContract;

  useEffect(() => {
    if (isServiceContract && (props.order?.serviceContractBosId || props.order?.salesContractPdfId)) {
      setServiceContractValid(true);
    } else if (!isServiceContract) {
      setServiceContractValid(true);
    }
  }, []);

  const onMobileValidityChange = (value: boolean) => {
    setMobileValid(value);
  };

  const onSsnValidityChange = (value: boolean) => {
    setSsnValid(value);
  };

  const previewContractClick = async () => {
    setProcessing(true);
    try {
      const variables = { id: props.order?.id };
      const { data } = await generateContract({ variables }).finally(() => {
        setProcessing(false);
      });
      const base64File = Buffer.from(data?.getNBFContractBeforeSigning?.content || '', 'base64');
      const file = new Blob([base64File], { type: 'application/pdf;base64' });
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl, '_blank');
    } catch (error) {
      setProcessing(false);
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  if (!props.show) return null;

  const isFactoryOrder = Boolean(get(props, 'order.vehicles[0].orderFromFactoryDetails', null));

  return (
    <CustomModalWrapper className="signing-modal backdrop smModal width32" active={props.show}>
      <DigitalSigningModalStyled>
        <CustomModal active={props.show} onCloseClick={() => props.onConfirm(false)} isModelCloseAllowedOutside={false}>
          <header>
            <h2>Signer digitalt</h2>
          </header>
          <ModalInner>
            <div className={'name-section'}>
              <InfoBlock label="Navn" value={getCustomerName(props.order?.customer?.bosCustomer) ?? 'ukjent kunde'} />
            </div>
            {props.order?.customer?.bosCustomer?.customerType === CustomerType[CustomerType.BUSINESS] && (
              <InfoBlock label="Org.nr." value={props.order?.customer?.bosCustomer?.organizationNo ?? '-'} />
            )}
            {!props?.isTestUser && (
              <CustomerInputs //As props?.isTestUser is true, the user is test user and we hide user input.
                customer={props.order?.customer}
                financingType={props.order?.chosenOffer?.financingType}
                onMobileValidityChange={onMobileValidityChange}
                onSsnValidityChange={onSsnValidityChange}
              />
            )}
            <Button isLoading={processing} colorVariant="primary" onClick={previewContractClick}>
              <span>Forhåndsvis NBF-kontrakt</span>
            </Button>
            {props?.order?.chosenOffer?.serviceContract && (
              <Button
                isLoading={serviceContractPdfProcessing}
                colorVariant="primary"
                onClick={() =>
                  previewServiceContractClick(
                    props.order?.id,
                    props.order?.serviceContractBosId || props.order?.salesContractPdfId,
                    setServiceContractPdfProcessing,
                  )
                }>
                <span>Forhåndsvise Servicekontrakt</span>
              </Button>
            )}
            <SignerFooterBtnContainer>
              {!props?.isTestUser && ( //As props?.isTestUser is true, the user is test user and we hide Digital sign confirm.
                <Button
                  onClick={() => props.onConfirm(true)}
                  disabled={!ssnValid || !mobileValid || !serviceContractValid}>
                  Bekreft
                </Button>
              )}
              <Button onClick={() => props.onConfirm(false)} variant="secondary">
                Avbryt
              </Button>
            </SignerFooterBtnContainer>
            {props.softLockForMinutes && !isFactoryOrder && !props?.isTestUser ? ( //As props?.isTestUser is true, the user is test user and we hide 5 min lock msg.
              <h5 style={{ marginTop: 15 }}>Bilen vil bli holdt av i {props.softLockForMinutes} minutter</h5>
            ) : null}
          </ModalInner>
        </CustomModal>
      </DigitalSigningModalStyled>
    </CustomModalWrapper>
  );
};

DigitalSigningModal.defaultProps = {
  customer: {},
};
