import React from 'react';
import { useMsal } from '@azure/msal-react';

export const LogOut = () => {
  const { instance, accounts } = useMsal();

  const handleLogout = async () => {
    sessionStorage.clear();
    await instance.logoutRedirect({
      onRedirectNavigate() {
        localStorage.removeItem('msal-token');
      },
      account: instance?.getAccountByHomeId(accounts?.[0]?.homeAccountId),
      postLogoutRedirectUri: '/',
    });
  };
  return (
    <div>
      <a onClick={handleLogout}>Logg ut</a>
    </div>
  );
};
