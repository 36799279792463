import React from 'react';
import header from './imgs/full-header-size.jpg';
import { View, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { SubHeader } from './SubHeader';
import { IOrder, MAKE } from 'next-common';
import { bosBrand, white } from '@next-components/common-styles';
import headerWithoutLogo from './imgs/full-header-without-logo.jpg';
import smartHeader from './imgs/smart-full-header-with-logo.png';
import smartHeaderWithoutLogo from './imgs/smart-full-header-without-logo.png';

import kiaIndependentWithHeader from './imgs/independent-dealer/kia-full-header-with-logo.png';
import SmartIndependentWithHeader from './imgs/independent-dealer/smart-full-header-with-logo.png';
import peugeotIndependentWithHeader from './imgs/independent-dealer/peugeot-full-header-with-logo.png';
import mercedesIndependentWithHeader from './imgs/independent-dealer/mercedes-full-header-with-logo.png';


import kiaIndependentWithoutHeader from './imgs/independent-dealer/kia-full-header-without-logo.png';
import SmartIndependentWithoutHeader from './imgs/independent-dealer/smart-full-header-without-logo.png';
import peugeotIndependentWithoutHeader from './imgs/independent-dealer/peugeot-full-header-without-logo.png';
import mercedesIndependentWithoutHeader from './imgs/independent-dealer/mercedes-full-header-without-logo.png';

const MainHeaderStyle: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: bosBrand,
  color: white,
  height: 70,
  padding: 10,
};

interface IHeaderProps {
  order: IOrder;
  dealerRole?: string;
  disablePdfLogo: boolean;
}

interface IMainHeaderProps {
  order: IOrder;
  dealerRole?: string;
}

const getImageWithLogo = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return SmartIndependentWithHeader;
      case MAKE.Mercedes:
        return mercedesIndependentWithHeader;
      case MAKE.Peugeot:
        return peugeotIndependentWithHeader;
      case MAKE.Kia:
        return kiaIndependentWithHeader;
      default:
        return header;
    }
  } else {
    switch (brand) {
      case MAKE.Smart:
        return smartHeader;
      default:
        return header;
    }
  }
};

const getImageWithoutLogo = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return SmartIndependentWithoutHeader;
      case MAKE.Mercedes:
        return mercedesIndependentWithoutHeader;
      case MAKE.Peugeot:
        return peugeotIndependentWithoutHeader;
      case MAKE.Kia:
        return kiaIndependentWithoutHeader;
      default:
        return headerWithoutLogo;
    }
  } else {
    switch (brand) {
      case MAKE.Smart:
        return smartHeaderWithoutLogo;
      default:
        return headerWithoutLogo;
    }
  }
};

const MainHeader = (props: IMainHeaderProps) => {
  const logo = getImageWithLogo(props?.dealerRole, props?.order?.lead?.make);
  return (
    <Image source={logo} />
  )
};

const MainHeaderWithoutLogo = (props: IMainHeaderProps) => {
  const logo = getImageWithoutLogo(props?.dealerRole, props?.order?.lead?.make);
  return (
    <Image source={logo} />
  )
};

export const Header = (props: IHeaderProps) => {
  return (
    <View fixed>
      {props?.disablePdfLogo ? <MainHeader order={props?.order} dealerRole={props?.dealerRole} /> : <MainHeaderWithoutLogo order={props?.order} dealerRole={props?.dealerRole} />}
      <SubHeader {...props?.order} dealerRole={props?.dealerRole} />
    </View>
  );
};
