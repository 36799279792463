import * as React from 'react';
import { getLatestMargin, IOrder, IVehicleConfiguration } from 'next-common';
import { MarginOverview } from './MarginOverview/MarginOverview';
import { MarginPaneStyled } from './MarginPane.styled';
import { MarginDetails } from './MarginDetails';

interface IMarginProps {
  order: IOrder;
  vehicleConfig: IVehicleConfiguration;
}

export const MarginPane = (props: IMarginProps) => {
  const activeMargin = getLatestMargin(props.order?.margins);
  return (
    <div>
      <MarginPaneStyled>
        <div className="pane">
          <div className="title">
            <h2>Marginutregning</h2>
          </div>
          <MarginDetails order={props.order} vehicleConfig={props.vehicleConfig} />
        </div>
        <div className="pane">
          <div className="title">
            <h2>Marginbilde</h2>
          </div>
          <MarginOverview margin={activeMargin} />
        </div>
      </MarginPaneStyled>
    </div>
  );
};
