import { gql } from '@apollo/client';
import { getVehicleOrderStatusQuery, findEventsOnOrderQuery } from 'next-common';
import { myOverviewOrderQuery } from '../../../components/dashboard/my-overview-page/orderQuery';

export const queryOrdersParsed = gql`
  ${myOverviewOrderQuery}
`;

export const vehicleOrderStatusQueryParsed = gql`
  ${getVehicleOrderStatusQuery}
`;

export const findEventsOnOrderParsed = gql`
  ${findEventsOnOrderQuery}
`;

export const getLeasingCompanyListQuery = gql`
  query getLeasingCompanyList {
    getLeasingCompanyList {
      organizationName
      organizationNo
      address1
      address2
      city
      zipCode
      idNumber
    }
  }
`;