import styled from 'styled-components';
import { responsive, bosColor, fontWeightBold, typography } from '@next-components/common-styles';

export const FilterBarStyled = styled.div`
  .dropdown {
    input {
      height: inherit;
    }
  }
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 1.25rem 0;

  ${responsive.smPlus} {
    grid-template-columns: 4fr 5fr 2fr;
    grid-template-rows: auto;
  }

  #searchContainer {
    padding-bottom: 1rem;
    border-bottom: 1px solid #e3e3e3;
    .searchLabel {
      color: ${bosColor.black};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 175%;
      margin-bottom: 4px;
    }
    .inputContainer {
      .inputAndIconContainer {
        .adornment-container {
          top: 0.75rem;
        }
        #searchField {
          padding: 0.75rem 1rem;
        }
      }
    }
  }
  #categoryContainer {
    .categoryFilterMenu {
      display: flex;
      gap: 2rem;
      a {
        font-size: 1rem;
        text-decoration: none;
        font-weight: ${fontWeightBold};
        justify-content: center;
        display: flex;
        color: ${bosColor.black};
        align-items: center;
        &:hover {
          cursor: pointer;
        }
        .circle {
          height: 1.75rem;
          margin-right: 0.875rem;
          width: 1.75rem;
          border-radius: 2rem;
          border: 0.125rem solid ${bosColor.black};
        }
        &.active {
          .circle {
            background-color: ${bosColor.black};
          }
        }
        .square {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 0.5rem;
          border-radius: 0.25rem;
          border: 2px solid ${bosColor.black};
        }
        &.active {
          .square {
            background-color: ${bosColor.black};
          }
        }
      }
    }
    & .radioButtonGroup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      label {
        font-weight: ${typography.weight.semibold};
      }
      [type='radio']:checked + label:before,
      [type='radio']:not(:checked) + label:before {
        box-sizing: initial;
      }

      > div.filterAccordion {
        width: 100%;
        padding: 1rem 1rem 0;
        margin-left: 0;
        &[open] {
          padding-bottom: 1.5rem;
        }

        .btnAccordion {
          padding-top: 0;
          .labelContainer {
            span.label {
              color: ${bosColor.black};
              font-size: 1rem;
              font-style: normal;
              font-weight: ${typography.weight.extrabold};
              line-height: 175%;
            }
          }
        }
        .content {
          padding: 0;
        }
      }
    }
    > div {
      > div {
        margin-left: 0.313rem;
      }
      flex-direction: row;
    }
  }
`;
