import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IValidationToastNotification, Routes } from '../../common';
import { getAppendingNumericIdFromUrl } from '../../utils';
import { useNavigate } from 'react-router';
import { WarningStyled } from './Warning.styled';
import { getErrorMessageForError } from './ErrorMessageMapper';

export interface IVehicleUnavailableNotificationNotificationProps extends IValidationToastNotification {
  errorCode: number;
  orderId?: string;
}

export const VehicleUnavailableNotification = (props: IVehicleUnavailableNotificationNotificationProps) => {
  const getOrderId = () => props?.orderId || getAppendingNumericIdFromUrl('');
  const navigate = useNavigate();
  //TBD: ServiceNowTicket Creation. Currently moves to View Page
  const raiseServiceNowIncident = async () => {
    const route = Routes.getViewPage(getOrderId());
    navigate(route);
  };

  return (
    <Messagebar severityLevel={props?.severityLevel} title={'Manglende kjøretøydata'} isOpen onClose={props?.onClose}>
      <WarningStyled>
        <p>{getErrorMessageForError(props?.errorCode)}</p>
        {getOrderId() && (
          <p id={'event-link'} onClick={() => raiseServiceNowIncident()}>
          Rapporter her
          </p>
        )}
      </WarningStyled>
    </Messagebar>
  );
};

VehicleUnavailableNotification.defaultProps = {
  severityLevel: MessagebarSeverityLevel.ERROR,
};
