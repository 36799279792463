import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AxiosHeaderEnum, apimEndpoint } from '../../../../common';
import { getHeader } from '../../../../utils';
export const handlePdfResult = (result: AxiosResponse) => {
  const file = new Blob([result.data], { type: 'application/pdf;base64' });
  const fileUrl = URL.createObjectURL(file);
  window.open(fileUrl, '_blank');
};

export const postServiceContractPdf = async (orderId: string, serviceContractId: number, pdfData: string) => {
  const formData = new FormData();
  formData.append('pdfData', pdfData);
  return await axios
    .post(`${apimEndpoint ?? window.location.origin}/api/saveServiceContractPdf/${orderId}/${serviceContractId}`, formData)
    .then((result) => {
      return result.data;
    })
    // tslint:disable-next-line:no-console
    .catch((err) => console.error(err));
};

export const getServiceContractPdf = async (orderId: string, serviceContractId: number | string) => {
  const HEADER_BLOB: AxiosRequestConfig = await getHeader(AxiosHeaderEnum.HEADER_BLOB);
  return await axios
    .get(`${apimEndpoint ?? window.location.origin}/api/getServiceContractPdf/${orderId}/${serviceContractId}`, HEADER_BLOB)
    .then((result) => {
      handlePdfResult(result);
    })
    // tslint:disable-next-line:no-console
    .catch((err) => console.error(err));
};

export const getSignedServiceContractPdf = async (orderId: string, signedDocumentName: string) => {
  const HEADER_BLOB: AxiosRequestConfig = await getHeader(AxiosHeaderEnum?.HEADER_BLOB);
  return await axios
    .get(`${apimEndpoint ?? window.location.origin}/api/getDigitalSignedPdf/${orderId}/${signedDocumentName}`, HEADER_BLOB)
    .then((result) => {
      handlePdfResult(result);
    })
    // tslint:disable-next-line:no-console
    .catch((err) => console.error(err));
};

export const previewServiceContractClick = async (orderId: string, salesContractPdfId: number | string, setLoading) => {
  setLoading(true);
  try {
    await getServiceContractPdf(orderId, salesContractPdfId).finally(() => {
      setLoading(false);
    });
  } catch (error) {
    setLoading(false);
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};
