import React from 'react';
import { PageSteps } from '../NewHeader';
import { CancelOrderModalWrapper } from '../CancelOrderModal/CancelOrderModal.styled';
import { ModalWrapper } from '../../../common-components/ModalWrapper';
import { AlertModal } from '@next-components/alert-modal';

export interface ICancelReserveOrderModalProps {
  modalStatus: boolean;
  closeModalClick(): void;
  confirmReserveCancelClick(): void;
  currentStep?: PageSteps;
}

export const CancelReserveOrderModal = (props: ICancelReserveOrderModalProps) => {
  const colorVariant = props.currentStep === PageSteps.START ? 'primary' : 'secondary';

  const cancelReserveOrderModalData = {
    title: 'Er du sikker på at du vil avbryte reservasjon?',
    description: 'Denne handlingen kan ikke angres.',
    actionsList: [
      {
        label: 'Bekreft',
        actionMethod: () => props.confirmReserveCancelClick(),
        actionVariant: 'secondary',
        actionColorVariant: colorVariant,
      },
      {
        label: 'Avbryt',
        actionMethod: () => props.closeModalClick(),
        actionColorVariant: colorVariant,
      },
    ],
    hideIcon: true,
  };

  return (
    <CancelOrderModalWrapper>
      <ModalWrapper isActive={props?.modalStatus}>
        <AlertModal isOpen={props?.modalStatus} onCloseClick={props.closeModalClick} {...cancelReserveOrderModalData} />
      </ModalWrapper>
    </CancelOrderModalWrapper>
  );
};

CancelReserveOrderModal.defaultProps = {
  currentStep: null,
};
