import { Configuration, RedirectRequest, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { appId, loginURL, redirectURL, msGraphApiRoot } from '../../common';

export const msalConfig: Configuration = {
  auth: {
    clientId: appId,
    authority: loginURL,
    redirectUri: redirectURL,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          // case LogLevel.Verbose:
          //   console.debug(message);
          //   return;
          // case LogLevel.Warning:
          //   console.warn(message);
          //   return;
        }
      },
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [`${appId}/.default`],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: `${msGraphApiRoot}/v1.0/me`,
};
