import React from 'react';
import styles from './StartPage.module.scss';
import { Dashboard } from '../../dashboard/dashboard-page';
import { IRootUser } from '../../../common';
import { IConfiguration } from 'next-common';

interface IStartPageProps extends IRootUser {
  configuration: IConfiguration
}

export const StartPage = (props: IStartPageProps) => {
  return (
    <div className={styles.startPage}>
      <Dashboard isAdmin={props.isAdmin} configuration={props.configuration} />
    </div>
  );
};

export default StartPage;
