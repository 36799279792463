import { backgroundCard, bosBrand, lightGray, white, bosColor } from '@next-components/common-styles';
import { Style } from '@react-pdf/types';

export const carInfoPriceMargin: string = '4px';
export const carInfoPriceHeight: string = '22px';

export const HorizontalWarpableListStyled: Style = {
  flexDirection: 'row',
  flexWrap: 'wrap',
};

export const SectionStyled: Style = {
  padding: '10 15',
};

export const NoneSelectedStyled: Style = {
  margin: 5,
  fontSize: 8,
};

export const OptionDataStyled: Style = {
  backgroundColor: backgroundCard,
  flexDirection: 'row',
  marginTop: 5,
};

export const OptionElementStyled: Style = {
  padding: '3 0 0 20',
  backgroundColor: backgroundCard,
  width: '25%',
};

export const OptionElementLeasingStyled: Style = {
  padding: '3 0 0 20',
  backgroundColor: backgroundCard,
  width: '28%',
};

export const OptionElementsStyled: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0 0 6 0',
};

export const LegalStuffStyled: Style = {
  padding: '12 16 12 16',
  backgroundColor: lightGray,
};

export const OptionPriceStyled: Style = {
  padding: '14 16',
  height: '100%',
  color: white,
  width: '25%',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const CarInfoStyle: Style = {
  padding: '10 20',
};

export const CarInfoDetailsStyle: Style = {
  flexDirection: 'column',
  height: '160',
};

export const CarInfoPriceStyle: Style = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '40px',
  marginTop: '8px',
  backgroundColor: bosBrand,
  alignItems: 'center',
};

export const CarInfoImageStyle: Style = { width: '186px', height: '123px', paddingRight: 20, objectFit: 'contain' };

export const CarInfoSpecsStyle: Style = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
  padding: '0 0 16px',
  minHeight: '24px',
  display: 'flex',
  flexWrap: 'wrap',
};

export const topPriceStyles: Style = {
  marginTop: carInfoPriceMargin,
  marginBottom: carInfoPriceMargin,
  marginRight: carInfoPriceMargin,
  marginLeft: '7px',
  fontSize: 10,
};
export const vatPriceStyle: Style = {
  ...topPriceStyles,
  color: '#b1b1b1',
};

export const PriceHeaderStyled: Style = {
  fontSize: 12,
  fontWeight: 800,
  marginTop: '12px',
  marginBottom: '8px',
  paddingLeft: '8px',
  paddingRight: '8px',
};

export const pdfMessageBarWrapper: Style = {
  display: 'flex',
  position: 'relative',
  width: '94%',
  margin: '0 auto',
  marginTop: '10px',
  borderRadius: '4px',
};
export const pdfMessageBarContent: Style = {
  display: 'flex',
  position: 'absolute',
  width: '100%',
  padding: '8px 12px',
  borderRadius: '4px',
  backgroundColor: bosColor.blue20,
  border: `1px solid ${bosColor.blue}`,
};
export const pdfMessageIconStyle: Style = {
  width: '50%',
  position: 'absolute',
  left: '8px',
  top: '6px',
  flexDirection: 'row',
  marginVertical: 2,
  alignItems: 'center',
  padding: '2 5 2 2',
};
export const pdfMessageBarText: Style = {
  color: bosColor.black,
  fontSize: '10px',
  fontWeight: 600,
  lineHeight: '150%',
  marginLeft: '20px',
  flex: '1 0 0',
};
