import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from '@next-components/loading-spinner';
import {
  AccessoryType,
  campaignImporter,
  getAccessoryCalculationPrice,
  getLatestMargin,
  IOrder,
  IVehicleAccessory,
  IVehicleConfiguration,
  marginOnOptionsRetail,
  marginOnSelectablesRetail,
  calcMargineValueWithoutVat,
  calculateTotalPriceWithoutVat,
  getServiceContractForMarginDetails,
  getSCExcludingVatPrice,
  AmountCalcServiceContract,
  IServiceContractMargins,
} from 'next-common';
import { formatNumberInput, InputType } from '@next-components/common-types';
import { InfoBlock } from '../../../sales-common/InfoBlock';
import { MarginDetailsStyled } from './MarginDetails.styled';

interface IMarginDetails {
  order: IOrder;
  vehicleConfig: IVehicleConfiguration;
}
const priceFormatter = (value: number): string | number => {
  if (Number.isNaN(value)) return value;
  return value ? `${formatNumberInput(value?.toString(), InputType.INTEGER)?.formatted} kr` : '0 kr';
};
export const MarginDetails = ({ order, vehicleConfig }: IMarginDetails) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [updatedOrder, setOrder] = useState<IOrder>(order);
  const [parsedAccessories, setParsedAccessories] = useState<IVehicleAccessory[]>([]);
  const serviceContract: IServiceContractMargins = getServiceContractForMarginDetails(order);
  const { isServiceContractSelected } = serviceContract;
  const dealerAmount = getSCExcludingVatPrice(serviceContract, AmountCalcServiceContract.DEALERAMOUNT);
  const serviceContractTotalPrice = getSCExcludingVatPrice(serviceContract, AmountCalcServiceContract.TOTALPRICE);
  const purchasePriceCalculation =
    (order?.pimSnapshot?.purchasePrice || 0) +
    (marginOnOptionsRetail(order?.options) || 0) +
    (marginOnSelectablesRetail(order?.equipments) || 0);
  const latestMargin = getLatestMargin(order?.margins);
  const campaignImporterValue = campaignImporter(latestMargin) || 0;
  const extraDeliveryCharge = order?.pimSnapshot?.extraDeliveryCharge || 0;
  const totalMargin = calcMargineValueWithoutVat(order, latestMargin) - (latestMargin?.discountValue ?? 0);
  const totalSalesFee = calculateTotalPriceWithoutVat(order) - (latestMargin?.discountValue ?? 0);

  useEffect(() => {
    if (order?.accessories?.length > 0) {
      const updateAccessories: IVehicleAccessory[] = order?.accessories?.map((accesory) => ({
        ...accesory,
        ...(accesory?.accessoryType.toString() === AccessoryType[AccessoryType.CUSTOM] && {
          accessoryType: AccessoryType.CUSTOM,
        }),
      }));
      setParsedAccessories(updateAccessories);
      const updateOrder = {
        ...updatedOrder,
        accessories: updateAccessories,
      };
      setOrder(updateOrder);
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [totalMargin]);

  if (loading) return <LoadingSpinner />;
  return (
    <MarginDetailsStyled>
      <InfoBlock key="Salg" label="Salg eks. avgift" value={`+ ${priceFormatter(totalSalesFee) || ''}`} />
      {isServiceContractSelected && (
        <InfoBlock
          key="Serviceavtale"
          label="Serviceavtale"
          value={`- ${priceFormatter(serviceContractTotalPrice) || ''}`}
        />
      )}
      <InfoBlock
        key="Beregning"
        label="Beregning innkjøpspris"
        value={`- ${priceFormatter(purchasePriceCalculation) || ''}`}
      />
      <InfoBlock
        key="Importørstøtte"
        label="Importørstøtte"
        value={`+ ${priceFormatter(campaignImporterValue) || ''}`}
      />
      <InfoBlock
        key="Leveringsomkostninger"
        label="Leveringsomkostninger"
        value={`- ${priceFormatter(extraDeliveryCharge) || ''}`}
      />
      {parsedAccessories.length > 0 &&
        parsedAccessories.map((accessory) => (
          <InfoBlock
            key={accessory?.id}
            label={accessory?.name ?? ''}
            value={`- ${priceFormatter(getAccessoryCalculationPrice(accessory)) || ''}`}
          />
        ))}
      {dealerAmount !== 0 && (
        <InfoBlock key="rabatt" label="Serviceavtale rabatt" value={`- ${priceFormatter(dealerAmount) || ''}`} />
      )}
      <div className="infoContainer">
        <InfoBlock key="brutto" label="Totalmargin brutto" value={priceFormatter(totalMargin) || ''} />
      </div>
    </MarginDetailsStyled>
  );
};
