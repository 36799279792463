import React from 'react';
import { DynamicList, IDynamicListColumn, IDynamicListConfig } from '@next-components/dynamic-list';
import { Icon, IconType } from '@next-components/bos-icon';
import { AgreementsComparisonListStyled } from './AgreementsComparisonList.styled';
import { IContractTypeList, IGetServiceContract } from 'sales-common';

export const getHeaderPriceConfig = (contractTypeList: IContractTypeList[]): IDynamicListColumn[] => {
  let priceConfigHeader = [{ header: 'Beskrivelse', key: 'coveringInfo', colWidth: '1fr', noSort: true }];
  contractTypeList?.map((item) =>
    priceConfigHeader.push({ header: item.name, key: 'header_' + item?.id, colWidth: '6.25rem', noSort: true }),
  );
  return priceConfigHeader;
};

export const CompareIcon = (props: any) => {
  const item = props.serviceContract.contractTypeCoveringList.filter(
    (listItem) =>
      listItem.coveringInfoId === props.coveringListItem.id && listItem.contractTypeId === props.contractTypeId,
  );

  return (
    <div className={`br-100 ${item.length ? 'bg-green' : 'bg-grey'}`}>
      <Icon icon={item.length ? IconType.NewNextCheckFilled : IconType.NewNextCancelSolid} />
    </div>
  );
};

export interface IAgreementComparisionListProps {
  serviceContract?: IGetServiceContract;
}

export const AgreementsComparisonList = (props: IAgreementComparisionListProps) => {
  const dynamicColumns = getHeaderPriceConfig(props?.serviceContract?.contractTypeList)?.slice(1);

  const getAgreementComparisonData = () => {
    let rowDataObj = {};
    return props.serviceContract?.coveringInformationList?.map((listItem) => {
      dynamicColumns?.map((header) => {
        const rowData = (
          <CompareIcon
            serviceContract={props.serviceContract}
            coveringListItem={listItem}
            contractTypeId={+header?.key?.split('_')?.[1]}
          />
        );
        rowDataObj[`${header.key}`] = rowData;
      });
      return {
        ...listItem,
        ...rowDataObj,
      };
    });
  };

  const getComparisonTableConfig = (contractTypeList): IDynamicListConfig => {
    return {
      cols: getHeaderPriceConfig(contractTypeList),
    };
  };

  return (
    <AgreementsComparisonListStyled>
      <DynamicList
        data={getAgreementComparisonData()}
        config={getComparisonTableConfig(props?.serviceContract?.contractTypeList)}
        emptyFormatter={() => <>Servicekontrakter er ikke tilgjengelig for denne modellen</>}
      />
    </AgreementsComparisonListStyled>
  );
};

export default AgreementsComparisonList;
