import { typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const CancelOrderModalWrapper = styled.div`
  .custom-modal {
    width: 33rem;
    border-radius: 0.5rem;

    .close-modal-button {
      display: block;
    }
    .inner-container {
      .content-container {
        .alertModalBodyContainer {
          div {
            h3 {
              font-weight: ${typography.weight.extrabold} !important;
            }
          }
        }
      }
    }
  }
`;
