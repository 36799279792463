import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import React from 'react';
import { IValidationToastNotification } from '../../common';
import { WarningStyled } from './Warning.styled';
import { v4 as uuidv4 } from 'uuid';

export interface ICustomerValidationWarningProps extends IValidationToastNotification {
  fields: string[];
}

export const CustomerValidationWarning = (props: ICustomerValidationWarningProps) => {
  const fields = props.fields;
  return (
    <Messagebar
      severityLevel={MessagebarSeverityLevel.ERROR}
      title="Mangler kundeinformasjon"
      isOpen
      onClose={props?.onClose}>
      <WarningStyled>
        <ul>{fields?.map((name) => <li key={uuidv4()}>{name}</li>)}</ul>
      </WarningStyled>
    </Messagebar>
  );
};
