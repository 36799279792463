import styled from 'styled-components';
import {
  appHeaderHeight,
  backgroundSite,
  black,
  bosColor,
  fontFamilyMuli,
  fontWeightBold,
  fontWeightExtraBold,
  lightGray,
  margin,
  padding,
  typography,
} from '@next-components/common-styles';
import { zIndexes } from '../../../../common';

export const Results = styled.div`
  background-color: ${backgroundSite};
  grid-area: results;
  min-height: calc(100vh - 2 * ${appHeaderHeight});
  overflow-x: hidden;
  overflow-y: auto;
  ${padding['32'].a};
  width: 100%;

  h2 {
    font-weight: ${typography.weight.semibold};
    line-height: 2.5rem;
  }

  .border-bottom {
    border-bottom: 1px solid ${lightGray};
  }
`;

export const ResultGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(16.25rem, 1fr));
  grid-gap: clamp(1.25rem, 2.5vw, 1.25rem);
`;

export const CarConfigureContainerStyled = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;

  div > button {
    ${padding['32'].b};
    span.label {
      font-size: 1.125rem;
      font-weight: ${fontWeightBold};
      color: ${bosColor.black};
    }
  }
  div[open] > button {
    ${padding['16'].b};
    + div {
      ${padding['16'].b};

      > span.label {
        display: block;
        font-size: 1rem;
        font-style: normal;
        font-weight: ${fontWeightExtraBold};
        color: ${bosColor.black};
        line-height: 130%;
      }
    }
  }
`;

export const SwatchGrid = styled.div`
  flex: 1 1 0;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.25rem, 1fr));
  gap: 1rem 2rem;

  div > .cardRightContainer {
    h3.title,
    div.sub-card-header > span.sub-title {
      font-size: 1rem;
    }

    .iconContainerContent {
      margin-top: -0.3rem;
    }
  }
  .checked-icon,
  .lightLockIcon {
    transform: rotate(0deg);
  }
  button.single-swatch {
    width: auto;
    ${padding['0'].a};
    border: none;
    background: transparent;
    position: relative;
    justify-content: center;
    &:disabled {
      figure picture img {
        opacity: 0.25;
      }
      + .child-container {
        p.subtitle,
        p.sku,
        p {
          color: ${bosColor.neutralPlus};
        }
      }
    }
  }
  .swatchContainer {
    > div {
      height: auto;
      place-items: flex-start;
    }
    display: flex;
    flex-direction: column;
    .buttonContainer {
      padding-left: 5.425rem;
      ${margin['8'].t};
      > button {
        ${padding['0'].a};
        justify-content: flex-start;
        gap: 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem;
        color: ${bosColor.turquoise};
        &[disabled] {
          color: ${bosColor.neutralPlus};
          cursor: not-allowed;
        }
      }
      .detailsContainer {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: ${zIndexes.zIndexFixed};
        .content-container {
          p {
            ${padding['8'].b};
            font-weight: 400;
            padding-bottom: 1rem;
            line-height: 150%;
            font-size: 0.875rem;
            a {
              ${margin['0'].l};
              color: ${black};
              text-decoration: underline;
              pointer-events: inherit;
            }
          }
          ul,
          ol {
            ${padding['16'].b};
            li {
              font-size: 1rem;
              font-weight: 400;
              line-height: 150%;
              a {
                ${margin['0'].l};
                color: ${black};
                text-decoration: underline;
                pointer-events: inherit;
              }
            }
          }
        }
      }
      .custom-modal {
        .close-modal-button {
          width: auto;
          ${padding['0'].a};
          position: absolute;
          right: 1rem;
        }
      }
    }
  }
`;
export const CarConfigureGroupStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.25rem, 1fr));
  gap: 1rem 2rem;

  div > .cardRightContainer {
    h3.title,
    div.sub-card-header > span.sub-title {
      font-size: 1rem;
    }

    .iconContainerContent {
      margin-top: -0.3rem;
    }
  }
`;

export const VehicleDescriptionContainer = styled.div`
  z-index: 10001;
`;
