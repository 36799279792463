import styled from 'styled-components';
import { black, bosColor, veryLightGray } from '@next-components/common-styles';

export const SalesWizardMenuWrapper = styled.div`
  align-items: center;
  background-color: ${bosColor.lightGrey};
  border-bottom: 1px solid ${bosColor.neutralGrey};
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 3rem;
  position: sticky;
  text-align: center;
  top: 0;
  white-space: pre-wrap;
  width: 100%;
  height: 4.5rem;
  .btn-grp {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-sign-btn {
    padding: 5px 0;
    display: flex;
    button {
      pointer-events: none;
      height: 2.5rem;
      width: auto;
      padding: 0.5rem 2.5rem;
      color: ${black};
      background-color: ${veryLightGray};
      border: 3px solid ${veryLightGray};
      :hover {
        border: 3px solid ${veryLightGray};
        background-color: ${veryLightGray};
      }
    }
  }
  .nextButton {
    button {
      padding: 0.75rem 1rem !important;
      height: 3.25rem;
    }
  }
  .main-btn {
    padding: 5px 0;
    button {
      height: 3rem;
      width: auto;
      padding: 0.5rem 2.5rem;
      background-color: ${bosColor.turquoise};
      color: ${black};
      .lds-ellipsis {
        height: 2rem;
        top: -6px;
        div {
          background: ${black};
        }
      }
    }
    .next-btn {
      width: inherit;
      padding: 0.5rem 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        background-color: transparent;
      }
    }
  }
  .update-btn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: grid;
    grid-template-columns: max-content;
    margin-right: 1rem;
    button {
      padding: 0.5rem 1.875rem;
      height: 1.875rem;
      background-color: transparent;
      color: ${black};
      height: 2.5rem;
      .lds-ellipsis {
        height: 2rem;
        top: -6px;
        div {
          background: ${black};
        }
      }
    }
  }

  .link-btn {
    display: grid;
    grid-template-columns: max-content;
    margin-right: 1rem;
    cursor: pointer;

    color: ${black};
    .link {
      padding-right: 20px;
    }
    .lds-ellipsis {
      height: 3.3rem;
      top: -6px;
    }
    button {
      height: 2.625rem;
      line-height: 2.625rem;
    }
  }
  .disable-link {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export const StyledSalesWizardMenu = styled.div`
  display: flex;
  gap: 1rem;
  button {
    background: ${bosColor.white};
    &.small {
      height: 2.25rem;
      border-width: 1px;
      padding: 0.5rem 1rem;
      &:disabled {
        border-color: ${bosColor.neutralGrey};
        color: ${bosColor.neutralPlus};
        background: ${bosColor.white};
      }
    }
    &.active {
      background-color: ${bosColor.darkBlue};
      color: ${bosColor.white};
    }
  }
`;
