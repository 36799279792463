import { useEffect, useState } from 'react';
import { ApolloClient } from '@apollo/client';
import { findServiceContract, findServiceContractBySku } from '../../graphql';
import { PageSteps } from '../../components/sales/sales-common/NewHeader';
import { isStockOrder } from '../constants';

export interface IServiceContract {
  sku?: string;
  serialNo?: string;
  activeStep: PageSteps;
  createdOrderType: string;
}

export const useServiceContract = (
  client: ApolloClient<object>,
  { serialNo, sku, activeStep = null , createdOrderType}: IServiceContract,
) => {
  const queryCall = [PageSteps.FINANACE, PageSteps.SIGN];

  const [loading, setLoading] = useState(false);
  const [responseObject, setResponseObject] = useState(null);

  const fetchServiceContract = async () => {
    setLoading(true);
    try {
      if (serialNo) {
        const { data } = await client.query({
          query: findServiceContract,
          variables: {
            serialNo: serialNo,
          },
          context: { clientName: 'sales-common' },
          fetchPolicy: 'network-only',
        });
        setResponseObject(data?.findServiceContract?.responseObject);
      } else if (sku) {
        const { data } = await client.query({
          query: findServiceContractBySku,
          variables: {
            sku: sku,
          },
          context: { clientName: 'sales-common' },
          fetchPolicy: 'network-only',
        });
        setResponseObject(data?.findServiceContractBySku?.responseObject);
      }
    } catch (error) {
      console.error('Error fetching service contract:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (queryCall.includes(activeStep) && !isStockOrder(createdOrderType) ) {
      fetchServiceContract();
    } else {
      return;
    }
  }, [serialNo, sku, activeStep]);

  const response = {
    data: responseObject?.contractTypeList?.length ? responseObject : null,
    loading,
  };

  return response;
};
