import { IServiceContractPriceConfig } from 'next-common';
import { IPriceTableForSalesList } from 'sales-common';

export interface IServiceContractCalculation {
  dealerAmount?: number;
  totalPrice?: number;
  importerDiscount?: number;
  workshopDiscount?: number;
  totalSavings?: number;
  importerDiscountPercentage?: number;
  workshopDiscountPercentage?: number;
  rebatePercentage?: number;
  monthlyPrice?: number;
}

export interface IServiceContractCreationStatus {
  isCreate?: boolean, 
  isUpdate?: boolean
}

export type IServiceContractPrice = IPriceTableForSalesList | IServiceContractPriceConfig;
export interface IServiceContractMargins {
  dealerAmount?: number;
  isLoanPaymentSelected?: boolean;
  totalPrice?: number;
}

/** Returns total price for given service-contract price with loan/leasing contractprice */
export const getTotalPrice = (serviceContractPrice: number, LoanLeasingPrice: number) => {
  if (typeof serviceContractPrice !== 'number' && typeof LoanLeasingPrice !== 'number') {
    parseInt(serviceContractPrice);
    parseInt(LoanLeasingPrice);
  }
  return Math.round(serviceContractPrice) + Math.round(LoanLeasingPrice);
};
