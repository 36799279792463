import React, { useEffect } from 'react';
import { IIconProps, InputField } from '@next-components/input-field';
import { StyledFormRow } from './NewFormRow.styled';
import { formatNumberInput, InputType } from '@next-components/common-types';

interface IFormRow {
  id: string;
  isEditable: boolean;
  isValid?: boolean;
  collapsedWarning?: boolean;
  label: string;
  maxLength?: number;
  placeholder?: string;
  type?: InputType;
  validationMessage?: string;

  startAdornment?: IIconProps | string;
  endAdornment?: IIconProps | string;
  value?: any;
  defaultValue?: any;
  valuePostfix?: string;

  decimals?: 0 | 2 | 1 | 3 | 4;
  onChange?(value): void;
  onSubmit?(value): void;
}

export const getDisplayValue = (props: IFormRow) => {
  return typeof props.value === 'number' || typeof props.defaultValue === 'number'
    ? props.value || props.defaultValue || '0'
    : props.value || props.defaultValue || '';
};

export const FormRow = (props: IFormRow) => {
  const [displayValue, setDisplayValue] = React.useState(getDisplayValue(props));

  useEffect(() => {
    setDisplayValue(getDisplayValue(props));
  }, [props.value, props.defaultValue]);

  return (
    <StyledFormRow>
      <label>{props.label}</label>
      {props.isEditable ? (
        <InputField
          className="input-with-warning"
          isValid={props.isValid}
          defaultValue={props.defaultValue?.toString()}
          value={props.value?.toString()}
          id={props.id}
          maxLength={props.maxLength}
          onChange={props.onChange}
          onSubmit={props.onSubmit}
          withErrorMessage={!props.isValid}
          errorMessage={props.validationMessage}
          startAdornment={props.startAdornment}
          endAdornment={props.endAdornment ? props.endAdornment : ""}
          placeholder={props.placeholder}
          type={props.type || InputType.TEXT_LINE}
          decimals={props.decimals}
        />
      ) : (
        <p className={'smallFont'}>
          {`${
            typeof displayValue === 'number'
              ? formatNumberInput(displayValue.toString(), props.type).formatted
              : displayValue
          }${props.valuePostfix}`}
        </p>
      )}
      <div />
    </StyledFormRow>
  );
};
