import styled from 'styled-components';
import { black, bosColor, fontWeightSemiBold } from '@next-components/common-styles';

const getActiveColor = (opacity = 0.2) => `rgba(126, 114, 242, ${opacity})`;

export const SettingsHeaderStyled = styled.div`
  padding: 10px 20px;
  background-color: ${getActiveColor()};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .user-head-icon {
    width: 20px;
    height: 20px;
    path {
      fill: currentColor !important;
    }
  }
  .profile-header-text {
    margin-right: 20px;
    font-size: 12px;
    font-weight: ${fontWeightSemiBold};
  }
  .profile-header-button {
    .close-button {
      width: 15px;
      height: 100%;
      background-color: transparent;
      color: ${black};
      opacity: 1;
      cursor: pointer;
      svg {
        path {
          fill: ${black};
        }
      }
    }
  }
`;
