import { ERROR_POLICY } from '../../../common';
import { useQuery } from '@apollo/client';
import { queryDetailsInfoSection } from '../../../graphql';
// props to be updated as per API params
export interface IDetailsInfoParams {
  serialNumber: string;
}

export const useDetailsInfo = (props: IDetailsInfoParams) => {
  const { data } = useQuery(queryDetailsInfoSection, {
    variables: {
      serialNo: props.serialNumber,
    },
    skip: !props.serialNumber,
    errorPolicy: ERROR_POLICY,
  });

  return data;
};
