import { defaultProductionDays, ProductionDaysForPeu } from '../common';
import { addDays, parse, format, isValid } from 'date-fns';
import { IDateValidation } from '../components/sales/sales-vehicle-picker-page';

export const formatDate = (value: Date) => {
  let date = new Date(value);
  if (Number.isNaN(date.getTime())) {
    date = new Date();
  }
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  const yyyy = date.getFullYear();
  return String(`${mm >= 10 ? mm : `0${mm}`}-${dd >= 10 ? dd : `0${dd}`}-${yyyy}`);
};

export const defaultProductionDate = () => addDays(new Date(), defaultProductionDays);
export const PeuProductionDate = () => addDays(new Date(), ProductionDaysForPeu);

export const getValidDate = (dateString: string, dateFormat: string = 'dd-MM-yyyy'): Date => {
  const parsedDate = parse(dateString, dateFormat, new Date());
  if (isValid(parsedDate)) {
    return parsedDate;
  }
};

export const getFormattedDate = (date: string, dateFormat = 'dd.MM.yy') => {
  if (!date) {
    return null;
  }
  const splitted = date?.split('-');
  const reFormatted = `${splitted[2]}.${splitted[1]}.${splitted[0]}`
  return format(new Date(reFormatted), dateFormat)
}

const emptyDateValidation = (fromDate: string, toDate: string): IDateValidation => {
  if (!fromDate && !toDate) return {isValid: true, reason: ''};
}

export const validateDateBetweenTwoDates = (
  selectable: string,
  fromDate: string,
  toDate: string,
  expectedProductionDate: string,
): IDateValidation => {
  if (!expectedProductionDate) return null;
  try {
    const validateEmptyDate = emptyDateValidation(fromDate, toDate);
    if (validateEmptyDate?.isValid) return validateEmptyDate;
    const validProdDate = getValidDate(expectedProductionDate, 'MM-dd-yyyy');
    const formattedFromDate = getFormattedDate(fromDate);
    const formattedToDate = getFormattedDate(toDate);
    
    if (fromDate && toDate) {
      const isValid = validProdDate <= getValidDate(toDate) && validProdDate >= getValidDate(fromDate);
      if (!isValid) {
        return { 
          isValid: false, 
          reason: `${selectable} er tilgjengelig fra ${formattedFromDate} til ${formattedToDate}.`};
      }
    } else if (fromDate && !toDate) {
      const isValid = validProdDate >= getValidDate(fromDate);
      if (!isValid) {
        return { isValid: false, reason: `${selectable} er tilgjengelig fra ${formattedFromDate}.`};
      }
    } else if (!fromDate && toDate) {
      const isValid = validProdDate <= getValidDate(toDate);
      if (!isValid) {
        return { isValid: false, reason: `${selectable} er tilgjengelig til ${formattedToDate}.`}
      }
    }
    return {isValid: true, reason: ''};
  } catch(error) {
    return {isValid: false, reason: error['message'], isError: true, selectableName: selectable};
  }
};

export const getDefaultDate = () => format(new Date(), 'yyyy-MM-dd');
