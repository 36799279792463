import styled from 'styled-components';
import { bosColor, disabled, typography } from '@next-components/common-styles';

export const MarginPaneStyled = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 2rem;

  .pane {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;
    flex-wrap: wrap;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${bosColor.neutralGrey};
    background: ${bosColor.white};
  }
  .title > h2 {
    display: inline-flex;
    flex-direction: row;
    color: ${bosColor.black};
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
`;

export const MarginOverviewStyled = styled.div`
  input {
    height: 2.5rem;
  }
  .inputAndIconContainer {
    span {
      padding-right: 3px;
    }
  }
  .dropDownContainer {
    width: 5rem;
    padding-top: 0;
    font-size: 1.125rem;
  }
  .errorMessage {
    padding-top: 5px;
    text-align: left;
  }
  #indicator {
    margin-top: -7px;
  }

  .value {
    display: grid;
    grid-row-gap: 1.5rem;

    div {
      display: grid;
      grid-template-columns: 10em 7em auto;

      .label {
        font-weight: 600;
      }
      .percentage {
        color: ${disabled};
        text-align: right;
      }
      .valueta {
        text-align: right;
      }
    }
  }

  .header {
    margin-bottom: 1.875rem;
    font-size: 1.375rem;
  }
  .sales-margin-buttons {
    margin-top: 2.5rem;
    display: flex;
    grid-column-gap: 1rem;
  }
  .div-width > div > .inputContainer {
    width: 80%;
  }
  .div-width > div > .inputContainer #inputField {
    &:hover {
      border-color: ${bosColor.concrete};
    }
    &:focus {
      border: 3px solid ${bosColor.black};
    }
  }
  #inputDropDown > div {
    min-height: 48px;
    div {
      font-size: 1.125rem;
    }
  }
  .margin-label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block;
    &.top-margin {
      margin-top: 0.5rem;
      span {
        margin-left: 0.5rem;
      }
    }
  }
  .margin-align {
    margin-top: 30px;
    display: block;
  }
  &.marginDetailContainer {
    width: 100%;

    .value {
      div {
        grid-template-columns: 17em auto 5rem;
        .percentage {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          span {
            color: ${bosColor.black};
            font-size: 0.875rem;
            font-style: normal;
            font-weight: ${typography.weight.semibold};
            line-height: 170%;
            padding: 0rem 0.75rem;
            border-radius: 62.4375rem;
            border: 1px solid ${bosColor.komorebi60};
            background: ${bosColor.komorebi20};

            &.valueZero {
              border: 1px solid ${bosColor.neutralPlus};
              background: ${bosColor.neutralGrey};
            }
          }
        }
      }
    }
  }
  .dropDownContainer #inputDropDown div:first-of-type {
    padding-right: 0px;
    grid-template-columns: 1fr;
  }
`;
