import React from 'react';
import { DealerOptionStyled } from './DealerSelect.styled';
import { IDealer } from 'next-common';
import { Icon, IconType } from '@next-components/bos-icon';

export interface IDealerOptionProps {
  dealer: IDealer;
  selected: boolean;

  handleSelect(): void;
}

export const DealerOption = (props: IDealerOptionProps) => (
  <DealerOptionStyled onClick={props.handleSelect} className={props.selected ? 'selected' : ''}>
    <div className="user-body-text">{props.dealer.dealerId}</div>
    <div className="user-body-button">
      {props.selected && (
        <span className="circle-checkbox">
          <Icon icon={IconType.NewNextCheck} viewBox="0 0 32 32" />
        </span>
      )}
    </div>
  </DealerOptionStyled>
);
