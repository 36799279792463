import { gql } from '@apollo/client';

export const allRestrictAccessQuery = gql`
  query allRestrictAccess {
    allRestrictAccess {
      resources
      dealer {
        dealerId
      }
    }
  }
`;
