export enum marginsActionTypes {
  SET_ALL_SYNC_FILTERS = 'SET_ALL_SYNC_FILTERS',
  RESET_ALL_SYNC_FILTERS = 'RESET_ALL_SYNC_FILTERS',

  SET_MONTH_FILTER = 'SET_MONTH_FILTER',
  RESET_MONTH_FILTER = 'RESET_MONTH_FILTER',

  SET_MAKE_FILTER = 'SET_MAKE_FILTER',
  RESET_MAKE_FILTER = 'RESET_MAKE_FILTER',

  SET_MODEL_FILTER = 'SET_MODEL_FILTER',
  RESET_MODEL_FILTER = 'RESET_MODEL_FILTER',

  SET_SERIAL_NUMBER_FILTER = 'SET_SERIAL_NUMBER_FILTER',
  RESET_SERIAL_NUMBER_FILTER = 'RESET_SERIAL_NUMBER_FILTER',

  SET_DEPARTMENT_NUMBER_FILTER = 'SET_DEPARTMENT_NUMBER_FILTER',
  RESET_DEPARTMENT_NUMBER_FILTER = 'RESET_DEPARTMENT_NUMBER_FILTER',

  SET_SALES_PERSON_FILTER = 'SET_SALES_PERSON_FILTER',
  RESET_SALES_PERSON_FILTER = 'RESET_SALES_PERSON_FILTER',

  TOGGLE_HIDE_WITHOUT_SALES_FILTER = 'TOGGLE_HIDE_WITHOUT_SALES_FILTER',
}