import { useEffect } from 'react';
import { IVehiclePageProps } from '../interfaces';
import { IOrder } from 'next-common';
import { useLazyQuery } from '@apollo/client';
import { findOrderByIdConcised } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export const useOrder = (props: IVehiclePageProps): IOrder => {
  const { data } = useOrderQuery(props);
  return data;
};

const useOrderQuery = (props: IVehiclePageProps) => {
  useEffect(() => {
    if (props.orderId) {
      orderData();
    }
  }, [props.orderId, props.pimId]);
  const [orderData, { loading, data }] = useLazyQuery(findOrderByIdConcised, {
    variables: {
      id: props.orderId,
    },
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });

  return { data: data?.findOrderById, loading };
};
