import styled from 'styled-components';
import { bosColor, disabled, margin, padding, typography } from '@next-components/common-styles';

export const MarginAccordion = styled.div`
  color: ${bosColor.black};
  border-top: 1px solid ${bosColor.neutralGrey};
  > div {
    border: none;
    ${padding['24'].x};

    button.btnAccordion {
      ${padding[0].b};
      svg {
        background: ${bosColor.white};
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1.5rem;
      }
    }
    &[open] button.btnAccordion{
      ${padding[16].b};
    }
    .content[open] {
      display: grid;
      gap: 2.5rem;
    }
    .btnGroup {
      display: flex;
      gap: 1.5rem;
      button[disabled] {
        border-radius: var(--corner-radius, 0.5rem);
        background: var(--color-neutrals-nytral-gr, #e3e3e3);
        opacity: 1;
        color: ${bosColor.neutralPlus};
      }
    }
  }

  .discountContainer,
  .marginCartBottomContainer .importerSupportDropdown {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: flex-end;
    .pivotFieldLabel {
      width: 100%;
      ${margin[8].b};
    }
    .errorMessage {
      color: ${bosColor.destructive};
    }
    .message-box-info{
      ${margin[8].t};
    }
    .dropDownContainer {
      position: relative;
    }
    .inputContainer .inputAndIconContainer input {
      min-width: 19.5rem;
    }
  }
  .marginContainer {
    display: grid;
    gap: 1.5rem;
    .marginCartBottomContainer {
      display: grid;
      gap: 1.5rem;

      .valueContainer {
        display: grid;
        grid-row-gap: 1.5rem;

        div {
          display: grid;
          grid-template-columns: 14rem 7rem auto;
          ${padding[8].x};

          .label {
            font-weight: 600;
            font-size: 1rem;
          }

          .percentage {
            //color: ${disabled};
            text-align: right;
          }

          .valueta {
            text-align: right;
          }
        }
        #totalMarginOverview {
          border-bottom: 1px solid ${bosColor.neutralPlus};
          border-top: 1px solid ${bosColor.neutralPlus};
          ${padding[8].x};
          ${padding[16].y};

          .label {
            font-weight: ${typography.weight.bold};
          }
        }
      }
    }
  }
`;
