import React from 'react';
import { EventItemStyled } from './EventItem.styled';
import { EventLevel, IOrderEventItem } from '../orderEventItem';
import { bosColor, disabled } from '@next-components/common-styles';
import { DateTimeBlock } from '../../../sales-common/DateTimeBlock';
import { IconType, Icon } from '@next-components/bos-icon';
import { OrderEventType } from 'next-common';

interface IEventItemProps {
  event: IOrderEventItem;
  isLastEvent: boolean;
}
export interface IEventLevelStyleProps {
  iconColor: bosColor | string;
  eventBackgroundColor: bosColor | string;
  eventBorderColor: bosColor | string;
};

export const EventItem = (props: IEventItemProps) => {
  const { event } = props;

  const doneByIcon = (doneBy: string) => {
    if (!doneBy) return IconType.NewNextGearsSolid;
    if (['system', 'ifs', 'santander'].includes(doneBy?.toLowerCase())) return IconType.DoughnutChart;
    if (['kunde'].includes(doneBy?.toLowerCase())) return IconType.NewNextUserCustomer;
    return IconType.NewNextUser;
  };

  return (
    <EventItemStyled eventLevel={event?.eventLevel ?? EventLevel.Error}>
      <div className="eventTimeline">
        <div className="icon">
          <Icon icon={event?.icon || IconType.TimelineIndicator} />
        </div>
        {!props.isLastEvent && <div className="dashes" />}
      </div>
      <div className={'eventInner'}>
        <div className="eventHeader">
          <div className={'eventDetail'}>
            <div
              className={
                event.eventType === OrderEventType.SMS_SENT_SIGNING && event.code !== '1001' ? 'type-failed' : 'type'
              }>
              {event?.displayName}
            </div>
            <DateTimeBlock dateTime={event?.doneAt} shortDate/>
          </div>

          {event?.note && (
            <div className="note">
              {event?.eventLabel && <label>{event?.eventLabel}</label>}
              {event?.formatter ? event?.formatter(event?.note) : event?.note}
            </div>
          )}
        </div>
        <div className="eventDoneBy">
          <div className="icon">
            <Icon icon={doneByIcon(event?.doneBy)} color={disabled} />
          </div>
          {!event?.doneBy ? 'System' : event?.doneBy}
        </div>
      </div>
    </EventItemStyled>
  );
};
