import React, { useState } from 'react';
import { OrderCategory } from '../orderQuery.utils';
import { Site } from '@next-components/common-types';
import { Accordion } from '@next-components/accordion';
import { RadioButton } from '@next-components/bos-radio-button';
import { IConfiguration } from 'next-common';

interface IRadioButtonGroupType {
  value: OrderCategory;
  label: string;
}

export const categoryOptions: IRadioButtonGroupType[] = [
  {
    label: 'Tilbud',
    value: OrderCategory.OFFER,
  },
  {
    label: 'Ordre',
    value: OrderCategory.ORDER,
  },
  {
    label: 'Utlevert',
    value: OrderCategory.DELIVERED,
  },
  {
    label: 'Annuller',
    value: OrderCategory.CANCEL_ORDER,
  },
];

export interface ICategoryFilterProps {
  configuration?: IConfiguration;
  onChange: (value: OrderCategory) => void;
}

export const CategoryFilter = (props: ICategoryFilterProps) => {
  const [currentActive, setCurrentActive] = useState<string>('OFFER');

  const handleCategory = (value: OrderCategory): void => {
    setCurrentActive(value);
    props?.onChange(value);
  };

  const showAnnullerTab = props?.configuration?.features?.orderCancel?.length < 1;
  const filteredCategory = categoryOptions.filter((category) => !(category.value === OrderCategory.CANCEL_ORDER && showAnnullerTab));

  return (
    <div className="radioButtonGroup">
      <Accordion site={Site.bosno} primaryLabel={'Vis'} className="filterAccordion" isOpen>
        {filteredCategory?.map((button) => (
          <div key={button?.label} className="categoryFilterMenu">
            <RadioButton
              id={button?.label}
              label={button?.label}
              checked={currentActive === button?.value}
              onChange={(): void => handleCategory(button?.value)}
            />
          </div>
        ))}
      </Accordion>
    </div>
  );
};
