import React from 'react';
import { CustomModal } from '@next-components/custom-modal';
import { IOrder, ITradeInVehicle } from 'next-common';
import { ModalState } from '../../HeaderEnum';
import { TradeInForm } from './TradeInForm';
import { TradeInHeaderStyled } from '../../../../sales-wizard/sales-header/SalesHeader.styled';
import { CustomModalWrapper } from '../../../../../layout/CustomModalWrapper.styled';
import { ModalWrapper } from '../../../../../common-components/ModalWrapper';

interface ITradeInModalProps {
  order: IOrder;
  modal: number;
  setModal(ModalState): void;
}

interface ITradeInDialogProps {
  orderId: string;
  tradeIns?: ITradeInVehicle[];
  className?: string;
  isModal?: boolean;
  isEditable?: boolean;
  title?: string;
  onClose?(): void;
}

export const TradeInDialog = (props: ITradeInDialogProps) => {
  const title = props.title
    ? props.title
    : props.isEditable
    ? `${props.tradeIns?.length > 0 ? 'Rediger' : 'Legg til'} innbyttebil`
    : 'Innbyttebil';

  return props.isModal ? (
    <CustomModalWrapper className="backdrop rightModal" active={props.isModal}>
      <ModalWrapper isActive={props?.isModal} className="tradeInDialog">
        <CustomModal active={props.isModal} onCloseClick={props.onClose} isModelCloseAllowedOutside={false}>
          <TradeInHeaderStyled>
            <label className={'header'}>Innbyttebil</label>
            <TradeInForm isEditable={true} tradeIns={props.tradeIns} orderId={props.orderId} onClose={props.onClose} />
          </TradeInHeaderStyled>
        </CustomModal>
      </ModalWrapper>
    </CustomModalWrapper>
  ) : (
    <>
      {title ? <h2 className={'title'}>{title}</h2> : null}
      <TradeInForm isEditable={true} tradeIns={props.tradeIns} orderId={props.orderId} />
    </>
  );
};

export const TradeInModal = (props: ITradeInModalProps) => {
  const order = props.order;
  const tradeIns: ITradeInVehicle[] = order?.tradeInVehicles;
  return (
    <TradeInDialog
      orderId={order?.id}
      isModal={props.modal === ModalState.TRADEIN}
      tradeIns={tradeIns}
      onClose={() => props.setModal(ModalState.NONE)}
    />
  );
};
