import { IBosApiVehicleMarginCalculationDetails } from 'next-common';
import { IDropdownOption } from 'sales-common';
import get from 'lodash/get';
import {
  getMonths,
  getUniqueValues,
  syncFilterMarginsForSales,
  deriveVisibleSelected,
  isDifferent,
} from '../../../../../../../utils';
import { IMarginsSyncFiltersState } from '.';

export enum FieldHierarchy {
  ancestor = 'ancestor',
  focused = 'focused',
  dependent = 'dependent',
}

const selectedFilterFieldsMap = {
  month: 'monthsSelectedFilters',
  make: 'makesSelectedFilters',
  model: 'modelsSelectedFilters',
  serialNo: 'serialNumbersSelectedFilters',
  departmentNo: 'departmentNumbersSelectedFilters',
  salesPerson: 'salesPersonsSelectedFilters',
};

interface IComputeFieldStateArgs<T> {
  field: keyof typeof selectedFilterFieldsMap;
  baseMargins: IBosApiVehicleMarginCalculationDetails[];
  selectedFilters: IDropdownOption<T>[];
  fieldHierarchy: FieldHierarchy;
}
interface IComputedFieldState<T> {
  syncFilteredMarginsTillCurrentField: IBosApiVehicleMarginCalculationDetails[];
  availableOptions: IDropdownOption<T>[];
  visibleSelectedFilters: IDropdownOption<T>[];
  finalSelectedFilters: IDropdownOption<T>[];
}

const computeFieldStates = <T>({
  field,
  baseMargins,
  selectedFilters,
  fieldHierarchy,
}: IComputeFieldStateArgs<T>): IComputedFieldState<T> => {
  let syncFilteredMarginsTillCurrentField = syncFilterMarginsForSales({
    baseMargins,
    [selectedFilterFieldsMap[field]]: selectedFilters,
  });
  let availableOptions: IDropdownOption<T>[] =
    field === 'month' ? getMonths(baseMargins) : getUniqueValues(baseMargins, field);
  let visibleSelectedFilters: IDropdownOption<T>[] = deriveVisibleSelected({
    selected: selectedFilters,
    availableOptions: availableOptions,
    key: 'value',
  });

  if (
    fieldHierarchy === FieldHierarchy.dependent &&
    isDifferent({ arr0: selectedFilters, arr1: visibleSelectedFilters, key: 'value' })
  ) {
    syncFilteredMarginsTillCurrentField = syncFilterMarginsForSales({
      baseMargins,
      [selectedFilterFieldsMap[field]]: visibleSelectedFilters,
    });
    availableOptions =
      field === 'month'
        ? getMonths(syncFilteredMarginsTillCurrentField)
        : getUniqueValues(syncFilteredMarginsTillCurrentField, field);
  }

  return {
    syncFilteredMarginsTillCurrentField,
    availableOptions,
    visibleSelectedFilters,
    finalSelectedFilters: visibleSelectedFilters,
  };
};

interface IComputeReducedStateArgs {
  state: IMarginsSyncFiltersState;
  fetchedMargins: IBosApiVehicleMarginCalculationDetails[];
  hideWithoutSales: boolean;
  monthsSelectedFilters: IDropdownOption<string>[];
  monthFieldHierarchy: FieldHierarchy;
  makesSelectedFilters: IDropdownOption<string>[];
  makesFieldHierarchy: FieldHierarchy;
  modelsSelectedFilters: IDropdownOption<string>[];
  modelsFieldHierarchy: FieldHierarchy;
  serialNumbersSelectedFilters: IDropdownOption<number>[];
  serialNumbersFieldHierarchy: FieldHierarchy;
  departmentNumbersSelectedFilters: IDropdownOption<number>[];
  departmentNumbersFieldHierarchy: FieldHierarchy;
  salesPersonsSelectedFilters: IDropdownOption<string>[];
  salesPersonsFieldHierarchy: FieldHierarchy;
}

export const computeReducedState = ({
  state,
  fetchedMargins,
  hideWithoutSales,
  monthsSelectedFilters,
  monthFieldHierarchy,
  makesSelectedFilters,
  makesFieldHierarchy,
  modelsSelectedFilters,
  modelsFieldHierarchy,
  serialNumbersSelectedFilters,
  serialNumbersFieldHierarchy,
  departmentNumbersSelectedFilters,
  departmentNumbersFieldHierarchy,
  salesPersonsSelectedFilters,
  salesPersonsFieldHierarchy,
}: IComputeReducedStateArgs): IMarginsSyncFiltersState => {
  const {
    syncFilteredMarginsTillCurrentField: syncFilteredMarginsTillMonths,
    availableOptions: monthsAvailableOptions,
    visibleSelectedFilters: monthsVisibleSelectedFilters,
    finalSelectedFilters: monthsFinalSelectedFilters,
  } = computeFieldStates({
    baseMargins: fetchedMargins,
    selectedFilters: monthsSelectedFilters,
    field: 'month',
    fieldHierarchy: monthFieldHierarchy,
  });

  const {
    syncFilteredMarginsTillCurrentField: syncFilteredMarginsTillMakes,
    availableOptions: makesAvailableOptions,
    visibleSelectedFilters: makesVisibleSelectedFilters,
    finalSelectedFilters: makesFinalSelectedFilters,
  } = computeFieldStates({
    baseMargins: syncFilteredMarginsTillMonths,
    selectedFilters: makesSelectedFilters,
    field: 'make',
    fieldHierarchy: makesFieldHierarchy,
  });

  const {
    syncFilteredMarginsTillCurrentField: syncFilteredMarginsTillModels,
    availableOptions: modelsAvailableOptions,
    visibleSelectedFilters: modelsVisibleSelectedFilters,
    finalSelectedFilters: modelsFinalSelectedFilters,
  } = computeFieldStates({
    baseMargins: syncFilteredMarginsTillMakes,
    selectedFilters: modelsSelectedFilters,
    field: 'model',
    fieldHierarchy: modelsFieldHierarchy,
  });

  const {
    syncFilteredMarginsTillCurrentField: syncFilteredMarginsTillSerialNumbers,
    availableOptions: serialNumbersAvailableOptions,
    visibleSelectedFilters: serialNumbersVisibleSelectedFilters,
    finalSelectedFilters: serialNumbersFinalSelectedFilters,
  } = computeFieldStates({
    baseMargins: syncFilteredMarginsTillModels,
    selectedFilters: serialNumbersSelectedFilters,
    field: 'serialNo',
    fieldHierarchy: serialNumbersFieldHierarchy,
  });

  const {
    syncFilteredMarginsTillCurrentField: syncFilteredMarginsTillDepartmentNumbers,
    availableOptions: departmentNumbersAvailableOptions,
    visibleSelectedFilters: departmentNumbersVisibleSelectedFilters,
    finalSelectedFilters: departmentNumbersFinalSelectedFilters,
  } = computeFieldStates({
    baseMargins: syncFilteredMarginsTillSerialNumbers,
    selectedFilters: departmentNumbersSelectedFilters,
    field: 'departmentNo',
    fieldHierarchy: departmentNumbersFieldHierarchy,
  });

  const {
    availableOptions: salesPersonsAvailableOptions,
    visibleSelectedFilters: salesPersonsVisibleSelectedFilters,
    finalSelectedFilters: salesPersonsFinalSelectedFilters,
  } = computeFieldStates({
    baseMargins: syncFilteredMarginsTillDepartmentNumbers,
    selectedFilters: salesPersonsSelectedFilters,
    field: 'salesPerson',
    fieldHierarchy: salesPersonsFieldHierarchy,
  });

  const syncFilteredMargins = syncFilterMarginsForSales({
    baseMargins: fetchedMargins,
    hideWithoutSales,
    monthsSelectedFilters: monthsFinalSelectedFilters,
    makesSelectedFilters: makesFinalSelectedFilters,
    modelsSelectedFilters: modelsFinalSelectedFilters,
    serialNumbersSelectedFilters: serialNumbersFinalSelectedFilters,
    departmentNumbersSelectedFilters: departmentNumbersFinalSelectedFilters,
    salesPersonsSelectedFilters: salesPersonsFinalSelectedFilters,
  });

  return {
    ...state,
    syncFilteredMargins,
    months: {
      ...get(state, 'months'),
      selectedFilters: monthsFinalSelectedFilters,
      availableOptions: monthsAvailableOptions,
      visibleSelectedFilters: monthsVisibleSelectedFilters,
    },
    makes: {
      ...get(state, 'makes'),
      selectedFilters: makesFinalSelectedFilters,
      availableOptions: makesAvailableOptions,
      visibleSelectedFilters: makesVisibleSelectedFilters,
    },
    models: {
      ...get(state, 'models'),
      selectedFilters: modelsFinalSelectedFilters,
      availableOptions: modelsAvailableOptions,
      visibleSelectedFilters: modelsVisibleSelectedFilters,
    },
    serialNumbers: {
      ...get(state, 'serialNumbers'),
      selectedFilters: serialNumbersFinalSelectedFilters,
      availableOptions: serialNumbersAvailableOptions,
      visibleSelectedFilters: serialNumbersVisibleSelectedFilters,
    },
    departmentNumbers: {
      ...get(state, 'departmentNumbers'),
      selectedFilters: departmentNumbersFinalSelectedFilters,
      availableOptions: departmentNumbersAvailableOptions,
      visibleSelectedFilters: departmentNumbersVisibleSelectedFilters,
    },
    salesPersons: {
      ...get(state, 'salesPersons'),
      selectedFilters: salesPersonsFinalSelectedFilters,
      availableOptions: salesPersonsAvailableOptions,
      visibleSelectedFilters: salesPersonsVisibleSelectedFilters,
    },
    hideWithoutSales,
  };
};
