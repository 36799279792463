import React from 'react';
import { white } from '@next-components/common-styles';

export const Logo = () => (
  <svg width="107" height="32" viewBox="0 0 107 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M58.3749 32C57.2475 32 56.162 31.8539 55.1181 31.5616C54.0882 31.2554 53.2323 30.8309 52.5503 30.2881L53.5941 27.9499C55.0277 28.952 56.6282 29.453 58.3958 29.453C59.3422 29.453 60.0659 29.3069 60.5669 29.0146C61.0819 28.7084 61.3394 28.2839 61.3394 27.7412C61.3394 27.2679 61.1028 26.8991 60.6296 26.6347C60.1703 26.3563 59.3561 26.0919 58.187 25.8414C56.8787 25.5769 55.8349 25.2568 55.0555 24.881C54.29 24.4913 53.7263 24.0251 53.3645 23.4823C53.0165 22.9256 52.8426 22.2367 52.8426 21.4155C52.8426 20.5248 53.0861 19.7245 53.5733 19.0147C54.0743 18.3049 54.7702 17.7551 55.6609 17.3654C56.5517 16.9618 57.5816 16.76 58.7507 16.76C59.8084 16.76 60.8244 16.9131 61.7987 17.2193C62.7729 17.5255 63.5523 17.943 64.1369 18.4719L63.093 20.8101C61.7847 19.808 60.3443 19.307 58.7715 19.307C57.9365 19.307 57.2684 19.474 56.7674 19.808C56.2663 20.142 56.0158 20.5944 56.0158 21.165C56.0158 21.499 56.1063 21.7774 56.2872 22.0001C56.4821 22.2227 56.8022 22.4246 57.2475 22.6055C57.6929 22.7725 58.3262 22.9395 59.1473 23.1065C61.0541 23.5241 62.425 24.0669 63.26 24.7349C64.109 25.403 64.5335 26.3146 64.5335 27.4698C64.5335 28.8615 63.9907 29.968 62.9051 30.7891C61.8195 31.5964 60.3095 32 58.3749 32Z"
      fill={white}
    />
    <path
      d="M76.6307 28.5136H69.6788L68.2592 31.8121H64.9815L71.8082 16.9479H74.4387L81.2654 31.8121H78.0504L76.6307 28.5136ZM75.566 26.0293L73.1652 20.4552L70.7644 26.0293H75.566Z"
      fill={white}
    />
    <path d="M82.5344 16.9479H85.7912V29.1608H92.43V31.8121H82.5344V16.9479Z" fill={white} />
    <path
      d="M106.34 23.6076V30.8935C105.616 31.2415 104.767 31.5129 103.793 31.7077C102.833 31.9026 101.845 32 100.829 32C99.2559 32 97.892 31.6938 96.7368 31.0814C95.5816 30.469 94.6909 29.5922 94.0646 28.451C93.4522 27.3097 93.146 25.9458 93.146 24.3591C93.146 22.8142 93.4522 21.4712 94.0646 20.3299C94.6909 19.1887 95.5677 18.3118 96.695 17.6995C97.8363 17.0732 99.1654 16.76 100.682 16.76C101.754 16.76 102.749 16.9061 103.668 17.1984C104.6 17.4907 105.366 17.9013 105.964 18.4301L104.92 20.7683C104.225 20.2673 103.55 19.9054 102.895 19.6827C102.241 19.4601 101.531 19.3487 100.766 19.3487C97.8989 19.3487 96.4654 21.0189 96.4654 24.3591C96.4654 27.769 97.9616 29.4739 100.954 29.4739C101.775 29.4739 102.596 29.3695 103.417 29.1608V25.8831H100.495V23.6076H106.34Z"
      fill={white}
    />
    <path
      d="M36.1414 30.7725C36.8162 31.4472 37.6545 31.7948 38.6359 31.7948C39.6173 31.7948 40.4556 31.4472 41.1507 30.7725C41.8459 30.0978 42.1935 29.2595 42.1935 28.2781C42.1935 27.2967 41.8459 26.4584 41.1507 25.7632C40.4556 25.068 39.6173 24.7205 38.6359 24.7205C37.6545 24.7205 36.8366 25.068 36.1414 25.7632C35.4667 26.4584 35.1191 27.2967 35.1191 28.2781C35.1191 29.2595 35.4667 30.0978 36.1414 30.7725Z"
      fill={white}
    />
    <path
      d="M24.7324 2.67952C26.4704 4.94904 27.4313 7.66836 27.5744 10.449C27.7176 13.4342 26.9202 16.4806 25.08 19.1182C23.6692 21.1219 21.8495 22.6349 19.7845 23.6367C23.9555 21.5512 25.7547 16.4806 23.751 12.2278C21.8087 8.03639 16.922 6.13491 12.6693 7.85238C12.6488 7.85238 12.6284 7.87282 12.6079 7.87282C12.5466 7.89327 12.4852 7.91372 12.4444 7.95461C12.383 7.97506 12.3217 8.01595 12.2399 8.03639C12.1581 8.07728 12.0968 8.11818 12.015 8.13862C9.97038 9.14048 8.13024 10.6535 6.71946 12.6572C4.87931 15.2947 4.08192 18.3412 4.22504 21.3263C4.36816 24.107 5.32913 26.8263 7.06704 29.0958C4.73619 27.5419 2.77337 25.3338 1.48527 22.594C0.217612 19.8542 -0.211755 16.9304 0.094936 14.1293C0.687872 8.7929 3.95924 3.90629 9.17299 1.47321C14.4072 -0.939428 20.2752 -0.285154 24.7324 2.67952Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M8.04869 19.568C10.0115 23.7594 14.8981 25.6609 19.1305 23.9434C19.1509 23.9434 19.1713 23.923 19.1918 23.923C19.2531 23.9025 19.3145 23.8821 19.3554 23.8412C19.4167 23.8207 19.4985 23.7798 19.5598 23.7594C19.6416 23.7185 19.7029 23.6776 19.7847 23.6572C21.8293 22.6553 23.6695 21.1423 25.0803 19.1386C26.9204 16.501 27.7178 13.4546 27.5747 10.4695C27.4316 7.68879 26.4706 4.96946 24.7327 2.69995C27.0635 4.25385 29.0263 6.46203 30.294 9.2018C31.5617 11.9416 31.991 14.8654 31.6843 17.6665C31.0914 23.0029 27.82 27.8895 22.6063 30.3226C17.3925 32.7557 11.5245 32.1014 7.06728 29.1367C5.32936 26.8672 4.3684 24.1479 4.22527 21.3672C4.08215 18.3821 4.87955 15.3356 6.71969 12.6981C8.13047 10.6944 9.95017 9.18135 12.0152 8.1795C7.82378 10.2445 6.04497 15.2947 8.04869 19.568Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="8.45751"
        y1="12.9423"
        x2="26.2809"
        y2="19.4298"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={white} />
        <stop offset="0.4003" stopColor={white} />
        <stop offset="1" stopColor={white} stopOpacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="22.8744"
        y1="18.6718"
        x2="5.49671"
        y2="12.3466"
        gradientUnits="userSpaceOnUse">
        <stop stopColor={white} />
        <stop offset="0.4003" stopColor={white} />
        <stop offset="1" stopColor={white} stopOpacity="0.3" />
      </linearGradient>
    </defs>
  </svg>
);
