import * as React from 'react';
import { Image as PdfImage } from '@react-pdf/renderer';
import { SourceObject, Style } from '@react-pdf/types';

interface IImageOrPlaceholderProps {
  source: SourceObject;
  style: Style;
}

export const ImageOrPlaceholder = (props: IImageOrPlaceholderProps) => {
  return <PdfImage style={props.style} src={props.source} />;
};
