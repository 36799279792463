import styled from 'styled-components';
import { backgroundCard, bosColor, typography } from '@next-components/common-styles';

export const OrderPaneContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  gap: 1.5rem;
`;

export const OrderPaneHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  > div.topWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .messageBarInfo {
    width: 100%;
  }
  h3.title {
    color: ${bosColor.black};
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
`;

export const OrderPaneStyled = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1.5rem 3.5rem;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid ${bosColor.neutralGrey};
  background: ${bosColor.white};

  .title {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      margin: 0 1rem 0 0;
    }

    .status {
      margin-top: -3px;
    }
  }
  .pane {
    &.paneHeader {
      grid-column: 1 / span 3;
      display: flex;
      justify-content: flex-start;
      height: fit-content;
      width: calc(50% - 3.5rem);

      .cartHeader {
        display: grid;
        grid-template-columns: 2fr 2fr;
        gap: 2rem;
        width: 100%;

        p {
          margin: 0;
        }
        .carImage {
          max-height: 8.25rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          img {
            max-width: 100%;
            max-height: 100%;
            height: auto;
          }
        }
        .carBrandDetail {
          .carBrand {
            color: ${bosColor.black};
            font-size: 1.125rem;
            font-weight: ${typography.weight.semibold};
            line-height: 175%;
            letter-spacing: 0.02rem;
            margin: 0;
          }
          .carModel {
            color: ${bosColor.black};
            font-size: 2rem;
            font-weight: ${typography.weight.extrabold};
            line-height: 139%;
            letter-spacing: 0.02rem;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
`;

export const OrderPaneArea = styled.div`
  form {
    height: auto;
    > div {
      margin: 0;
      padding: 0.5rem 0;
      p,
      label {
        margin: 0;
      }
      &.form-row,
      &.duration {
        margin: 0;
      }
    }
  }
  span.tooltip {
    top: 30px;
  }
  h3 {
    font-weight: bold;
  }
`;

export const PaneTitle = styled.div`
  display: flex;
  width: fit-content;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  align-items: center;
  gap: 1rem;

  border-radius: 0.5rem 0.5rem 0rem 0rem;
  background: ${bosColor.lightGrey};

  > span {
    color: ${bosColor.black};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 170%;
  }
`;
