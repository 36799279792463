export const TradeInVehiclesMakeStyle = {
    'DEFAULT': {
        'SRT': {
            'tradInLabel': {
                fontSize: 16, 
                margin: '0 5 5'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 'extrabold'
            }
        },
        'MER': {
            'tradInLabel': {
                fontSize: 16,
                margin: '0 5 5'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12, 
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 'extrabold'
            }
        },
        'KIA': {
            'tradInLabel': {
                fontSize: 16,
                margin: '0 5 5'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 'extrabold'
            }
        },
        'PEU': {
            'tradInLabel': {
                fontSize: 16,
                margin: '0 5 5'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 'extrabold'
            }
        }
    },
    'INDEPENDENT_DEALER': {
        'SRT': {
            'tradInLabel': {
                fontSize: 12,
                fontWeight: 700,
                margin: '0 5 5'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 700
            },
            'optionPriceLabel': {
                fontSize: 8,
                color: '#D7E600'
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 700
            }
        },
        'MER': {
            'tradInLabel': {
                fontSize: 12,
                fontWeight: 700,
                margin: '0 5 5'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 700
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 700
            }
        },
        'KIA': {
            'tradInLabel': {
                fontSize: 12,
                fontWeight: 'bold',
                margin: '0 5 5'
            },
            'optionElementLabel': {
                fontSize: 8
            },
            'optionElementValue': {
                fontSize: 12,
                fontWeight: 'bold'
            },
            'optionPriceLabel': {
                fontSize: 8
            },
            'optionPriceValue': {
                fontSize: 20,
                fontWeight: 'bold'
            }
        },
        'PEU': {
            'tradInLabel': {
                fontSize: 10,
                fontWeight: 700,
                margin: '0 5 5',
                textTransform: 'uppercase'
            },
            'optionElementLabel': {
                fontSize: 7
            },
            'optionElementValue': {
                fontSize: 10,
                fontWeight: 700,
                textTransform: 'uppercase'
            },
            'optionPriceLabel': {
                fontSize: 7
            },
            'optionPriceValue': {
                fontSize: 16,
                fontWeight: 700
            }
        }
    }
}