import { ERROR_POLICY } from '../../../common';
import { useQuery } from '@apollo/client';
import { queryDetailsOwner } from '../../../graphql';
// props to be updated as per API params
export interface IDetailsOwnerParams {
  id: string;
}

export const useDetailsOwner = (props: IDetailsOwnerParams) => {
  const { data } = useQuery(queryDetailsOwner, {
    variables: {
      id: props.id,
    },
    skip: !props.id,
    errorPolicy: ERROR_POLICY,
  });

  return data;
};
