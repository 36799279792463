import { bosColor } from '@next-components/common-styles';
import styled from 'styled-components';

export const ModelYearStyled = styled.div`
  .model-year-container {
    width: 11.25rem;

    .errorMessage:not(.disabled).error {
      span {
        background: none;
        color: ${bosColor.poppy};
      }
    }
  }
`;
