import { IFinancing, IFinancingRequest, IOrder, IVehicleConfiguration, MAKE, ResidualMapper, defaultLeasingInput, defaultLoanInput, getFactoryEquipmentLevelForFactoryOrder, invokeProvidedBrandSpecificCode } from 'next-common';
import { IDropdownOption } from '@next-components/common-types';
import { get, isEmpty } from 'lodash';
import { removeIdentifierFromSKU } from 'sales-common';
import { ICrystallizeMappedFactoryEquipmentLevels } from 'sales-crystallize-common';

/// Duration
export const loanDurationOptions: IDropdownOption<number>[] = [
  { label: '1 år', value: 12 },
  { label: '2 år', value: 24 },
  { label: '3 år', value: 36 },
  { label: '4 år', value: 48 },
  { label: '5 år', value: 60, isSelected: true },
  { label: '6 år', value: 72 },
  { label: '7 år', value: 84 },
  { label: '8 år', value: 96 },
  { label: '9 år', value: 108 },
  { label: '10 år', value: 120 },
];

export const getDurationOptions = (
  rangeValue: number,
  updatedDuration: number,
  isLoan = false,
): IDropdownOption<number>[] => {
  if (isLoan) return markSelectedOption(loanDurationOptions, updatedDuration);
  const options = ResidualMapper?.getLeasingDurationDropdownOptionsByRange(rangeValue);
  const hasSelectedValue = options.some((option) => option.value === updatedDuration);
  const selectedValue = hasSelectedValue
    ? updatedDuration
    : options.find((option) => !!option?.isSelected)?.value || options[0]?.value;
  return markSelectedOption(options, selectedValue);
};

// Range
export const getRangeOptions = (
  durationValue: number,
  updatedRange: number,
): IDropdownOption<number>[] => {
  const options = durationValue ? ResidualMapper?.getLeasingRangeDropdownOptionsByDuration(durationValue) : [];
  const hasSelectedValue = options.some((option) => option.value === updatedRange);
  const selectedValue = hasSelectedValue
    ? updatedRange
    : options.find((option) => !!option?.isSelected)?.value || options[0]?.value;
  const markedOptions = markSelectedOption(options, selectedValue);
  return !isEmpty(markedOptions) ? markedOptions : [];
};

// Common
const markSelectedOption = (options: IDropdownOption<number>[], selectedValue): IDropdownOption<number>[] =>
  options.map((option) => ({
    ...option,
    isSelected: option.value === selectedValue,
  }));

export const getIncVatDownPayment = (financing: IFinancing, downPayment: number) => {
  const incVatDownPayment = downPayment + (downPayment * financing?.vatFactor) / 100;
  return Math.round(incVatDownPayment);
};

export const getCategoryCodePIMFromConfig = (vehicleConfig: IVehicleConfiguration, order?: IOrder) => {
  if(invokeProvidedBrandSpecificCode(MAKE.Kia, {vehicleConfiguration: vehicleConfig})) {
    const factoryEquipmentLevel: ICrystallizeMappedFactoryEquipmentLevels = getFactoryEquipmentLevelForFactoryOrder(
      vehicleConfig,
      order?.equipments,
    );
    return get(factoryEquipmentLevel, 'articleId');
  }
  return removeIdentifierFromSKU(vehicleConfig?.sku, vehicleConfig?.uniqueBrandIdentifier === '' ? null : vehicleConfig?.uniqueBrandIdentifier);
}

//Mapping of loan/leasing response object from DNB API
export const getOriginalDownPayment = (isLoan: boolean, request: IFinancingRequest): number => {
  const defaultDownPayment = isLoan ? defaultLoanInput.downPayment : defaultLeasingInput.downPayment;
  return typeof request?.downPayment === 'number' ? request.downPayment : defaultDownPayment;
};

export const getEstablishmentFee = (financing: IFinancing) => financing?.establishmentFeeInclVat;

export const getAdministrationFee = (financing: IFinancing) => financing?.termFeeInclVat;

export const getMonthlyPrice = (financing: IFinancing) => financing?.monthlyCostInclVatAndFee;

export const getResidualValue = (financing: IFinancing) => financing?.residualValue;

export const getNominalInterest = (financing: IFinancing) => financing?.nominalInterest;

export const getResidualValuePer = (financing: IFinancing) => financing?.residualValuePercentage;

export const getTotalPriceIncVat = (financing: IFinancing) => financing?.totalCostInclVatAndFee;

export const getRequestedNominalInterest = (financing: IFinancing) => financing?.request?.manualNominalInterest;