import {
  FinancingType,
  getCalculationPriceByType,
  ICalculation,
  IFinancingRequest,
  IOrder,
  CustomerType,
  INextArticle,
} from 'next-common';
import { IFormChange } from '../../components/sales/sales-common/FinancingDialog/FinancingForm';
import { prepareArticles } from './vat';
import { VehicleConfig } from './vehicleOrder';
import { ArticleType, VatCode } from 'sales-common';

export const getOrderPrices = (order: IOrder) => ({
  cash: getCalculationPriceByType(order, FinancingType.CASH),
  loan: getCalculationPriceByType(order, FinancingType.LOAN),
  leasing: getCalculationPriceByType(order, FinancingType.LEASING),
});

export const getOriginalNominalInterest = (calculation: ICalculation) => {
  if (!calculation.history || calculation.history.length < 1) return calculation.financing?.nominalInterest ?? 0;
  const original = [...calculation.history].shift();
  return original.nominalInterest;
};

export const updateCalculation = async (
  changes: IFormChange[],
  calculation: ICalculation,
  updateCalculationFinancing,
  customerType: CustomerType,
  orderId: string,
  financingType: FinancingType,
  articles?: INextArticle[],
): Promise<ICalculation> => {
  const input: Partial<IFinancingRequest> = changes.reduce(
    (payload, change) => ({
      ...payload,
      [change.fieldName]: change.value,
      customerType,
      financingType: typeof financingType === 'number' ? FinancingType?.[financingType] : financingType,
    }),
    {},
  );

  const serviceContractArticle = prepareArticles(
    VehicleConfig.SERVICE_CONTRACT,
    Math.round(calculation?.serviceContract?.totalPrice || 0),
    calculation?.serviceContract?.isLoanPaymentIntervalSelected ? VatCode.NON_DEDUCTABLE : VatCode.NOT_APPLICABLE,
    ArticleType.SERVICECONTRACT
  );

  input.articles = [...articles, ...serviceContractArticle];

  const variables = {
    orderId,
    calculationId: calculation?.id,
    input,
  };
  try {
    const { data } = await updateCalculationFinancing({ variables });
    return data.updateCalculationFinancing;
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.error(error);
  }
};
export const isLoan = (calculation: ICalculation) => FinancingType.LOAN === calculation?.financingType;
export const isLeasing = (calculation: ICalculation) => FinancingType.LEASING === calculation?.financingType;
export const isCash = (calculation: ICalculation) => FinancingType.CASH === calculation?.financingType;
