import { gql } from '@apollo/client';
import {
  DetailsActualsSection,
  OverviewDetails,
  DetailsBudgetSection,
  DetailsInfoSection,
  DetailsOwner,
  VendorInvoice,
} from '../types';

export const queryOverviewDetails = gql`
  query getVehicleMarginCalculationBudget($dealerId: String!,$fromInvoiceDate: String!,$toInvoiceDate: String!) {
    getVehicleMarginCalculationBudget(dealerId:$dealerId,fromInvoiceDate:$fromInvoiceDate, toInvoiceDate:$toInvoiceDate) {
        ${OverviewDetails}
    }
  }
`;

export const queryDetailsActualsSection = gql`
  query getVehicleMarginCalculationActuals($serialNumber: String!,$fromVoucherDate: String!, $departmentNumber: String!, $companyId : String!, $toVoucherDate: String!) {
    getVehicleMarginCalculationActuals(serialNumber:$serialNumber,fromVoucherDate: $fromVoucherDate, departmentNumber: $departmentNumber, companyId: $companyId, toVoucherDate: $toVoucherDate) {
        ${DetailsActualsSection}
    }
  }
`;

export const queryDetailsBudgetSection = gql`
  query getVehicleMarginCalculationSales($serialNumber: String!,$orderNo: String!,$dealerId: String!) {
    getVehicleMarginCalculationSales(serialNumber:$serialNumber,orderNo:$orderNo, dealerId:$dealerId) {
        ${DetailsBudgetSection}
    }
  }
`;

export const queryDetailsInfoSection = gql`
  query getVehicleStatusBosEntity($serialNo: String!) {
    getVehicleStatusBosEntity(serialNo:$serialNo) {
        ${DetailsInfoSection}
    }
  }
`;

export const queryDetailsOwner = gql`
  query getOwnerForCustomer($id: String!) {
    getOwnerForCustomer(id:$id) {
        ${DetailsOwner}
    }
  }
`;

export const queryVendorInvoice = gql`
  query getVendorInvoiceForEyeshare($id: String!) {
    getVendorInvoiceForEyeshare(id:$id) {
      ${VendorInvoice}
    }
  }
`;
