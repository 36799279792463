import React from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { AlertModal } from '@next-components/alert-modal';
import { IAlertPopup } from '../../sales/sales-wizard';

interface IDeletePopupProps {
  isOpen: boolean;
  onCloseClick: () => void;
  deleteOrderData: IAlertPopup;
  hideIcon?: boolean;
  className?: string;
}

export const CustomAlertPopup = ({
  isOpen,
  onCloseClick,
  deleteOrderData,
  hideIcon,
  className,
}: IDeletePopupProps) => {
  return (
    <ModalWrapper isActive={isOpen} className={className ?? ''}>
      <AlertModal isOpen={isOpen} onCloseClick={onCloseClick} {...deleteOrderData} hideIcon={hideIcon}></AlertModal>
    </ModalWrapper>
  );
};
