import React from 'react';
import { OverviewHeaderStyled } from './OverviewHeader.styled';
import { SellerFilter } from '../FilterBar/SellerFilter';
import { IFilterBarProps } from '../FilterBar';
import { categoryOptions } from '../FilterBar/CategoryFilter';
import { Button } from '@next-components/button';

interface IOverviewHeader extends Partial<IFilterBarProps> {
  isAdmin: boolean;
  onClick(): void;
}

export const OverviewHeader = (props: IOverviewHeader) => {
  const { isAdmin, onClick } = props;

  const handleSellerChange = (e) => {
    if (props?.onChange) props?.onChange('salesPersonIdent', e.value);
  };

  return (
    <OverviewHeaderStyled>
      <div className="titleSection">
        <span className="newSubHeader">Min oversikt</span>
        <h1 className="newHeader">{categoryOptions?.find((facet) => facet?.value === props?.category)?.label ?? ''}</h1>
      </div>

      <div id="sellerContainer">
        {isAdmin && (
          <div className="addNewOrder">
            <Button onClick={onClick} className="btnNewOrder">
              Start nytt salg
            </Button>
          </div>
        )}
        <SellerFilter
          employee={props.employee}
          dealerIds={props.filter.dealerIds}
          selected={props.filter.salesPersonIdent}
          onChange={handleSellerChange}
        />
      </div>
    </OverviewHeaderStyled>
  );
};
