import { responsive } from '@next-components/common-styles';
import styled from 'styled-components';

export const OverviewBodyWrapper = styled.div`
  display: grid;
  grid-template-columns: 18rem auto;
  gap: 1.5rem;

  ${responsive.mdMax} {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;
