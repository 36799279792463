import { useEffect, useState } from 'react';
import { eventEmitter, Events } from '../../utils';

export const useEnableSkus = () => {
  const [enableSku, setEnableSku] = useState(new Map());

  const eventCall = (skus) => {
    setEnableSku(skus);
  };

  const isEnableSKUonChange = (sku: string) => {
    return enableSku?.has(sku);
  };

  useEffect(() => {
    eventEmitter.subscribe(Events.ENABLE_SKU, eventCall);
    return () => {
      eventEmitter.unsubscribe(Events.ENABLE_SKU);
    };
  }, []);

  return { enableSku, isEnableSKUonChange };
};
