import styled from 'styled-components';
import { white, backgroundCard, black, fontWeightBold } from '@next-components/common-styles';

export const UserSettingsInfo = styled.div`
  position: absolute;
  top: 60px;
  right: 28px;
  z-index: 99;
  .user-profile-popup {
    background-color: ${white};
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    width: 230px;
    height: 400px;
    z-index: 100;
    border-radius: 10px;
    overflow: hidden;
    color: ${black};
    .iconStyled { 
      svg {
        path {
          fill: ${black};
        }
      }
    }
    .user-profile-body {
      padding: 10px 0px;
      flex: auto;
      overflow-y: auto;
      li {
        padding: 12px 20px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        &.selectedchecked {
          background-color: ${backgroundCard};
        }
      }
      .user-body-text {
        font-size: 16px;
        font-weight: ${fontWeightBold};
      }
      .user-body-icon {
        width: 20px;
        height: 20px;
      }
      .user-body-button {
        width: 20px;
        display: flex;
        input[type='checkbox'] {
          width: 20px;
          height: 20px;
        }
        .state {
          min-height: 20px;
          min-width: 20px;
          max-height: 20px;
          max-width: 20px;
          margin-right: 0px;
        }
      }
      .ScrollbarsCustom-Wrapper {
        inset: 10px 10px 10px 0px;
      }
      .ScrollbarsCustom-TrackY {
        max-width: 6px;
      }
    }
  }
`;

export const UserSettingsList = styled.div`
  display: flex;
  flex-direction: column;
`;
